/* eslint-disable react-hooks/exhaustive-deps */
import { SetStateAction, Dispatch, useEffect, useMemo, useState } from "react";
import moment from "moment";
import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormReset,
  UseFormSetError,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from "react-hook-form";

import {
  useFetchBuildingsByResidentId,
  useFetchOneBuildingsByBuildingId,
  useFetchByBuildingAndResidentId,
} from "@bms/hooks";
import { Building, SelectOptionsType, ServiceCard } from "@bms/types";
import dayjs from "dayjs";
import { get, isArray, isEmpty, uniq } from "lodash";

export type ReceivedProps = {
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  reset: UseFormReset<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  trigger: UseFormTrigger<FieldValues>;
  setFilterKey: Dispatch<SetStateAction<string | null>>;
  setError: UseFormSetError<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  onSubmit: () => Promise<void>;
  isSubmitting: boolean;
  errors: FieldErrors<FieldValues>;
  control: Control<FieldValues>;
  isView: boolean;
  serviceCardData: ServiceCard;
  receptionOptions: SelectOptionsType[];
  filterKey: string | null;
  isRedirectFromBuilding: boolean;
};

const useOrderInformation = (props: ReceivedProps) => {
  const {
    getValues,
    reset,
    watch,
    filterKey,
    setFilterKey,
    trigger,
    setValue,
    setError,
    clearErrors,
    errors,
    serviceCardData,
    isSubmitting,
    isRedirectFromBuilding,
  } = props;

  const [buildingOptions, setBuildingOptions] = useState<Building[]>([]);
  const [roomNumberOptions, setRoomNumberOptions] = useState<string[]>([]);
  const [filterKeySearch, setFilterKeySearch] = useState<any>(null);

  const isResidentId = !!watch("resident_id");
  const isBuildingId = !!watch("building_id");
  const isResidentRootId = !!watch("resident_root_id");
  const buildingDataChange = watch("building_data");
  // 39083

  // only get data of building_id
  const {
    data: buildingDataByBuildingId,
    isSuccess: isBuildingSuccess,
    isFetching: isBuildingLoading,
  } = useFetchOneBuildingsByBuildingId(
    watch("building_id"),
    // watch("resident_id"),
    isBuildingId && filterKeySearch === "building_id"
  );

  // only get data of resident_id
  const {
    data: buildingDataByResidentId,
    isSuccess: isResidentSuccess,
    isFetching: isResidentLoading,
  } = useFetchBuildingsByResidentId(
    watch("resident_id"),
    watch("building_id"),
    isResidentId && filterKeySearch === "resident_id"
  );

  const {
    data: dataBuildingAndResidentId,
    isSuccess: isResidentBuildingSuccess,
    isFetching: isResidentBuildingLoading,
  } = useFetchByBuildingAndResidentId(
    watch("resident_id"),
    watch("building_id"),
    watch("resident_root_id"),
    (isRedirectFromBuilding &&
      filterKey === "both" &&
      filterKeySearch === null) ||
      (isResidentId && isBuildingId && filterKeySearch === "both")
  );

  const handleCheck = (checkedId: string, name: string) => {
    const { support_type, address_payment_type } = getValues();
    const ids = name === "support_type" ? support_type : address_payment_type;
    const newIds = ids?.includes(checkedId) ? [] : [checkedId];
    return newIds;
  };

  const isEditField = useMemo(() => {
    return isEmpty(watch("address_payment_type"))
      ? !isEmpty(watch("address_payment_type"))
      : ![
          "contractor",
          "tenant",
          "owner",
          "general",
          "nomura_development",
        ].includes(watch("address_payment_type")?.[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("address_payment_type")]);

  let buildingData = useMemo(() => {
    return buildingDataByBuildingId;
  }, [buildingDataByBuildingId]);

  const buildingAndResidentData = useMemo(() => {
    return dataBuildingAndResidentId;
  }, [dataBuildingAndResidentId]);

  const isSuccess = useMemo(() => {
    return isBuildingSuccess || isResidentSuccess || isResidentBuildingSuccess;
  }, [isBuildingSuccess, isResidentSuccess, isResidentBuildingSuccess]);

  const isLoading = useMemo(() => {
    return isBuildingLoading || isResidentLoading || isResidentBuildingLoading;
  }, [isBuildingLoading, isResidentLoading, isResidentBuildingLoading]);

  const handleErrors = () => {
    Object.keys(errors).forEach((errorKey) => {
      const getError: any = get(errors, `${errorKey}`);

      if (getError) {
        if (errorKey === "work_report_attributes" && isArray(getError)) {
          getError.forEach((item: any, index: number) => {
            setError(`work_report_attributes.${index}.title`, {
              type: item?.title?.type,
              message: item?.title?.message,
            });
          });
        } else {
          setError(errorKey, {
            type: get(getError, "type"),
            message: get(getError, "message", ""),
          });
        }
      }
    });
  };

  useEffect(() => {
    if (
      isBuildingId &&
      buildingData &&
      watch("building_id") &&
      filterKeySearch === "building_id"
    ) {
      const buildingAddress = [
        buildingData?.building_prefecture_name,
        buildingData?.building_municipality_name,
        buildingData?.building_town_name,
        buildingData?.building_district,
        buildingData?.building_street_address,
      ].join("");

      trigger("report_printing");

      reset({
        ...getValues(),
        report_printing: serviceCardData?.order_information?.report_printing,
        building_id: buildingData?.building_id || "",
        resident_id: "",
        building_name:
          buildingDataChange?.building_name || buildingData?.building_name,
        room_number: "",
        resident_name: "",
        resident_contact: "",
        building_tentative_name:
          buildingDataChange?.building_tentative_name ||
          buildingData?.building_tentative_name,
        move_in_date: "",
        tenant_contact: "",
        building_address:
          buildingDataChange?.building_address
            ?.replaceAll(/,/g, "")
            ?.replaceAll(" ", "") || buildingAddress,
        building_management_number:
          buildingDataChange?.building_management_number ||
          buildingData?.management_number,
        owner_name: buildingDataChange?.owner_name || buildingData?.owner?.name,
        owner_id:
          buildingDataChange?.owner_id || buildingData?.owner?.owner_data_id,
        contractor_name: "",
        contractor_data_id: "",
        owner_manager_number:
          buildingDataChange?.owner_manager_number ||
          buildingData?.owner?.management_number,
        contractor_address:
          buildingDataChange?.contractor_address ||
          buildingData?.contractor?.address,
        resident_address:
          buildingDataChange?.resident_address ||
          buildingData?.resident?.address,
        owner_address:
          buildingDataChange?.owner_address || buildingData?.owner?.address,
        order_date: getValues("order_date")
          ? dayjs(getValues("order_date"))
          : null,
      });
    }
    handleErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    buildingData,
    getValues,
    isBuildingId,
    isResidentId,
    serviceCardData?.order_information?.report_printing,
    reset,
    watch,
  ]);

  const resetDataField = (dataExist: any) => {
    let buildingAddress = "";

    buildingAddress = [
      dataExist?.building_prefecture_name,
      dataExist?.building_municipality_name,
      dataExist?.building_town_name,
      dataExist?.building_district,
      dataExist?.building_street_address,
    ].join("");

    reset({
      ...getValues(),
      report_printing: serviceCardData?.order_information?.report_printing,
      building_id: dataExist?.building_id || dataExist?.building_data_id || "",
      resident_id:
        dataExist?.resident?.resident_data_id ||
        dataExist?.resident_data_id ||
        "",
      building_name: dataExist?.building_name || "",
      room_number:
        dataExist?.resident?.room_number || dataExist?.room_number || "",
      resident_name:
        dataExist?.resident?.name || dataExist?.resident_name || "",
      resident_contact:
        dataExist?.resident?.tel || dataExist?.resident_contact || "",
      building_tentative_name: dataExist?.building_tentative_name,
      move_in_date: dataExist?.contractor?.move_in_date
        ? moment(dataExist?.contractor?.move_in_date).format("YYYY-MM-DD")
        : dataExist?.move_in_date
        ? moment(dataExist?.move_in_date).format("YYYY-MM-DD")
        : "",
      tenant_contact:
        dataExist?.contractor?.mobile_phone || dataExist?.tenant_contact || "",
      building_address:
        dataExist?.building_address
          ?.replaceAll(/,/g, "")
          ?.replaceAll(" ", "") || buildingAddress,
      building_management_number:
        dataExist?.management_number ||
        dataExist?.building_management_number ||
        "",
      owner_name: dataExist?.owner?.name || dataExist?.owner_name || "",
      owner_id: dataExist?.owner?.owner_data_id || dataExist?.owner_id || "",
      contractor_name:
        dataExist?.contractor?.name || dataExist?.contractor_name || "",
      contractor_data_id:
        dataExist?.contractor?.contractor_data_id ||
        dataExist?.contractor_data_id ||
        "",
      owner_manager_number:
        dataExist?.owner?.management_number ||
        dataExist?.owner_manager_number ||
        "",
      contractor_address:
        dataExist?.contractor?.address || dataExist?.contractor_address || "",
      resident_address:
        dataExist?.resident?.address || dataExist?.resident_address || "",
      owner_address:
        dataExist?.owner?.address || dataExist?.owner_address || "",
      order_date: getValues("order_date")
        ? dayjs(getValues("order_date"))
        : null,
    });
  };

  useEffect(() => {
    let buildingAddress = "";
    if (
      isSuccess &&
      buildingAndResidentData !== undefined &&
      (buildingAndResidentData.length > 0 ||
        buildingAndResidentData?.building_id !== undefined) &&
      (filterKey === "both" || filterKeySearch === "both")
    ) {
      resetDataField(buildingAndResidentData);
    }

    if (serviceCardData && filterKeySearch === null) {
      resetDataField(serviceCardData?.order_information?.building_data);
    }

    if (
      !buildingData &&
      buildingDataByResidentId &&
      buildingDataByResidentId.length > 0
    ) {
      buildingDataByResidentId?.find((item: any) => {
        if (item.building_id === getValues("building_id")) {
          buildingData = item;
        }
      });
    }

    if (isSuccess && buildingData && filterKeySearch !== "both") {
      buildingAddress = [
        buildingData?.building_prefecture_name,
        buildingData?.building_municipality_name,
        buildingData?.building_town_name,
        buildingData?.building_district,
        buildingData?.building_street_address,
      ].join("");

      if (isBuildingId && filterKeySearch === "building_id") {
        reset({
          ...getValues(),
          report_printing: serviceCardData?.order_information?.report_printing,
          building_id: buildingData?.building_id || "",
          resident_id: "",
          building_name:
            buildingDataChange?.building_name || buildingData?.building_name,
          room_number: "",
          resident_name: "",
          resident_contact: "",
          building_tentative_name:
            buildingDataChange?.building_tentative_name ||
            buildingData?.building_tentative_name,
          move_in_date: "",
          tenant_contact: "",
          building_address:
            buildingDataChange?.building_address
              ?.replaceAll(/,/g, "")
              ?.replaceAll(" ", "") || buildingAddress,
          building_management_number:
            buildingDataChange?.building_management_number ||
            buildingData?.management_number,
          owner_name:
            buildingDataChange?.owner_name || buildingData?.owner?.name,
          owner_id:
            buildingDataChange?.owner_id || buildingData?.owner?.owner_data_id,
          contractor_name: "",
          contractor_data_id: "",
          owner_manager_number:
            buildingDataChange?.owner_manager_number ||
            buildingData?.owner?.management_number,
          contractor_address:
            buildingDataChange?.contractor_address ||
            buildingData?.contractor?.address,
          resident_address:
            buildingDataChange?.resident_address ||
            buildingData?.resident?.address,
          owner_address:
            buildingDataChange?.owner_address || buildingData?.owner?.address,
          order_date: getValues("order_date")
            ? dayjs(getValues("order_date"))
            : null,
        });
      }

      if (isResidentId && filterKeySearch === "resident_id") {
        reset({
          ...getValues(),
          report_printing: serviceCardData?.order_information?.report_printing,
          building_id: buildingData?.building_id || "",
          resident_id: buildingData?.resident?.resident_data_id || "",
          building_name:
            buildingDataChange?.building_name || buildingData?.building_name,
          room_number: !isEmpty(roomNumberOptions)
            ? getValues("room_number")
            : buildingDataChange?.room_number ||
              buildingData?.resident?.room_number,
          resident_name:
            buildingDataChange?.resident_name || buildingData?.resident?.name,
          resident_contact:
            buildingDataChange?.resident_contact || buildingData?.resident?.tel,
          building_tentative_name:
            buildingDataChange?.building_tentative_name ||
            buildingData?.building_tentative_name,
          move_in_date: buildingDataChange?.move_in_date
            ? buildingDataChange?.move_in_date
            : buildingData?.contractor?.move_in_date
            ? moment(buildingData?.contractor?.move_in_date).format(
                "YYYY-MM-DD"
              )
            : "",
          tenant_contact: buildingData?.contractor?.mobile_phone,
          building_address:
            buildingDataChange?.building_address
              ?.replaceAll(/,/g, "")
              ?.replaceAll(" ", "") || buildingAddress,
          building_management_number:
            buildingDataChange?.building_management_number ||
            buildingData?.management_number,
          owner_name:
            buildingDataChange?.owner_name || buildingData?.owner?.name,
          owner_id:
            buildingDataChange?.owner_id || buildingData?.owner?.owner_data_id,
          contractor_name:
            buildingDataChange?.contractor_name ||
            buildingData?.contractor?.name,
          contractor_data_id:
            buildingDataChange?.contractor_data_id ||
            buildingData?.contractor?.id,
          owner_manager_number:
            buildingDataChange?.owner_manager_number ||
            buildingData?.owner?.management_number,
          contractor_address:
            buildingDataChange?.contractor_address ||
            buildingData?.contractor?.address,
          resident_address:
            buildingDataChange?.resident_address ||
            buildingData?.resident?.address,
          owner_address:
            buildingDataChange?.owner_address || buildingData?.owner?.address,
          order_date: getValues("order_date")
            ? dayjs(getValues("order_date"))
            : null,
        });
      }
    }
    handleErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    buildingData,
    buildingAndResidentData,
    buildingDataChange?.building_name,
    buildingDataChange?.building_tentative_name,
    buildingDataChange?.building_management_number,
    buildingDataChange?.contractor_address,
    buildingDataChange?.contractor_data_id,
    buildingDataChange?.contractor_name,
    buildingDataChange?.move_in_date,
    buildingDataChange?.owner_address,
    buildingDataChange?.owner_id,
    buildingDataChange?.owner_manager_number,
    buildingDataChange?.owner_name,
    buildingDataChange?.resident_address,
    buildingDataChange?.resident_contact,
    buildingDataChange?.resident_name,
    buildingDataChange?.room_number,
    serviceCardData?.order_information?.report_printing,
    filterKey,
    getValues,
    isBuildingId,
    isResidentId,
    isSuccess,
    reset,
    roomNumberOptions,
  ]);

  useEffect(() => {
    const value = watch("move_in_date");
    const isValid = dayjs(value, "YYYY-MM-DD", true).isValid();

    if (watch("move_in_date") && isValid) {
      clearErrors("move_in_date");
    }
  }, [clearErrors, setError, watch("move_in_date")]);

  useEffect(() => {
    if (
      isResidentSuccess &&
      buildingDataByResidentId &&
      buildingDataByResidentId.length > 0 &&
      buildingDataByResidentId !== undefined
    ) {
      const building_id_search = getValues("building_id");
      let building_options: any = [];

      if (building_id_search !== undefined && building_id_search) {
        buildingDataByResidentId?.map((item: any) => {
          if (item.building_id === building_id_search) {
            building_options.push(item);
          }
        });

        building_options =
          building_options && building_options.length > 0
            ? building_options
            : [];
      } else {
        building_options = buildingDataByResidentId;
      }

      setBuildingOptions(buildingDataByResidentId);
      setRoomNumberOptions(
        uniq(building_options?.map((item: any) => item?.resident?.room_number))
      );

      trigger("room_number");
    } else {
      setBuildingOptions([]);
      setRoomNumberOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    buildingDataByResidentId,
    buildingAndResidentData,
    isResidentBuildingSuccess,
    isResidentSuccess,
    filterKeySearch,
  ]);

  useEffect(() => {
    setValue(
      "report_printing",
      props?.serviceCardData?.order_information?.report_printing
    );
    trigger("report_printing");
  }, [
    props?.serviceCardData?.order_information?.report_printing,
    setValue,
    trigger,
  ]);

  return {
    ...props,
    isLoading,
    buildingData,
    buildingOptions,
    isEditField,
    filterKey,
    roomNumberOptions,
    setFilterKey,
    setFilterKeySearch,
    handleCheck,
  };
};

export type Props = ReturnType<typeof useOrderInformation>;

export default useOrderInformation;
