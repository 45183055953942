import { useQuery } from "@tanstack/react-query";
import { QUERY_NAME, SERVICES } from "@bms/constants";
import axios from "@bms/libs/axios";
import { Permissions } from "@bms/types";

export const useFetchPermissions = () => {
  return useQuery(
    [QUERY_NAME.PERMISSIONS],
    () =>
      axios({
        method: "GET",
        url: SERVICES.PERMISSIONS,
      }).then((res) => (res.data as Permissions[]) || []),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
};
