import { Dispatch, SetStateAction, useEffect, useState } from "react";
import moment from "moment";
import { ShiftSchedules } from "@bms/types";

export type ReceivedProps = {
  setOpenScheduleDialog: Dispatch<SetStateAction<boolean>>;
  setCurrentDate: Dispatch<SetStateAction<Date>>;
  setCurrentDay: Dispatch<SetStateAction<any>>;
  holidaysData: any[];
  scheduleList: {
    data: ShiftSchedules[];
  };
  currentDate: Date;
  openScheduleFormDialog: boolean;
  specificHolidays: any;
};

const useSchedulerBox = (props: ReceivedProps) => {
  const [localScheduleDate, setLocalScheduleData] = useState<any[]>();

  const deleteSchedule = async () => {};

  useEffect(() => {
    let counting = 0;
    let prevItemDate: any = null;

    let schedulerDataForMonth: any = [];

    props.scheduleList.data.forEach((item) => {
      const currentItemDate = moment(item.startDate).format("YYYY-MM-DD");
      const startHours = item?.start_working_hour || "-";
      const endHours = item?.end_working_hour || "-";
      if (currentItemDate === prevItemDate && counting < 3) {
        counting += 1;

        schedulerDataForMonth = [
          ...schedulerDataForMonth,
          {
            ...item,
            name: item?.worker?.name || "",
            title: `${startHours}-${endHours}/${item?.worker?.name}`,
          },
        ];
      } else if (currentItemDate === prevItemDate && counting === 3) {
        counting += 1;

        schedulerDataForMonth = [
          ...schedulerDataForMonth,
          {
            ...item,
            name: item?.worker?.name || "",
            title: `他の${
              props.scheduleList.data.filter(
                (subItem) => subItem.date_on === item.date_on
              ).length - 4
            }件`,
            showWeekView: true,
          },
        ];
      } else if (currentItemDate !== prevItemDate) {
        counting = 0;
        prevItemDate = moment(item.startDate).format("YYYY-MM-DD");
        schedulerDataForMonth = [
          ...schedulerDataForMonth,
          {
            ...item,
            name: item?.worker?.name || "",
            title: `${startHours}-${endHours}/${item?.worker?.name}`,
          },
        ];
      }
    });

    setLocalScheduleData(schedulerDataForMonth);
  }, [props.scheduleList.data]);

  return {
    ...props,
    localScheduleDate,
    deleteSchedule,
    setLocalScheduleData,
  };
};

export type Props = ReturnType<typeof useSchedulerBox>;

export default useSchedulerBox;
