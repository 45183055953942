import { forwardRef, useRef } from "react";
import { get, isEmpty } from "lodash";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import { Permissions } from "@bms/types";
import { formatTel } from "@bms/utility";
import {
  PERMISSION_OPTIONS,
  ROLE,
  ROLE_OPTIONS,
  WORKER_STATUS,
} from "@bms/constants";
import { ColDef } from "ag-grid-community";

// eslint-disable-next-line react/display-name
export default forwardRef((props: any) => {
  const inputRef: any = useRef<HTMLInputElement>(null);
  const { column } = props;
  const options = () => {
    switch (column.colId) {
      case "role":
        return ROLE_OPTIONS;
      case "status":
        return [
          // {
          //   value: "clear",
          //   label: "すべて",
          // },
          {
            label: WORKER_STATUS["long_holiday"],
            value: "long_holiday",
          },
          {
            label: WORKER_STATUS["retired"],
            value: "retired",
          },
          {
            label: WORKER_STATUS["day_off"],
            value: "day_off",
          },
        ];
      case "permissions":
        return PERMISSION_OPTIONS;

      default:
        return [];
    }
  };

  const onBoxChanged = (input: SelectChangeEvent) => {
    if (input.target.value === "") {
      props.parentFilterInstance((instance: any) => {
        instance.onFloatingFilterChanged(null, null);
      });
      return;
    }

    props.parentFilterInstance((instance: any) => {
      instance.onFloatingFilterChanged("greaterThan", input.target.value);
    });
  };

  return (
    <div className="floatingFilter-select">
      <Select
        placeholder="検索"
        variant="standard"
        ref={inputRef}
        onChange={onBoxChanged}
        defaultValue="絞り込み"
      >
        <MenuItem value="絞り込み" style={{ display: "none" }}>
          <span style={{ color: "#b9b9b9" }}>絞り込み</span>
        </MenuItem>
        {options().map((item: any) => (
          <MenuItem
            value={item.value}
            key={item.value}
            disabled={item.disabled}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
});

export const columns: ColDef[] = [
  {
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true,
    maxWidth: 40,
    floatingFilter: false,
  },
  {
    headerName: "ID",
    field: "user_id",
    maxWidth: 120,
    minWidth: 80,
    floatingFilter: false,
    flex: 1,
    cellStyle: {
      display: "inline-block",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whitespace: "nowrap",
    },
  },
  {
    headerName: "名前",
    field: "name",
    maxWidth: 150,
    minWidth: 150,
  },
  {
    headerName: "役割",
    field: "role",
    maxWidth: 100,
    minWidth: 100,
    cellRenderer: (e: any) => <>{get(ROLE, e.value, "")}</>,
  },
  {
    headerName: "電話番号",
    field: "phone_number",
    minWidth: 160,
    cellRenderer: (e: any) => <>{formatTel(e.value)}</>,
  },
  {
    headerName: "ログインパスワード",
    field: "login_pass",
    minWidth: 160,
  },
  // {
  //   headerName: "権限",
  //   field: "permissions",
  //   minWidth: 340,
  //   cellRenderer: (e: any) => (
  //     <div className="group-button">
  //       {!isEmpty(e.value)
  //         ? (e.value.slice(0, 3) as Permissions[]).map((item) => (
  //             <button key={item.id}>{item.access_type_ja}</button>
  //           ))
  //         : ""}
  //     </div>
  //   ),
  // },
  {
    headerName: "退社",
    field: "status",
    maxWidth: 100,
    minWidth: 100,
    cellClass: "flex",
    cellRenderer: (e: any) => {
      const isDayOff = e.value === "day_off";
      const isRetired = e.value === "retired";
      const longHoliday = e.value === "long_holiday";

      return (
        <div
          className={`tag ${
            isRetired
              ? "bg-red"
              : isDayOff
              ? "bg-green"
              : longHoliday
              ? "bg-blue"
              : ""
          }`}
        >
          {isDayOff
            ? WORKER_STATUS["day_off"]
            : isRetired
            ? WORKER_STATUS["retired"]
            : longHoliday
            ? WORKER_STATUS["long_holiday"]
            : ""}
        </div>
      );
    },
  },
];

export const SORT_PARAMS = {
  number_id: "sort_number_id",
  name: "sort_name",
  phone_number: "sort_phone_number",
  login_pass: "sort_login_pass",
  status: "sort_status",
};
