import styled from "styled-components";

export const Styles = styled.div`
  .flex {
    display: flex;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22.5px;

    h3 {
      color: var(--main-color);
      font-size: 28px;
      font-family: "Inter";
    }

    button {
      width: 159px;
      background-color: var(--main-color);
      color: #ffffff;
      padding: 6.5px 20px;
      border: none;
      border-radius: 64px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      font-family: "Inter";

      p {
        font-size: 16px;
        font-weight: 600;
        padding-left: 10px;
      }

      > svg {
        margin-left: 10px;
      }
    }
  }

  .table-wrapper {
    .btn-group {
      gap: 2px;

      .btn-clear {
        border: 1px solid transparent;
        margin-right: 0px;
        border: none;
        background-color: #ffffff;
        font-family: "Inter";
        font-weight: 400;
        font-size: 16px;
        color: #000000;
        padding: 5px 15px;

        &.btn-active {
          background-color: var(--sub-color);
          border: 1px solid var(--sub-color);
          color: #ffffff;
          border-radius: 64px;
        }
      }

      .btn-outline {
        border: 1px solid transparent;
        background-color: #ffffff;
        font-family: "Inter";
        font-weight: 400;
        font-size: 16px;
        color: #000000;
        padding: 5px 15px;

        &.btn-active {
          background-color: var(--sub-color);
          border: 1px solid var(--sub-color);
          color: #ffffff;
          border-radius: 64px;
        }
      }
    }

    .ag-header-viewport {
      border-top: 1px solid #bcbcbc;
    }

    .ag-root-wrapper {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    .ag-cell {
      // display: flex;
    }

    .tag {
      width: 66px;
      height: 31px;
      line-height: 20px;
      padding: 5px 0px;
      text-align: center;
      color: #ffffff;
      font-family: "Inter";
      font-size: 15px;

      &.bg-red {
        background-color: #ff0000;
      }

      &.bg-green {
        background-color: #4e7c3d;
      }

      &.bg-blue {
        background-color: #2d31aa;
      }
    }

    .group-button {
      column-gap: 8px;
      row-gap: 2px;
      height: 100%;
      display: flex;
      align-items: center;

      button {
        background-color: var(--sub-color);
        border: none;
        height: 31px;
        display: flex;
        text-align: center;
        align-items: center;
        color: #ffffff;
        padding: 0 10px;
        font-family: "Inter";
        font-weight: 400;
        font-size: 12px;
        border-radius: 64px;

        &:nth-child(1) {
          padding: 0 9px;
        }

        &:nth-child(2) {
          padding: 0 19px 0 23px;
        }

        &:nth-child(3) {
          padding: 0 27px;
        }
      }
    }
  }

  .ag-row {
    .cell-disabled {
      color: #c1c1c1;

      button {
        background-color: #e0e0e0;
      }
    }
  }

  .ag-ltr {
    .ag-cell {
      &:last-child {
        display: flex;
      }
    }
  }
`;
