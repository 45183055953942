import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QUERY_NAME, SERVICES, SORTING } from "@bms/constants";
import axios from "@bms/libs/axios";

export const useFetchBuildings = (
  page: number,
  pageSize: number,
  filterParams?: any,
  sortParams?: any
) => {
  for (const obj in sortParams) {
    if (sortParams[obj] === SORTING.ASC || sortParams[obj] === SORTING.DESC) {
      delete sortParams[obj];
    }
  }

  return useQuery(
    [QUERY_NAME.BUILDINGS, page, pageSize, filterParams, sortParams],
    () =>
      axios({
        method: "GET",
        url: SERVICES.BUILDINGS,
        params: {
          page: page,
          per_page: pageSize,
          ...filterParams,
          ...sortParams,
        },
      }).then((res) => res.data || []),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
};

export const useFetchByBuildingAndResidentId = (
  residentId: string | undefined,
  buildingId: string | undefined,
  residentRootId: string | undefined,
  type: boolean
) => {
  return useQuery(
    [QUERY_NAME.BUILDINGS, residentId, buildingId, residentRootId],
    () =>
      axios({
        method: "GET",
        url: `${SERVICES.BUILDINGS}/search_multi_equal`,
        params: {
          resident_id: residentId,
          building_id: buildingId,
          resident_root_id: residentRootId,
        },
      }).then((res) => res.data || []),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!buildingId && type,
    }
  );
};

export const useFetchBuildingsByResidentId = (
  residentId: number,
  buildingId: string | undefined,
  type: boolean
) => {
  return useQuery(
    [QUERY_NAME.BUILDINGS, residentId],
    () =>
      axios({
        method: "GET",
        url: `${SERVICES.BUILDINGS}/search_equal`,
        params: {
          resident_id: residentId,
        },
      }).then((res) => res.data || []),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!residentId && type,
    }
  );
};

export const useImportBuildings = () => {
  return useMutation(({ formData }: any) =>
    axios({
      method: "POST",
      url: SERVICES.BUILDINGS_IMPORT_CSV,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  );
};

export const useFetchOneBuildings = (buildingId: string | undefined) => {
  return useQuery(
    [QUERY_NAME.BUILDING_ID, buildingId],
    () =>
      axios({
        method: "GET",
        url: `${SERVICES.BUILDINGS}/${buildingId}`,
      }).then((res) => res.data),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!buildingId,
    }
  );
};

export const useFetchOneBuildingsByBuildingId = (
  buildingId: string | undefined,
  // residentId: string | undefined,
  type: boolean
) => {
  return useQuery(
    [QUERY_NAME.BUILDING_ID, buildingId],
    () =>
      axios({
        method: "GET",
        url: `${SERVICES.BUILDINGS}/${buildingId}`,
        // params: {
        //   resident_id: residentId,
        // },
      }).then((res) => res.data),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!buildingId && type,
    }
  );
};

export const useFileImportStatus = () => {
  return useMutation(({ uuid }: any) =>
    axios({
      method: "GET",
      url: `file_imports/${uuid}`,
    })
  );
};

export const useDeleteBuildings = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ payload }: any) =>
      axios({
        method: "DELETE",
        url: `${SERVICES.BUILDINGS}/delete`,
        data: payload,
      }),
    {
      onSuccess() {
        queryClient.invalidateQueries([QUERY_NAME.BUILDINGS]);
      },
    }
  );
};

export const useColumnDefs = () => {
  return useMutation(
    ({ payload }: any) =>
      axios({
        method: "PUT",
        url: `${SERVICES.BUILDINGS}/column_defs`,
        data: payload,
      }),
    {
      onSuccess() {},
    }
  );
};

export const useFetchColumnDefs = () => {
  return useMutation(({ uuid }: any) =>
    axios({
      method: "GET",
      url: `${SERVICES.BUILDINGS}/column_defs`,
    }).then((res) => res.data)
  );
};
