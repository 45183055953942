import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { omit } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";

import { useFetchOneReception, useSubmitReception } from "@bms/hooks";
import { validation } from "./schema";
import { openNotification } from "@bms/components";

export type ReceivedProps = Record<string, any>;

const useReceptionControl = (props: ReceivedProps) => {
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const isView = state?.view || false;
  const {
    data: receptionData,
    isSuccess,
    isLoading,
  } = useFetchOneReception(id);
  const { mutate, isLoading: isSubmitting } = useSubmitReception();

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validation()),
  });

  const onSubmit = async (values: Record<string, any>) => {
    if (isView) return;

    mutate(
      {
        payload: id ? values : omit(values, ["id"]),
        receptionId: id,
      },
      {
        onSuccess() {
          if (!id) {
            reset();
          }
          openNotification();
          navigate(-1);
        },
      }
    );
  };

  useEffect(() => {
    if (isSuccess) {
      reset(receptionData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, receptionData, reset]);

  return {
    ...props,
    id,
    isLoading,
    isSubmitting,
    errors,
    isView,
    register,
    handleSubmit,
    onSubmit,
  };
};

export type Props = ReturnType<typeof useReceptionControl>;

export default useReceptionControl;
