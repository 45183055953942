import {
  Control,
  FieldValues,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormHandleSubmit,
} from "react-hook-form";

export type ReceivedProps = {
  control: Control<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  register: UseFormRegister<FieldValues>;
  handleSubmit: UseFormHandleSubmit<FieldValues>;
  onSubmit: (values: Record<string, any>) => void;
};

const useOutputSearch = (props: ReceivedProps) => {
  const handleCheck = (checkedId: any, name: string) => {
    const { status, address_payment_type } = props.getValues();
    const ids = name === "status" ? status : address_payment_type;
    const newIds = ids?.includes(checkedId)
      ? ids?.filter((id: any) => id !== checkedId)
      : [...(ids ?? []), checkedId];
    return newIds;
  };

  return {
    ...props,
    handleCheck,
  };
};

export type Props = ReturnType<typeof useOutputSearch>;

export default useOutputSearch;
