import { useCallback, useEffect, useRef, useState } from "react";
import {
  ColumnApi,
  GridApi,
  GridSizeChangedEvent,
  DragStoppedEvent,
} from "ag-grid-community";
import { uid } from "uid";
import { useQueryClient } from "@tanstack/react-query";
import { notification } from "antd";

import { Building } from "@bms/types";
import {
  useDeleteBuildings,
  useFetchBuildings,
  useFileImportStatus,
  useImportBuildings,
  useColumnDefs,
  useFetchColumnDefs,
} from "@bms/hooks";
import { QUERY_NAME, SORTING, ROUTES } from "@bms/constants";
import { columns } from "./utility";
import { openNotification } from "@bms/components";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

export type ReceivedProps = Record<string, any>;

const useBuildingList = (props: ReceivedProps) => {
  const gridRef: any = useRef();
  const queryClient = useQueryClient();
  const outputExportUuid = localStorage.getItem("building_import_uuid");
  const [initColumnDefs, setInitColumnDefs] = useState<any[]>([]);
  const [isLoadColumnDefs, setLoadColumnDefs] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (initColumnDefs && initColumnDefs.length > 0) {
      if (!isLoadColumnDefs) {
        const newColumnDefs: any = [];
        columnDefsSetting.map((item) => {
          initColumnDefs.filter((itemy) => {
            if (itemy.field === item.field) {
              item.sort_index = itemy.sort_index;
            }
          });
          newColumnDefs.push(item);
        });

        setColumnDefs(
          newColumnDefs.sort((a: any, b: any) => a.sort_index - b.sort_index)
        );
        setLoadColumnDefs(true);
      }
      return;
    }

    mutateFetchColumnDefs(
      {},
      {
        onSuccess(data: any) {
          setInitColumnDefs(data.column_defs);
        },
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initColumnDefs]);

  const columnDefsSetting = columns()
    .sort((a, b) => a.sort_index - b.sort_index)
    .map((item) => ({
      ...item,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      filterParams: {
        textMatcher: ({ value, filterText }: any) => {
          const filterTextLowerCase = filterText.toLowerCase();
          const valueLowerCase = value.toString().toLowerCase();
          return valueLowerCase.includes(filterTextLowerCase);
        },
      },
    }));

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(100);
  const [total, setTotal] = useState<number>(0);
  const [rowData, setRowData] = useState<Building[]>([]);
  const [columnDefs, setColumnDefs] = useState<any[]>(columnDefsSetting);
  const [gridApi, setGridApi] = useState<GridApi | undefined>();
  const [file, setFile] = useState<File | null>(null);
  const [isOpenModalImport, setIsOpenModalImport] = useState<boolean>(false);
  const [isOpenModalDelete, setIsOpenModalDelete] = useState<boolean>(false);
  const [statusFileImport, setStatusFileImport] = useState<boolean>(false);
  const [filterParams, setFilterParams] = useState<Record<string, any>>({});
  const [sortParams, setSortParams] = useState<Record<string, any>>({});
  const [buildingIds, setBuildingIds] = useState<{ id: number }[]>([]);
  const [valueSelected, setValueSelected] = useState<any[]>([]);
  const [isAddServiceCard, setIsAddServiceCard] = useState<boolean>(false);

  const {
    data: buildingsData,
    isSuccess,
    isLoading,
    isFetching,
  } = useFetchBuildings(page, pageSize, filterParams, sortParams);
  const {
    mutate,
    reset,
    isSuccess: isImportSuccess,
    isLoading: isSubmitting,
  } = useImportBuildings();
  const { mutate: mutateFileImportStatus } = useFileImportStatus();
  const { mutate: mutateDelete, isLoading: isSubmittingDelete } =
    useDeleteBuildings();
  const { mutate: mutateUpdateColumnDefs } = useColumnDefs();
  const { mutate: mutateFetchColumnDefs } = useFetchColumnDefs();

  const clearFilter = useCallback(
    () => gridRef.current.api.setFilterModel(null),
    []
  );

  const onGridReady = useCallback((params: GridSizeChangedEvent) => {
    setGridApi(params.api);
  }, []);

  const onUpdateColumnDef = useCallback((params: DragStoppedEvent) => {
    let positionColumn: any = [];
    const gridColumn = params.columnApi.getAllGridColumns();
    for (let index = 0; index < gridColumn.length; index++) {
      const element = gridColumn[index];
      positionColumn = positionColumn.concat(element.getColDef().field);
    }

    mutateUpdateColumnDefs(
      {
        payload: { data: positionColumn },
      },
      {
        onSuccess() {},
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onOpenModalImport = () => setIsOpenModalImport(true);
  const onCloseModalImport = () => {
    if (isImportSuccess) {
      queryClient.invalidateQueries([QUERY_NAME.BUILDINGS]);
    }
    setIsOpenModalImport(false);
  };

  const onOpenModalDelete = () => {
    if (!gridApi) return;
    const rowSelected = gridApi.getSelectedRows();
    setBuildingIds(rowSelected.map((item) => ({ id: item.id })));
    setIsOpenModalDelete(true);
  };
  const onCloseModalDelete = () => setIsOpenModalDelete(false);

  const onDeleteRecord = async () => {
    mutateDelete(
      {
        payload: { building_attributes: buildingIds },
      },
      {
        onSuccess() {
          setRowData([]);
          setIsOpenModalDelete(false);
        },
      }
    );
  };

  const onImportCSV = async () => {
    if (!file) return;

    const uuid = uid();
    const formData = new FormData();
    formData.append("csv_file", file);
    formData.append("uuid", uuid);

    mutate(
      {
        formData,
      },
      {
        onSuccess(res: any) {
          notification.config({ duration: 1 });

          notification["warning"]({
            message: res?.data?.message,
          });
          mutateFileImportStatus(
            { uuid },
            {
              onSuccess(data: any) {
                if (data?.data?.status === "done") {
                  openNotification();
                  setStatusFileImport(true);
                  localStorage.removeItem("building_import_uuid");
                  queryClient.invalidateQueries([QUERY_NAME.BUILDINGS]);
                  setStatusFileImport(false);
                } else {
                  localStorage.setItem("building_import_uuid", uuid);
                }
                onCloseModalImport();
              },
            }
          );
        },
      }
    );
  };

  const onAddServiceCard = async () => {
    navigate(ROUTES.SERVICE_CARD + "/create", {
      state: {
        tabKey: "2",
        building_id: valueSelected[0]?.building_id,
        resident_id: valueSelected[0]?.resident_id,
        resident_root_id: valueSelected[0]?.resident_root_id,
        flagRedirect: true,
        searchFilter: "both",
      },
    });
  };

  const onSortChanged = ({ columnApi }: { columnApi: ColumnApi }) => {
    const columnState = columnApi.getColumnState();
    const cusParams: any = columnState.reduce(
      (arr, cur) => ({
        ...arr,
        [cur.colId]: cur.sort === null ? SORTING.ASC : cur.sort,
      }),
      {}
    );

    setSortParams({
      ...sortParams,
      ...cusParams,
    });
    setPage(1);
  };

  const onFilterUpdate = useCallback(() => {
    if (!gridApi) return;
    const model = gridApi.getFilterModel();

    const curParams = Object.keys(model).reduce(
      (arr, cur) => ({
        ...arr,
        [cur]: model[cur].filter,
      }),
      {}
    );
    setFilterParams({
      ...filterParams,
      ...curParams,
    });
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridApi]);

  useEffect(() => {
    if (valueSelected && valueSelected.length === 1) {
      setIsAddServiceCard(true);
    } else {
      setIsAddServiceCard(false);
    }
  }, [valueSelected]);

  useEffect(() => {
    if (isLoading || isFetching) {
      setRowData([]);
    } else {
      if (isSuccess) {
        setRowData(
          buildingsData.buildings.map((item: any) => ({
            id: item.id,
            building_id: item?.building_id,
            building_name: item?.building_name,
            building_name_kana: item?.building_name_kana,
            building_zip_code_firt_3_digits:
              item?.building_zip_code_firt_3_digits,
            building_zip_code_last_4_digits:
              item?.building_zip_code_last_4_digits,
            building_prefecture_name: item?.building_prefecture_name,
            building_municipality_name: item?.building_municipality_name,
            building_town_name: item?.building_town_name,
            building_district: item?.building_district,
            building_street_address: item?.building_street_address,
            building_tentative_name: item?.building_tentative_name,
            management_number: item?.management_number,

            owner_id: item?.owner?.owner_data_id,
            owner_name: item?.owner?.name,
            owner_name_furigana: item?.owner?.name_furigana,
            owner_management_number: item?.owner?.management_number,
            owner_address: item?.owner?.address,
            owner_zip_code_firt_3_digits: item?.owner?.zip_code_firt_3_digits,
            owner_zip_code_last_4_digits: item?.owner?.zip_code_last_4_digits,
            owner_prefecture_name: item?.owner?.prefecture_name,
            owner_municipality_name: item?.owner?.municipality_name,
            owner_town_name: item?.owner?.town_name,
            owner_district_name: item?.owner?.district_name,
            owner_apartment_name: item?.owner?.apartment_name,
            owner_tel: item?.owner?.tel,
            owner_mobile_phone: item?.owner?.mobile_phone,
            management_pattern: item?.management_pattern,
            system_division: item?.system_division,

            resident_root_id: item?.resident?.id,
            resident_room_number: item?.resident?.room_number,
            contractor_name: item?.contractor?.name,
            contractor_id: item?.contractor?.contractor_data_id,
            resident_name: item?.resident?.name,
            resident_id: item?.resident?.resident_data_id,
            contractor_move_in_date: item?.contractor?.move_in_date
              ? dayjs(item?.contractor?.move_in_date).format("YYYY/MM/DD")
              : "",
            contractor_address: item?.contractor?.address,
            contractor_zip_code_firt_3_digits:
              item?.contractor?.zip_code_firt_3_digits,
            contractor_zip_code_last_4_digits:
              item?.contractor?.zip_code_last_4_digits,
            contractor_prefecture_name: item?.contractor?.prefecture_name,
            contractor_municipality_name: item?.contractor?.municipality_name,
            contractor_town_name: item?.contractor?.town_name,
            contractor_district_name: item?.contractor?.district_name,
            contractor_apartment_name: item?.contractor?.apartment_name,
            contractor_tel: item?.contractor?.tel,
            contractor_mobile_phone: item?.contractor?.mobile_phone,
            resident_address: item?.resident?.address,
            resident_zip_code_firt_3_digits:
              item?.resident?.zip_code_firt_3_digits,
            resident_zip_code_last_4_digits:
              item?.resident?.zip_code_last_4_digits,
            resident_prefecture_name: item?.resident?.prefecture_name,
            resident_municipality_name: item?.resident?.municipality_name,
            resident_town_name: item?.resident?.town_name,
            resident_district_name: item?.resident?.district_name,
            resident_apartment_name: item?.resident?.apartment_name,
            resident_tel: item?.resident?.tel,
            resident_mobile_phone: item?.resident?.mobile_phone,
          }))
        );
        setTotal(buildingsData.meta.total_records);
      }
    }
  }, [buildingsData, isFetching, isLoading, isSuccess]);

  useEffect(() => {
    if (!isOpenModalImport) {
      setFile(null);
    }
  }, [isOpenModalImport]);

  useEffect(() => {
    if (!outputExportUuid || statusFileImport) {
      return;
    }

    const getFile = setInterval(() => {
      mutateFileImportStatus(
        { uuid: outputExportUuid },
        {
          onSuccess(data: any) {
            if (data?.data?.status === "done") {
              openNotification();
              setStatusFileImport(true);
              localStorage.removeItem("building_import_uuid");
              queryClient.invalidateQueries([QUERY_NAME.BUILDINGS]);
              setStatusFileImport(false);
            }
          },
        }
      );
    }, 5000);
    return () => {
      clearInterval(getFile);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutateFileImportStatus, outputExportUuid, statusFileImport]);

  return {
    ...props,
    columnDefs,
    rowData,
    pageSize,
    gridRef,
    gridApi,
    page,
    total,
    isSubmitting,
    isOpenModalImport,
    isOpenModalDelete,
    file,
    isLoading,
    isFetching,
    isImportSuccess,
    isSubmittingDelete,
    statusFileImport,
    outputExportUuid,
    onSortChanged,
    onFilterUpdate,
    reset,
    setFile,
    onDeleteRecord,
    onOpenModalDelete,
    onCloseModalDelete,
    onOpenModalImport,
    onCloseModalImport,
    onImportCSV,
    setPage,
    clearFilter,
    onGridReady,
    setPageSize,
    onUpdateColumnDef,
    onAddServiceCard,
    setValueSelected,
    isAddServiceCard,
  };
};

export type Props = ReturnType<typeof useBuildingList>;

export default useBuildingList;
