import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QUERY_NAME, SERVICES, SORTING } from "@bms/constants";
import axios from "@bms/libs/axios";
import { Workers } from "@bms/types";

export const useFetchWorkers = (
  page: number,
  pageSize: number,
  filterParams?: any,
  sortParams?: any
) => {
  for (const obj in sortParams) {
    if (sortParams[obj] === SORTING.ASC) {
      delete sortParams[obj];
    }
  }

  if (filterParams.filter_by_worker == undefined) {
    filterParams = {
      ...filterParams,
      filter_page_by_worker: true,
    };
  }

  return useQuery(
    [QUERY_NAME.WORKERS, page, pageSize, filterParams, sortParams],
    () =>
      axios({
        method: "GET",
        url: SERVICES.WORKERS,
        params: {
          page: page,
          per_page: pageSize,
          ...filterParams,
          ...sortParams,
        },
      }).then((res) => res.data || []),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
};

export const useFetchOneWorker = (workerId: string | undefined) => {
  return useQuery(
    [QUERY_NAME.WORKER_ID, workerId],
    () =>
      axios({
        method: "GET",
        url: `${SERVICES.WORKERS}/${workerId}`,
      }).then((res) => res.data as Workers),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!workerId,
    }
  );
};

export const useDeleteWorker = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ payload }: any) =>
      axios({
        method: "DELETE",
        url: `${SERVICES.WORKERS}/delete`,
        data: payload,
      }),
    {
      onSuccess() {
        queryClient.invalidateQueries([QUERY_NAME.WORKERS]);
      },
    }
  );
};

export const useSubmitWorker = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ payload, workerId }: any) =>
      axios({
        method: workerId ? "PUT" : "POST",
        url: workerId ? `${SERVICES.WORKERS}/${workerId}` : SERVICES.WORKERS,
        data: payload,
      }),
    {
      onSuccess() {
        queryClient.invalidateQueries([QUERY_NAME.WORKERS]);
        queryClient.invalidateQueries([QUERY_NAME.WORKER_ID]);
      },
    }
  );
};
