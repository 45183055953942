import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { omit } from "lodash";
import moment from "moment";
import { addDays, subDays } from "date-fns";
import { notification } from "antd";
import { uid } from "uid";
import dayjs from "dayjs";

import { DATE_TIME_FORMAT } from "@bms/constants";
import { SelectOptionsType } from "@bms/types";
import {
  useFetchOneWorker,
  useFetchPermissions,
  useSubmitWorker,
} from "@bms/hooks";
import { validation } from "./schema";
import { openNotification } from "@bms/components";

export type ReceivedProps = Record<string, any>;

const useWorkerControl = (props: ReceivedProps) => {
  const [api, contextHolder] = notification.useNotification();
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const isView = state?.view || false;
  const { data: workerData, isSuccess, isLoading } = useFetchOneWorker(id);
  const { data: permissionsData } = useFetchPermissions();
  const { mutate, isLoading: isSubmitting } = useSubmitWorker();

  const [initial, setInitial] = useState<boolean>(false);
  const [permissionOptions, setPermissionOptions] = useState<
    SelectOptionsType[]
  >([]);
  const [selected, setSelected] = useState<any[]>(["2", "3", "4"]);
  const [isOpenScopeLeave, setIsOpenScopeLeave] = useState<boolean>(false);
  const [isOpenDateSpecific, setIsOpenDateSpecific] = useState<boolean>(false);
  const [stateDateRange, setStateDateRange] = useState<any>([
    {
      startDate: subDays(new Date(), 0),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);
  const [stateDateLongRange, setStateDateLongRange] = useState<any>([
    {
      startDate: subDays(new Date(), 0),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);

  const {
    control,
    setValue,
    getValues,
    register,
    reset,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validation(id)),
  });

  const onSubmit = async (values: Record<string, any>) => {
    if (isView) return;
    const uuid = uid();

    // TODO
    const payload = {
      ...omit(values, ["scopeOfLeave1", "day_off_1", "day_off_2"]),
      number_id: uuid,
      join_date: dayjs().format(DATE_TIME_FORMAT.PAYLOAD),
      retired_date: values.retired_date
        ? moment(new Date(values.retired_date)).format(DATE_TIME_FORMAT.PAYLOAD)
        : null,
      start_of_holiday: values.start_of_holiday
        ? moment(new Date(values.start_of_holiday)).format(
            DATE_TIME_FORMAT.PAYLOAD
          )
        : null,
      end_of_holiday: values.end_of_holiday
        ? moment(new Date(values.end_of_holiday)).format(
            DATE_TIME_FORMAT.PAYLOAD
          )
        : null,
      start_of_long_holiday: values.start_of_long_holiday
        ? moment(new Date(values.start_of_long_holiday)).format(
            DATE_TIME_FORMAT.PAYLOAD
          )
        : null,
      end_of_long_holiday: values.end_of_long_holiday
        ? moment(new Date(values.end_of_long_holiday)).format(
            DATE_TIME_FORMAT.PAYLOAD
          )
        : null,
      permission_ids: selected,
    };

    mutate(
      {
        payload: !id
          ? payload
          : omit(payload, ["permissions", "is_working", "leave_requests"]),
        workerId: id,
      },
      {
        onSuccess() {
          if (!id) {
            reset();
            setSelected([]);
          }
          openNotification();
          navigate(-1);
        },
        onError(error: any) {
          api["error"]({
            message: { error }?.error?.response?.data?.error || "Failed",
          });
        },
      }
    );
  };

  const onCloseScopeLeave = () => setIsOpenScopeLeave(false);
  const onOpenScopeLeave = () => setIsOpenScopeLeave(true);
  const onOpenDateSpecific = () => setIsOpenDateSpecific(true);
  const onCloseDateSpecific = () => setIsOpenDateSpecific(false);

  useEffect(() => {
    if (!id) {
      setInitial(true);
    }
    if (isSuccess) {
      setInitial(true);
      setSelected(workerData.permissions.map((item) => item.id.toString()));
      reset({
        ...workerData,
        permission_ids: workerData.permissions.map((item) =>
          item.id.toString()
        ),
        start_of_holiday: workerData?.start_of_holiday || null,
        end_of_holiday: workerData?.end_of_holiday || null,
      });

      setStateDateRange([
        {
          startDate: workerData.start_of_holiday
            ? subDays(new Date(workerData.start_of_holiday as any), 0)
            : subDays(new Date(), 0),
          endDate: workerData.end_of_holiday
            ? subDays(new Date(workerData.end_of_holiday as any), 0)
            : addDays(new Date(), 0),
          key: "selection",
        },
      ]);
      setStateDateLongRange([
        {
          startDate: workerData?.start_of_long_holiday
            ? subDays(new Date(workerData.start_of_long_holiday as any), 0)
            : subDays(new Date(), 0),
          endDate: workerData?.end_of_long_holiday
            ? subDays(new Date(workerData.end_of_long_holiday as any), 0)
            : addDays(new Date(), 0),
          key: "selection",
        },
      ]);
    }
    if (permissionsData) {
      setPermissionOptions(
        permissionsData.slice(1).map((item) => ({
          value: item.id.toString(),
          label: item.access_type_ja,
        }))
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, permissionsData, reset, workerData]);

  return {
    ...props,
    id,
    control,
    isSubmitting,
    errors,
    permissionOptions,
    isLoading,
    isView,
    isOpenScopeLeave,
    stateDateRange,
    initial,
    selected,
    contextHolder,
    api,
    isOpenDateSpecific,
    stateDateLongRange,
    setStateDateLongRange,
    onOpenDateSpecific,
    onCloseDateSpecific,
    setSelected,
    onCloseScopeLeave,
    onOpenScopeLeave,
    setStateDateRange,
    setValue,
    getValues,
    register,
    handleSubmit,
    onSubmit,
    trigger,
  };
};

export type Props = ReturnType<typeof useWorkerControl>;

export default useWorkerControl;
