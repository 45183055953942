import { FC } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import SelectField from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import useContractorInformation, { Props, ReceivedProps } from "./hook";
import { Styles } from "./styled";
import { DEPARTMENT_IN_CHANGE } from "@bms/constants";
import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";

const ContractorInformationLayout: FC<Props> = ({
  register,
  getValues,
  setValue,
  watch,
  handleContactPerson,
  handleSubContactPerson,
  trigger,
  errors,
  isView,
  control,
  receptionOptions,
  workersDataOriginal,
  workersOfficeDataOriginal,
}) => {
  return (
    <Styles className="contract-information">
      <div className="title">受付</div>
      <div className="field-wrapper">
        <div className="input-field">
          <FormControl variant="outlined">
            <InputLabel>担当部署</InputLabel>
            <Controller
              name="department_in_charge"
              control={control}
              render={({ field: { value } }) => (
                <SelectField
                  {...register("department_in_charge")}
                  value={value}
                  variant="standard"
                  defaultValue={getValues("department_in_charge")}
                  label="担当部署"
                  disabled={isView}
                  onChange={(e) => {
                    setValue("department_in_charge", e.target.value);
                    setValue("reception_staff_id", undefined);
                    setValue("contact_person", undefined);
                    trigger("department_in_charge");
                  }}
                >
                  <MenuItem value="third_houses">
                    {DEPARTMENT_IN_CHANGE["third_houses"]}
                  </MenuItem>
                  <MenuItem value="oyakudachiya">
                    {DEPARTMENT_IN_CHANGE["oyakudachiya"]}
                  </MenuItem>
                  <MenuItem value="consignment" disabled>
                    {DEPARTMENT_IN_CHANGE["consignment"]}
                  </MenuItem>
                </SelectField>
              )}
            />
          </FormControl>
          {errors?.department_in_charge && (
            <p className="error">{errors.department_in_charge?.message}</p>
          )}
        </div>

        <div className="input-field">
          <FormControl variant="outlined">
            <InputLabel>受付社員</InputLabel>
            <Controller
              name="reception_staff_id"
              control={control}
              render={({ field: { value } }) => (
                <SelectField
                  {...register("reception_staff_id")}
                  value={value}
                  variant="standard"
                  defaultValue={getValues("reception_staff_id")}
                  onChange={(e) => {
                    const contactPersonValue = workersDataOriginal.find(
                      (item) => item.id === e.target.value
                    );
                    setValue("reception_staff_id", e.target.value);
                    setValue(
                      "contact_person",
                      contactPersonValue?.phone_number
                    );
                    trigger(["reception_staff_id", "contact_person"]);
                  }}
                  label="受付社員"
                  disabled={isView || !watch("department_in_charge")}
                  MenuProps={{ style: { maxWidth: 300 } }}
                >
                  {workersDataOriginal
                    .filter(
                      (item) =>
                        item.department_in_charge ===
                        watch("department_in_charge")
                    )
                    .map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        <span
                          style={{
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {item.name}
                        </span>
                      </MenuItem>
                    ))}
                </SelectField>
              )}
            />
          </FormControl>
          {errors?.reception_staff_id && (
            <p className="error">{errors.reception_staff_id?.message}</p>
          )}
        </div>

        <div
          className={`input-field ${
            !watch("reception_staff_id") ? "field-empty" : ""
          }`}
        >
          <FormControl variant="outlined">
            {watch("reception_staff_id") ? (
              <>
                <InputLabel>担当者連絡先</InputLabel>
                <Controller
                  name="contact_person"
                  control={control}
                  render={({ field: { value } }) => (
                    <SelectField
                      {...register("contact_person")}
                      variant="standard"
                      value={value}
                      defaultValue={watch("contact_person")}
                      label="担当者連絡先"
                      disabled={isView || !watch("reception_staff_id")}
                    >
                      {watch("reception_staff_id") ? (
                        handleContactPerson().map((item, index) => (
                          <MenuItem key={index} value={item.phone_number}>
                            {item.phone_number}
                          </MenuItem>
                        ))
                      ) : (
                        <></>
                      )}
                    </SelectField>
                  )}
                />
              </>
            ) : (
              <TextField
                {...register("contact_person_mock")}
                name="resident_id"
                variant="standard"
                label="担当者連絡先"
                disabled={isView || !watch("reception_staff_id")}
              />
            )}
          </FormControl>
          {errors?.contact_person && (
            <p className="error">{errors.contact_person?.message}</p>
          )}
        </div>

        <div className="input-field">
          <FormControl variant="outlined">
            <InputLabel>受付媒体</InputLabel>
            <Controller
              name="contract_reception_id"
              control={control}
              render={({ field: { value } }) => (
                <SelectField
                  {...register("contract_reception_id")}
                  value={value}
                  variant="standard"
                  defaultValue={getValues("contract_reception_id")}
                  label="受付媒体"
                  disabled={isView}
                  onChange={(e) => {
                    setValue("contract_reception_id", e.target.value);
                    trigger(["contract_reception_id"]);
                  }}
                >
                  {receptionOptions.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </SelectField>
              )}
            />
          </FormControl>
          {errors?.contract_reception_id && (
            <p className="error">{errors.contract_reception_id?.message}</p>
          )}
        </div>
      </div>

      <div className="title">担当者</div>
      <div className="field-wrapper">
        <div className="input-field">
          <FormControl variant="outlined">
            <InputLabel>担当部署</InputLabel>
            <Controller
              name="sub_department_in_charge"
              control={control}
              render={({ field: { value } }) => (
                <SelectField
                  {...register("sub_department_in_charge")}
                  value={value}
                  variant="standard"
                  defaultValue={getValues("sub_department_in_charge")}
                  label="担当部署"
                  disabled={isView}
                  onChange={(e) => {
                    setValue("sub_department_in_charge", e.target.value);
                    setValue("employee_in_charge_id", undefined);
                    setValue("contact_person_employee_in_charge", undefined);
                    trigger("sub_department_in_charge");
                  }}
                >
                  <MenuItem value="third_houses">
                    {DEPARTMENT_IN_CHANGE["third_houses"]}
                  </MenuItem>
                  <MenuItem value="oyakudachiya">
                    {DEPARTMENT_IN_CHANGE["oyakudachiya"]}
                  </MenuItem>
                  <MenuItem value="consignment" disabled>
                    {DEPARTMENT_IN_CHANGE["consignment"]}
                  </MenuItem>
                </SelectField>
              )}
            />
          </FormControl>
        </div>

        <div className="input-field">
          <FormControl variant="outlined">
            <InputLabel>受付社員</InputLabel>
            <Controller
              name="employee_in_charge_id"
              control={control}
              render={({ field: { value } }) => (
                <SelectField
                  {...register("employee_in_charge_id")}
                  value={value}
                  variant="standard"
                  defaultValue={getValues("employee_in_charge_id")}
                  onChange={(e) => {
                    const contactPersonValue = workersOfficeDataOriginal.find(
                      (item) => item.id === e.target.value
                    );
                    setValue("employee_in_charge_id", e.target.value);
                    setValue(
                      "contact_person_employee_in_charge",
                      contactPersonValue?.phone_number
                    );
                    trigger([
                      "employee_in_charge_id",
                      "contact_person_employee_in_charge",
                    ]);
                  }}
                  label="受付社員"
                  disabled={isView || !watch("sub_department_in_charge")}
                  MenuProps={{ style: { maxWidth: 300 } }}
                >
                  {workersOfficeDataOriginal
                    .filter(
                      (item) =>
                        item.department_in_charge ===
                        watch("sub_department_in_charge")
                    )
                    .map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        <span
                          style={{
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {item.name}
                        </span>
                      </MenuItem>
                    ))}
                </SelectField>
              )}
            />
          </FormControl>
        </div>

        <div
          className={`input-field ${
            !watch("employee_in_charge_id") ? "field-empty" : ""
          }`}
        >
          <FormControl variant="outlined">
            {watch("employee_in_charge_id") ? (
              <>
                <InputLabel>担当者連絡先</InputLabel>
                <Controller
                  name="contact_person_employee_in_charge"
                  control={control}
                  render={({ field: { value } }) => (
                    <SelectField
                      {...register("contact_person_employee_in_charge")}
                      value={value}
                      variant="standard"
                      defaultValue={watch("contact_person_employee_in_charge")}
                      label="担当者連絡先"
                      disabled={isView || !watch("employee_in_charge_id")}
                    >
                      {watch("employee_in_charge_id") ? (
                        handleSubContactPerson().map((item, index) => (
                          <MenuItem key={index} value={item.phone_number}>
                            {item.phone_number}
                          </MenuItem>
                        ))
                      ) : (
                        <></>
                      )}
                    </SelectField>
                  )}
                />
              </>
            ) : (
              <TextField
                {...register("contact_person_employee_in_charge_mock")}
                name="resident_id"
                variant="standard"
                label="担当者連絡先"
                disabled={isView || !watch("employee_in_charge_id")}
              />
            )}
          </FormControl>
        </div>
      </div>
    </Styles>
  );
};

const ContractorInformation: FC<ReceivedProps> = (props) => (
  <ContractorInformationLayout {...useContractorInformation(props)} />
);

export default ContractorInformation;
