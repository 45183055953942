import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";

export const Styles = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 28px;

    h3 {
      color: var(--main-color);
      font-size: 28px;
      font-family: "Inter";
    }

    .group-btn {
      display: flex;
      gap: 20px;

      button {
        width: 120px;
        background-color: var(--main-color);
        color: #ffffff;
        padding: 6.5px 20px;
        border: none;
        border-radius: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        font-size: 16px;
        font-weight: 400;
        font-family: "Inter";
      }

      .btn-back {
        background-color: #ffffff;
        border: 2px solid var(--main-color);
        color: var(--main-color);
      }
    }
  }

  .title {
    margin-bottom: 30px;
    font-family: "Lato";
    font-weight: 400;
    font-size: 30px;
    color: var(--main-color);
  }

  form {
    column-gap: 90px;
    display: flex;
    flex-direction: row;
    /* align-items: flex-end; */
    flex-wrap: wrap;

    .box {
      &.left {
        display: flex;
        align-items: flex-start;
        column-gap: 90px;

        .field-wrapper {
          margin-bottom: 36px;

          /* .MuiInputBase-input {
            visibility: hidden;
          } */
        }
      }
    }

    .box {
      &.right {
        transform: translateY(-60px);
        @media screen and (max-width: 1100px) {
          transform: unset;
        }

        .field-wrapper {
          &:nth-child(1) {
            display: flex;
            flex-direction: column;
            min-height: 77px;
            /* margin-bottom: 77px; */

            .tag-wrapper {
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              column-gap: 20px;
              row-gap: 10px;
              max-width: 600px;
              /* width: 800px; */

              .tag {
                border: 1px solid #707070;
                min-width: 150px;
                border-radius: 46px;
                display: flex;
                /* justify-content: center; */
                align-items: center;
                padding: 10px 13px;
                gap: 13px;

                img {
                  margin-left: 3px;
                }

                p {
                  font-family: "Inter";
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 19px;
                }
              }
            }
          }

          &:nth-child(2) {
            margin-top: 35px;

            .time-wrapper {
              display: flex;
              /* align-items: center; */
              align-items: flex-start;
              gap: 11px;
              margin-top: 3px;

              .spreader {
                padding-top: 14px;
              }
            }

            .ant-picker:hover,
            .ant-picker-focused {
              border-color: #cdcdcd;
              box-shadow: none;
            }

            .ant-picker {
              padding: 13px 23px 13px;
              width: 98px;
              border: 1px solid #cdcdcd;

              input {
                font-family: "Lato";
                font-weight: 400;
                font-size: 16px;
                color: #000000;
              }
            }
          }

          &:nth-child(3) {
            margin-top: 35px;

            .MuiInputBase-root {
              width: 235px;
            }

            .MuiSelect-select {
              height: 52px;
              line-height: 52px;
              padding: 0 23px;
            }

            label {
              margin-bottom: 17px;
            }

            .field-container {
              display: flex;
              gap: 45px;
              align-items: flex-start;

              .holidays {
                /* width: 53%; */
                .title {
                  display: flex;
                  align-items: center;
                  gap: 112px;
                  margin-bottom: 18px;

                  h3 {
                    font-family: "Inter";
                    font-weight: 400;
                    font-size: 20px;
                    color: var(--main-color);
                  }
                }

                .field-inner {
                  display: flex;
                  align-items: center;
                  margin-bottom: 19px;
                  gap: 17px;
                }

                .separator {
                  font-family: "Inter";
                  font-weight: 400;
                  font-size: 20px;
                  color: var(--main-color);
                  margin-right: 28px;
                  padding-top: 19px;
                }

                .MuiInputBase-root {
                  border: 1px solid #cdcdcd;
                  border-radius: 6px;
                  width: 130px;

                  .placeholder {
                    font-family: "Inter";
                    font-weight: 400;
                    font-size: 16px;
                    color: #adadad;
                  }

                  fieldset {
                    border: none;
                  }

                  .MuiSelect-select {
                    padding: 0 14px;
                    height: 50px;
                    line-height: 52px;
                    font-weight: 400;
                    font-size: 16px;
                    font-family: "Lato";
                  }
                }
              }

              .field {
                border: 1px solid #cdcdcd;
                border-radius: 6px;
                height: 52px;
                line-height: 52px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-left: 23px;
                overflow: hidden;
                /* width: 235px; */
                margin-top: 45px;
                /* width: 100%; */
                /* min-width: 235px; */

                &.empty {
                  p {
                    color: #adadad;
                  }
                }

                p {
                  color: #000000;
                  font-weight: 400;
                  font-size: 16px;
                  font-family: "Inter";
                  display: inline-block;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }

                img {
                  padding-right: 18px;
                  padding-left: 5px;
                }
              }
            }
          }
        }
      }
    }

    .field-wrapper {
      label {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        color: var(--main-color);
        margin-bottom: 18px;
      }

      .MuiInputBase-root {
        border: 1px solid #bcbcbc;
        border-radius: 6px;
        width: 400px;

        .placeholder {
          font-family: "Inter";
          font-weight: 400;
          font-size: 16px;
          color: #adadad;
        }

        fieldset {
          border: none;
        }

        .MuiSelect-select {
          padding: 0 14px;
          height: 45px;
          line-height: 45px;
          font-weight: 400;
          font-size: 16px;
          font-family: "Lato";
        }
      }
    }
  }
`;

export const useStylesWorker = makeStyles(() => ({
  select: {
    fontSize: "16px",
    fontFamily: "Inter",
    fontWeight: 400,
  },
  paper: {
    marginTop: 12,
    borderRadius: "16px !important",
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    "& li": {
      fontFamily: "Inter",
      fontWeight: 400,
      fontSize: "16px !important",
      color: "#000000",
      borderBottom: "1px solid #bcbcbc",
      padding: "23px 21px 17px",
    },
  },
  listBox: {
    padding: "0 !important",

    "& li": {
      padding: "17px 21px 14px !important",
      fontFamily: "Inter",
      fontWeight: 400,
      fontSize: "16px !important",
      color: "#000000",
      borderBottom: "1px solid #bcbcbc",
    },

    "& li:last-child": {
      borderBottom: "none",
    },
  },
}));

export const useStylesHolidays = makeStyles(() => ({
  select: {
    fontSize: "20px",
  },
  paper: {
    marginTop: 2,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    "& li": {
      fontFamily: "Inter",
      fontWeight: 400,
      // fontSize: "20px !important",
      color: "#8b8b8b",
      borderBottom: "1px solid #bcbcbc",
      paddingBottom: 8,
    },
  },
}));
