import { FC } from "react";
import Box from "@mui/material/Box";

import { ModalWrapper, StylesModal } from "./styled";

type IProps = {
  isOpen: boolean;
  isSubmitting: boolean;
  content: string;
  width?: number;
  height?: number;
  className?: string;
  onOk: () => Promise<void>;
  onClose: () => void;
};

const ModalConfirm: FC<IProps> = ({
  isOpen,
  isSubmitting,
  content,
  width = 310,
  height = 214,
  className,
  onOk,
  onClose,
}) => {
  return (
    <ModalWrapper open={isOpen} onClose={onClose} className={className}>
      <Box sx={{ ...StylesModal, width, height }}>
        <h3>{content}</h3>

        <div className="modal-footer">
          <div className="group-btn">
            <button
              type="submit"
              className={`${isSubmitting ? "disabled" : ""} btn-submit`}
              onClick={onOk}
            >
              はい
            </button>
            <button
              className={`${isSubmitting ? "disabled" : ""} btn-cancel`}
              onClick={onClose}
            >
              いいえ
            </button>
          </div>
        </div>
      </Box>
    </ModalWrapper>
  );
};

export default ModalConfirm;
