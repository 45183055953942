export * from "./routes.constant";
export * from "./services.constant";
export * from "./messages.constant";

export const ADMIN_ID = process.env.REACT_APP_ADMIN_ID;

export const DATE_TIME_FORMAT = {
  DATE_JP: "YYYY年 MM月DD日",
  DATE: "YYYY.MM.DD",
  PAYLOAD: "DD/MM/YYYY",
  TIME: "HH:mm",
};

export const LOCALE_JP = "ja-JP";

export const SORTING = {
  ASC: "asc",
  DESC: "desc",
};

export enum DAY_OF_WEEK_KIND {
  "monday" = "月曜日",
  "tuesday" = "火曜日",
  "wednesday" = "水曜日",
  "thursday" = "木曜日",
  "friday" = "金曜日",
  "saturday" = "土曜日",
  "sunday" = "日曜日",
  "day_null" = "",
}

export enum PERIOD_KIND {
  "every_week" = "毎週",
  "twice_a_week" = "隔週",
  "week_1st" = "第1週",
  "week_2nd" = "第2週",
  "week_3rd" = "第3週",
  "week_4th" = "第4週",
  "week_null" = "",
}

export enum ROLE {
  "office_staff" = "事務員",
  "worker" = "作業員",
  "entrustment" = "委託",
  "admin" = "admin",
}

export enum DEPARTMENT_IN_CHANGE {
  "third_houses" = "3家",
  "oyakudachiya" = "おやくだち家",
  "consignment" = "委託(今後)",
}

export enum WORKER_STATUS {
  "working" = "作業中",
  "retired" = "退社",
  "day_off" = "休職中",
  "long_holiday" = "長期休暇 ",
}

export enum CLAIM_STATUS {
  "billed" = "請求済",
  "unclaimed" = "未請求",
}

export enum WORK_STATUS {
  "resolved" = "対応済",
  "not_compatible" = "未対応",
}

export enum PAYMENT_STATUS {
  "paid" = "入金済",
  "not_payment" = "未入金",
}

export const DAY_OF_WEEK_KIND_OPTIONS = [
  {
    value: "day_null",
    label: null,
  },
  {
    value: "monday",
    label: "月曜日",
  },
  {
    value: "tuesday",
    label: "火曜日",
  },
  {
    value: "wednesday",
    label: "水曜日",
  },
  {
    value: "thursday",
    label: "木曜日",
  },
  {
    value: "friday",
    label: "金曜日",
  },
  {
    value: "saturday",
    label: "土曜日",
  },
  {
    value: "sunday",
    label: "日曜日",
  },
];

export const PERIOD_KIND_OPTIONS = [
  {
    value: "week_null",
    label: null,
  },
  {
    value: "every_week",
    label: "毎週",
  },
  {
    value: "twice_a_week",
    label: "隔週",
  },
  {
    value: "week_1st",
    label: "第1週",
  },
  {
    value: "week_2nd",
    label: "第2週",
  },
  {
    value: "week_3rd",
    label: "第3週",
  },
  {
    value: "week_4th",
    label: "第4週",
  },
];

export const PERMISSION = {
  SERVICE_CARD: "service_card",
  SCHEDULE: "schedule",
  SHIFT: "shift",
  HOLIDAY: "holiday",
  OPERATOR: "operator",
  PROPERTY: "property",
  // MERCHANDISE: "merchandise",
  RECEPTION: "reception",
  PAYMENT: "payment",
  SUBCONTRACTOR: "subcontractor",
  TAX_RATE: "tax_rate",
  INVOICE: "invoice",
  REPORT: "report",
  OUTPUT: "output",
};

export const PERMISSION_OPTIONS = [
  {
    value: 1,
    label: "すべて",
    access_type: "access_all",
  },
  {
    value: 2,
    label: "サービスカード",
    access_type: PERMISSION.SERVICE_CARD,
  },
  {
    value: 3,
    label: "スケジュール",
    access_type: PERMISSION.SCHEDULE,
  },
  {
    value: 4,
    label: "シフト",
    access_type: PERMISSION.SHIFT,
  },
  {
    value: 5,
    label: "定休日",
    access_type: PERMISSION.HOLIDAY,
  },
  {
    value: 6,
    label: "作業員",
    access_type: PERMISSION.OPERATOR,
  },
  {
    value: 7,
    label: "物件",
    access_type: PERMISSION.PROPERTY,
  },
  // {
  //   value: 8,
  //   label: "商品",
  //   access_type: PERMISSION.MERCHANDISE,
  // },
  {
    value: 9,
    label: "受付媒体",
    access_type: PERMISSION.RECEPTION,
  },
  {
    value: 10,
    label: "支払区分",
    access_type: PERMISSION.PAYMENT,
  },
  {
    value: 11,
    label: "外注業者",
    access_type: PERMISSION.SUBCONTRACTOR,
  },
  {
    value: 12,
    label: "税率",
    access_type: PERMISSION.TAX_RATE,
  },
  {
    value: 13,
    label: "出力",
    access_type: PERMISSION.OUTPUT,
  },
];

export const WORKER_STATUS_OPTIONS = [
  {
    value: "clear",
    label: "すべて",
  },
  {
    label: WORKER_STATUS["working"],
    value: "working",
  },
  {
    label: WORKER_STATUS["retired"],
    value: "retired",
  },
  {
    label: WORKER_STATUS["day_off"],
    value: "day_off",
  },
];

export const ROLE_OPTIONS = [
  {
    value: "clear",
    label: "すべて",
  },
  {
    label: ROLE["office_staff"],
    value: "office_staff",
  },
  {
    label: ROLE["worker"],
    value: "worker",
  },
  {
    label: ROLE["entrustment"],
    value: "entrustment",
    disabled: true,
  },
];

export const WORK_STATUS_OPTIONS = [
  {
    label: WORK_STATUS["resolved"],
    value: "resolved",
  },
  {
    label: WORK_STATUS["not_compatible"],
    value: "not_compatible",
  },
];

export const CLAIM_STATUS_OPTIONS = [
  {
    label: CLAIM_STATUS["billed"],
    value: "billed",
  },
  {
    label: CLAIM_STATUS["unclaimed"],
    value: "unclaimed",
  },
];

export const PAYMENT_STATUS_OPTIONS = [
  {
    label: PAYMENT_STATUS["not_payment"],
    value: "not_payment",
  },
  {
    label: PAYMENT_STATUS["paid"],
    value: "paid",
  },
];
