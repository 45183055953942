import { useCallback, useEffect, useRef, useState } from "react";
import {
  // ColumnApi,
  GridApi,
  GridSizeChangedEvent,
} from "ag-grid-community";
// import { get } from "lodash";

// import { SORTING } from "@bms/constants";
import { useDeleteReception, useFetchReceptions } from "@bms/hooks";
import {
  columns,
  //  SORT_PARAMS
} from "./utility";

export type ReceivedProps = Record<string, any>;
const columnDefsSetting: any = columns().map((item, index) => ({
  ...item,
  unSortIcon: index !== 0,
  filter: "agTextColumnFilter",
  filterParams: {
    textMatcher: () => {
      return true;
    },
  },
}));

const useBuildingList = (props: ReceivedProps) => {
  const gridRef: any = useRef();

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(100);
  const [total, setTotal] = useState<number>(0);
  const [rowData, setRowData] = useState<any[]>([]);
  const [gridApi, setGridApi] = useState<GridApi | undefined>();
  const [isOpenModalDelete, setIsOpenModalDelete] = useState<boolean>(false);
  const [filterParams, setFilterParams] = useState<Record<string, any>>({});
  const [
    sortParams,
    //  setSortParams
  ] = useState<Record<string, any>>({});
  const [receptionIds, setReceptionIds] = useState<{ id: number }[]>([]);

  const {
    data: receptionsData,
    isSuccess,
    isLoading,
    isFetching,
  } = useFetchReceptions(page, pageSize, filterParams, sortParams);
  const { mutate, isLoading: isSubmitting } = useDeleteReception();

  const onSortChanged = () =>
    // { columnApi }: { columnApi: ColumnApi }
    {
      // const columnState = columnApi.getColumnState();
      // const cusParams: any = columnState.reduce(
      //   (arr, cur) => ({
      //     ...arr,
      //     [get(SORT_PARAMS, cur.colId)]:
      //       cur.sort === null ? SORTING.ASC : cur.sort,
      //   }),
      //   {}
      // );
      // console.log(columnState, sortParams, cusParams);
      // setSortParams({
      //   ...sortParams,
      //   ...cusParams,
      // });
      setPage(1);
    };

  const onFilterUpdate = useCallback(() => {
    if (!gridApi) return;
    const model = gridApi.getFilterModel();
    const curParams = Object.keys(model).reduce(
      (arr, cur) => ({
        ...arr,
        [cur]: model[cur].filter,
      }),
      {}
    );
    setFilterParams({
      ...filterParams,
      ...curParams,
    });
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridApi]);

  const onOpenModalDelete = () => {
    if (!gridApi) return;
    const rowSelected = gridApi.getSelectedRows();
    setReceptionIds(rowSelected.map((item) => ({ id: item.id })));
    setIsOpenModalDelete(true);
  };
  const onCloseModalDelete = () => setIsOpenModalDelete(false);

  const onDeleteRecord = async () => {
    mutate(
      {
        payload: { reception_attributes: receptionIds },
      },
      {
        onSuccess() {
          setRowData([]);
          setIsOpenModalDelete(false);
        },
      }
    );
  };

  const onGridReady = (params: GridSizeChangedEvent) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  useEffect(() => {
    if (isLoading || isFetching) {
      setRowData([]);
    } else {
      if (isSuccess) {
        setRowData(receptionsData.receptions);
        setTotal(receptionsData.meta.total_records);
      }
    }
  }, [isFetching, isLoading, isSuccess, receptionsData]);

  return {
    ...props,
    rowData,
    pageSize,
    gridRef,
    gridApi,
    page,
    isOpenModalDelete,
    isSubmitting,
    total,
    isLoading,
    isFetching,
    columnDefs: columnDefsSetting,
    onSortChanged,
    onFilterUpdate,
    onDeleteRecord,
    onOpenModalDelete,
    onCloseModalDelete,
    setPage,
    onGridReady,
    setPageSize,
  };
};

export type Props = ReturnType<typeof useBuildingList>;

export default useBuildingList;
