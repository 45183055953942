export const columns = () => {
  return [
    {
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      maxWidth: 40,
      floatingFilter: false,
    },
    {
      headerName: "No.",
      field: "id",
      maxWidth: 60,
      floatingFilter: false,
      sort: "desc",
    },
    {
      headerName: "種類",
      field: "type_name",
    },
  ];
};

export const SORT_PARAMS = {
  index: "sort_index",
  type_name: "sort_type_name",
};
