import { SelectOptionsType } from "@bms/types";
import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";

export type ReceivedProps = {
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  errors: FieldErrors<FieldValues>;
  control: Control<FieldValues>;
  isView: boolean;
  paymentCategoryData: SelectOptionsType[];
  workerOptions: SelectOptionsType[];
};

const usePaymentInformation = (props: ReceivedProps) => {
  return {
    ...props,
  };
};

export type Props = ReturnType<typeof usePaymentInformation>;

export default usePaymentInformation;
