import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Note, Pen, Plus } from "@bms/assets";
import { ROLE, ROUTES } from "@bms/constants";
import { ModalDelete, Table } from "@bms/components";
import useWorkerList, { Props, ReceivedProps } from "./hook";
import { Styles } from "./styled";

const WorkerListLayout: FC<Props> = ({
  rowData,
  pageSize,
  isLoading,
  isFetching,
  gridApi,
  gridRef,
  page,
  isOpenModalDelete,
  isSubmitting,
  columnDefs,
  total,
  onSortChanged,
  onFilterUpdate,
  onDeleteRecord,
  onOpenModalDelete,
  onCloseModalDelete,
  clearFilter,
  setPage,
  onGridReady,
  setPageSize,
  setFilterParams,
}) => {
  const navigate = useNavigate();
  const [btnActive, setBtnActive] = useState(1);

  if (rowData && rowData.length > 0) {
    columnDefs = [
      ...columnDefs,
      {
        maxWidth: 100,
        minWidth: 100,
        field: "動作",
        floatingFilter: false,
        cellRenderer: (e: any) => {
          return (
            <div className="group-action">
              <img
                src={Note}
                onClick={() =>
                  navigate(`${e.data.id}`, {
                    state: {
                      view: true,
                    },
                  })
                }
              />
              <img src={Pen} onClick={() => navigate(`${e.data.id}`)} />
            </div>
          );
        },
      },
    ];
  }
  return (
    <Styles>
      <div className="header">
        <h3>作業員一覧</h3>
        <button onClick={() => navigate(ROUTES.CREATE)}>
          <p>作業員追加</p>
          <img src={Plus} />
        </button>
      </div>

      <div className="table-wrapper">
        <Table
          total={total}
          page={page}
          pageSize={pageSize}
          gridRef={gridRef}
          rowData={rowData}
          onSortChanged={onSortChanged}
          onFilterUpdate={onFilterUpdate}
          columnDefs={columnDefs}
          height={554}
          loading={isFetching || isLoading}
          gridApi={gridApi}
          setPage={setPage}
          setPageSize={setPageSize}
          onOpenModalDelete={onOpenModalDelete}
          tableHeader={
            <div className="btn-group">
              <button
                className={`btn-clear ${btnActive === 1 ? "btn-active" : ""}`}
                onClick={() => {
                  clearFilter();
                  setFilterParams({});
                  setBtnActive(1);
                }}
              >
                すべて
              </button>
              <button
                className={`btn-outline ${btnActive === 2 ? "btn-active" : ""}`}
                onClick={() => {
                  clearFilter();
                  setFilterParams({ role: "worker" });
                  setBtnActive(2);
                }}
              >
                {ROLE["worker"]}
              </button>
              <button
                className={`btn-outline ${btnActive === 3 ? "btn-active" : ""}`}
                onClick={() => {
                  clearFilter();
                  setFilterParams({ role: "office_staff" });
                  setBtnActive(3);
                }}
              >
                {ROLE["office_staff"]}
              </button>
            </div>
          }
          onGridReady={onGridReady}
        />
      </div>

      <ModalDelete
        isOpen={isOpenModalDelete}
        isSubmitting={isSubmitting}
        onDeleteRecord={onDeleteRecord}
        onClose={onCloseModalDelete}
      />
    </Styles>
  );
};

export const WorkerList: FC<ReceivedProps> = (props) => (
  <WorkerListLayout {...useWorkerList(props)} />
);

export default WorkerList;
