import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type InitialState = {
  loggedInUser: boolean;
  user: any;
};

const initialState: InitialState = {
  loggedInUser: false,
  user: null,
};
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess(state) {
      state.loggedInUser = true;
    },
    userLogin(state, action: PayloadAction<{ user: any }>) {
      state.user = action.payload.user;
    },
    userLogout(state) {
      state.user = null;
      state.loggedInUser = false;
    },
  },
});

export default authSlice;

export const { loginSuccess, userLogin, userLogout } = authSlice.actions;
