import { FC } from "react";
import Box from "@mui/material/Box";
import SelectField from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import LoadingButton from "@mui/lab/LoadingButton";

import { Loading } from "@bms/components";
import { DAY_OF_WEEK_KIND_OPTIONS, PERIOD_KIND_OPTIONS } from "@bms/constants";
import useHolidaysControl, { Props, ReceivedProps } from "./hook";
import { Styles, useStyles } from "./styled";
import ClearIcon from "@mui/icons-material/Clear";

const HolidaysControlLayout: FC<Props> = ({
  handleSubmit,
  setIsEdit,
  register,
  reset,
  onSubmit,
  isLoading,
  initial,
  fields,
  isSubmitting,
  initFields,
  setInitFields,
}) => {
  const classes = useStyles();

  const menuProps = {
    classes: {
      list: classes.list,
      paper: classes.paper,
    },
  };

  if (isLoading || !initial) {
    return <Loading />;
  }

  return (
    <Styles>
      <div className="header">
        <h3>定休日作成</h3>
        <div className="group-btn">
          <button onClick={() => setIsEdit(false)} className="btn-back">
            戻る
          </button>
          <LoadingButton
            loadingPosition="start"
            loading={isSubmitting}
            type="submit"
            variant="outlined"
            onClick={handleSubmit(onSubmit)}
          >
            確定
          </LoadingButton>
        </div>
      </div>

      <Box component="form">
        <div className="title">
          <h3>曜日設定</h3>
          <h3>繰り返し設定</h3>
        </div>
        {initFields.map((item: any, index: any) => {
          return (
            <div className="field-wrapper" key={index}>
              <SelectField
                {...register(`holiday_attributes.${index}.day_of_week_kind`)}
                name={`holiday_attributes.${index}.day_of_week_kind`}
                displayEmpty
                MenuProps={menuProps}
                classes={{
                  select: classes.select,
                }}
                value={initFields[index]["day_of_week_kind"]}
                onChange={(item) => {
                  const selected = item.target.value;
                  const label = DAY_OF_WEEK_KIND_OPTIONS.find(
                    (item) => item.value === selected
                  );

                  initFields[index]["day_of_week_kind"] = label?.value;
                  if (label?.value == "day_null") {
                    initFields[index]["period_kind"] = "week_null";
                  }

                  reset({
                    holiday_attributes: initFields,
                  });
                  setInitFields(initFields);
                }}
                // defaultValue={initFields[index]["day_of_week_kind"]}
                IconComponent={() => <></>}
                inputProps={{ "aria-label": "Without label" }}
                renderValue={(selected) => {
                  const label = DAY_OF_WEEK_KIND_OPTIONS.find(
                    (item) => item.value === selected
                  );
                  if (!selected) {
                    return <div className="placeholder">選択</div>;
                  }
                  return label?.label || "";
                }}
                {...(item?.day_of_week_kind !== "day_null" && {
                  endAdornment: (
                    <ClearIcon
                      sx={{
                        visibility: register(
                          `holiday_attributes.${index}.day_of_week_kind`
                        )
                          ? "visible"
                          : "hidden",
                      }}
                      className="clearable"
                      onClick={() => {
                        initFields[index]["day_of_week_kind"] = "day_null";
                        initFields[index]["period_kind"] = "week_null";
                        initFields[index]["holiday_name"] = "";
                        reset({
                          holiday_attributes: initFields,
                        });
                        setInitFields(initFields);
                      }}
                    />
                  ),
                })}
              >
                {DAY_OF_WEEK_KIND_OPTIONS.map((item) => {
                  return (
                    <MenuItem key={item?.value} value={item?.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </SelectField>
              <div className="separator">曜日</div>
              <SelectField
                {...register(`holiday_attributes.${index}.period_kind`)}
                name={`holiday_attributes.${index}.period_kind`}
                displayEmpty
                MenuProps={menuProps}
                classes={{
                  select: classes.select,
                }}
                value={initFields[index]["period_kind"]}
                IconComponent={() => <></>}
                inputProps={{ "aria-label": "Without label" }}
                onChange={(item) => {
                  const selected = item.target.value;
                  const label = PERIOD_KIND_OPTIONS.find(
                    (item) => item.value === selected
                  );

                  initFields[index]["period_kind"] = label?.value;
                  if (label?.value == "week_null") {
                    initFields[index]["day_of_week_kind"] = "day_null";
                  }

                  reset({
                    holiday_attributes: initFields,
                  });
                  setInitFields(initFields);
                }}
                renderValue={(selected) => {
                  const label = PERIOD_KIND_OPTIONS.find(
                    (item) => item.value === selected
                  );
                  if (!selected) {
                    return <div className="placeholder">選択</div>;
                  }
                  return label?.label || "";
                }}
              >
                {PERIOD_KIND_OPTIONS.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </SelectField>
            </div>
          );
        })}
      </Box>
    </Styles>
  );
};

const HolidaysControl: FC<ReceivedProps> = (props) => (
  <HolidaysControlLayout {...useHolidaysControl(props)} />
);

export default HolidaysControl;
