import { ROUTES } from "@bms/constants";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const useScreenWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();

  const isWindow = typeof window !== "undefined";

  const getWidth: any = () => (isWindow ? window.innerWidth : windowWidth);

  const resize = () => {
    setWindowWidth(getWidth());
    if (getWidth() == 967) {
      navigate(ROUTES.DASHBOARD);
    } else if (getWidth() == 969) {
      navigate(ROUTES.SERVICE_CARD);
    }
  };

  useEffect(() => {
    if (isWindow) {
      setWindowWidth(getWidth());

      window.addEventListener("resize", resize);

      return () => window.removeEventListener("resize", resize);
    }
    //eslint-disable-next-line
  }, [isWindow]);

  return windowWidth < 968;
};
