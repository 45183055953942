import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { SignOutMobile } from "@bms/assets";
import { ROUTES, SERVICES } from "@bms/constants";
import { userSelector } from "@bms/features/auth/selector";
import useDashBoard, { Props, ReceivedProps } from "./hook";
import { Styles } from "./styled";
import { ModalConfirmLogout } from "@bms/components";
import axios from "@bms/libs/axios";
import { userLogout } from "@bms/features/auth/slice";

const DashBoardLayout: FC<Props> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onSubmit = async () => {
    try {
      setIsSubmitting(true);
      await axios({
        method: "delete",
        url: SERVICES.LOGOUT,
      });

      dispatch(userLogout());
      setIsOpen(false);
      navigate(ROUTES.SIGNIN);
      localStorage.clear();
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [navigate, window]);

  return (
    <Styles>
      <button
        className="service-card"
        onClick={() => navigate(ROUTES.MY_SERVICE_CARD_TICKET)}
      >
        サービスカード
      </button>
      <button
        className="other-service-cards"
        onClick={() => navigate(ROUTES.OTHER_SERVICE_CARD_TICKET)}
      >
        その他のサービスカード
      </button>
      <button
        className="schedule"
        onClick={() => navigate(`${ROUTES.MY_SCHEDULE}/${user?.worker?.id}`)}
      >
        スケジュール
      </button>
      <button className="logout" onClick={() => setIsOpen(true)}>
        <img src={SignOutMobile} />
        <p>ログアウト</p>
      </button>

      <ModalConfirmLogout
        isSubmitting={isSubmitting}
        isOpen={isOpen}
        onLogOut={onSubmit}
        onClose={() => setIsOpen(false)}
      />
    </Styles>
  );
};

const DashBoard: FC<ReceivedProps> = (props) => (
  <DashBoardLayout {...useDashBoard(props)} />
);

export default DashBoard;
