import styled from "styled-components";

export const Styles = styled.div`
  padding: 0 16px;

  padding-bottom: 40px;

  .work-report-view {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    max-width: 352px;

    .no-content {
      font-family: "Inter";
      font-weight: 400;
      font-size: 16px;
      color: #000000;
      text-align: left;
    }

    .box {
      display: flex;
      flex-direction: column;
      gap: 27px;

      .box-wrapper {
        // background-color: #2e8135;
        padding: 10px 0;
        border-radius: 4px;

        .title {
          font-family: "Inter";
          font-weight: 400;
          font-size: 18px;
          color: #ffffff;
          text-align: left;
          padding: 0 8px 8px;
          border-bottom: 1px solid #ffffff;
        }

        .image-wrapper {
          display: flex;
          align-items: center;
          gap: 8px;
          margin-top: 8px;
          margin-bottom: 18px;
          padding: 0 8px;
          width: 100%;

          img {
            height: auto;
            object-fit: contain;
            max-width: 158px;
            max-height: 104px;

            @media screen and (max-width: 340px) {
              max-width: 120px;
            }
          }
        }

        .description {
          font-family: "Inter";
          font-weight: 400;
          font-size: 13px;
          color: #ffffff;
          text-align: left;
          padding: 0 8px;
        }

        &.new-record {
          background-color: #55606a;

          .img-empty {
            border: 1px dashed #ffffff;
            width: 48%;
            height: 88px;
          }
        }
      }
    }
  }

  .btn-add {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    background-color: #347bc3;
    max-width: 343px;
    height: 85px;
    line-height: 85px;
    width: 100%;
    border: 0;
    border-radius: 4px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;

    p {
      font-family: "Inter";
      font-weight: 400;
      font-size: 25px;
      color: #ffffff;
    }

    img {
      width: 26px;
      height: 26px;
    }
  }

  .work-report-control {
    form {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      text-align: left;
      gap: 29px;
      max-width: 352px;
      width: 100%;
      margin: 0 auto;

      .MuiFormControl-root {
        width: 100%;
      }

      label {
        font-family: "Inter";
        font-weight: 400;
        font-size: 16px;
        color: #000000;
        margin-bottom: 10px;
      }

      .input-field {
        width: 100%;

        input {
          max-width: 352px;
          width: 100%;
          padding: 11px 14px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          /* color: #b2b2b2; */
        }
      }

      .before-photo,
      .after-photo {
        &.ant-upload-wrapper,
        .ant-upload.ant-upload-select {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }

      .upload-wrapper {
        display: flex;
        gap: 14px;
        width: 100%;

        .item {
          width: 100%;
          > div {
            border: 1px dashed #707070;
            max-width: 360px;
            width: 100%;
            padding-top: 75%;
            height: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            span {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            img {
              object-fit: contain;
            }
            .photo {
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      .btn-keep {
        background-color: #b23131;
        padding: 0 23px;
        gap: 8px;
        max-width: 343px;
        height: 85px;
        line-height: 85px;
        width: 100%;
        border: 0;
        border-radius: 4px;
        /* position: fixed;
        bottom: 0;
        left: 0;
        right: 0; */
        margin: 30px auto 0;

        p {
          font-family: "Inter";
          font-weight: 400;
          font-size: 25px;
          color: #ffffff;
          text-align: center;
        }
      }
    }

    .Mui-focused {
      outline: none;
      box-shadow: none;
    }

    input {
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
`;
