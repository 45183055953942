import { Dispatch, SetStateAction } from "react";

import { useFetchHolidays } from "@bms/hooks";

export type ReceivedProps = {
  setIsEdit: Dispatch<SetStateAction<boolean>>;
};

const useHolidaysView = (props: ReceivedProps) => {
  const { data, isLoading } = useFetchHolidays();

  return {
    ...props,
    isLoading,
    data,
  };
};

export type Props = ReturnType<typeof useHolidaysView>;

export default useHolidaysView;
