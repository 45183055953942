import { FC } from "react";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";

import { PencilFilledIcon } from "@bms/assets";
import { Loading, ModalConfirm } from "@bms/components";
import useWorkingHours, { Props, ReceivedProps } from "./hook";
import { Styles } from "./styled";

dayjs.extend(weekday);
dayjs.extend(localeData);

const WorkingHoursLayout: FC<Props> = ({
  openStartDialog,
  isStart,
  isEdit,
  openEndDialog,
  isLoadingButton,
  startTime,
  endTime,
  isDatePickerEnd,
  isDatePickerStart,
  isLoading,
  isFetching,
  disabledDate,
  setIsDatePickerStart,
  setIsDatePickerEnd,
  diffTime,
  onClickEnd,
  onSubmitEnd,
  onSubmitStart,
  setOpenEndDialog,
  setOpenStartDialog,
  flagOnClick,
  setFlagOnClick,
  setStartTime,
  setEndTime,
}) => {
  if (isLoading || isFetching) {
    return <Loading />;
  }

  const handleKeyDown = (e: any, flag: any) => {
    const sanitizedValue = e.target?.value?.replace(/[^\d]/g, "");
    if (sanitizedValue.length >= 12) {
      if (
        e.key !== "Backspace" &&
        e.key !== "ArrowRight" &&
        e.key !== "ArrowLeft" &&
        e.key !== "Enter"
      ) {
        e.preventDefault();
      }
    } else {
      const allowedKeys = /[0-9\-:]/;
      if (
        !allowedKeys.test(e.key) &&
        e.key !== "Backspace" &&
        e.key !== "ArrowRight" &&
        e.key !== "ArrowLeft" &&
        e.key !== "Enter"
      ) {
        e.preventDefault();
      }
    }

    if (e.key == "Enter") {
      const date = dayjs(e.target.value);
      if (flag) {
        onSubmitStart(dayjs(date));
      } else {
        onSubmitEnd(dayjs(date));
      }
    }
  };

  return (
    <Styles>
      <div
        className={`${isDatePickerStart || isDatePickerEnd ? "backdrop" : ""}`}
        onClick={() => {
          if (isDatePickerStart) {
            setIsDatePickerStart(false);
          }
          if (isDatePickerEnd) {
            setIsDatePickerEnd(false);
          }
        }}
      />
      {isStart ? (
        <button className="btn btn-start transparent">
          {/* <p>{dayjs(startTime).format("HH:mm")}</p> */}
          {startTime && (
            <div className={"datepicker-start"}>
              <DatePicker
                className="picker-working-hour"
                popupClassName={`datetime-picker datetime-picker-start ${
                  flagOnClick ? "disable-datetime-picker" : ""
                }`}
                disabledDate={disabledDate}
                showToday={false}
                showNow={false}
                value={dayjs(startTime)}
                onChange={(date) => {
                  setStartTime(date);
                  onSubmitStart(date);
                  setIsDatePickerStart(false);
                  setIsDatePickerEnd(false);
                }}
                inputReadOnly={false}
                allowClear
                placement="bottomLeft"
                open={isDatePickerStart}
                format="YYYY-MM-DD HH:mm"
                showTime
                onClick={() => {
                  setFlagOnClick(true);
                  setIsDatePickerStart(true);
                  setIsDatePickerEnd(false);
                }}
                onKeyDown={(e) => handleKeyDown(e, true)}
              />
            </div>
          )}
          {isEdit && (
            <img
              src={PencilFilledIcon}
              alt=""
              onClick={() => {
                if (flagOnClick || !isDatePickerStart) {
                  setIsDatePickerStart(true);
                } else {
                  setIsDatePickerStart(false);
                }
                setFlagOnClick(false);
                setIsDatePickerEnd(false);
              }}
            />
          )}
        </button>
      ) : (
        <button
          className="btn btn-start"
          onClick={() => setOpenStartDialog(true)}
        >
          <p>開始</p>
        </button>
      )}

      <button
        className={`btn btn-end ${isEdit ? "transparent" : ""}`}
        {...(!isEdit && { onClick: onClickEnd })}
      >
        {!isEdit ? <p>終了</p> : ""}
        {/* <p>{isEdit ? dayjs(endTime).format("HH:mm") : "終了"}</p> */}
        {endTime && (
          <div className={"datepicker-end"}>
            <DatePicker
              className="picker-working-hour"
              popupClassName={`datetime-picker datetime-picker-end ${
                flagOnClick ? "disable-datetime-picker" : ""
              }`}
              disabledDate={disabledDate}
              value={dayjs(endTime)}
              showToday={false}
              showNow={false}
              placement="bottomLeft"
              open={isDatePickerEnd}
              format="YYYY-MM-DD HH:mm"
              inputReadOnly={false}
              allowClear
              onChange={(value) => {
                setEndTime(value);
                onSubmitEnd(value);
                setIsDatePickerEnd(false);
                setIsDatePickerStart(false);
              }}
              showTime
              onClick={() => {
                setFlagOnClick(true);
                setIsDatePickerEnd(true);
              }}
              onKeyDown={(e) => handleKeyDown(e, false)}
            />
          </div>
        )}
        {isEdit && (
          <img
            src={PencilFilledIcon}
            alt=""
            onClick={() => {
              if (flagOnClick || !isDatePickerEnd) {
                setIsDatePickerEnd(true);
              } else {
                setIsDatePickerEnd(false);
              }
              setFlagOnClick(false);
              setIsDatePickerStart(false);
            }}
          />
        )}
      </button>

      {isEdit && (
        <>
          <h3 className="title">作業時間</h3>
          <div className="box">
            <button className="btn btn-date">
              <p>
                <span>{diffTime().hours}時間</span>
                <span>{diffTime().minutes}分</span>
              </p>
            </button>
          </div>
        </>
      )}

      <ModalConfirm
        isOpen={openStartDialog}
        isSubmitting={isLoadingButton}
        content={"作業を開始しますか？"}
        onOk={() => onSubmitStart(dayjs())}
        onClose={() => setOpenStartDialog(false)}
        className="custom-title"
      />

      <ModalConfirm
        isOpen={openEndDialog}
        isSubmitting={isLoadingButton}
        content={"対応済にしますか？"}
        onOk={() => onSubmitEnd(dayjs())}
        onClose={() => setOpenEndDialog(false)}
        className="custom-title"
      />
    </Styles>
  );
};

const WorkingHours: FC<ReceivedProps> = (props) => (
  <WorkingHoursLayout {...useWorkingHours(props)} />
);

export default WorkingHours;
