import styled from "styled-components";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import Select from "@mui/material/Select";

export const Styles = styled.div`
  padding-left: 3px;
  .no-overflow.ag-cell-value,
  .ag-group-value {
    overflow: initial;
  }
  input {
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .btn-group {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;

    .MuiSvgIcon-root {
      height: 18px;
      width: 18px;
      border-radius: 4px;
      border: 1px solid #777777;
    }

    .Mui-checked {
      background-color: #000000;

      &:hover {
        background-color: #000000 !important;
      }
    }

    .MuiCheckbox-root {
      color: #ffffff;
      padding: 0px;
      width: 12px;
      height: 12px;
      border-radius: 4px;

      &:focus {
        outline: none;
      }

      &:hover {
        background-color: #ffffff;
      }
    }

    .btn-clear {
      border-radius: 64px;
      border: 0;
      background-color: var(--sub-color);
      width: 90px;
      margin: 7px 0 6px 10px;
      padding: 6px;
      color: #ffffff;
      text-align: center;
      cursor: pointer;
      margin-right: 50px;
      font-size: 14px;
    }
  }

  .table-inner {
    border: 1px solid #bcbcbc;
    border-radius: 6px;
    filter: drop-shadow(0px 3px 16px rgba(10, 75, 109, 0.078));
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 28px;
    position: relative;
    z-index: 10;

    .btn-delete {
      cursor: pointer;
      margin-left: 10px;
      margin-top: 1px;
    }

    .pagination {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 35px;
      margin-top: -4px;

      .select {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-weight: 400;
        font-size: 15px;
        padding-right: 10px;
        padding-top: 8px;

        .MuiInputBase-root {
          margin-top: -3px;
        }

        > div {
          font-family: "Inter";
          height: 24px;

          &:nth-child(1) {
            transform: translate(-8px, 0);
          }
        }
      }

      .btn-paging {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        padding-top: 5px;

        .paging {
          ul li {
            &:first-child,
            &:last-child {
              display: none;
            }
          }
          .MuiPaginationItem-root.Mui-selected {
            color: #ffffff;
            background-color: var(--sub-color);
          }
        }

        .btn-prev,
        .btn-next {
          background-color: transparent;
          border: none;
          cursor: pointer;
          margin-top: -2px;
        }

        .group-paging {
          display: flex;
          align-items: center;
          gap: 4px;
          margin: 0 20px;

          button {
            font-weight: 400;
            font-size: 15px;
            color: #777777;
            /* width: 24px; */
            /* min-width: 24px; */
            height: 24px;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: all 0.8 ease;
            font-family: "Lato";
          }
        }

        .btn-prev {
          .custom-icon {
            transform: rotate(180deg);
          }
        }

        .btn-next {
          .custom-icon {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
`;

export const TableWrapper = styled(AgGridReact)`
  .ag-pinned-left-header {
    border-right: 1px solid #bcbcbc !important;
    border-top: 1px solid #bcbcbc;
  }

  .ag-pinned-left-cols-container {
    border-right: 1px solid #bcbcbc;

    .ag-cell {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .btn-edit {
      color: var(--main-color);
      cursor: pointer;
      border-radius: 4px;
      height: 22px;
      width: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .ag-root {
    overflow: auto;
    background-color: #ffffff;
  }

  .ag-overlay-loading-wrapper {
    background-color: unset;
  }

  .ag-root-wrapper {
    border: none;
    background-color: transparent;
    /* border-bottom-left-radius: 6px; */
    /* border-bottom-right-radius: 6px; */
    border-radius: 6px;
  }

  .ag-header {
    border-bottom: 1px solid #bcbcbc;
    position: relative;
    z-index: 3;
  }

  .ag-body-viewport {
    position: relative;
    z-index: 2;
  }
  .ag-body-horizontal-scroll-viewport {
    z-index: 3;
  }
  .ag-header-viewport {
    background-color: #ffffff;
    /* border-top: 1px solid #bcbcbc; */
  }

  .ag-header-cell:not(.ag-header-cell-auto-height) {
    .ag-header-cell-comp-wrapper {
      background-color: #ffffff;
    }
  }

  .ag-header-cell,
  .ag-header-group-cell {
    padding-left: 0;
    padding-right: 0;
    background-color: #ffffff;
  }

  .ag-header-group-cell-label,
  .ag-header-cell-label {
    padding: 0 10px;
  }

  .ag-header-cell-label {
    justify-content: space-between;
  }

  .ag-header-icon,
  .ag-floating-filter-button {
    display: none;
  }

  .ag-icon {
    &.ag-icon-none {
      display: block;

      &::before {
        color: #777777;
      }
    }
  }

  .ag-icon-asc,
  .ag-icon-desc {
    display: block;

    &::before {
      color: #777777;
    }
  }

  .ag-header-row {
    &.ag-header-row-column-filter {
      background-color: #ffffff;
    }
  }

  .ag-header-cell {
    .floatingFilter-select {
      /* padding-top: 4px; */
      /* height: 36px; */
      width: 100%;

      .MuiInputBase-root {
        width: 100%;

        &::before {
          border-bottom: 1px solid #c9c9c9;
        }

        &::after {
          border-bottom: none;
        }

        .MuiSelect-select {
          padding: 10px 0 3px;
          height: 23px;
        }

        .MuiInputBase-inputMultiline {
          padding: 6px 0 0px;
          height: 18px;
        }

        svg {
          margin-top: 4px;
        }
      }

      .Mui-focused {
        background-color: #ffffff;

        &::after {
          border-bottom: none;
        }
      }

      .MuiSelect-select {
        font-family: "Lato";
        font-weight: 400;
        font-size: 15px;
        background-color: transparent;
      }
    }

    &:last-child {
      .ag-header-cell-text {
        margin-left: 5px;
        margin-top: 6px;
      }
    }

    &:hover {
      background-color: #ffffff !important;
    }

    .ag-header-cell-text {
      font-family: "Inter";
      margin-left: -6px;
      margin-top: 6px;
    }

    &:nth-child(2) {
      .ag-header-cell-text {
        font-family: "Lato";
      }
    }

    &.ag-floating-filter {
      border-top: 1px solid #bcbcbc !important;
      padding-left: 5px;
      padding-right: 10px;
    }
  }

  .ag-row {
    border-bottom: 1px solid #bcbcbc !important;
    background-color: #ffffff !important ;

    .ag-cell {
      font-family: "Inter";

      /* &:nth-child(2) {
        font-family: "Lato";
      } */
    }

    &.ag-row-even,
    &.ag-row-odd {
      &.ag-row-last.ag-row-position-absolute {
        border-bottom: 1px solid #bcbcbc;
      }
    }

    &.ag-row-odd {
      &.ag-row-last {
        /* border-bottom: none !important; */
      }
    }

    &.ag-row-hover {
      &::before {
        background-color: #ffffff;
      }
    }
  }

  .ag-input-field-input {
    border: none !important;
    border-bottom: 1px solid #c9c9c9 !important;
    border-radius: 0 !important ;
    position: relative;
    padding-left: 0 !important;
    font-family: "Inter";

    &.ag-text-field-input {
      font-weight: 400;
      font-size: 15px;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }

  .ag-header-cell-text {
    color: #777777;
    font-weight: 400;
    font-size: 15px;
    font-size: 15px;
    line-height: 18px;
    overflow: initial;
    text-overflow: unset;
    white-space: normal !important;
  }

  .ag-ltr {
    .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
      border-color: #ffffff !important;
    }

    .ag-header-select-all {
      margin-left: 10px;
      margin-top: 4px;
    }

    .ag-cell {
      font-weight: 400;
      font-size: 15px;
      color: #000000;
      border: none !important ;
      align-items: center;
      line-height: 51px;
      &:not(.ag-cell-value) {
        padding-left: 10px !important;
      }
      &:first-child {
        // padding-left: 10px !important;
      }

      /* &:last-child {
        padding-left: 15px !important;
      } */

      &:not(&:first-child),
      &:not(&:last-child) {
        padding-left: 4px;
      }

      .ag-cell-wrapper {
        height: 100%;
      }

      &:focus {
        outline: none !important;
        box-shadow: none !important;
      }
    }

    .ag-selection-checkbox {
      margin-right: 0;
    }

    .ag-sort-indicator-container {
      margin-top: 3px;
    }

    .ag-sort-indicator-icon {
      &.ag-sort-order {
        display: none;
      }
    }
  }

  input[type="checkbox"] {
    cursor: pointer;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .ag-wrapper.ag-input-wrapper {
    // &.ag-checkbox-input-wrapper {
    //   border: 1px solid #7e7e7e;
    //   box-sizing: content-box;
    //   width: 18px;
    //   height: 17px;
    // }
    &.ag-checkbox-input-wrapper {
      font-size: 18px;
    }

    // &:focus-within,
    // &:active {
    //   box-shadow: none;
    // }

    // &.ag-indeterminate::after {
    //   background-color: #000000;
    //   color: #ffffff;
    //   top: 1px;
    //   left: 1px;
    // }

    // &::after {
    //   background-color: #ffffff;
    //   border-radius: 6px;
    //   color: #ffffff;
    // }

    // &.ag-checked::after {
    //   color: #ffffff;
    //   background-color: #000000;
    //   border-radius: 6px;
    //   top: 1px;
    //   left: 1px;
    // }
  }

  .ag-overlay-wrapper.ag-layout-normal.ag-overlay-no-rows-wrapper {
    color: #777777;
    opacity: 0.8;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .group-action {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 21px;
    cursor: pointer;
    line-height: 51px;
    height: 100%;

    > img {
      &:nth-child(1) {
        margin-left: -3px;
      }
    }
  }

  & input[class^="ag-"]:not([type]),
  & input[class^="ag-"][type="text"],
  & input[class^="ag-"][type="number"],
  & input[class^="ag-"][type="tel"],
  & input[class^="ag-"][type="date"],
  & input[class^="ag-"][type="datetime-local"],
  & textarea[class^="ag-"] {
    min-height: calc(var(--ag-grid-size) * 5);
  }
`;

export const SelectWrapper = styled(Select)`
  .MuiOutlinedInput-notchedOutline {
    border: none !important ;
  }

  .MuiSelect-select {
    margin-right: -7px;
    font-weight: 400;
    font-size: 15px;
    padding-right: 21px !important ;
    padding-bottom: 0 !important ;
    padding-top: 0 !important ;
    font-family: "Lato";
    padding-top: 13px;
  }

  svg {
    position: absolute;
    right: -5px;
    z-index: -1;
    /* margin-top: -3px; */
  }
`;
