import styled from "styled-components";

export const Styles = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 51px;

    h3 {
      color: var(--main-color);
      font-size: 28px;
      font-family: "Inter";
    }

    .group-btn {
      display: flex;
      gap: 25px;
      padding-top: 7px;

      button {
        width: 120px;
        background-color: var(--main-color);
        color: #ffffff;
        height: 37px;
        border: none;
        border-radius: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        font-size: 16px;
        font-weight: 600;
        font-family: "Inter";
      }

      .btn-back {
        background-color: #ffffff;
        border: 2px solid var(--main-color);
        color: var(--main-color);
      }
    }
  }

  .title {
    display: flex;
    align-items: center;
    gap: 152px;
    margin-bottom: 19px;
    margin-left: 7px;

    h3 {
      font-family: "Inter";
      font-weight: 400;
      font-size: 20px;
      color: var(--main-color);
    }
  }

  .field-wrapper {
    display: flex;
    align-items: center;
    margin-left: 7px;
    margin-bottom: 19px;
    gap: 17px;

    .separator {
      font-family: "Inter";
      font-weight: 400;
      font-size: 20px;
      color: var(--main-color);
      margin-right: 28px;
      padding-top: 19px;
    }

    p {
      border: 1px solid #cdcdcd;
      border-radius: 6px;
      width: 130px;
      font-family: "Inter";
      font-weight: 400;
      font-size: 16px;
      color: #000000;
      height: 52px;
      line-height: 52px;
      padding-left: 14px;

      &.placeholder {
        color: #adadad;
        font-size: 16px;
      }
    }
  }
`;
