import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormGetValues,
  UseFormRegister,
  UseFormReset,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
  useFieldArray,
} from "react-hook-form";

export type ReceivedProps = {
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  reset: UseFormReset<FieldValues>;
  trigger: UseFormTrigger<FieldValues>;
  errors: FieldErrors<FieldValues>;
  control: Control<FieldValues>;
  isView: boolean;
};

const useWorkCost = (props: ReceivedProps) => {
  const { fields, append, remove } = useFieldArray({
    control: props.control,
    name: "work_cost_items",
  });

  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);

  useEffect(() => {
    if (props.reset && isEmpty(props.getValues("work_cost_items"))) {
      props.reset({
        ...props.getValues(),
        work_cost_items: [
          {
            material_cost: null,
            product_name: null,
            part_number: null,
            quantity: null,
          },
        ],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleValue = () => {
    const workCostItems =
      props.getValues("work_cost_items")?.length < 0
        ? 0
        : props
            .getValues("work_cost_items")
            .reduce(
              (cur: any, value: any) =>
                cur +
                (Number(value?.material_cost) * Number(value?.quantity) || 0),
              0
            );
    const businesTripFee = +props.getValues("busines_trip_fee") || 0;
    const workFee = +props.getValues("work_fee") || 0;
    const disposalFee = +props.getValues("disposal_fee") || 0;

    props.setValue("total_material_cost", workCostItems);
    props.setValue(
      "total",
      businesTripFee + workFee + disposalFee + workCostItems
    );
  };

  return {
    ...props,
    fields,
    openModalDelete,
    setOpenModalDelete,
    remove,
    handleValue,
    append,
  };
};

export type Props = ReturnType<typeof useWorkCost>;

export default useWorkCost;
