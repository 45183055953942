import { FC } from "react";
import Scheduler, { Editing, Resource } from "devextreme-react/scheduler";
import "devextreme/dist/css/dx.light.css";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

import { Loading } from "@bms/components";
import { LOCALE_JP, QUERY_NAME, ROUTES } from "@bms/constants";
import { ClockIcon, DateIcon, NextIcon, PreviousIcon } from "@bms/assets";
import useSchedule, { Props, ReceivedProps } from "./hook";
import { Styles } from "./styled";
import { useScreenWidth } from "@bms/hooks";
import { formatDay } from "@bms/utility";

const ScheduleLayout: FC<Props> = ({
  workerColumn,
  isLoading,
  isFetching,
  currentDate,
  schedule,
  open,
  value,
  queryClient,
  setWorkerColumn,
  setScheduleData,
  setValue,
  setCurrentDate,
  setOpen,
  getDate,
}) => {
  const isMobile = useScreenWidth();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isMySchedule = `/${pathname.split("/")?.[1]}` === ROUTES.MY_SCHEDULE;

  return (
    <Styles className={`${isMySchedule ? "my-schedule" : ""}`}>
      {!isMySchedule && (
        <div className="header">
          <h3>スケジュール</h3>
          <div className="date">
            <img onClick={() => getDate("previous")} src={PreviousIcon} />
            <div>{`${dayjs(currentDate).format("YYYY.M.DD")}(${formatDay(
              currentDate
            )})`}</div>
            <img onClick={() => getDate("next")} src={NextIcon} />
          </div>

          <img src={DateIcon} onClick={() => setOpen(true)} />
        </div>
      )}

      {isLoading || isFetching ? (
        <Loading />
      ) : (
        <Scheduler
          timeZone="UTC+9"
          dataSource={schedule}
          views={["day"]}
          defaultCurrentView="day"
          defaultCurrentDate={new Date(currentDate.toISOString())}
          groups={["workerId"]}
          height={680}
          firstDayOfWeek={0}
          cellDuration={30}
          startDayHour={1}
          endDayHour={24}
          editing={false}
          showAllDayPanel={false}
          crossScrollingEnabled={true}
          timeCellComponent={(props: any) => {
            return (
              <>
                {props.index % 2 ? (
                  <></>
                ) : (
                  <div {...props}>
                    {dayjs(props.data.date).locale("en").format("h A")}
                  </div>
                )}
              </>
            );
          }}
          focusStateEnabled={false}
          onAppointmentDblClick={(e) => {
            e.cancel = true;
          }}
          appointmentDragging={{
            onDragStart: (e) => {
              e.cancel = true;
            },
          }}
          onAppointmentTooltipShowing={(e) => {
            e.cancel = true;
          }}
          appointmentComponent={(props: any) => {
            const {
              status,
              text,
              startDate,
              endDate,
              service_card_id,
              content,
              building_name,
              resident_room_number,
              resident_name,
            } = props.data.appointmentData || {};
            return (
              <div
                className={`appointment ${status}`}
                {...props}
                onClick={() => {
                  if (
                    !isMobile &&
                    !["finished", "in_progress"].includes(status)
                  ) {
                    navigate(`${ROUTES.SERVICE_CARD}/${service_card_id}`, {
                      state: {
                        tabKey: "4",
                      },
                    });
                  }

                  if (isMobile) {
                    if (status === "finished" || status === "in_progress") {
                      navigate(`${ROUTES.WORKING_HOURS}/${service_card_id}`);
                    } else {
                      navigate(
                        `${ROUTES.MY_SERVICE_CARD_TICKET}/${service_card_id}`
                      );
                    }
                  }
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "3px",
                    fontFamily: "Inter",
                    fontWeight: "400",
                    fontSize: "12px",
                    color: "#FFFFFF",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  <img src={ClockIcon} alt="" />
                  <div>{startDate ? dayjs(startDate).format("HH:mm") : ""}</div>
                  <span>-</span>
                  <div>
                    {endDate && status !== "in_progress"
                      ? dayjs(endDate).format("HH:mm")
                      : ""}
                  </div>
                </div>
                <div
                  style={{
                    fontFamily: "Inter",
                    fontWeight: "700",
                    fontSize: "12px",
                    color: "#FFFFFF",
                    whiteSpace: "normal",
                    wordBreak: "break-word",
                    textAlign: "center",
                  }}
                >
                  <p className="multiline-content">{content}</p>
                  <p>{building_name}</p>
                  <p>{resident_room_number}</p>
                  <p>{resident_name}</p>
                </div>
              </div>
            );
          }}
        >
          <Editing allowAdding={false} />
          <Resource dataSource={workerColumn} fieldExpr="workerId" />
        </Scheduler>
      )}

      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={LOCALE_JP}
      >
        <MobileDatePicker
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          onAccept={(e) => {
            setWorkerColumn([]);
            setScheduleData([]);
            setCurrentDate(dayjs(e));
            setValue(e);
            queryClient.invalidateQueries([QUERY_NAME.SCHEDULES]);
          }}
          onChange={(e) => {
            setValue(e);
          }}
          value={value}
          components={{
            ActionBar: ({ onAccept }: any) => (
              <div className="MuiDialogActions-root">
                <button onClick={() => setOpen(false)}>クリア</button>
                <button
                  onClick={() => {
                    // setWorkerColumn([]);
                    // setScheduleData([]);
                    queryClient.invalidateQueries([QUERY_NAME.SCHEDULES]);
                    onAccept();
                    setOpen(false);
                  }}
                >
                  OK
                </button>
              </div>
            ),
          }}
          renderInput={() => <></>}
        />
      </LocalizationProvider>
    </Styles>
  );
};

const Schedule: FC<ReceivedProps> = (props) => (
  <ScheduleLayout {...useSchedule(props)} />
);

export default Schedule;
