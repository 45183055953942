import { FC } from "react";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";

import { ModalWrapper, StylesModal } from "./styled";

type IProps = {
  isOpen: boolean;
  isSubmitting: boolean;
  onLogOut: () => Promise<void>;
  onClose: () => void;
};

const ModalConfirmLogout: FC<IProps> = ({
  isSubmitting,
  isOpen,
  onLogOut,
  onClose,
}) => {
  return (
    <ModalWrapper open={isOpen} onClose={onClose}>
      <Box sx={{ ...StylesModal, width: 310, height: 214 }}>
        <h3>ログアウトしますか？</h3>

        <div className="modal-footer">
          <div className="group-btn">
            <LoadingButton
              loadingPosition="start"
              loading={isSubmitting}
              type="submit"
              variant="outlined"
              className="btn-submit"
              onClick={onLogOut}
            >
              はい
            </LoadingButton>
            <button
              className={`btn-cancel ${isSubmitting ? "disabled" : ""}`}
              onClick={onClose}
              disabled={isSubmitting}
            >
              いいえ
            </button>
          </div>
        </div>
      </Box>
    </ModalWrapper>
  );
};

export default ModalConfirmLogout;
