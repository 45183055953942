import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { ColumnApi, GridApi, GridSizeChangedEvent } from "ag-grid-community";
import { get } from "lodash";
import { FieldValues, UseFormGetValues } from "react-hook-form";

import { SORTING } from "@bms/constants";
import { useDeletePaymentCategory, useFetchOutputs } from "@bms/hooks";
import { ServiceCard } from "@bms/types";
import SelectFloatingFilterComponent, { columns, SORT_PARAMS } from "./utility";

const columnDefsSetting: any = columns().map((item, index) => {
  return {
    ...item,
    unSortIcon: ![0, 14].includes(index),
    ...([8, 9, 11].includes(index) && {
      floatingFilterComponent: SelectFloatingFilterComponent,
    }),
    filterParams: {
      textMatcher: () => {
        return true;
      },
    },
  };
});
export type ReceivedProps = {
  filterParams: any;
  gridApi: GridApi | undefined;
  setGridApi: Dispatch<SetStateAction<GridApi | undefined>>;
  getValues: UseFormGetValues<FieldValues>;
  setFilterParams: Dispatch<SetStateAction<any>>;
};

const useOutputList = (props: ReceivedProps) => {
  const gridRef: any = useRef();

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(100);
  const [total, setTotal] = useState<number>(0);
  const [rowData, setRowData] = useState<any[]>([]);
  const [isOpenModalDelete, setIsOpenModalDelete] = useState<boolean>(false);
  const [sortParams, setSortParams] = useState<Record<string, any>>({});
  const [paymentIds, setPaymentIds] = useState<{ id: number }[]>([]);

  const {
    data: outPutsData,
    isSuccess,
    isLoading,
    isFetching,
  } = useFetchOutputs(page, pageSize, props.filterParams, sortParams);
  const { mutate, isLoading: isSubmitting } = useDeletePaymentCategory();

  const onSortChanged = ({ columnApi }: { columnApi: ColumnApi }) => {
    const columnState = columnApi.getColumnState();
    const cusParams: any = columnState.reduce(
      (arr, cur) => ({
        ...arr,
        [get(SORT_PARAMS, cur.colId)]:
          cur.sort === null ? SORTING.ASC : cur.sort,
      }),
      {}
    );

    setSortParams({
      ...sortParams,
      ...cusParams,
    });
    setPage(1);
  };

  const onFilterUpdate = useCallback(() => {
    if (!props.gridApi) return;
    const model = props.gridApi.getFilterModel();

    const curParams = Object.keys(model)
      .filter((item) => !["order_date", "return_order_date"].includes(item))
      .reduce(
        (arr: any, cur: any) => ({
          ...arr,
          [cur]: model?.[cur]?.filter,
        }),
        {}
      );

    const orderDateField = model?.order_date
      ? {
          start_order_date: model?.order_date?.filter?.split("-")?.[0],
          end_order_date: model?.order_date?.filter?.split("-")?.[1],
        }
      : {};
    const returnDateField = model?.return_order_date
      ? {
          start_return_order_date:
            model?.return_order_date?.filter?.split("-")?.[0],
          end_return_order_date:
            model?.return_order_date?.filter?.split("-")?.[1],
        }
      : {};

    props.setFilterParams({
      ...props.filterParams,
      ...curParams,
      ...orderDateField,
      ...returnDateField,
    });
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.gridApi]);

  const onOpenModalDelete = () => {
    if (!props.gridApi) return;
    const rowSelected = props.gridApi.getSelectedRows();
    setPaymentIds(rowSelected.map((item) => ({ id: item.id })));
    setIsOpenModalDelete(true);
  };
  const onCloseModalDelete = () => setIsOpenModalDelete(false);

  const onDeleteRecord = async () => {
    mutate(
      {
        payload: { payment_category_attributes: paymentIds },
      },
      {
        onSuccess() {
          setRowData([]);
          setIsOpenModalDelete(false);
        },
      }
    );
  };

  const onGridReady = (params: GridSizeChangedEvent) => {
    props.setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  useEffect(() => {
    if (isLoading || isFetching) {
      setRowData([]);
    } else {
      if (isSuccess) {
        setRowData(
          (outPutsData.data as ServiceCard[]).map((item) => {
            return {
              ...item,
              order_date: item?.request_content?.order_date,
              return_order_date: item?.request_content?.return_order_date,
              building_name:
                item?.order_information?.building_data?.building_name || "",
              owner_name:
                item?.order_information?.building_data?.owner_name || "",
              resident_name:
                item?.order_information?.building_data?.resident_name || "",
              resident_contact:
                item?.order_information?.building_data?.resident_contact || "",
              working_status: item?.status?.working_status || "",
              invoice_status: item?.status?.invoice_status || "",
              paid_at: item?.status?.paid_at || "",
              billing_amount: item?.work_cost?.total
                ? item?.work_cost?.total
                : "",
              confirm_payment_status:
                item?.status?.confirm_payment_status || "",
              billing_address:
                item?.order_information?.address_payment_type?.[0] ===
                "オーナー"
                  ? item?.order_information?.building_data?.owner_address
                  : item?.order_information?.address_payment_type?.[0] ===
                    "契約者"
                  ? item?.order_information?.building_data?.contractor_address
                  : "",
            };
          })
        );
        setTotal(outPutsData.meta.total_records);
      }
    }
  }, [isFetching, isLoading, isSuccess, outPutsData]);

  return {
    ...props,
    rowData,
    pageSize,
    gridRef,
    page,
    isOpenModalDelete,
    isSubmitting,
    total,
    columnDefs: columnDefsSetting,
    isLoading,
    isFetching,
    onSortChanged,
    onFilterUpdate,
    onDeleteRecord,
    onOpenModalDelete,
    onCloseModalDelete,
    setPage,
    onGridReady,
    setPageSize,
  };
};

export type Props = ReturnType<typeof useOutputList>;

export default useOutputList;
