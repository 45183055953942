import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { Note, Pen, Plus } from "@bms/assets";
import { ROUTES } from "@bms/constants";
import { ModalDelete, Table } from "@bms/components";
import useShiftList, { Props, ReceivedProps } from "./hook";
import { Styles } from "./styled";

const ShiftListLayout: FC<Props> = ({
  rowData,
  pageSize,
  isLoading,
  isFetching,
  gridApi,
  gridRef,
  page,
  isOpenModalDelete,
  isSubmitting,
  columnDefs,
  total,
  onSortChanged,
  onFilterUpdate,
  onDeleteRecord,
  onOpenModalDelete,
  onCloseModalDelete,
  setPage,
  onGridReady,
  setPageSize,
}) => {
  const navigate = useNavigate();

  if (rowData && rowData.length > 0) {
    columnDefs = [
      ...columnDefs,
      {
        minWidth: 100,
        maxWidth: 100,
        field: "動作",
        floatingFilter: false,
        cellRenderer: ({ data }: any) => {
          const { workerShift, id, name } = data;
          return (
            <div className="group-action">
              <img
                src={Note}
                onClick={() =>
                  navigate(
                    `${workerShift?.id ? workerShift.id : ROUTES.CREATE}`,
                    {
                      state: {
                        view: Boolean(workerShift?.id),
                        worker: {
                          value: id,
                          label: name,
                        },
                      },
                    }
                  )
                }
              />
              <img
                src={Pen}
                onClick={() =>
                  navigate(
                    `${workerShift?.id ? workerShift.id : ROUTES.CREATE}`,
                    {
                      state: {
                        worker: {
                          value: id,
                          label: name,
                        },
                      },
                    }
                  )
                }
              />
            </div>
          );
        },
      },
    ];
  }

  return (
    <Styles>
      <div className="header">
        <h3>シフト一覧</h3>
        <div className="group-btn">
          <button onClick={() => navigate(ROUTES.CALENDAR)}>
            <p>カレンダー</p>
          </button>
          <button onClick={() => navigate(ROUTES.CREATE)}>
            <p>シフト作成</p>
            <img src={Plus} />
          </button>
        </div>
      </div>

      <div className="table-wrapper">
        <Table
          total={total}
          page={page}
          pageSize={pageSize}
          gridRef={gridRef}
          rowData={rowData}
          onSortChanged={onSortChanged}
          onFilterUpdate={onFilterUpdate}
          columnDefs={columnDefs}
          loading={isLoading || isFetching}
          gridApi={gridApi}
          setPage={setPage}
          setPageSize={setPageSize}
          onOpenModalDelete={onOpenModalDelete}
          onGridReady={onGridReady}
        />
      </div>

      <ModalDelete
        isOpen={isOpenModalDelete}
        isSubmitting={isSubmitting}
        onDeleteRecord={onDeleteRecord}
        onClose={onCloseModalDelete}
      />
    </Styles>
  );
};

export const ShiftList: FC<ReceivedProps> = (props) => (
  <ShiftListLayout {...useShiftList(props)} />
);

export default ShiftList;
