import { useCallback, useEffect, useRef, useState } from "react";
import { ColumnApi, GridApi, GridSizeChangedEvent } from "ag-grid-community";
import { get } from "lodash";

import { SORTING } from "@bms/constants";
import {
  useDeletePaymentCategory,
  useFetchPaymentCategories,
} from "@bms/hooks";
import { columns, SORT_PARAMS } from "./utility";

export type ReceivedProps = Record<string, any>;

const columnDefsSetting: any = columns().map((item, index) => ({
  ...item,
  unSortIcon: index !== 0,
  filter: "agTextColumnFilter",
  filterParams: {
    textMatcher: () => {
      return true;
    },
  },
}));
const usePaymentList = (props: ReceivedProps) => {
  const gridRef: any = useRef();

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(100);
  const [total, setTotal] = useState<number>(0);
  const [rowData, setRowData] = useState<any[]>([]);
  const [gridApi, setGridApi] = useState<GridApi | undefined>();
  const [isOpenModalDelete, setIsOpenModalDelete] = useState<boolean>(false);
  const [filterParams, setFilterParams] = useState<Record<string, any>>({});
  const [sortParams, setSortParams] = useState<Record<string, any>>({});
  const [paymentIds, setPaymentIds] = useState<{ id: number }[]>([]);

  const {
    data: paymentCategoriesData,
    isSuccess,
    isLoading,
    isFetching,
  } = useFetchPaymentCategories(page, pageSize, filterParams, sortParams);
  const { mutate, isLoading: isSubmitting } = useDeletePaymentCategory();

  const onSortChanged = ({ columnApi }: { columnApi: ColumnApi }) => {
    const columnState = columnApi.getColumnState();
    const cusParams: any = columnState.reduce(
      (arr, cur) => ({
        ...arr,
        [get(SORT_PARAMS, cur.colId)]:
          cur.sort === null ? SORTING.ASC : cur.sort,
      }),
      {}
    );

    setSortParams({
      ...sortParams,
      ...cusParams,
    });
    setPage(1);
  };

  const onFilterUpdate = useCallback(() => {
    if (!gridApi) return;
    const model = gridApi.getFilterModel();
    const curParams = Object.keys(model).reduce(
      (arr, cur) => ({
        ...arr,
        [cur]: model[cur].filter,
      }),
      {}
    );
    setFilterParams({
      ...filterParams,
      ...curParams,
    });
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridApi]);

  const onOpenModalDelete = () => {
    if (!gridApi) return;
    const rowSelected = gridApi.getSelectedRows();
    setPaymentIds(rowSelected.map((item) => ({ id: item.id })));
    setIsOpenModalDelete(true);
  };
  const onCloseModalDelete = () => setIsOpenModalDelete(false);

  const onDeleteRecord = async () => {
    mutate(
      {
        payload: { payment_category_attributes: paymentIds },
      },
      {
        onSuccess() {
          setRowData([]);
          setIsOpenModalDelete(false);
        },
      }
    );
  };

  const onGridReady = (params: GridSizeChangedEvent) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  useEffect(() => {
    if (isLoading || isFetching) {
      setRowData([]);
    } else {
      if (isSuccess) {
        setRowData(paymentCategoriesData.payment_categories);
        setTotal(paymentCategoriesData.meta.total_records);
      }
    }
  }, [isFetching, isLoading, isSuccess, paymentCategoriesData]);

  return {
    ...props,
    rowData,
    pageSize,
    gridRef,
    gridApi,
    page,
    isOpenModalDelete,
    isSubmitting,
    total,
    columnDefs: columnDefsSetting,
    isLoading,
    isFetching,
    onSortChanged,
    onFilterUpdate,
    onDeleteRecord,
    onOpenModalDelete,
    onCloseModalDelete,
    setPage,
    onGridReady,
    setPageSize,
  };
};

export type Props = ReturnType<typeof usePaymentList>;

export default usePaymentList;
