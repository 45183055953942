import * as yup from "yup";

import { ERRORS_MESSAGE } from "@bms/constants";

export const validation = () => {
  return yup.object().shape({
    title: yup.string().nullable().required(ERRORS_MESSAGE["REQUIRED"]),
    comment: yup.string().nullable(),
  });
};
