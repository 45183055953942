import styled from "styled-components";

export const Styles = styled.div`
  /* .main {
    padding-right: 0 !important;
  } */

  button {
    width: 239px;
    background-color: #347bc3;
    color: #ffffff;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-family: "Inter";
    width: 184px;
    height: 46px;
    border-radius: 4px;
    margin-top: 160px;

    p {
      font-family: "Inter";
      font-weight: 400;
      font-size: 11px;
      color: #ffffff;
    }
  }

  label {
    font-family: "Inter";
    font-weight: 400;
    font-size: 14px;
    color: #000000 !important;
    margin-bottom: 9px;

    &.Mui-focused,
    &.MuiInputLabel-shrink {
      top: 0;
    }
  }

  .field-wrapper {
    display: flex;
    flex-direction: row;
    gap: 29px;
    position: relative;

    &:nth-child(2) {
      margin-bottom: 50px;
    }

    &:nth-child(1),
    &:nth-child(3),
    &.additional-field {
      .MuiOutlinedInput-notchedOutline {
        border-color: #bcbcbc;
        border-width: 1px;

        &:hover {
          border-color: #bcbcbc;
        }
      }

      .MuiFormControl-root {
        width: 100%;

        .MuiInputBase-input {
          font-weight: 400;
          font-size: 16p;
          font-family: "Inter";
        }
      }

      .input-field {
        width: 214.5px;
      }
    }

    &.add &:nth-child(3),
    &.additional-field {
      margin-top: 30px;
      margin-bottom: 30px;

      .input-field {
        width: 307px;

        &:nth-child(4) {
          width: 160px;
        }
      }
    }

    &:nth-child(2) {
      margin-top: 54px;

      .MuiFormLabel-root.Mui-focused {
        color: #adadad;
      }

      .MuiFormControl-root {
        width: 100%;

        .MuiInputBase-input {
          font-weight: 400;
          font-size: 16px;
          font-family: "Inter";
        }
      }

      .MuiInputBase-root {
        &:hover:not(.Mui-disabled):before,
        &::after {
          border-bottom: 1px solid #bcbcbc;
        }

        &:before {
          border-bottom: 1px solid #bcbcbc;
        }
      }

      .MuiInputBase-input {
        &::after {
          border-bottom: 1px solid #bcbcbc;
        }
      }
    }
  }

  input {
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
`;
