import styled from "styled-components";

export const Styles = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 195px;
  background: var(--main-color);
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
    background: transparent;
  }

  .logo {
    width: 100%;
    padding: 17px 23px 1px 19px;
    margin-bottom: 30px;

    img {
      width: 100%;
      object-fit: contain;
      object-position: center;
    }
  }

  li {
    color: #ffffff c3;
    font-size: 16px;
    font-weight: 400;

    &:nth-child(1) {
      img {
        margin-top: 4px;
      }
    }

    &:nth-child(5) {
      img {
        margin-left: 2px;
      }
    }

    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(9),
    &:nth-child(10) {
      img {
        margin-left: 2px;
      }
    }

    &:nth-child(12),
    &:nth-child(13),
    &:nth-child(14) {
      img {
        margin-left: 4px;
      }
    }

    a {
      position: relative;
      display: flex;
      padding-left: 13px;
      align-items: center;
      height: 47px;
      width: 100%;
      font-weight: 400;
      font-size: 16px;
      color: #ffffff;
      font-family: "Inter";
    }

    a.active {
      background: var(--sub-color);
      font-weight: 400;
    }

    a.active::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 0.3rem;
      background: #ffffff;
    }

    a div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 2rem;
    }
  }
`;
