import { forwardRef, useRef } from "react";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { Select as AntSelect } from "antd";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  CLAIM_STATUS_OPTIONS,
  PAYMENT_STATUS_OPTIONS,
  WORK_STATUS_OPTIONS,
} from "@bms/constants";
import { NotificationIcon } from "@bms/assets";
import { formatDay } from "@bms/utility";

// eslint-disable-next-line react/display-name
export default forwardRef((props: any) => {
  const inputRef: any = useRef<HTMLInputElement>(null);
  const { column } = props;
  const options = () => {
    switch (column.colId) {
      case "working_status":
        return WORK_STATUS_OPTIONS;
      case "invoice_status":
        return CLAIM_STATUS_OPTIONS;
      case "confirm_payment_status":
        return PAYMENT_STATUS_OPTIONS;
      default:
        return [];
    }
  };

  const onBoxChanged = (input: SelectChangeEvent) => {
    if (input.target.value === "") {
      props.parentFilterInstance((instance: any) => {
        instance.onFloatingFilterChanged(null, null);
      });
      return;
    }

    props.parentFilterInstance((instance: any) => {
      instance.onFloatingFilterChanged("greaterThan", input.target.value);
    });
  };

  return (
    <div className="floatingFilter-select">
      <Select
        placeholder="絞り込み"
        variant="standard"
        ref={inputRef}
        onChange={onBoxChanged}
        defaultValue="絞り込み"
      >
        <MenuItem value="絞り込み" style={{ display: "none", width: 100 }}>
          <span style={{ color: "#b9b9b9" }}>絞り込み</span>
        </MenuItem>
        {options().map((item) => (
          <MenuItem value={item.value} key={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
});

export const columns = (onNavigateTab: any, onStatusChange: any) => {
  return [
    {
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      maxWidth: 40,
      floatingFilter: false,
    },
    {
      headerName: "受付ID",
      field: "id",
      minWidth: 100,
    },
    {
      headerName: "訪問日",
      field: "order_date",
      minWidth: 250,
      cellClass: "no-overflow",
      cellRenderer: (e: any) => {
        return (
          <div style={{}}>
            <div style={{ lineHeight: "normal" }}>
              {e.value &&
                `${moment(e.value).format("YYYY/MM/DD")}(${formatDay(
                  e.value
                )})`}
            </div>
            {e.data?.return_order_date ? (
              <div
                style={{
                  lineHeight: "normal",
                  marginLeft: -24,
                }}
              >
                &nbsp;
                <span
                  style={{
                    color: "red",
                    border: "1px solid red",
                  }}
                >
                  再
                </span>
                &nbsp;
                <span>
                  {`${dayjs(e.data?.return_order_date).format("YYYY/MM/DD")}
                    (${formatDay(e.data?.return_order_date)})`}
                </span>
              </div>
            ) : (
              ""
            )}
          </div>
        );
      },
      floatingFilterComponent: (props: any) => (
        <DatePicker.RangePicker
          placeholder={["範囲選択", "範囲選択"]}
          bordered={false}
          showTime={false}
          style={{ paddingLeft: 0, fontWeight: "bold" }}
          format="YYYY/MM/DD"
          onChange={(value) => {
            if (value === null) {
              props.parentFilterInstance((instance: any) => {
                instance.onFloatingFilterChanged(null, null);
              });
              return;
            }

            props.parentFilterInstance((instance: any) => {
              instance.onFloatingFilterChanged(
                "greaterThan",
                `${dayjs(value[0]).format("YYYY/MM/DD")}-${dayjs(
                  value[1]
                ).format("YYYY/MM/DD")}`
              );
            });
          }}
        />
      ),
    },
    {
      headerName: "受注日",
      field: "order_date_search",
      minWidth: 250,
      cellRenderer: (e: any) => {
        return (
          <>
            {e?.data?.order_information?.order_date && (
              <div>{`${dayjs(e?.data?.order_information?.order_date).format(
                "YYYY/MM/DD"
              )}(${formatDay(e?.data?.order_information?.order_date)})`}</div>
            )}
          </>
        );
      },
      floatingFilterComponent: (props: any) => (
        <DatePicker.RangePicker
          placeholder={["範囲選択", "範囲選択"]}
          bordered={false}
          style={{ paddingLeft: 0, fontWeight: "bold" }}
          format="YYYY/MM/DD"
          onChange={(value) => {
            if (value === null) {
              props.parentFilterInstance((instance: any) => {
                instance.onFloatingFilterChanged(null, null);
              });
              return;
            }

            props.parentFilterInstance((instance: any) => {
              instance.onFloatingFilterChanged(
                "greaterThan",
                `${dayjs(value[0]).format("YYYY/MM/DD")}-${dayjs(
                  value[1]
                ).format("YYYY/MM/DD")}`
              );
            });
          }}
        />
      ),
    },
    {
      headerName: "担当作業員①",
      field: "department_in_charge",
      minWidth: 160,
      cellRenderer: (e: any) => {
        return (
          <>
            {e?.data?.request_content?.workers?.length > 0 ? (
              <div>{e?.data?.request_content?.workers[0]?.name}</div>
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      headerName: "建物名",
      field: "building_name",
      minWidth: 260,
    },
    {
      headerName: "オーナー名",
      field: "owner_name",
      minWidth: 160,
    },
    {
      headerName: "入居者名",
      field: "resident_name",
      minWidth: 260,
    },
    {
      headerName: "入居者連絡先",
      field: "resident_contact",
      minWidth: 150,
      floatingFilter: false,
    },
    {
      headerName: "作業",
      field: "working_status",
      minWidth: 100,
      cellRenderer: (e: any) => {
        return (
          <AntSelect
            suffixIcon={null}
            bordered={false}
            popupClassName="select-custom service-card"
            className="select-custom no-padding"
            defaultValue={e.value}
            onChange={(value) => {
              onStatusChange?.({ ...e.data, working_status: value });
            }}
          >
            {[
              { value: "resolved", label: "対応済" },
              { value: "not_compatible", label: "未対応" },
            ].map((item) => (
              <AntSelect.Option
                label={item.label}
                value={item.value}
                key={item.value}
              >
                <div className={`tag option-wrapper ${item.value}`}>
                  <p>{item.label}</p>
                </div>
              </AntSelect.Option>
            ))}
          </AntSelect>
        );
      },
    },
    {
      headerName: "請求",
      field: "invoice_status",
      minWidth: 100,
      cellRenderer: (e: any) => {
        return (
          <AntSelect
            suffixIcon={null}
            bordered={false}
            popupClassName="select-custom service-card"
            className="select-custom no-padding"
            defaultValue={e.value}
            onChange={(value) => {
              onStatusChange?.({ ...e.data, invoice_status: value });
            }}
          >
            {[
              { value: "billed", label: "請求済" },
              { value: "unclaimed", label: "未請求" },
            ].map((item) => (
              <AntSelect.Option
                label={item.label}
                value={item.value}
                key={item.value}
              >
                <div className={`tag option-wrapper ${item.value}`}>
                  <p>{item.label}</p>
                </div>
              </AntSelect.Option>
            ))}
          </AntSelect>
        );
      },
    },
    {
      headerName: "請求日時",
      field: "paid_at",
      minWidth: 180,
      cellRenderer: (e: any) => {
        return (
          <>
            {e.value && (
              <div>
                {`${moment(e.value).format("YYYY.MM.DD HH:mm")}(${formatDay(
                  e.value
                )})`}
              </div>
            )}
          </>
        );
      },
      floatingFilterComponent: (props: any) => (
        <DatePicker
          showTime={false}
          placeholder="絞り込み"
          bordered={false}
          style={{ paddingLeft: 0, fontWeight: "bold" }}
          format="YYYY/MM/DD"
          onChange={(value) => {
            if (value === null) {
              props.parentFilterInstance((instance: any) => {
                instance.onFloatingFilterChanged(null, null);
              });
              return;
            }

            props.parentFilterInstance((instance: any) => {
              instance.onFloatingFilterChanged(
                "greaterThan",
                dayjs(value).format("YYYY/MM/DD")
              );
            });
          }}
        />
      ),
    },
    {
      headerName: "入金",
      field: "confirm_payment_status",
      minWidth: 100,
      cellRenderer: (e: any) => {
        return (
          <AntSelect
            suffixIcon={null}
            bordered={false}
            popupClassName="select-custom service-card"
            className="select-custom no-padding"
            defaultValue={e.value}
            onChange={(value) => {
              onStatusChange?.({ ...e.data, confirm_payment_status: value });
            }}
          >
            {[
              { value: "paid", label: "入金済" },
              { value: "not_payment", label: "未入金" },
            ].map((item) => (
              <AntSelect.Option
                label={item.label}
                value={item.value}
                key={item.value}
              >
                <div className={`tag option-wrapper ${item.value}`}>
                  <p>{item.label}</p>
                </div>
              </AntSelect.Option>
            ))}
          </AntSelect>
        );
      },
    },
    {
      headerName: "請求金額",
      field: "billing_amount",
      minWidth: 110,
      cellRenderer: (e: any) => e.value.toLocaleString("ja-JP"),
    },
    {
      headerName: "請求先",
      field: "billing_address",
      minWidth: 90,
      cellRenderer: (e: any) => {
        const isContractor =
          e?.data?.order_information?.address_payment_type === "contractor";
        const isTenant =
          e?.data?.order_information?.address_payment_type === "tenant";
        const isOwner =
          e?.data?.order_information?.address_payment_type === "owner";
        const isGeneral =
          e?.data?.order_information?.address_payment_type === "general";
        const isNomuraDevelopment =
          e?.data?.order_information?.address_payment_type ===
          "nomura_development";

        return (
          <>
            <div>
              {isContractor
                ? e?.data?.order_information?.building_data
                    ?.contractor_address ||
                  e?.data?.order_information?.building?.contractor?.address
                : isTenant
                ? e?.data?.order_information?.resident_address ||
                  e?.data?.order_information?.building?.resident?.address
                : isOwner
                ? e?.data?.order_information?.owner_address ||
                  e?.data?.order_information?.building?.owner?.address
                : isGeneral
                ? e?.data?.resident_name ||
                  e?.data?.order_information?.building?.resident?.name
                : isNomuraDevelopment
                ? "野村開発(営業3家)"
                : ""}
            </div>
          </>
        );
      },
    },
    {
      headerName: "通知",
      field: "has_notification",
      minWidth: 90,
      floatingFilter: false,
      cellRenderer: (e: any) => {
        const {
          status,
          order_information,
          contractor_information,
          request_content,
          work_reports,
          work_cost,
          payment,
          memo,
        } = e?.data || {};

        const hasNotification =
          status?.has_notification ||
          order_information?.has_notification ||
          contractor_information?.has_notification ||
          request_content?.has_notification ||
          work_reports.find((item: any) => item?.has_notification) ||
          payment?.has_notification ||
          work_cost?.has_notification ||
          memo?.has_notification;

        return (
          <>
            {hasNotification && (
              <img
                style={{
                  cursor: "pointer",
                }}
                src={NotificationIcon}
                onClick={() => onNavigateTab(e?.data)}
              />
            )}
          </>
        );
      },
    },
  ];
};

export const SORT_PARAMS = {
  id: "sort_index",
};
