import { PERMISSION, ROUTES } from "@bms/constants";
import {
  BaseLineCalendar,
  Calendar,
  OutlineDashboard,
  OutlinePeople,
  Payment,
  Percent,
  Property,
  Reception,
  // Regular,
  User2,
  RequestReport,
} from "@bms/assets";

export const NavItems = [
  {
    id: 1,
    title: "サービスカード",
    icon: <img src={OutlineDashboard} />,
    navigate: ROUTES.SERVICE_CARD,
    permission: PERMISSION.SERVICE_CARD,
    mb: "3px",
  },
  // {
  //   id: 2,
  //   title: "定期清掃",
  //   icon: <img src={Regular} />,
  //   navigate: ROUTES.CLEANING,
  // },
  {
    id: 3,
    title: "スケジュール",
    icon: <img src={BaseLineCalendar} />,
    navigate: ROUTES.SCHEDULE,
    permission: PERMISSION.SCHEDULE,
  },
  {
    id: 4,
    title: "シフト",
    icon: <img src={BaseLineCalendar} />,
    navigate: ROUTES.SHIFT,
    permission: PERMISSION.SHIFT,
  },
  {
    id: 5,
    title: "定休日",
    icon: <img src={Calendar} />,
    navigate: ROUTES.HOLIDAY,
    permission: PERMISSION.HOLIDAY,
    mb: "20px",
  },
  {
    id: 6,
    title: "作業員",
    icon: <img src={OutlinePeople} />,
    navigate: ROUTES.OPERATOR,
    permission: PERMISSION.OPERATOR,
  },
  {
    id: 7,
    title: "物件",
    icon: <img src={Property} />,
    navigate: ROUTES.PROPERTY,
    permission: PERMISSION.PROPERTY,
  },
  // {
  //   id: 8,
  //   title: "商品",
  //   icon: <img src={Product} />,
  //   navigate: ROUTES.MERCHANDISE,
  //   permission: PERMISSION.MERCHANDISE,
  // },
  {
    id: 9,
    title: "受付媒体",
    icon: <img src={Reception} />,
    navigate: ROUTES.RECEPTION,
    permission: PERMISSION.RECEPTION,
  },
  {
    id: 10,
    title: "支払区分",
    icon: <img src={Payment} />,
    navigate: ROUTES.PAYMENT,
    permission: PERMISSION.PAYMENT,
  },
  {
    id: 11,
    title: "外注業者",
    icon: <img src={User2} />,
    navigate: ROUTES.SUBCONTRACTOR,
    permission: PERMISSION.SUBCONTRACTOR,
  },
  {
    id: 12,
    title: "税率",
    icon: <img src={Percent} />,
    navigate: ROUTES.TAX_RATE,
    permission: PERMISSION.TAX_RATE,
    mb: "30px",
  },
  {
    id: 13,
    title: "出力",
    icon: <img src={RequestReport} />,
    navigate: ROUTES.OUTPUT,
    permission: PERMISSION.OUTPUT,
  },
  // {
  //   id: 13,
  //   title: "請求書",
  //   icon: <img src={RequestReport} />,
  //   navigate: ROUTES.INVOICE,
  //   permission: PERMISSON.INVOICE,
  // },
  // {
  //   id: 14,
  //   title: "報告書",
  //   icon: <img src={RequestReport} />,
  //   navigate: ROUTES.REPORT,
  //   permission: PERMISSON.REPORT,
  // },
];
