import styled from "styled-components";

export const Styles = styled.div`
  &.payment-information {
    border: 1px solid #bcbcbc;
    padding: 10px 19px 28px 19px;
    border-radius: 6px;

    .ant-select {
      border-bottom: 0;
    }
  }

  label {
    font-family: "Inter";
    font-weight: 400;
    font-size: 15px;
    color: #adadad;
    position: absolute;
    top: 4px;
    left: 0;
    z-index: 1;

    &.Mui-focused,
    &.MuiInputLabel-shrink {
      top: 0;
    }
  }

  .MuiFormLabel-root.Mui-focused {
    color: #adadad;
  }

  .MuiInputBase-root {
    &:hover:not(.Mui-disabled):before,
    &::after {
      border-bottom: 1px solid #bcbcbc;
    }

    &:before {
      border-bottom: 1px solid #bcbcbc;
    }
  }

  .MuiInputBase-input {
    &::after {
      border-bottom: 1px solid #bcbcbc;
    }
  }

  .MuiSelect-select {
    background-color: transparent;
    font-weight: 400;
    font-family: "Lato";
  }

  .field-wrapper {
    display: flex;
    flex-direction: row;
    gap: 19px;
    position: relative;

    label {
      top: 3px;
      left: -13px;
    }

    .input-field {
      width: 205px;

      .MuiFormControl-root {
        width: 100%;
      }
    }
  }

  input {
    font-weight: 400;
    font-size: 15px;
    font-family: "Lato";

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
`;
