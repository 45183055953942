export const getAccessToken = () => localStorage.getItem("access_token") || "";
export const getUser = () => localStorage.getItem("user") || "";

export const formatTel = (value: string) => {
  if (!value) return "";
  return value.replace(/^(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
};

export const formatDay = (value: any) => {
  if (!value) return "";
  // ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
  return ["日", "月", "火", "水", "木", "金", "土"][new Date(value).getDay()];
};
