import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QUERY_NAME, SERVICES, SORTING } from "@bms/constants";
import axios from "@bms/libs/axios";
import { Workers } from "@bms/types";

export const useFetchOutputs = (
  page: number,
  pageSize: number,
  filterParams?: any,
  sortParams?: any
) => {
  for (const obj in sortParams) {
    if (sortParams[obj] === SORTING.ASC) {
      delete sortParams[obj];
    }
  }

  return useQuery(
    [QUERY_NAME.OUT_PUTS, page, pageSize, filterParams, sortParams],
    () =>
      axios({
        method: "GET",
        url: SERVICES.OUT_PUTS,
        params: {
          page: page,
          per_page: pageSize,
          ...filterParams,
          ...sortParams,
        },
      }).then((res) => res.data?.json || []),
    {
      cacheTime: 0,
      staleTime: 0,
      refetchOnWindowFocus: false,
    }
  );
};

export const useFetchOneOutputs = (outputId: string | undefined) => {
  return useQuery(
    [QUERY_NAME.OUT_PUT_ID, outputId],
    () =>
      axios({
        method: "GET",
        url: `${SERVICES.OUT_PUTS}/${outputId}`,
      }).then((res) => res.data as Workers),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!outputId,
    }
  );
};

export const useDeleteOutput = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ payload }: any) =>
      axios({
        method: "DELETE",
        url: `${SERVICES.OUT_PUTS}/delete`,
        data: payload,
      }),
    {
      onSuccess() {
        queryClient.invalidateQueries([QUERY_NAME.OUT_PUTS]);
      },
    }
  );
};

export const useSubmitOutput = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ payload, outputId }: any) =>
      axios({
        method: outputId ? "PUT" : "POST",
        url: outputId ? `${SERVICES.OUT_PUTS}/${outputId}` : SERVICES.OUT_PUTS,
        data: payload,
      }),
    {
      onSuccess() {
        queryClient.invalidateQueries([QUERY_NAME.OUT_PUTS]);
        queryClient.invalidateQueries([QUERY_NAME.OUT_PUT_ID]);
      },
    }
  );
};

export const useExportOutput = () => {
  return useMutation(({ export_type, uuid, service_card_ids }: any) =>
    axios({
      method: "GET",
      url: `${SERVICES.OUT_PUTS}/export_data`,
      params: {
        export_type,
        uuid,
        service_card_ids,
      },
    })
  );
};

export const useFileExportStatus = () => {
  return useMutation(({ uuid }: any) =>
    axios({
      method: "GET",
      url: `file_exports/${uuid}`,
    })
  );
};

export const usePrintOutput = () => {
  return useMutation(({ print_type, id, service_card_ids }: any) =>
    axios({
      method: "GET",
      url: `${SERVICES.OUT_PUTS}/print_pdfs`,
      params: {
        print_type,
        id,
        service_card_ids,
      },
      timeout: 900000,
    })
  );
};
