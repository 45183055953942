import { FC } from "react";
import { Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  Select as SelectField,
  Box,
  TextField,
  Autocomplete,
  MenuItem,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import Paper from "@material-ui/core/Paper";
import { TimePicker } from "antd";
import moment from "moment";
import { uniqBy } from "lodash";
import LoadingButton from "@mui/lab/LoadingButton";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import dayjs from "dayjs";
import "dayjs/locale/ja";

import { Tag } from "@bms/assets";
import {
  DATE_TIME_FORMAT,
  DAY_OF_WEEK_KIND_OPTIONS,
  PERIOD_KIND_OPTIONS,
} from "@bms/constants";
import { Loading, ModalDelete } from "@bms/components";
import useShiftControl, { Props, ReceivedProps } from "./hook";
import { Styles, useStylesHolidays, useStylesWorker } from "./styled";
import { v4 } from "uuid";

const ShiftControlLayout: FC<Props> = ({
  id,
  isOpenModalDelete,
  isSubmitting,
  workerOptions,
  workersSelect,
  pagination,
  control,
  fields,
  errors,
  isView,
  contextHolder,
  isLoadingWorker,
  initial,
  isLoading,
  workerShiftData,
  setValue,
  getValues,
  register,
  handleSubmit,
  setPagination,
  setWorkersSelect,
  onDeleteRecord,
  onOpenModalDelete,
  onCloseModalDelete,
  onSubmit,
  trigger,
}) => {
  const navigate = useNavigate();
  const classesWorker = useStylesWorker();
  const classesHolidays = useStylesHolidays();

  if ((id && isLoading) || !initial) {
    return <Loading />;
  }

  return (
    <Styles>
      {contextHolder}
      <div className="header">
        <h3>
          シフト
          {!id ? "登録" : isView ? "詳細" : "編集"}
        </h3>
        <div className="group-btn">
          <button className="btn-back" onClick={() => navigate(-1)}>
            戻る
          </button>
          {!isView && (
            <LoadingButton
              loadingPosition="start"
              loading={isSubmitting}
              type="submit"
              variant="outlined"
              onClick={handleSubmit(onSubmit)}
            >
              {id ? "確定" : "保存"}
            </LoadingButton>
          )}
        </div>
      </div>

      <div className="title">
        <h4>{moment().format(DATE_TIME_FORMAT.DATE)}</h4>
      </div>

      <Box component="form">
        <div className="box left">
          <div className="field-wrapper">
            <InputLabel>作業員選択</InputLabel>
            <Controller
              name="worker_id"
              control={control}
              render={() => (
                <Autocomplete
                  disablePortal
                  disableClearable
                  options={workerOptions}
                  renderOption={(props, option: any) => {
                    return (
                      <li {...props} key={option.value}>
                        {option.label}
                      </li>
                    );
                  }}
                  noOptionsText="データがありません。"
                  disabled={isView || Boolean(id)}
                  //TODO:
                  // ListboxProps={{
                  //   onScroll: (event: SyntheticEvent) => {
                  //     const listboxNode = event.currentTarget;
                  //     const targetHeight =
                  //       listboxNode.scrollTop + listboxNode.clientHeight;
                  //     const boxHeight = listboxNode.scrollHeight;

                  //     if (workerOptions.length >= pagination.total) return;

                  //     if (targetHeight + 2 > boxHeight) {
                  //       setPagination((prev) => ({
                  //         ...prev,
                  //         page: pagination.page + 1,
                  //       }));
                  //     }
                  //   },
                  // }}
                  {...(Boolean(id) && {
                    value: workerShiftData?.worker?.name || "",
                  })}
                  PaperComponent={(props) => (
                    <Paper variant="outlined" {...props}>
                      {props.children}
                      {isLoadingWorker && (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            margin: "0 auto",
                            justifyContent: "center",
                            paddingBottom: 10,
                          }}
                        >
                          <CircularProgress size={12} />
                        </div>
                      )}
                    </Paper>
                  )}
                  onChange={(_, value: any) => {
                    if (!value) return;
                    setWorkersSelect(
                      uniqBy([...workersSelect, value], "value")
                    );
                    setValue("worker_id", value?.value);
                    trigger("worker_id");
                    setPagination({
                      ...pagination,
                      page: 1,
                      total: 0,
                    });
                  }}
                  classes={{
                    listbox: classesWorker.listBox,
                    paper: classesWorker.paper,
                  }}
                  sx={{ width: 400 }}
                  renderInput={(params) => {
                    return <TextField {...params} />;
                  }}
                />
              )}
            />
            {errors.worker_id && (
              <p className="error">{errors.worker_id?.message}</p>
            )}
          </div>
        </div>

        <div className="box right">
          <div className="field-wrapper">
            <InputLabel>作業員</InputLabel>
            <div className="tag-wrapper">
              {workersSelect.map((item) => (
                <div
                  className="tag"
                  style={{
                    ...(id && { justifyContent: "center" }),
                  }}
                  key={item?.value}
                >
                  {!id && (
                    <img
                      src={Tag}
                      alt=""
                      onClick={() => onOpenModalDelete(item?.value)}
                    />
                  )}
                  <p>{item?.label}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="field-wrapper">
            <InputLabel>勤務時間</InputLabel>
            <div className="time-wrapper">
              <div className="input-field">
                <Controller
                  name="start_working_hour"
                  control={control}
                  render={(props) => (
                    <TimePicker
                      {...props}
                      disabled={isView}
                      clearIcon={<></>}
                      name="start_working_hour"
                      format={DATE_TIME_FORMAT.TIME}
                      value={
                        getValues("start_working_hour")
                          ? dayjs(
                              getValues("start_working_hour"),
                              DATE_TIME_FORMAT.TIME
                            )
                          : null
                      }
                      placeholder=""
                      suffixIcon={<></>}
                      showNow={false}
                      onChange={(_, timeString: any) => {
                        setValue("start_working_hour", timeString);
                        trigger("start_working_hour");
                      }}
                    />
                  )}
                />
                {errors.start_working_hour && (
                  <p className="error">{errors.start_working_hour?.message}</p>
                )}
              </div>
              <div className="spreader">〜</div>
              <div className="input-field">
                <Controller
                  name="end_working_hour"
                  control={control}
                  render={(props) => (
                    <TimePicker
                      {...props}
                      disabled={isView}
                      clearIcon={<></>}
                      name="end_working_hour"
                      format={DATE_TIME_FORMAT.TIME}
                      value={
                        getValues("end_working_hour")
                          ? dayjs(
                              getValues("end_working_hour"),
                              DATE_TIME_FORMAT.TIME
                            )
                          : null
                      }
                      placeholder=""
                      suffixIcon={<></>}
                      showNow={false}
                      onChange={(_, timeString: any) => {
                        setValue("end_working_hour", timeString);
                        trigger("end_working_hour");
                      }}
                    />
                  )}
                />
                {errors.end_working_hour && (
                  <p className="error">{errors.end_working_hour?.message}</p>
                )}
              </div>
            </div>
          </div>

          <div className="field-wrapper">
            <div className="field-container">
              <div className="holidays">
                <div className="title">
                  <h3>休み曜日設定</h3>
                  <h3>繰り返し設定</h3>
                </div>
                {fields.map((item, index) => (
                  <div className="field-inner" key={index}>
                    <SelectField
                      {...register(
                        `holiday_attributes.${index}.day_of_week_kind`
                      )}
                      disabled={isView}
                      name={`holiday_attributes.${index}.day_of_week_kind`}
                      displayEmpty
                      MenuProps={{
                        classes: {
                          list: classesHolidays.list,
                          paper: classesHolidays.paper,
                        },
                      }}
                      classes={{
                        select: classesHolidays.select,
                      }}
                      defaultValue={item.day_of_week_kind}
                      IconComponent={() => <></>}
                      renderValue={(selected) => {
                        const value = DAY_OF_WEEK_KIND_OPTIONS.find(
                          (item) => item.value === selected
                        );
                        if (!selected) {
                          return <div className="placeholder">選択</div>;
                        }
                        return value?.label || "";
                      }}
                    >
                      {DAY_OF_WEEK_KIND_OPTIONS.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </SelectField>
                    <div className="separator">曜日</div>
                    <SelectField
                      {...register(`holiday_attributes.${index}.period_kind`)}
                      disabled={isView}
                      name={`holiday_attributes.${index}.period_kind`}
                      displayEmpty
                      MenuProps={{
                        classes: {
                          list: classesHolidays.list,
                          paper: classesHolidays.paper,
                        },
                      }}
                      classes={{
                        select: classesHolidays.select,
                      }}
                      defaultValue={item.period_kind}
                      IconComponent={() => <></>}
                      renderValue={(selected) => {
                        const value = PERIOD_KIND_OPTIONS.find(
                          (item) => item.value === selected
                        );
                        if (!selected) {
                          return <div className="placeholder">選択</div>;
                        }
                        return value?.label || "";
                      }}
                    >
                      {PERIOD_KIND_OPTIONS.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </SelectField>
                  </div>
                ))}
              </div>

              <ModalDelete
                isOpen={isOpenModalDelete}
                isSubmitting={isSubmitting}
                onDeleteRecord={onDeleteRecord}
                onClose={onCloseModalDelete}
              />
            </div>
          </div>
        </div>
      </Box>
    </Styles>
  );
};

const ShiftControl: FC<ReceivedProps> = (props) => (
  <ShiftControlLayout {...useShiftControl(props)} />
);

export default ShiftControl;
