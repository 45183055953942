import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { get } from "lodash";
import { notification } from "antd";

import { loginSuccess, userLogin } from "@bms/features/auth/slice";
import axios from "@bms/libs/axios";
import { SERVICES } from "@bms/constants";
import { validation } from "./schema";

const useLogin = () => {
  const dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(validation()),
  });

  const fetchCurrentUser = async () => {
    try {
      const { data } = await axios({
        method: "GET",
        url: SERVICES.AUTH,
      });
      dispatch(userLogin({ user: data }));
    } catch (error) {
      //
    }
  };

  const onSubmit = async (response: Record<string, any>) => {
    try {
      const { data } = await axios({
        method: "post",
        url: SERVICES.LOGIN,
        data: response,
      });

      if (data) {
        localStorage.setItem("access_token", data.access_token);
        await fetchCurrentUser();
        dispatch(loginSuccess());
      }
    } catch (error) {
      api["error"]({
        message: get(error, "response.data.error", "Login Failed"),
      });
    }
  };

  return {
    onSubmit,
    register,
    handleSubmit,
    contextHolder,
    errors,
    isSubmitting,
  };
};

export type Props = ReturnType<typeof useLogin>;

export default useLogin;
