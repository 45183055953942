import { FC } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { TextField, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { Loading } from "@bms/components";
import useSubContractorControl, { Props, ReceivedProps } from "./hook";
import { Styles } from "./styled";

const SubContractorControlLayout: FC<Props> = ({
  register,
  handleSubmit,
  onSubmit,
  setValue,
  trigger,
  isView,
  errors,
  isLoading,
  isSubmitting,
  id,
}) => {
  const navigate = useNavigate();

  if (id && isLoading) {
    return <Loading />;
  }

  return (
    <Styles>
      <div className="header">
        <h3>
          外注業者
          {!id ? "登録" : isView ? "詳細" : "編集"}{" "}
        </h3>
        <div className="group-btn">
          <button className="btn-back" onClick={() => navigate(-1)}>
            戻る
          </button>
          {!isView && (
            <LoadingButton
              loadingPosition="start"
              loading={isSubmitting}
              type="submit"
              variant="outlined"
              onClick={handleSubmit(onSubmit)}
            >
              {!id ? "追加" : "保存"}
            </LoadingButton>
          )}
        </div>
      </div>

      <div className="title">
        <h4>外注業者情報</h4>
      </div>

      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="field-wrapper">
          <TextField
            {...register("id")}
            name="id"
            label="No."
            variant="standard"
            disabled
          />
          <div className="input-field">
            <TextField
              {...register("trader_name")}
              name="trader_name"
              label="取引業者名"
              variant="standard"
              disabled={isView}
            />
            {errors.trader_name && (
              <p className="error">{errors.trader_name?.message}</p>
            )}
          </div>
          <div className="input-field">
            <TextField
              {...register("form_name")}
              name="form_name"
              label="帳票名称"
              variant="standard"
              disabled={isView}
            />
            {errors.form_name && (
              <p className="error">{errors.form_name?.message}</p>
            )}
          </div>
        </div>
        <div className="field-wrapper">
          <div className="input-field">
            <TextField
              {...register("postal_code")}
              name="postal_code"
              label="郵便番号"
              variant="standard"
              disabled={isView}
            />
            {errors.postal_code && (
              <p className="error">{errors.postal_code?.message}</p>
            )}
          </div>
          <div className="input-field">
            <TextField
              {...register("address")}
              name="address"
              label="住所"
              variant="standard"
              disabled={isView}
            />
            {errors.address && (
              <p className="error">{errors.address?.message}</p>
            )}
          </div>
          <div className="input-field">
            <TextField
              {...register("apartment_name")}
              name="apartment_name"
              label="マンション名"
              variant="standard"
              disabled={isView}
            />
            {errors.apartment_name && (
              <p className="error">{errors.apartment_name?.message}</p>
            )}
          </div>
        </div>
        <div className="field-wrapper">
          <div className="input-field">
            <TextField
              {...register("phone_number")}
              onChange={(e: any) => {
                setValue("phone_number", e.target.value.replace(/\D+/g, ""));
                trigger("phone_number");
              }}
              name="phone_number"
              label="TEL"
              variant="standard"
              disabled={isView}
            />
            {errors.phone_number && (
              <p className="error">{errors.phone_number?.message}</p>
            )}
          </div>
          <div className="input-field">
            <TextField
              {...register("fax")}
              onChange={(e: any) => {
                setValue("fax", e.target.value.replace(/\D+/g, ""));
                trigger("fax");
              }}
              name="fax"
              label="FAX"
              variant="standard"
              disabled={isView}
            />
            {errors.fax && <p className="error">{errors.fax?.message}</p>}
          </div>
        </div>
      </Box>
    </Styles>
  );
};

const SubContractorControl: FC<ReceivedProps> = (props) => (
  <SubContractorControlLayout {...useSubContractorControl(props)} />
);

export default SubContractorControl;
