import { FC } from "react";
import { Controller } from "react-hook-form";
import "dayjs/locale/ja";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import {
  Select as SelectField,
  FormControl,
  TextField,
  InputLabel,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Checkbox,
  FormControlLabel,
  FormGroup,
  MenuItem,
} from "@mui/material";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjs from "dayjs";
import { isEmpty } from "lodash";

import { DATE_TIME_FORMAT, LOCALE_JP, QUERY_NAME } from "@bms/constants";
import { DatePickerIcon } from "@bms/assets";
import { Loading } from "@bms/components";
import useOrderInformation, { Props, ReceivedProps } from "./hook";
import { Styles } from "./styled";
import { useQueryClient } from "@tanstack/react-query";
import { formatDay } from "@bms/utility";

dayjs.extend(utc);
dayjs.extend(timezone);

const OrderInformationLayout: FC<Props> = ({
  register,
  setValue,
  getValues,
  handleCheck,
  watch,
  trigger,
  setFilterKey,
  setFilterKeySearch,
  roomNumberOptions,
  filterKey,
  isEditField,
  buildingOptions,
  errors,
  control,
  isView,
  buildingData,
  isLoading,
  // receptionOptions,
}) => {
  const queryClient = useQueryClient();

  if (buildingData?.id && isLoading) {
    return <Loading />;
  }
  isEditField = isEditField || isView;
  return (
    <Styles className="order-information-wrapper">
      <div className="main">
        <div className="field-wrapper">
          <div className="input-field">
            <Controller
              name="order_date"
              control={control}
              defaultValue={null}
              render={() => (
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={LOCALE_JP}
                >
                  <FormControl variant="outlined">
                    <InputLabel>受注日</InputLabel>
                    <MobileDatePicker
                      {...register("order_date")}
                      onAccept={(e) => setValue("order_date", e)}
                      label="受注日"
                      disabled={isView}
                      value={getValues("order_date")}
                      inputFormat={
                        DATE_TIME_FORMAT.DATE_JP +
                        `(${formatDay(getValues("order_date"))})`
                      }
                      onChange={(e) => {
                        setValue("order_date", e);
                        trigger("order_date");
                      }}
                      components={{
                        ActionBar: ({ onAccept }: any) => (
                          <div className="MuiDialogActions-root">
                            <button
                              onClick={() => setValue("order_date", null)}
                            >
                              クリア
                            </button>
                            <button onClick={onAccept}>OK</button>
                          </div>
                        ),
                      }}
                      renderInput={(params: any) => (
                        <OutlinedInput
                          {...params}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton edge="end">
                                <img src={DatePickerIcon} />
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      )}
                    />
                  </FormControl>
                </LocalizationProvider>
              )}
            />
            {errors?.order_date && (
              <p className="error">{errors.order_date?.message}</p>
            )}
          </div>

          <div className="input-field">
            <FormControl variant="outlined">
              <TextField
                {...register("card_id")}
                name="card_id"
                variant="standard"
                label="受付ID"
                disabled={isEditField}
                onChange={() => {
                  if (filterKey === "building_id") return;
                  setFilterKey("resident_id");
                  setValue("field_key", "resident_id");
                }}
                InputLabelProps={{
                  shrink: Boolean(watch("card_id")),
                }}
              />
            </FormControl>

            {errors?.reception_id && (
              <p className="error">{errors.reception_id?.message}</p>
            )}
          </div>
        </div>

        <div className="field-wrapper">
          <div className="input-field">
            <TextField
              {...register("resident_id")}
              name="resident_id"
              variant="standard"
              label="入居者ID"
              onChange={(e) => {
                setValue("resident_id", e?.target?.value);
                if (getValues("building_id")) {
                  setValue("building_id", getValues("building_id"));
                  setFilterKeySearch("both");
                } else {
                  setFilterKeySearch("resident_id");
                }
              }}
              disabled={isView}
              InputLabelProps={{
                shrink: Boolean(watch("resident_id")),
              }}
            />
            {errors?.resident_id && (
              <p className="error">{errors.resident_id?.message}</p>
            )}
          </div>
          <div className="input-field">
            <TextField
              {...register("building_name")}
              name="building_name"
              variant="standard"
              label="建物名"
              disabled={isEditField}
              InputLabelProps={{
                shrink: Boolean(watch("building_name")),
              }}
            />
          </div>
          <div
            className={`input-field ${
              (filterKey !== "building_id" &&
                filterKey &&
                !isEmpty(roomNumberOptions)) ||
              !isEmpty(roomNumberOptions)
                ? "building-select"
                : ""
            }`}
          >
            {filterKey === "building_id" || isEmpty(roomNumberOptions) ? (
              <TextField
                {...register("room_number")}
                name="room_number"
                variant="standard"
                label="部屋番号"
                disabled={isEditField}
                InputLabelProps={{
                  shrink: Boolean(watch("room_number")),
                }}
                value={getValues("room_number") || ""}
              />
            ) : (
              <FormControl variant="outlined">
                <InputLabel>部屋番号</InputLabel>
                <SelectField
                  {...register("room_number")}
                  name="room_number"
                  variant="standard"
                  label="部屋番号"
                  key={`${Math.floor(Math.random() * 1000)}-min`}
                  defaultValue={getValues("room_number")}
                  disabled={isView}
                  style={{ marginTop: 9.5 }}
                >
                  {roomNumberOptions.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </SelectField>
              </FormControl>
            )}
          </div>
          <div className="input-field">
            <TextField
              {...register("resident_name")}
              name="resident_name"
              variant="standard"
              label="入居者名"
              disabled={isEditField}
              InputLabelProps={{
                shrink: Boolean(watch("resident_name")),
              }}
            />
          </div>
          <div className="input-field">
            <TextField
              {...register("resident_contact")}
              name="resident_contact"
              variant="standard"
              label="入居者連絡先_TEL"
              disabled={isEditField}
              InputLabelProps={{
                shrink: Boolean(watch("resident_contact")),
              }}
            />
          </div>
        </div>

        <div className="field-wrapper">
          <div
            className={`input-field ${
              watch("resident_id") &&
              !watch("building_id") &&
              !isEmpty(buildingOptions)
                ? "building-select"
                : ""
            }`}
          >
            {watch("building_id") ||
            !watch("resident_id") ||
            isEmpty(buildingOptions) ? (
              <TextField
                {...register("building_id")}
                name="building_id"
                variant="standard"
                label="建物ID"
                onChange={(e) => {
                  setValue("building_id", e?.target?.value);
                  const residentId = getValues("resident_id");
                  const buildingId = getValues("building_id");
                  if (residentId && buildingId) {
                    setFilterKeySearch("both");
                  } else {
                    if (buildingId) {
                      setFilterKeySearch("building_id");
                    } else {
                      setFilterKeySearch("resident_id");
                    }
                  }
                }}
                disabled={isView}
                InputLabelProps={{
                  shrink: Boolean(watch("building_id")),
                }}
              />
            ) : (
              <FormControl variant="outlined">
                <InputLabel>建物ID</InputLabel>
                <SelectField
                  {...register("building_id")}
                  name="building_id"
                  variant="standard"
                  label="建物ID"
                  disabled={isView}
                  style={{ marginTop: 9.5 }}
                  onChange={(e) => {
                    const residentId = getValues("resident_id");
                    if (residentId && e?.target?.value) {
                      setValue("building_id", e?.target?.value);
                      setFilterKeySearch("both");
                    } else {
                      // setValue("building_id", null);
                      setFilterKeySearch("resident_id");
                    }
                    // setValue("resident_id", getValues("resident_id"));
                    queryClient.invalidateQueries([QUERY_NAME.BUILDING_ID]);
                  }}
                >
                  {buildingOptions.map((item) => (
                    <MenuItem key={item?.building_id} value={item?.building_id}>
                      {item?.building_id}
                    </MenuItem>
                  ))}
                </SelectField>
              </FormControl>
            )}
            {errors?.building_id && (
              <p className="error">{errors.building_id?.message}</p>
            )}
          </div>
          <div className="input-field">
            <TextField
              {...register("building_tentative_name")}
              name="building_tentative_name"
              variant="standard"
              label="建物仮称名"
              disabled={isEditField}
              InputLabelProps={{
                shrink: Boolean(watch("building_tentative_name")),
              }}
            />
          </div>
          <div className="input-field">
            <TextField
              {...register("move_in_date")}
              name="move_in_date"
              variant="standard"
              label="入居日"
              disabled={isEditField}
              InputLabelProps={{
                shrink: Boolean(watch("move_in_date")),
              }}
            />
            {errors?.move_in_date && (
              <p className="error">{errors.move_in_date?.message}</p>
            )}
          </div>
          <div className="input-field">
            <TextField
              {...register("tenant_contact")}
              name="tenant_contact"
              variant="standard"
              label="入居者連絡先_携帯"
              disabled={isEditField}
              InputLabelProps={{
                shrink: Boolean(watch("tenant_contact")),
              }}
            />
          </div>
        </div>

        <div className="field-wrapper">
          <div className="input-field">
            <TextField
              {...register("building_address")}
              name="building_address"
              variant="standard"
              label="建物住所"
              disabled={isEditField}
              InputLabelProps={{
                shrink: Boolean(watch("building_address")),
              }}
            />
          </div>
          <div className="input-field">
            <TextField
              {...register("contractor_name")}
              name="contractor_name"
              variant="standard"
              label="契約者名"
              disabled={isEditField}
              InputLabelProps={{
                shrink: Boolean(watch("contractor_name")),
              }}
            />
          </div>
          <div className="input-field">
            <TextField
              {...register("contractor_data_id")}
              name="contractor_data_id"
              variant="standard"
              label="契約者ID"
              disabled={isEditField}
              InputLabelProps={{
                shrink: Boolean(watch("contractor_data_id")),
              }}
            />
          </div>
        </div>

        <div className="field-wrapper">
          <div className="input-field">
            <TextField
              {...register("building_management_number")}
              name="building_management_number"
              variant="standard"
              label="建物管理番号"
              disabled={isEditField}
              InputLabelProps={{
                shrink: Boolean(watch("building_management_number")),
              }}
            />
          </div>
          <div className="input-field">
            <TextField
              {...register("owner_name")}
              name="owner_name"
              variant="standard"
              label="家主名"
              disabled={isEditField}
              InputLabelProps={{
                shrink: Boolean(watch("owner_name")),
              }}
            />
          </div>
          <div className="input-field">
            <TextField
              {...register("owner_id")}
              name="owner_id"
              variant="standard"
              label="家主ID"
              disabled={isEditField}
              InputLabelProps={{
                shrink: Boolean(watch("owner_id")),
              }}
            />
          </div>
          <div className="input-field">
            <TextField
              {...register("owner_manager_number")}
              name="owner_manager_number"
              variant="standard"
              label="家主管理番号"
              disabled={isEditField}
              InputLabelProps={{
                shrink: Boolean(watch("owner_manager_number")),
              }}
            />
          </div>
          <div className="input-field">
            <FormControl variant="outlined">
              <InputLabel>報告印刷</InputLabel>
              <SelectField
                {...register("report_printing")}
                name="report_printing"
                variant="standard"
                value={watch("report_printing") || "doing"}
                defaultValue={watch("report_printing")}
                label="報告印刷"
                disabled={isView}
                style={{ marginTop: 9.5 }}
                onChange={(e) => {
                  setValue("report_printing", e?.target?.value);
                  trigger("report_printing");
                }}
              >
                <MenuItem value="doing">する</MenuItem>
                <MenuItem value="do_not">しない</MenuItem>
              </SelectField>
            </FormControl>
          </div>
        </div>

        <div className="field-wrapper">
          <div className="input-field">
            <TextField
              {...register("contractor_address")}
              name="contractor_address"
              variant="standard"
              label="契約者_連絡先1_宛名"
              disabled={isEditField}
              InputLabelProps={{
                shrink: Boolean(watch("contractor_address")),
              }}
            />
          </div>
          <div className="input-field">
            <TextField
              {...register("resident_address")}
              name="resident_address"
              variant="standard"
              label="入居者_連絡先1_宛名"
              disabled={isEditField}
              InputLabelProps={{
                shrink: Boolean(watch("resident_address")),
              }}
            />
          </div>
          <div className="input-field">
            <TextField
              {...register("owner_address")}
              name="owner_address"
              variant="standard"
              label="家主_連絡先1_宛名"
              disabled={isEditField}
              InputLabelProps={{
                shrink: Boolean(watch("owner_address")),
              }}
            />
          </div>
        </div>

        <div className="input-field">
          <InputLabel className="title">連絡者</InputLabel>
          <TextField
            {...register("order_contact_person")}
            name="order_contact_person"
            placeholder=""
            disabled={isView}
          />
          {errors?.order_contact_person && (
            <p className="error">{errors.order_contact_person?.message}</p>
          )}
        </div>

        <div className="input-field">
          <InputLabel className="title">MEMO</InputLabel>
          <TextField
            {...register("memo")}
            name="memo"
            multiline
            rows={2}
            maxRows={10}
            placeholder=""
            disabled={isView}
          />
          {errors?.memo && <p className="error">{errors.memo?.message}</p>}
        </div>
      </div>

      <h4 className="title" style={{ fontWeight: 600 }}>
        スマイルサポート
      </h4>
      <div className="main" style={{ marginTop: 0 }}>
        <Controller
          name="support_type"
          control={control}
          render={(props: any) => (
            <FormGroup className="checkbox-wrapper">
              {[
                { value: "joining", label: "加入" },
                { value: "non_member", label: "未加入" },
              ].map((item, index) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={isView}
                      checked={item.value === props.field?.value?.[0]}
                      onChange={() =>
                        props.field.onChange(
                          handleCheck(item.value, "support_type")
                        )
                      }
                      defaultChecked={getValues("support_type")?.includes(
                        item.value
                      )}
                    />
                  }
                  key={index}
                  label={item.label}
                />
              ))}
            </FormGroup>
          )}
        />
        {errors?.support_type && (
          <p className="error">{errors.support_type?.message}</p>
        )}
      </div>

      <h4 className="title" style={{ fontWeight: 600 }}>
        請求先
      </h4>
      <div className="main" style={{ marginTop: 0 }}>
        <Controller
          name="address_payment_type"
          control={control}
          render={(props: any) => (
            <FormGroup className="checkbox-wrapper">
              {[
                { value: "contractor", label: "契約者" },
                { value: "tenant", label: "入居者" },
                { value: "owner", label: "オーナー" },
                { value: "general", label: "一般" },
                { value: "nomura_development", label: "野村開発(営業3家)" },
              ].map((item, index) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={isView}
                      checked={item.value === props.field?.value?.[0]}
                      onChange={() => {
                        props.field.onChange(
                          handleCheck(item.value, "address_payment_type")
                        );
                      }}
                      defaultChecked={getValues(
                        "address_payment_type"
                      )?.includes(item.value)}
                    />
                  }
                  key={index}
                  label={item.label}
                />
              ))}
            </FormGroup>
          )}
        />
        {errors?.address_payment_type && (
          <p className="error">{errors.address_payment_type?.message}</p>
        )}
      </div>
    </Styles>
  );
};

const OrderInformation: FC<ReceivedProps> = (props) => (
  <OrderInformationLayout {...useOrderInformation(props)} />
);

export default OrderInformation;
