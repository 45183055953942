import { FC } from "react";
import { TextField, InputLabel } from "@mui/material";
import { NumericFormat } from "react-number-format";
import { Controller } from "react-hook-form";

import { AddIcon, Trash } from "@bms/assets";
import useWorkCost, { Props, ReceivedProps } from "./hook";
import { Styles } from "./styled";
import { ModalDelete } from "@bms/components";

const WorkCostLayout: FC<Props> = ({
  register,
  setValue,
  getValues,
  append,
  handleValue,
  remove,
  setOpenModalDelete,
  trigger,
  openModalDelete,
  errors,
  fields,
  isView,
  control,
}) => {
  return (
    <Styles>
      <div className="main">
        <div className="field-wrapper">
          <div className="input-field">
            <InputLabel>出張料</InputLabel>
            <Controller
              name="busines_trip_fee"
              control={control}
              render={(props: any) => (
                <NumericFormat
                  {...props}
                  value={props?.field?.value || ""}
                  onValueChange={({ value }) => {
                    setValue("busines_trip_fee", value);
                    trigger("busines_trip_fee");
                    handleValue();
                  }}
                  thousandSeparator=","
                  decimalSeparator="."
                  placeholder="出張料を入力"
                  disabled={isView}
                  customInput={TextField}
                />
              )}
            />

            {errors?.busines_trip_fee && (
              <p className="error">{errors.busines_trip_fee?.message}</p>
            )}
          </div>

          <div className="input-field">
            <InputLabel>作業代</InputLabel>
            <Controller
              name="work_fee"
              control={control}
              render={(props: any) => (
                <NumericFormat
                  {...props}
                  value={props?.field?.value || ""}
                  {...register("work_fee")}
                  onValueChange={({ value }) => {
                    setValue("work_fee", value);
                    handleValue();
                  }}
                  thousandSeparator=","
                  decimalSeparator="."
                  name="work_fee"
                  placeholder="作業代を入力"
                  disabled={isView}
                  customInput={TextField}
                />
              )}
            />

            {errors?.work_fee && (
              <p className="error">{errors.work_fee?.message}</p>
            )}
          </div>

          <div className="input-field">
            <InputLabel>処分代</InputLabel>
            <Controller
              name="disposal_fee"
              control={control}
              render={(props: any) => (
                <NumericFormat
                  {...props}
                  value={props?.field?.value}
                  thousandSeparator=","
                  decimalSeparator="."
                  customInput={TextField}
                  onValueChange={({ value }) => {
                    setValue("disposal_fee", value);
                    handleValue();
                  }}
                  {...register("disposal_fee")}
                  name="disposal_fee"
                  placeholder="処分代を入力"
                  disabled={isView}
                />
              )}
            />

            {errors?.disposal_fee && (
              <p className="error">{errors.disposal_fee?.message}</p>
            )}
          </div>

          <div className="input-field">
            <InputLabel>部材代(合計)</InputLabel>
            <Controller
              control={control}
              name="total_material_cost"
              render={({ field: { value } }) => (
                <NumericFormat
                  style={{ width: 240 }}
                  thousandSeparator=","
                  decimalSeparator="."
                  customInput={TextField}
                  value={value || ""}
                  {...register("total_material_cost")}
                  placeholder="自動で合計金額算出されます"
                  disabled={true}
                  InputLabelProps={{
                    shrink: Boolean(getValues("total_material_cost")),
                  }}
                />
              )}
            />
          </div>
        </div>

        <div className="field-wrapper">
          <div className="input-field">
            <Controller
              name="total"
              control={control}
              render={({ field: { value } }) => (
                <NumericFormat
                  thousandSeparator=","
                  decimalSeparator="."
                  customInput={TextField}
                  {...register("total")}
                  value={value}
                  variant="standard"
                  label="合計"
                  disabled={true}
                  InputLabelProps={{
                    shrink:
                      getValues("total") !== 0 || getValues("total") === 0,
                  }}
                />
              )}
            />
          </div>
        </div>

        {fields.map((_, index) => (
          <div
            className="field-wrapper additional-field"
            key={index}
            style={{ alignItems: "center" }}
          >
            <div className="input-field">
              <InputLabel>部材代</InputLabel>
              <Controller
                name={`work_cost_items.${index}.material_cost`}
                control={control}
                render={({ field: { value } }) => (
                  <NumericFormat
                    value={value}
                    thousandSeparator=","
                    decimalSeparator="."
                    customInput={TextField}
                    {...register(`work_cost_items.${index}.material_cost`)}
                    name={`work_cost_items.${index}.material_cost`}
                    onValueChange={({ value }) => {
                      setValue(`work_cost_items.${index}.material_cost`, value);
                      handleValue();
                    }}
                    placeholder="部材代を入力"
                    disabled={isView}
                  />
                )}
              />
            </div>
            <div className="input-field">
              <InputLabel>品名</InputLabel>
              <TextField
                {...register(`work_cost_items.${index}.product_name`)}
                name={`work_cost_items.${index}.product_name`}
                placeholder="品名を入力"
                disabled={isView}
              />
            </div>
            <div className="input-field">
              <InputLabel>品番</InputLabel>
              <TextField
                {...register(`work_cost_items.${index}.part_number`)}
                name={`work_cost_items.${index}.part_number`}
                placeholder="品番を入力"
                disabled={isView}
              />
            </div>
            <div
              className="input-field"
              style={{
                marginRight: index === 0 || isView ? "48px" : 0,
              }}
            >
              <InputLabel>数量</InputLabel>
              <Controller
                name={`work_cost_items.${index}.quantity`}
                control={control}
                render={(props: any) => (
                  <NumericFormat
                    {...props}
                    value={props?.field?.value || ""}
                    {...register(`work_cost_items.${index}.quantity`)}
                    onValueChange={({ value }) => {
                      setValue(`work_cost_items.${index}.quantity`, value);
                      handleValue();
                    }}
                    thousandSeparator=","
                    decimalSeparator="."
                    name={`work_cost_items.${index}.quantity`}
                    placeholder="数量を入力"
                    disabled={isView}
                    customInput={TextField}
                  />
                )}
              />
            </div>
            {!isView && (
              <>
                {index !== 0 && (
                  <div
                    className="btn-delete"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingTop: 27,
                    }}
                  >
                    <img src={Trash} onClick={() => setOpenModalDelete(true)} />
                  </div>
                )}
                <ModalDelete
                  isOpen={openModalDelete}
                  isSubmitting={false}
                  onDeleteRecord={async () => {
                    remove(index);
                    setOpenModalDelete(false);
                  }}
                  onClose={() => setOpenModalDelete(false)}
                />
              </>
            )}
          </div>
        ))}

        {!isView && (
          <button
            disabled={isView}
            onClick={(e) => {
              e.preventDefault();
              append({
                material_cost: null,
                product_name: null,
                part_number: null,
                quantity: null,
              });
              return false;
            }}
            onKeyPress={(e) => {
              e.preventDefault();
              return false;
            }}
            onKeyDown={(e) => {
              e.preventDefault();
              return false;
            }}
          >
            <img src={AddIcon} />
            <p>項目追加</p>
          </button>
        )}
      </div>
    </Styles>
  );
};

const WorkCost: FC<ReceivedProps> = (props) => (
  <WorkCostLayout {...useWorkCost(props)} />
);

export default WorkCost;
