import { useCallback, useEffect, useRef, useState } from "react";
import { ColumnApi, GridApi, GridSizeChangedEvent } from "ag-grid-community";
import { get } from "lodash";
import { AgGridReact } from "ag-grid-react";

import { SORTING } from "@bms/constants";
import { useDeleteWorker, useFetchWorkers } from "@bms/hooks";
import SelectFloatingFilterComponent, { columns, SORT_PARAMS } from "./utility";

export type ReceivedProps = Record<string, any>;

const columnDefsSetting: any = columns.map((item, index) => {
  return {
    ...item,
    unSortIcon: ![0, 6].includes(index),
    filter: "agTextColumnFilter",
    ...([3, 6, 7].includes(index) && {
      floatingFilterComponent: SelectFloatingFilterComponent,
    }),
    cellClassRules: {
      "cell-disabled": (params: any) =>
        ["day_off", "retired", "long_holiday"].includes(params.data.status),
    },
    filterParams: {
      textMatcher: () => {
        return true;
      },
    },
  };
});
const useWorkerList = (props: ReceivedProps) => {
  const gridRef = useRef<AgGridReact>(null);

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(100);
  const [total, setTotal] = useState<number>(0);
  const [rowData, setRowData] = useState<any[]>([]);
  const [gridApi, setGridApi] = useState<GridApi | undefined>();
  const [isOpenModalDelete, setIsOpenModalDelete] = useState<boolean>(false);
  const [filterParams, setFilterParams] = useState<Record<string, any>>({});
  const [sortParams, setSortParams] = useState<Record<string, any>>({});
  const [workerIds, setWorkerIds] = useState<{ id: number }[]>([]);

  const {
    data: workersData,
    isSuccess,
    isLoading,
    isFetching,
  } = useFetchWorkers(page, pageSize, filterParams, sortParams);
  const { mutate, isLoading: isSubmitting } = useDeleteWorker();

  const clearFilter = useCallback(
    () => gridRef?.current?.api.setFilterModel(null),
    []
  );

  const onSortChanged = ({ columnApi }: { columnApi: ColumnApi }) => {
    const columnState = columnApi.getColumnState();
    const cusParams: any = columnState.reduce(
      (arr, cur) => ({
        ...arr,
        [get(SORT_PARAMS, cur.colId)]:
          cur.sort === null ? SORTING.ASC : cur.sort,
      }),
      {}
    );

    setSortParams({
      ...sortParams,
      ...cusParams,
    });
    setPage(1);
  };

  const onFilterUpdate = useCallback(() => {
    if (!gridApi) return;
    const model = gridApi.getFilterModel();

    const curParams = Object.keys(model).reduce(
      (arr, cur) => ({
        ...arr,
        [cur === "permissions" ? "permission_types" : cur]:
          (cur === "status" && model[cur].filter === "clear") ||
          (cur === "role" && model[cur].filter === "clear") ||
          (cur === "permissions" && model[cur].filter == 1)
            ? null
            : model[cur].filter,
      }),
      {}
    );
    setFilterParams({
      ...filterParams,
      ...curParams,
    });
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridApi]);

  const onOpenModalDelete = () => {
    if (!gridApi) return;
    const rowSelected = gridApi.getSelectedRows();
    setWorkerIds(rowSelected.map((item) => ({ id: item.id })));
    setIsOpenModalDelete(true);
  };
  const onCloseModalDelete = () => setIsOpenModalDelete(false);

  const onDeleteRecord = async () => {
    mutate(
      {
        payload: { worker_attributes: workerIds },
      },
      {
        onSuccess() {
          setRowData([]);
          setIsOpenModalDelete(false);
        },
      }
    );
  };

  const onGridReady = (params: GridSizeChangedEvent) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
    params.api.resetRowHeights();
  };

  useEffect(() => {
    if (isLoading || isFetching) {
      setRowData([]);
    } else {
      if (isSuccess) {
        setRowData(
          workersData.workers.map((item: any) => ({
            ...item,
          }))
        );
        setTotal(workersData.meta.total_records);
      }
    }
  }, [isFetching, isLoading, isSuccess, workersData]);

  return {
    ...props,
    isLoading,
    isFetching,
    rowData,
    pageSize,
    gridRef,
    gridApi,
    page,
    isOpenModalDelete,
    isSubmitting,
    total,
    columnDefs: columnDefsSetting,
    clearFilter,
    onSortChanged,
    onFilterUpdate,
    onDeleteRecord,
    onOpenModalDelete,
    onCloseModalDelete,
    setPage,
    onGridReady,
    setPageSize,
    setFilterParams,
  };
};

export type Props = ReturnType<typeof useWorkerList>;

export default useWorkerList;
