import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

const authSelector = (state: RootState) => state.auth;

export const loggedInUserSelector = createSelector(
  authSelector,
  (authState) => authState.loggedInUser
);

export const userSelector = createSelector(
  authSelector,
  (authState) => authState.user
);
