import { FC } from "react";
import { Loading, ModalConfirm } from "@bms/components";
import { DatePicker } from "antd";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import dayjs from "dayjs";

import useRequestContent, { Props, ReceivedProps, STATUS } from "./hook";
import { Styles } from "./styled";

dayjs.extend(weekday);
dayjs.extend(localeData);

const RequestContentLayout: FC<Props> = ({
  isEnd,
  date,
  isDatePicker,
  openConfirmDialog,
  openSetDate,
  isLoading,
  isFetching,
  status,
  data,
  disabledDate,
  setIsDatePicker,
  onSubmit,
  onSubmitConfirm,
  setDate,
  setOpenSetDate,
  setOpenConfirmDialog,
}) => {
  if (isLoading || isFetching) {
    return <Loading />;
  }

  return (
    <Styles>
      <button
        className={`btn btn-start ${!isEnd ? "transparent" : ""} ${
          data?.status?.working_status
        }`}
        onClick={() => {
          setOpenConfirmDialog(true);
          setIsDatePicker(false);
        }}
      >
        <p>
          {data?.status?.working_status === "resolved"
            ? "対応済"
            : data?.status?.working_status === "not_compatible"
            ? "未対応"
            : ""}
        </p>
      </button>

      {date ? (
        <>
          <button
            className={`btn btn-date`}
            onClick={() => {
              setIsDatePicker(true);
            }}
          >
            <p>再訪問</p>
            <p>{dayjs(date).format("YYYY.MM.DD")}</p>
          </button>
        </>
      ) : (
        <button
          className={`btn btn-end`}
          onClick={() => {
            setIsDatePicker(!isDatePicker);
          }}
        >
          <p>再訪問</p>
        </button>
      )}

      <DatePicker
        popupClassName="datetime-picker"
        style={{
          position: "absolute",
          zIndex: "-1",
          marginTop: "60px",
        }}
        {...(date && { defaultValue: dayjs(date) })}
        placement="bottomLeft"
        disabledDate={disabledDate}
        open={isDatePicker}
        showNow={false}
        showToday={false}
        showTime={{ format: "HH:mm" }}
        onOk={(e) => {
          setDate(e);
          setOpenSetDate(true);
        }}
        onChange={(e) => setDate(e)}
      />

      <ModalConfirm
        isOpen={openConfirmDialog}
        isSubmitting={false}
        content={
          status === STATUS["resolved"]
            ? "未対応にしますか？"
            : "対応済にしますか？"
        }
        onOk={() =>
          onSubmitConfirm(
            status === STATUS["not_compatible"] ? "resolved" : "not_compatible"
          )
        }
        onClose={() => setOpenConfirmDialog(false)}
        className="custom-title"
      />

      <ModalConfirm
        isOpen={openSetDate}
        isSubmitting={false}
        content={"再訪問の設定をしますか？"}
        onOk={onSubmit}
        onClose={() => {
          setDate(null);
          setOpenSetDate(false);
        }}
        className="custom-title"
      />
    </Styles>
  );
};

const RequestContent: FC<ReceivedProps> = (props) => (
  <RequestContentLayout {...useRequestContent(props)} />
);

export default RequestContent;
