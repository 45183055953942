import { FC } from "react";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";

import { ModalWrapper, StylesModal } from "./styled";

type IProps = {
  isOpen: boolean;
  isSubmitting: boolean;
  onDeleteRecord: () => Promise<void>;
  onClose: () => void;
};

const ModalDelete: FC<IProps> = ({
  isOpen,
  isSubmitting,
  onDeleteRecord,
  onClose,
}) => {
  return (
    <ModalWrapper open={isOpen} onClose={onClose}>
      <Box sx={{ ...StylesModal, width: 310, height: 214 }}>
        <h3>削除しますか？</h3>

        <div className="modal-footer">
          <div className="group-btn">
            <LoadingButton
              loadingPosition="start"
              loading={isSubmitting}
              type="submit"
              variant="outlined"
              className="btn-submit"
              onClick={onDeleteRecord}
            >
              はい
            </LoadingButton>
            <button
              className={`${isSubmitting ? "disabled" : ""} btn-cancel`}
              onClick={onClose}
            >
              いいえ
            </button>
          </div>
        </div>
      </Box>
    </ModalWrapper>
  );
};

export default ModalDelete;
