import { FC } from "react";
import { TextField, Box } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import { Loading } from "@bms/components";
import useTaxRate, { Props, ReceivedProps } from "./hook";
import { Styles } from "./styled";

const TaxRateLayout: FC<Props> = ({
  register,
  handleSubmit,
  onSubmit,
  setValue,
  errors,
  isLoading,
  isSubmitting,
}) => {
  if (isLoading) {
    return <Loading />;
  }

  return (
    <Styles>
      <div className="header">
        <h3>税率</h3>
        <LoadingButton
          loadingPosition="start"
          loading={isSubmitting}
          type="submit"
          variant="outlined"
          onClick={handleSubmit(onSubmit)}
        >
          確定
        </LoadingButton>
      </div>

      <Box className="form">
        <label htmlFor="">消費税</label>
        <div className="field-wrapper">
          <TextField
            style={{
              width: 74,
            }}
            {...register("rate")}
            variant="standard"
            onChange={(e: any) => {
              const value = e.target.value.replace(/\D+/g, "");
              setValue("rate", Number(value) > 100 ? 100 : value);
            }}
            name="rate"
          />
          <p>%</p>
        </div>
        {errors.rate && <p className="error">{errors.rate?.message}</p>}
      </Box>
    </Styles>
  );
};

const TaxRate: FC<ReceivedProps> = (props) => (
  <TaxRateLayout {...useTaxRate(props)} />
);

export default TaxRate;
