import { FC } from "react";
import { useNavigate } from "react-router-dom";
// import dayjs from "dayjs";

import {
  CameraIcon,
  CheckIcon,
  CheckSolidIcon,
  DuplicateIcon,
  EditIcon,
  HamburgerIcon,
  LocationIcon,
  MemoIcon,
  NotificationWhiteIcon,
  TimeIcon,
  UserActiveIcon,
} from "@bms/assets";
import { ROUTES } from "@bms/constants";
import { Loading } from "@bms/components";
import useServiceCardTicketDetail, { Props, ReceivedProps } from "./hook";
import { Styles } from "./styled";
import { TextField } from "@mui/material";

const ServiceCardTicketDetailLayout: FC<Props> = ({
  isShowBox,
  serviceCardData,
  isLoading,
  isFetching,
  workingHoursDone,
  reportDone,
  workCostDone,
  workStatusDone,
  isEditContent,
  handleNotification,
  handleAddress,
  onSubmit,
  register,
  setIsShowBox,
  inputRef,
  value,
  setValue,
}) => {
  const navigate = useNavigate();

  if (isLoading || isFetching) {
    return <Loading />;
  }

  return (
    <Styles>
      <div className={`service-card ${!isShowBox ? "hidden" : ""}`}>
        <div className="card-title">
          <h3>作業内容</h3>
          <img
            src={HamburgerIcon}
            alt=""
            onClick={() => setIsShowBox(!isShowBox)}
          />
        </div>

        <form action="">
          {isShowBox && (
            <div className="box">
              <div className="item-box">
                <span>建物名</span>
                <p>
                  {serviceCardData?.order_information?.building
                    ?.building_tentative_name ||
                    serviceCardData?.order_information?.building_data
                      ?.building_tentative_name ||
                    ""}
                </p>
              </div>

              <div className="item-box">
                <span>号室</span>
                <p>
                  {serviceCardData?.order_information?.building_data
                    ?.room_number ||
                    serviceCardData?.order_information?.building?.resident
                      ?.room_number ||
                    ""}
                </p>
              </div>

              <div className="item-box">
                <span>入居者名</span>
                <p>
                  {serviceCardData?.order_information?.building_data
                    ?.resident_name ||
                    serviceCardData?.order_information?.building?.resident
                      ?.name ||
                    ""}
                </p>
              </div>

              <div className="item-box">
                <span>入居者_連絡先1_携帯TEL</span>
                <p>
                  {serviceCardData?.order_information?.building_data
                    ?.tenant_contact ||
                    serviceCardData?.order_information?.building?.resident
                      ?.mobile_phone ||
                    ""}
                </p>
              </div>

              <div className="item-box">
                <span>住所</span>
                <p>{handleAddress(serviceCardData)}</p>
              </div>

              <button
                className="btn google-map"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(
                    `http://maps.google.com/?q=${handleAddress(
                      serviceCardData
                    )}`
                  );
                }}
              >
                <img src={LocationIcon} alt="" />
                <p>Google MAP</p>
              </button>

              <div className="item-box">
                <span>スマイルサポート加入</span>
                <p>
                  {(serviceCardData?.order_information?.support_type as any) ===
                  "joining"
                    ? "加入"
                    : (serviceCardData?.order_information
                        ?.support_type as any) === "non_member"
                    ? "未加入"
                    : ""}
                </p>
              </div>

              {/* <div className="item-box">
                <span>タイトル</span>
                <p>{`${dayjs(
                  serviceCardData?.request_content?.start_time
                ).format("HH:mm")} - ${dayjs(
                  serviceCardData?.request_content?.end_time
                ).format("HH:mm")}`}</p>
              </div> */}

              <div className="item-box">
                <span>報告書に記載</span>
                {isEditContent ? (
                  <TextField
                    className="edit-content-item"
                    inputRef={(input) => input && input.focus()}
                    onFocus={(e) => {
                      const textarea = e.currentTarget;
                      e.currentTarget.setSelectionRange(
                        e.currentTarget.value.length,
                        e.currentTarget.value.length
                      );
                      if (textarea.selectionStart == textarea.selectionEnd) {
                        textarea.scrollTop = textarea.scrollHeight + 10;
                      }
                    }}
                    multiline
                    rows={4}
                    {...register("content")}
                    name="content"
                    autoFocus
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                  />
                ) : (
                  <p className="multiline-content">
                    {serviceCardData?.request_content?.content}
                  </p>
                )}
              </div>

              <div className="edit" onClick={onSubmit}>
                <img src={EditIcon} alt="" />
              </div>

              <>
                {serviceCardData?.status?.working_status && (
                  <>
                    {serviceCardData.status.working_status === "resolved" ? (
                      <button className="btn">
                        <img src={CheckIcon} alt="" />
                        <p>対応済</p>
                      </button>
                    ) : (
                      <button className="btn error">
                        <p>未対応</p>
                      </button>
                    )}
                  </>
                )}
              </>
            </div>
          )}
        </form>
      </div>

      <div className="control">
        <button
          className="btn btn-memo"
          onClick={() => {
            handleNotification("memo");
            navigate(
              `${ROUTES.MEMO}/${serviceCardData?.memo?.id}?service_card_id=${serviceCardData.id}`
            );
          }}
        >
          <img src={DuplicateIcon} alt="" />
          <p>メモ</p>
          {serviceCardData?.memo?.has_notification && (
            <img src={NotificationWhiteIcon} alt="" />
          )}
        </button>

        <button
          className={`btn btn-working ${workingHoursDone ? "done" : ""}`}
          onClick={() => {
            handleNotification("request-content");
            navigate(`${ROUTES.WORKING_HOURS}/${serviceCardData?.id}`);
          }}
        >
          <img src={TimeIcon} alt="" />
          <p>作業時間</p>
          {!serviceCardData?.request_content?.has_notification &&
            workingHoursDone && <img src={CheckSolidIcon} alt="" />}
          {serviceCardData?.request_content?.has_notification &&
            !workingHoursDone && <img src={NotificationWhiteIcon} alt="" />}
        </button>

        <button
          className={`btn btn-report ${reportDone ? "done" : ""}`}
          onClick={() => {
            handleNotification("work-report");
            navigate(`${ROUTES.WORKER_REPORT}/${serviceCardData?.id}`);
          }}
        >
          <img src={CameraIcon} alt="" />
          <p>作業報告</p>
          {reportDone &&
            !serviceCardData?.work_reports?.find(
              (item) => item.has_notification
            ) && <img src={CheckSolidIcon} alt="" />}
          {serviceCardData?.work_reports?.find(
            (item) => item.has_notification
          ) && <img src={NotificationWhiteIcon} alt="" />}
        </button>

        <button
          className={`btn btn-cost ${workCostDone ? "done" : ""}`}
          onClick={() => {
            handleNotification("work-cost");
            navigate(`${ROUTES.WORK_COST}/${serviceCardData?.id}`);
          }}
        >
          <img src={MemoIcon} alt="" />
          <p>作業費用</p>
          {Boolean(
            workCostDone && !serviceCardData?.work_cost?.has_notification
          ) && <img src={CheckSolidIcon} alt="" />}
          {Boolean(serviceCardData?.work_cost?.has_notification) && (
            <img src={NotificationWhiteIcon} alt="" />
          )}
        </button>

        <button
          className={`btn btn-support ${workStatusDone ? "done" : ""}`}
          onClick={() =>
            navigate(`${ROUTES.REQUEST_CONTENT}/${serviceCardData?.id}`)
          }
        >
          <img src={UserActiveIcon} alt="" />
          <p>対応</p>
          {Boolean(workStatusDone) && <img src={CheckSolidIcon} alt="" />}
        </button>
      </div>
    </Styles>
  );
};

const ServiceCardTicketDetail: FC<ReceivedProps> = (props) => (
  <ServiceCardTicketDetailLayout {...useServiceCardTicketDetail(props)} />
);

export default ServiceCardTicketDetail;
