import { FC, SetStateAction, Dispatch } from "react";
import Box from "@mui/material/Box";
import { useDropzone } from "react-dropzone";

import { TickSuccessIcon, UploadIcon } from "@bms/assets";
import { ModalWrapper, StylesModal } from "./styled";

type IProps = {
  isOpen: boolean;
  file: File | null;
  isSubmitting: boolean;
  isSuccess: boolean;
  reset: () => void;
  onImportCSV: () => Promise<void>;
  setFile: Dispatch<SetStateAction<File | null>>;
  onClose: () => void;
};

const ModalImport: FC<IProps> = ({
  isOpen,
  file,
  isSubmitting,
  isSuccess,
  reset,
  onImportCSV,
  setFile,
  onClose,
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (e: File[]) => setFile(e[0]),
    accept: { "text/csv": [".csv"] },
  });

  return (
    <ModalWrapper open={isOpen} onClose={onClose}>
      <Box sx={{ ...StylesModal, width: 667, height: 460 }}>
        <h3>CSVファイルをアップロード</h3>

        <div className="modal-content">
          <div className="modal-content-wrapper" {...getRootProps()}>
            <input accept=".csv" {...getInputProps()} />

            <div className="icon">
              <img src={UploadIcon} />
            </div>
            <p>
              {file ? file?.name : "ファイルをここにドラッグ&ドロップまたは"}
            </p>
            <button
              disabled={isSubmitting || isSuccess}
              className={`${isSubmitting || isSuccess ? "disabled" : ""}`}
            >
              ファイルを選択
            </button>
          </div>
        </div>

        <div className="modal-footer">
          {file && (
            <div className="description">
              <img src={TickSuccessIcon} />
              <p>アップロードが完了しました</p>
            </div>
          )}

          {file ? (
            <div className="group-btn">
              <button
                disabled={isSubmitting}
                className={`btn-import ${isSubmitting ? "disabled" : ""}`}
                onClick={onImportCSV}
              >
                インポート
              </button>
              <button
                disabled={isSubmitting}
                className={`btn-cancel ${isSubmitting ? "disabled" : ""}`}
                onClick={() => {
                  onClose();
                  reset();
                  setFile(null);
                }}
              >
                キャンセル
              </button>
            </div>
          ) : (
            <button
              disabled={isSubmitting}
              className="btn-close"
              onClick={() => {
                onClose();
                reset();
                setFile(null);
              }}
            >
              閉じる
            </button>
          )}
        </div>
      </Box>
    </ModalWrapper>
  );
};

export default ModalImport;
