import { FC } from "react";
import { Box, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";

import { Loading } from "@bms/components";
import usePaymentControl, { Props, ReceivedProps } from "./hook";
import { Styles } from "./styled";

const PaymentControlLayout: FC<Props> = ({
  register,
  handleSubmit,
  onSubmit,
  isView,
  errors,
  isLoading,
  isSubmitting,
  id,
}) => {
  const navigate = useNavigate();

  if (id && isLoading) {
    return <Loading />;
  }
  return (
    <Styles>
      <div className="header">
        <h3>
          {!id ? "支払区分登録" : isView ? "支払区分詳細" : "支払区分編集"}
        </h3>
        <div className="group-btn">
          <button className="btn-back" onClick={() => navigate(-1)}>
            戻る
          </button>
          {!isView && (
            <LoadingButton
              loadingPosition="start"
              loading={isSubmitting}
              type="submit"
              variant="outlined"
              onClick={handleSubmit(onSubmit)}
            >
              {!id ? "追加" : "保存"}
            </LoadingButton>
          )}
        </div>
      </div>

      <div className="title">
        <h4>支払区分</h4>
      </div>

      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <TextField
          {...register("id")}
          name="id"
          label="No."
          variant="standard"
          disabled
        />
        <div className="field-wrapper">
          <div className="input-field">
            <TextField
              {...register("category")}
              name="category"
              label="カテゴリ"
              variant="standard"
              disabled={isView}
            />
            {errors.category && (
              <p className="error">{errors.category?.message}</p>
            )}
          </div>
          <div className="input-field">
            <TextField
              {...register("method")}
              name="method"
              label="区分"
              variant="standard"
              disabled={isView}
            />
            {errors.method && <p className="error">{errors.method?.message}</p>}
          </div>
        </div>
      </Box>
    </Styles>
  );
};

const PaymentControl: FC<ReceivedProps> = (props) => (
  <PaymentControlLayout {...usePaymentControl(props)} />
);

export default PaymentControl;
