export enum ROUTES {
  HOME = "/",
  SIGNIN = "login",
  SERVICE_CARD = "/service-cards",
  SHIFT = "/shifts",
  CLEANING = "/cleaning",
  HOLIDAY = "/holidays",
  OPERATOR = "/workers",
  SCHEDULE = "/schedule",
  PROPERTY = "/buildings",
  // MERCHANDISE = "/products",
  RECEPTION = "/receptions",
  PAYMENT = "/payment-categories",
  SUBCONTRACTOR = "/subcontractors",
  TAX_RATE = "/tax-rate",
  INVOICE = "/invoices",
  REPORT = "/reports",
  OUTPUT = "/output",
  ID = ":id",
  CREATE = "create",
  CALENDAR = "calendar",

  // Mobile
  DASHBOARD = "/dashboard",
  MY_SERVICE_CARD_TICKET = "/my-service-card-ticket",
  OTHER_SERVICE_CARD_TICKET = "/other-service-card-ticket",
  WORKER_REPORT = "/work-report",
  WORKING_HOURS = "/working-hours",
  WORK_COST = "/work-cost",
  MEMO = "/memo",
  REQUEST_CONTENT = "/request-content",
  MY_SCHEDULE = "/my-schedule",
}
