import { FC } from "react";
import {
  Scheduler,
  MonthView,
  Toolbar,
  DateNavigator,
  Appointments,
} from "@devexpress/dx-react-scheduler-material-ui";
import { ViewState, EditingState } from "@devexpress/dx-react-scheduler";
import moment from "moment";

import { ArrowLeftSvg, ArrowRightSvg } from "@bms/assets";
import AppointmentItem from "./AppointmentItem";
import { MonthViewDayScaleCell, MonthViewTimeTableCell } from "./Components";
import useSchedulerBox, { Props, ReceivedProps } from "./hook";
import { SchedulerBoxWrapper } from "./styled";

const SchedulerBoxLayout: FC<Props> = ({
  localScheduleDate,
  holidaysData,
  specificHolidays,
  setCurrentDay,
  setOpenScheduleDialog,
  setCurrentDate,
}) => {
  return (
    <>
      <SchedulerBoxWrapper>
        <div className="scheduler-wrapper">
          <Scheduler data={localScheduleDate} firstDayOfWeek={1}>
            <ViewState
              defaultCurrentDate={(() => new Date())()}
              currentViewName={"Month"}
              onCurrentDateChange={(date) => setCurrentDate(date)}
            />
            <EditingState onCommitChanges={() => {}} />
            <MonthView
              timeTableCellComponent={(props) =>
                MonthViewTimeTableCell(
                  props,
                  holidaysData || [],
                  specificHolidays
                )
              }
              dayScaleCellComponent={(props) =>
                MonthViewDayScaleCell(
                  props,
                  holidaysData || [],
                  specificHolidays
                )
              }
            />
            <Toolbar />
            <DateNavigator
              rootComponent={({ onNavigate, navigatorText }: any) => {
                if (!navigatorText) return <></>;

                const date = new Date(navigatorText);
                const month = date.getMonth();
                const prevMonth = new Date(navigatorText).setMonth(month - 1);
                const nextMonth = new Date(navigatorText).setMonth(month + 1);

                return (
                  <div className="header-wrapper">
                    <button className="btn-back">
                      <img
                        src={ArrowLeftSvg}
                        alt=""
                        onClick={() => onNavigate("back")}
                      />
                      <p>{new Date(prevMonth).getMonth() + 1}</p>
                    </button>
                    <h2>{moment(navigatorText).format("YYYY.M")}</h2>
                    <button className="btn-next">
                      <p>{new Date(nextMonth).getMonth() + 1}</p>
                      <img
                        src={ArrowRightSvg}
                        alt=""
                        onClick={() => onNavigate("forward")}
                      />
                    </button>
                  </div>
                );
              }}
            />
            <Appointments
              appointmentComponent={({ ...restProps }) => (
                <AppointmentItem
                  restProps={restProps}
                  setCurrentDay={setCurrentDay}
                  setOpenScheduleDialog={setOpenScheduleDialog}
                  setCurrentDate={setCurrentDate}
                />
              )}
            />
          </Scheduler>
        </div>
      </SchedulerBoxWrapper>
    </>
  );
};

const SchedulerBox: FC<ReceivedProps> = (props) => (
  <SchedulerBoxLayout {...useSchedulerBox(props)} />
);

export default SchedulerBox;
