import { useSelector } from "react-redux";

import { userSelector } from "@bms/features/auth/selector";
import { useFetchServiceCard } from "@bms/hooks";
import { useCallback, useEffect } from "react";
import { ServiceCard } from "@bms/types";
import { useLocation } from "react-router-dom";
import { ROUTES } from "@bms/constants";
import dayjs from "dayjs";

export type ReceivedProps = Record<string, any>;

const useServiceCardTicket = (props: ReceivedProps) => {
  const user = useSelector(userSelector);
  const { pathname } = useLocation();
  const isOtherServiceCard = pathname === ROUTES.OTHER_SERVICE_CARD_TICKET;

  const {
    data: serviceCardData,
    isSuccess,
    isLoading,
    isFetching,
  } = useFetchServiceCard(1, 100000, {
    ...(pathname === ROUTES.OTHER_SERVICE_CARD_TICKET && {
      order_date: dayjs().format("YYYY/MM/DD"),
      group_by: "worker_name",
    }),
    ...(pathname === ROUTES.MY_SERVICE_CARD_TICKET && {
      sort_type: "worker",
      worker_id: user?.worker?.id,
      order_date: dayjs().format("YYYY/MM/DD"),
    }),
    ...(pathname !== ROUTES.OTHER_SERVICE_CARD_TICKET && {
      worker_id: user?.worker?.id,
    }),
  });

  const hasNotification = useCallback((data) => {
    const {
      // status,
      order_information,
      contractor_information,
      request_content,
      work_reports,
      work_cost,
      payment,
      memo,
    } = data || {};

    return (
      // status?.has_notification ||
      order_information?.has_notification ||
      contractor_information?.has_notification ||
      request_content?.has_notification ||
      work_reports?.find((item: any) => item?.has_notification) ||
      payment?.has_notification ||
      work_cost?.has_notification ||
      memo?.has_notification
    );
  }, []);

  const handleAddress = (data: ServiceCard) => {
    return (
      [
        data?.order_information?.building?.building_prefecture_name,
        data?.order_information?.building?.building_municipality_name,
        data?.order_information?.building?.building_town_name,
        data?.order_information?.building?.building_district,
        data?.order_information?.building?.building_street_address,
      ]
        .filter((obj) => obj)
        .join("") ||
      data?.order_information?.building_data?.building_address
        ?.replaceAll(/,/g, "")
        ?.replaceAll(" ", "")
    );
  };

  const handleReturnOrderDate = (data: ServiceCard) => {
    const returnOrderDate = dayjs(
      data?.request_content?.return_order_date
    ).format("YYYY-MM-DD");
    const startTime = dayjs(data?.request_content?.actual_start_time).format(
      "YYYY-MM-DD"
    );
    const endTime = dayjs(data?.request_content?.actual_end_time).format(
      "YYYY-MM-DD"
    );

    return (
      data?.request_content?.return_order_date &&
      dayjs(startTime).isSame(dayjs(returnOrderDate)) &&
      dayjs(endTime).isSame(dayjs(returnOrderDate))
    );
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [isSuccess, isLoading, isFetching]);

  return {
    ...props,
    isOtherServiceCard,
    serviceCardData: serviceCardData?.json?.data || [],
    isLoading,
    isFetching,
    handleReturnOrderDate,
    hasNotification,
    handleAddress,
  };
};

export type Props = ReturnType<typeof useServiceCardTicket>;

export default useServiceCardTicket;
