import { FC } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import SelectField from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ClearIcon from "@mui/icons-material/Clear";

import usePaymentInformation, { Props, ReceivedProps } from "./hook";
import { Styles } from "./styled";

const PaymentInformationLayout: FC<Props> = ({
  register,
  getValues,
  setValue,
  watch,
  errors,
  paymentCategoryData,
  isView,
}) => {
  return (
    <Styles className="payment-information">
      <div className="field-wrapper">
        <div className="input-field">
          <FormControl variant="outlined">
            <InputLabel>請求者区分</InputLabel>
            <SelectField
              {...register("claimant_classification_id")}
              sx={{
                "& .MuiSelect-icon": {
                  display: watch(`claimant_classification_id`) ? "none" : "",
                },
              }}
              name="claimant_classification_id"
              variant="standard"
              defaultValue={getValues("claimant_classification_id") || ""}
              label="請求者区分"
              disabled={isView}
              {...(!isView && {
                endAdornment: (
                  <ClearIcon
                    sx={{
                      visibility: watch(`claimant_classification_id`)
                        ? "visible"
                        : "hidden",
                    }}
                    className="clearable"
                    onClick={() => {
                      setValue(`claimant_classification_id`, "");
                    }}
                  />
                ),
              })}
            >
              {paymentCategoryData
                .filter((item: any) => item.category === "請求者区分")
                .map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
            </SelectField>
            {errors?.claimant_classification_id && (
              <p className="error">
                {errors.claimant_classification_id?.message}
              </p>
            )}
          </FormControl>
        </div>

        <div className="input-field">
          <FormControl variant="outlined">
            <InputLabel>費用支払区分</InputLabel>
            <SelectField
              sx={{
                "& .MuiSelect-icon": {
                  display: watch(`expense_payment_category_id`) ? "none" : "",
                },
              }}
              {...register("expense_payment_category_id")}
              name="expense_payment_category_id"
              variant="standard"
              defaultValue={getValues("expense_payment_category_id") || ""}
              label="費用支払区分"
              disabled={isView}
              {...(!isView && {
                endAdornment: (
                  <ClearIcon
                    sx={{
                      visibility: watch(`expense_payment_category_id`)
                        ? "visible"
                        : "hidden",
                    }}
                    className="clearable"
                    onClick={() => {
                      setValue(`expense_payment_category_id`, "");
                    }}
                  />
                ),
              })}
            >
              {paymentCategoryData
                .filter((item: any) => item.category === "費用支払区分")
                .map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
            </SelectField>
            {errors?.expense_payment_category_id && (
              <p className="error">
                {errors.expense_payment_category_id?.message}
              </p>
            )}
          </FormControl>
        </div>

        <div className="input-field">
          <FormControl variant="outlined">
            <InputLabel>費用負担区分</InputLabel>
            <SelectField
              sx={{
                "& .MuiSelect-icon": {
                  display: watch(`cost_sharing_category_id`) ? "none" : "",
                },
              }}
              {...register("cost_sharing_category_id")}
              name="cost_sharing_category_id"
              variant="standard"
              defaultValue={getValues("cost_sharing_category_id")}
              label="費用負担区分"
              disabled={isView}
              {...(!isView && {
                endAdornment: (
                  <ClearIcon
                    sx={{
                      visibility: watch(`cost_sharing_category_id`)
                        ? "visible"
                        : "hidden",
                    }}
                    className="clearable"
                    onClick={() => {
                      setValue(`cost_sharing_category_id`, "");
                    }}
                  />
                ),
              })}
            >
              {paymentCategoryData
                .filter((item: any) => item.category === "費用負担区分")
                .map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
            </SelectField>
            {errors?.cost_sharing_category_id && (
              <p className="error">
                {errors.cost_sharing_category_id?.message}
              </p>
            )}
          </FormControl>
        </div>

        <div className="input-field">
          <FormControl variant="outlined">
            <InputLabel>３家請求書</InputLabel>
            <SelectField
              sx={{
                "& .MuiSelect-icon": {
                  display: watch(`bill_id`) ? "none" : "",
                },
              }}
              name="bill_id"
              variant="standard"
              defaultValue={getValues("bill_id")}
              label="３家請求書"
              value={watch(`bill_id`) || ""}
              disabled={isView}
              onChange={(e) => {
                setValue(`bill_id`, e.target.value);
              }}
              {...(!isView && {
                endAdornment: (
                  <ClearIcon
                    sx={{
                      visibility: watch(`bill_id`) ? "visible" : "hidden",
                    }}
                    className="clearable"
                    onClick={() => {
                      setValue(`bill_id`, "");
                    }}
                  />
                ),
              })}
            >
              {paymentCategoryData
                .filter((item: any) => item.category === "３家請求書")
                .map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
            </SelectField>
          </FormControl>
        </div>

        <div className="input-field">
          <FormControl variant="outlined">
            <InputLabel>３家担当者</InputLabel>
            <SelectField
              sx={{
                "& .MuiSelect-icon": {
                  display: watch(`person_in_charge_id`) ? "none" : "",
                },
              }}
              name="person_in_charge_id"
              variant="standard"
              defaultValue={getValues("person_in_charge_id")}
              label="３家担当者"
              value={watch(`person_in_charge_id`) || ""}
              onChange={(e) => {
                setValue(`person_in_charge_id`, e.target.value);
              }}
              disabled={isView}
              {...(!isView && {
                endAdornment: (
                  <ClearIcon
                    sx={{
                      visibility: watch(`person_in_charge_id`)
                        ? "visible"
                        : "hidden",
                    }}
                    className="clearable"
                    onClick={() => {
                      setValue(`person_in_charge_id`, "");
                    }}
                  />
                ),
              })}
            >
              {paymentCategoryData
                .filter((item: any) => item.category === "３家担当者")
                .map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
            </SelectField>
          </FormControl>
        </div>
      </div>
    </Styles>
  );
};

const PaymentInformation: FC<ReceivedProps> = (props) => (
  <PaymentInformationLayout {...usePaymentInformation(props)} />
);

export default PaymentInformation;
