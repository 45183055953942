import styled from "styled-components";

export const Styles = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  form {
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 38px;
  }

  .logo {
    margin-bottom: 2rem;
  }

  .logo img {
    width: 20rem;
  }

  .input-field {
    max-width: 384px;
    width: 100%;
    margin-bottom: 1.5rem;
    text-align: left;
  }

  label {
    display: inline-block;
    margin-bottom: 0.3rem;
    text-transform: uppercase;
    font-size: 0.9rem;
  }

  input {
    width: 100%;
    padding: 0.5rem;
    border-radius: 3px;
    border: 0.1rem solid #eee;
    font-size: 1rem;
  }

  .btn-submit {
    padding: 15px 40px;
    font-size: 18px;
    border: none;
    background: var(--sub-color);
    border-radius: 6px;
    color: #ffffff;
    font-weight: 500;
    margin-top: 15px;
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 0 auto;
  }
`;
