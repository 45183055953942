import { useCallback, useEffect, useRef, useState } from "react";
import { ColumnApi, GridApi, GridSizeChangedEvent } from "ag-grid-community";
import { get } from "lodash";
import dayjs from "dayjs";

import { DATE_TIME_FORMAT, SORTING } from "@bms/constants";
import { WorkersShifts } from "@bms/types";
import SelectFloatingFilterComponent, { columns, SORT_PARAMS } from "./utility";
import { useDeleteShiftWorker, useFetchWorkersShifts } from "@bms/hooks";

export type ReceivedProps = Record<string, any>;

const columnDefsSetting: any = columns.map((item, index) => ({
  ...item,
  unSortIcon: index !== 0,
  filter: "agTextColumnFilter",
  ...([5].includes(index) && {
    floatingFilterComponent: SelectFloatingFilterComponent,
  }),
  cellClassRules: {
    "cell-disabled": (params: any) =>
      ["day_off", "retired", "long_holiday"].includes(params.data.status),
  },
  filterParams: {
    textMatcher: () => {
      return true;
    },
  },
}));

const useShiftList = (props: ReceivedProps) => {
  const gridRef: any = useRef();

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(100);
  const [total, setTotal] = useState<number>(0);
  const [rowData, setRowData] = useState<any[]>([]);
  const [gridApi, setGridApi] = useState<GridApi | undefined>();
  const [isOpenModalDelete, setIsOpenModalDelete] = useState<boolean>(false);
  const [filterParams, setFilterParams] = useState<Record<string, any>>({});
  const [sortParams, setSortParams] = useState<Record<string, any>>({});
  const [shiftIds, setShiftIds] = useState<{ id: number }[]>([]);

  const {
    data: workerShiftsData,
    isLoading,
    isFetching,
    isSuccess,
  } = useFetchWorkersShifts(page, pageSize, filterParams, sortParams);
  const { mutate, isLoading: isSubmitting } = useDeleteShiftWorker();

  const onSortChanged = ({ columnApi }: { columnApi: ColumnApi }) => {
    const columnState = columnApi.getColumnState();
    const cusParams: any = columnState.reduce(
      (arr, cur) => ({
        ...arr,
        [get(SORT_PARAMS, cur.colId)]:
          cur.sort === null ? SORTING.ASC : cur.sort,
      }),
      {}
    );

    setSortParams({
      ...sortParams,
      ...cusParams,
    });
    setPage(1);
  };

  const onFilterUpdate = useCallback(() => {
    if (!gridApi) return;
    const model = gridApi.getFilterModel();

    const curParams = Object.keys(model).reduce(
      (arr, cur) => ({
        ...arr,
        [cur]:
          cur === "status" && model[cur].filter === "clear"
            ? null
            : model[cur].filter,
      }),
      {}
    );
    setFilterParams({
      ...filterParams,
      ...curParams,
    });
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridApi]);

  const onOpenModalDelete = () => {
    if (!gridApi) return;
    const rowSelected = gridApi.getSelectedRows();
    setShiftIds(rowSelected.map((item) => ({ id: item.shift?.id || null })));
    setIsOpenModalDelete(true);
  };
  const onCloseModalDelete = () => setIsOpenModalDelete(false);

  const onDeleteRecord = async () => {
    mutate(
      {
        payload: shiftIds.filter((item) => item.id),
      },
      {
        onSuccess() {
          setRowData([]);
          setIsOpenModalDelete(false);
        },
      }
    );
  };

  const onGridReady = (params: GridSizeChangedEvent) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  const handleColumnRange = (data: WorkersShifts) => {
    if (data.status === "retired") {
      return data?.retired_date
        ? `${dayjs(data.retired_date).format(DATE_TIME_FORMAT.DATE)} `
        : "";
    }

    if (data.status === "long_holiday") {
      return data?.start_of_long_holiday && data?.end_of_long_holiday
        ? `${dayjs(data.start_of_long_holiday, "YYYY-MM-DD").format(
            DATE_TIME_FORMAT.DATE
          )} - ${dayjs(data.end_of_long_holiday, "YYYY-MM-DD").format(
            DATE_TIME_FORMAT.DATE
          )}`
        : "";
    }

    if (data.status === "day_off") {
      return data?.start_of_holiday && data?.end_of_holiday
        ? `${dayjs(data.start_of_holiday, "YYYY-MM-DD").format(
            DATE_TIME_FORMAT.DATE
          )} - ${dayjs(data.end_of_holiday, "YYYY-MM-DD").format(
            DATE_TIME_FORMAT.DATE
          )}`
        : "";
    }

    return "なし";
  };

  useEffect(() => {
    if (isLoading || isFetching) {
      setRowData([]);
    } else {
      if (isSuccess) {
        setRowData(
          (workerShiftsData.workers as WorkersShifts[]).map((item) => ({
            ...item,
            workerShift: item.shift,
            working_hour: item?.shift
              ? `${item.shift.start_working_hour} - ${item.shift.end_working_hour}`
              : "",
            holiday_name: item.shift?.list_holiday_names
              ? item.shift.list_holiday_names
              : "なし",
            range: handleColumnRange(item),
          }))
        );
        setTotal(workerShiftsData.meta.total_records);
      }
    }
  }, [isFetching, isLoading, isSuccess, workerShiftsData]);

  return {
    ...props,
    isLoading,
    isFetching,
    rowData,
    pageSize,
    gridRef,
    gridApi,
    page,
    isOpenModalDelete,
    isSubmitting,
    total,
    shiftIds,
    columnDefs: columnDefsSetting,
    onSortChanged,
    onFilterUpdate,
    onDeleteRecord,
    onOpenModalDelete,
    onCloseModalDelete,
    setPage,
    onGridReady,
    setPageSize,
  };
};

export type Props = ReturnType<typeof useShiftList>;

export default useShiftList;
