import { FC } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import { CheckIcon, LocationIcon, NotificationLgIcon } from "@bms/assets";
import useServiceCardTicket, { Props, ReceivedProps } from "./hook";
import { Loading } from "@bms/components";
import { ServiceCard } from "@bms/types";
import { Styles } from "./styled";
import { get } from "lodash";
import { TextField } from "@mui/material";

const ServiceCardTicketLayout: FC<Props> = ({
  serviceCardData,
  isLoading,
  isFetching,
  isOtherServiceCard,
  handleReturnOrderDate,
  hasNotification,
  handleAddress,
}) => {
  const navigate = useNavigate();

  if (isLoading || isFetching) {
    return <Loading />;
  }

  const renderComponent = (data: ServiceCard[]) => {
    return (
      <>
        {data?.map((item) => (
          <div
            className="box"
            onClick={() => {
              if (isOtherServiceCard) return;
              navigate(`${item.id}`);
            }}
            key={item.id}
          >
            <div className="item-box building-name">
              <span>建物名</span>
              <p>
                {item?.order_information?.building?.building_tentative_name ||
                  item?.order_information?.building_data
                    ?.building_tentative_name ||
                  ""}
              </p>
            </div>

            <div className="item-box room">
              <span>号室</span>
              <p>
                {item?.order_information?.building_data?.room_number ||
                  item?.order_information?.building?.resident?.room_number ||
                  ""}
              </p>
            </div>

            <div className="item-box">
              <span>入居者名</span>
              <p>
                {item?.order_information?.building_data?.resident_name ||
                  item?.order_information?.building?.resident?.name ||
                  ""}
              </p>
            </div>

            <div className="item-box">
              <span>入居者_連絡先1_携帯TEL</span>
              <p>
                {item?.order_information?.building_data?.tenant_contact ||
                  item?.order_information?.building?.resident?.mobile_phone ||
                  ""}
              </p>
            </div>

            <div className="item-box address">
              <span>住所</span>
              <p>{handleAddress(item)}</p>
            </div>

            <button
              className="btn"
              onClick={() => {
                window.open(`http://maps.google.com/?q=${handleAddress(item)}`);
              }}
            >
              <img src={LocationIcon} alt="" />
              <p>Google MAP</p>
            </button>

            <div className="item-box subscribe">
              <span>スマイルサポート加入</span>
              <p>
                {(item?.order_information?.support_type as any) === "joining"
                  ? "加入"
                  : (item?.order_information?.support_type as any) ===
                    "non_member"
                  ? "未加入"
                  : ""}
              </p>
            </div>

            <div className="item-box title">
              <span>相談依頼内容</span>
              {/* <p>{item?.request_content?.content || ""}</p> */}
              <TextField
                className="disable-edit-content-item"
                multiline
                name="content"
                disabled={true}
                value={item?.request_content?.content || ""}
              />
            </div>

            <div className="item-box schedule">
              <span>作業予定時間</span>
              <p>
                {item?.request_content?.start_time
                  ? `${dayjs(item?.request_content?.start_time).format(
                      "HH:mm"
                    )} - ${dayjs(item?.request_content?.end_time).format(
                      "HH:mm"
                    )}`
                  : ""}
              </p>
            </div>

            <div
              className="item-box schedule"
              style={{
                marginBottom:
                  !item.request_content?.return_order_date ||
                  (!item?.request_content?.actual_start_time &&
                    !item?.request_content?.actual_end_time)
                    ? "60px"
                    : 0,
              }}
            >
              <span>作業時間</span>
              <>
                {handleReturnOrderDate(item) ||
                item?.request_content?.return_order_date == null ? (
                  <>
                    {item?.request_content?.actual_start_time &&
                      item?.request_content?.actual_end_time && (
                        <p>{`${dayjs(
                          item?.request_content?.actual_start_time
                        ).format("HH:mm")} - ${dayjs(
                          item?.request_content?.actual_end_time
                        ).format("HH:mm")}`}</p>
                      )}
                  </>
                ) : (
                  <>
                    {item?.request_content?.return_order_date && (
                      <div className="return-order-date">
                        <p>再訪問</p>
                        <p>
                          {dayjs(
                            item?.request_content?.return_order_date
                          ).format("YYYY.MM.DD")}
                        </p>
                      </div>
                    )}
                  </>
                )}
              </>
            </div>

            <div className="item-box notify">
              <span>通知</span>
              {hasNotification(item) ? (
                <img src={NotificationLgIcon} alt="" />
              ) : (
                <p className="no-notify">通知はありません</p>
              )}
            </div>

            {item?.status?.working_status === "resolved" ? (
              <button className="btn">
                <img src={CheckIcon} alt="" />
                <p>対応済</p>
              </button>
            ) : item?.request_content?.return_order_date &&
              item?.status?.working_status === "not_compatible" ? (
              <button className="btn error transparent">
                <p>再訪問</p>
              </button>
            ) : (
              <button className="btn error">
                <p>未対応</p>
              </button>
            )}
          </div>
        ))}
      </>
    );
  };

  return (
    <Styles>
      <div className="box-wrapper">
        <>
          {isOtherServiceCard ? (
            <>
              {(serviceCardData || [])?.map((item: any, index: number) => (
                <div className="other-service-card" key={index}>
                  {Object.keys(item).map((workerName: string) => (
                    <>
                      <p className="worker-name">{workerName}</p>
                      <div className="other-service-card-wrapper">
                        {renderComponent(get(item, workerName, []))}
                      </div>
                    </>
                  ))}
                </div>
              ))}
            </>
          ) : (
            <>{renderComponent(serviceCardData)}</>
          )}
        </>
      </div>
    </Styles>
  );
};

const ServiceCardTicket: FC<ReceivedProps> = (props) => (
  <ServiceCardTicketLayout {...useServiceCardTicket(props)} />
);

export default ServiceCardTicket;
