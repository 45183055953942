import * as yup from "yup";

import { ERRORS_MESSAGE } from "@bms/constants";

export const validation = (id: string | null | undefined) => {
  return yup.object().shape({
    // ...(!id && {
    //   number_id: yup.string().nullable().required(ERRORS_MESSAGE["REQUIRED"]),
    // }),
    name: yup.string().nullable().required(ERRORS_MESSAGE["REQUIRED"]),
    role: yup.string().nullable().required(ERRORS_MESSAGE["REQUIRED"]),
    ...(!id && {
      login_pass: yup
        .string()
        .nullable()
        .required(ERRORS_MESSAGE["REQUIRED"])
        .min(6, `6 ${ERRORS_MESSAGE["MIN"]}`),
    }),
    phone_number: yup
      .string()
      .nullable()
      .required(ERRORS_MESSAGE["REQUIRED"])
      .max(13, `13 ${ERRORS_MESSAGE["MAX"]}`),
  });
};
