import styled from "styled-components";

export const Styles = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22.5px;

    h3 {
      color: var(--main-color);
      font-size: 28px;
      font-family: "Inter";
    }

    button {
      width: 239px;
      background-color: var(--main-color);
      color: #ffffff;
      padding: 6.5px 20px;
      border: none;
      border-radius: 64px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      font-family: "Inter";

      p {
        font-size: 16px;
        font-weight: 600;
        font-family: "Inter";
        padding-left: 57px;
      }

      > svg {
        margin-left: 10px;
      }
    }
  }

  .ag-ltr {
    .ag-cell {
      &:last-child {
        display: flex;
      }
    }
  }

  // .ag-header-cell {
  //   &:nth-child(1) {
  //     .ag-header-cell-label {
  //       justify-content: start;
  //     }

  //     .ag-sort-indicator-container {
  //       margin-left: -3px;
  //     }

  //     .ag-sort-indicator-icon {
  //       padding-left: 0;
  //       padding-top: 1px;
  //     }
  //   }

  //   &:nth-child(2) {
  //     .ag-header-cell-label {
  //       padding-right: 14px;
  //     }

      .ag-floating-filter-input {
        width: 242px;
      }
    }

  //   &:nth-child(3) {
  //     .ag-sort-indicator-icon {
  //       padding-left: 0;
  //       margin-left: -12px;
  //     }

  //     .ag-header-cell-label {
  //       justify-content: flex-start;
  //     }

  //     .ag-header-cell-text {
  //       padding-right: 210px;
  //     }

  //     .ag-floating-filter-input {
  //       width: 242px;
  //     }
  //   }
  // }
`;
