import { notification } from "antd";
import { TickIcon } from "@bms/assets";

const openNotification = () => {
  notification["success"]({
    className: "custom-notification",
    icon: <></>,
    closeIcon: <></>,
    message: (
      <div className="notification-info-success">
        <img src={TickIcon} alt="" />
        <p>保存しました</p>
      </div>
    ),
    duration: 1,
    placement: "top",
  });
};

export default openNotification;
