import * as yup from "yup";

import { DATE_TIME_FORMAT, ERRORS_MESSAGE } from "@bms/constants";
import moment from "moment";

export const validation = () => {
  return yup.object().shape({
    worker_id: yup.string().nullable().required(ERRORS_MESSAGE["REQUIRED"]),
    start_working_hour: yup
      .string()
      .nullable()
      .required(ERRORS_MESSAGE["REQUIRED"]),
    end_working_hour: yup
      .string()
      .nullable()
      .required(ERRORS_MESSAGE["REQUIRED"])
      .test(
        "is-greater",
        "終了時間は開始時間の後に選択してください。",
        function (value) {
          const { start_working_hour } = this.parent;
          return moment(value, DATE_TIME_FORMAT.TIME).isSameOrAfter(
            moment(start_working_hour, DATE_TIME_FORMAT.TIME)
          );
        }
      ),
  });
};
