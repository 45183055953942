/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ColumnApi, GridApi, GridSizeChangedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { get } from "react-hook-form";

import { ROUTES, SORTING } from "@bms/constants";
import {
  // useCreateServiceCard,
  useDeleteServiceCard,
  useDuplicateServiceCard,
  useFetchServiceCard,
  useSendMessageServiceCard,
  useSubmitStatuses,
} from "@bms/hooks";
import SelectFloatingFilterComponent, { columns, SORT_PARAMS } from "./utility";
import dayjs from "dayjs";
import { ServiceCard } from "@bms/types";
import { useNavigate } from "react-router-dom";
import { pick } from "lodash";
import { notification } from "antd";

export type ReceivedProps = Record<string, any>;

const useServiceCardsList = (props: ReceivedProps) => {
  const gridRef = useRef<AgGridReact>(null);
  const navigate = useNavigate();

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(100);
  const [total, setTotal] = useState<number>(0);
  const [rowData, setRowData] = useState<any[]>([]);
  const [gridApi, setGridApi] = useState<GridApi | undefined>();
  const [isOpenModalDelete, setIsOpenModalDelete] = useState<boolean>(false);
  const [isOpenCopyDialog, setIsOpenCopyDialog] = useState<boolean>(false);
  const [filterParams, setFilterParams] = useState<Record<string, any>>({});
  const [sortParams, setSortParams] = useState<Record<string, any>>({});
  const [serviceCardIds, setServiceCardIds] = useState<{ id: number }[]>([]);
  const [serviceCardId, setServiceCardId] = useState<number | null>(null);
  const [btnActive, setBtnActive] = useState<1 | 2>(1);

  const {
    data: serviceCardData,
    isSuccess,
    isLoading,
    isFetching,
    refetch,
  } = useFetchServiceCard(page, pageSize, filterParams, sortParams);
  // const { mutate } = useCreateServiceCard();
  const { mutate: mutateDuplicate } = useDuplicateServiceCard();
  const { mutate: mutateDelete, isLoading: isSubmitting } =
    useDeleteServiceCard();
  const { mutate: mutateSendMessage } = useSendMessageServiceCard();
  const { mutate: statusesAction } = useSubmitStatuses();

  const clearFilter = useCallback(() => {
    gridRef?.current?.api.setFilterModel(null);
    setFilterParams({});
  }, []);

  const onSortChanged = ({ columnApi }: { columnApi: ColumnApi }) => {
    const columnState = columnApi.getColumnState();
    const cusParams: any = columnState.reduce(
      (arr, cur) => ({
        ...arr,
        [get(SORT_PARAMS, cur.colId)]:
          cur.sort === null ? SORTING.ASC : cur.sort,
      }),
      {}
    );

    setSortParams({
      ...sortParams,
      ...cusParams,
    });
    setPage(1);
  };

  const onFilterUpdate = useCallback(() => {
    if (!gridApi) return;
    const model = gridApi.getFilterModel();

    const curParams = Object.keys(model)
      .filter(
        (item) =>
          !["order_date", "return_order_date", "order_date_search"].includes(
            item
          )
      )
      .reduce(
        (arr: any, cur: any) => ({
          ...arr,
          [cur]: model?.[cur]?.filter,
        }),
        {}
      );

    const orderDateField = model?.order_date
      ? {
          start_order_date: model?.order_date?.filter?.split("-")?.[0],
          end_order_date: model?.order_date?.filter?.split("-")?.[1],
        }
      : {};

    // order_information - order_date
    const orderDateSearchField = model?.order_date_search
      ? {
          start_search_date: model?.order_date_search?.filter?.split("-")?.[0],
          end_search_date: model?.order_date_search?.filter?.split("-")?.[1],
        }
      : {};

    const returnDateField = model?.return_order_date
      ? {
          start_return_order_date:
            model?.return_order_date?.filter?.split("-")?.[0],
          end_return_order_date:
            model?.return_order_date?.filter?.split("-")?.[1],
        }
      : {};

    setFilterParams({
      ...filterParams,
      ...curParams,
      ...orderDateField,
      ...returnDateField,
      ...orderDateSearchField,
    });
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridApi]);

  const onOpenModalDelete = () => {
    if (!gridApi) return;
    const rowSelected = gridApi.getSelectedRows();
    setServiceCardIds(rowSelected.map((item) => ({ id: item.id })));
    setIsOpenModalDelete(true);
  };
  const onCloseModalDelete = () => setIsOpenModalDelete(false);

  const onDeleteRecord = async () => {
    mutateDelete(
      {
        payload: { service_card_attributes: serviceCardIds },
      },
      {
        onSuccess() {
          setRowData([]);
          setIsOpenModalDelete(false);
        },
      }
    );
  };

  const onGridReady = (params: GridSizeChangedEvent) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
    params.api.resetRowHeights();
  };

  // const onCreateServiceCard = async () => mutate();
  const onCreateServiceCard = async () => {
    navigate(`${ROUTES.CREATE}`);
  };

  const onCopy = async () => {
    mutateDuplicate(
      {
        id: serviceCardId,
      },
      {
        onSuccess() {
          setIsOpenCopyDialog(false);
        },
      }
    );
  };

  const getDataToday = () => {
    setFilterParams({
      ...filterParams,
      order_date: dayjs().format("YYYY/MM/DD"),
    });
    setPage(1);
  };

  const onNavigateTab = (data: ServiceCard) => {
    const parseData = pick(data, [
      "status",
      "order_information",
      "contractor_information",
      "request_content",
      "work_reports",
      "payment",
      "work_cost",
      "memo",
    ]);

    if (parseData?.memo?.has_notification) {
      mutateSendMessage(
        {
          payload: { has_notification: false },
          id: data?.id,
        },
        {
          onSuccess() {
            navigate(`${data?.id}`, {
              state: {
                tabKey: "8",
              },
            });
          },
        }
      );
    }
    if (parseData?.work_cost?.has_notification) {
      navigate(`${data?.id}`, {
        state: {
          tabKey: "7",
        },
      });
    }
    if (parseData?.payment?.has_notification) {
      navigate(`${data?.id}`, {
        state: {
          tabKey: "6",
        },
      });
    }
    if (parseData?.work_reports?.find((item) => item?.has_notification)) {
      navigate(`${data?.id}`, {
        state: {
          tabKey: "5",
        },
      });
    }
    if (parseData?.request_content?.has_notification) {
      navigate(`${data?.id}`, {
        state: {
          tabKey: "4",
        },
      });
    }
    if (parseData?.contractor_information?.has_notification) {
      navigate(`${data?.id}`, {
        state: {
          tabKey: "3",
        },
      });
    }
    if (parseData?.order_information?.has_notification) {
      navigate(`${data?.id}`, {
        state: {
          tabKey: "2",
        },
      });
    }
    if (parseData?.status?.has_notification) {
      navigate(`${data?.id}`, {
        state: {
          tabKey: "1",
        },
      });
    }
  };
  const onStatusChange = async (data: any) => {
    const payload = pick(data, [
      "confirm_payment_status",
      "invoice_status",
      "working_status",
    ]);
    statusesAction(
      {
        payload: {
          ...payload,
          has_notification: true,
          service_card_id: data.id,
        },
        statusId: data?.status?.id,
      },
      {
        onSuccess({ data }) {
          // refetch();
        },
        onError(error: any) {
          notification["error"]({
            message: { error }?.error?.response?.data?.error || "Failed",
          });
        },
      }
    );
  };
  const columnDefsSetting: any = useMemo(
    () =>
      columns(onNavigateTab, onStatusChange).map((item, index) => {
        return {
          ...item,
          unSortIcon: ![0, 14].includes(index),
          ...([8, 9, 11].includes(index) && {
            floatingFilterComponent: SelectFloatingFilterComponent,
          }),
          filterParams: {
            textMatcher: () => {
              return true;
            },
          },
        };
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (btnActive === 2) {
      setFilterParams({
        ...filterParams,
        order_date: dayjs().format("YYYY/MM/DD"),
      });
    }
    if (isLoading || isFetching) {
      setRowData([]);
    } else {
      if (isSuccess) {
        setRowData(
          serviceCardData?.json?.data?.map((item: ServiceCard) => ({
            ...item,
            order_date: item?.request_content?.order_date,
            return_order_date: item?.request_content?.return_order_date,
            building_name:
              item?.order_information?.building_data?.building_name || "",
            owner_name:
              item?.order_information?.building_data?.owner_name || "",
            resident_name:
              item?.order_information?.building_data?.resident_name || "",
            resident_contact:
              item?.order_information?.building_data?.resident_contact || "",
            working_status: item?.status?.working_status || "",
            invoice_status: item?.status?.invoice_status || "",
            paid_at: item?.status?.paid_at || "",
            billing_amount: item?.work_cost?.total
              ? item?.work_cost?.total
              : "",
            confirm_payment_status: item?.status?.confirm_payment_status || "",
            billing_address:
              item?.order_information?.address_payment_type?.[0] === "オーナー"
                ? item?.order_information?.building_data?.owner_address
                : item?.order_information?.address_payment_type?.[0] ===
                  "契約者"
                ? item?.order_information?.building_data?.contractor_address
                : "",
            order_date_search: item?.order_information?.order_date || "",
          }))
        );
        setTotal(serviceCardData?.json?.meta?.total_records);
      }
    }
  }, [serviceCardData, isFetching, isLoading, isSuccess]);

  return {
    ...props,
    isLoading,
    isFetching,
    rowData,
    pageSize,
    gridRef,
    gridApi,
    page,
    isOpenModalDelete,
    total,
    columnDefs: columnDefsSetting,
    isSubmitting,
    isOpenCopyDialog,
    btnActive,
    setBtnActive,
    setServiceCardId,
    onCopy,
    setIsOpenCopyDialog,
    getDataToday,
    onCreateServiceCard,
    clearFilter,
    onSortChanged,
    onFilterUpdate,
    onDeleteRecord,
    onOpenModalDelete,
    onCloseModalDelete,
    setPage,
    onGridReady,
    setPageSize,
  };
};

export type Props = ReturnType<typeof useServiceCardsList>;

export default useServiceCardsList;
