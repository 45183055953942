import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import axios from "@bms/libs/axios";
import { SERVICES } from "@bms/constants";
import { loginSuccess, userLogout, userLogin } from "@bms/features/auth/slice";

export const useReAuthentication = () => {
  const [state, setState] = useState<{
    loading: boolean;
    error: any;
  }>({
    loading: true,
    error: null,
  });

  const dispatch = useDispatch();

  const accessToken = localStorage.getItem("access_token");

  useEffect(() => {
    (async () => {
      if (!accessToken) {
        setState({ error: null, loading: false });
        return;
      } else {
        try {
          const { data } = await axios({
            method: "GET",
            url: SERVICES.AUTH,
          });

          dispatch(userLogin({ user: data }));
          dispatch(loginSuccess());
          setState({ error: null, loading: false });
        } catch (error) {
          localStorage.clear();
          dispatch(userLogout());
          setState({
            error,
            loading: false,
          });
        }
      }
    })();
  }, [accessToken, dispatch]);

  return { ...state };
};
