import { FC } from "react";

import { ModalDelete, ModalImport, Table } from "@bms/components";
import { ImportIcon, Plus } from "@bms/assets";
import useBuildingList, { Props, ReceivedProps } from "./hook";
import { Styles } from "./styled";
import { CircularProgress } from "@mui/material";

const BuildingListLayout: FC<Props> = ({
  rowData,
  columnDefs,
  pageSize,
  isLoading,
  isFetching,
  gridApi,
  gridRef,
  page,
  total,
  // isSubmitting,
  isOpenModalImport,
  // isImportSuccess,
  file,
  isOpenModalDelete,
  isSubmittingDelete,
  statusFileImport,
  outputExportUuid,
  onSortChanged,
  onFilterUpdate,
  reset,
  setFile,
  onDeleteRecord,
  onOpenModalDelete,
  onCloseModalDelete,
  onOpenModalImport,
  onCloseModalImport,
  onImportCSV,
  setPage,
  clearFilter,
  onGridReady,
  setPageSize,
  onUpdateColumnDef,
  onAddServiceCard,
  setValueSelected,
  isAddServiceCard,
}) => {
  return (
    <Styles>
      <div className="header">
        <div className="title-btn-plus">
          <h3>物件一覧</h3>
          <button
            className={`btn-plus pl-1 ${
              !isAddServiceCard ? "hide-display" : ""
            }`}
            onClick={onAddServiceCard}
          >
            <p>サービスカード作成</p>
            <img src={Plus} />
          </button>
        </div>
        <div>
          <button
            disabled={
              outputExportUuid != undefined &&
              outputExportUuid !== null &&
              !statusFileImport
            }
            onClick={onOpenModalImport}
          >
            {outputExportUuid != undefined &&
            outputExportUuid !== null &&
            !statusFileImport ? (
              <CircularProgress size={26} />
            ) : (
              ""
            )}
            <p>マスターインポート</p>
            <img src={ImportIcon} />
          </button>
        </div>
      </div>

      <div className="table-wrapper">
        <Table
          total={total}
          page={page}
          pageSize={pageSize}
          gridRef={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          // height={554}
          height={"100vh"}
          loading={isLoading || isFetching}
          gridApi={gridApi}
          setPage={setPage}
          setPageSize={setPageSize}
          onOpenModalDelete={onOpenModalDelete}
          onGridReady={onGridReady}
          setValueSelected={setValueSelected}
          onSortChanged={onSortChanged}
          onFilterUpdate={onFilterUpdate}
          onDragStopped={onUpdateColumnDef}
          enableCellTextSelection={true}
          tableHeader={
            <div className="btn-group">
              <button className="btn-clear" onClick={clearFilter}>
                クリア
              </button>
            </div>
          }
        />
      </div>

      <ModalImport
        isOpen={isOpenModalImport}
        file={file}
        isSubmitting={false}
        isSuccess={false}
        reset={reset}
        onImportCSV={onImportCSV}
        setFile={setFile}
        onClose={onCloseModalImport}
      />

      <ModalDelete
        isOpen={isOpenModalDelete}
        isSubmitting={isSubmittingDelete}
        onDeleteRecord={onDeleteRecord}
        onClose={onCloseModalDelete}
      />
    </Styles>
  );
};

export const BuildingList: FC<ReceivedProps> = (props) => (
  <BuildingListLayout {...useBuildingList(props)} />
);

export default BuildingList;
