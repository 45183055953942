import {
  useExportOutput,
  useFileExportStatus,
  usePrintOutput,
} from "@bms/hooks";
import { GridApi } from "ag-grid-community";
import { notification } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { uid } from "uid";

export type ReceivedProps = Record<string, any>;
const useOutput = (props: ReceivedProps) => {
  const outputExportUuid = localStorage.getItem("output_export_uuid");

  const { control, setValue, getValues, register, handleSubmit } = useForm();
  const { mutate: mutatePrint, isLoading: isPrintSubmitting } =
    usePrintOutput();
  const { mutate: mutateExport, isLoading: isExportSubmitting } =
    useExportOutput();
  const { mutate: mutateFileExportStatus } = useFileExportStatus();

  const [gridApi, setGridApi] = useState<GridApi | undefined>();
  const [filterParams, setFilterParams] = useState<Record<string, any>>({
    status: ["請求済のみ"],
    address_payment_type: ["all"],
  });
  const [statusFileExport, setStatusFileExport] = useState<boolean>(false);

  const onSubmit = (values: Record<string, any>) => {
    setFilterParams({
      ...filterParams,
      status: values?.status,
      address_payment_type: values?.address_payment_type,
      start_date: values?.start_date
        ? dayjs(values.start_date).format("YYYY/MM/DD")
        : undefined,
      end_date: values?.end_date
        ? dayjs(values.end_date).format("YYYY/MM/DD")
        : undefined,
      keyword: values?.keyword || undefined,
    });
  };

  const handleCheck = (checkedId: any, name: string) => {
    const { print_type, export_type } = getValues();
    const ids = name === "print_type" ? print_type : export_type;
    const newIds = ids?.includes(checkedId)
      ? ids?.filter((id: any) => id !== checkedId)
      : [...(ids ?? []), checkedId];
    return newIds;
  };

  const onExport = () => {
    const values = getValues();
    if (!values?.export_type || !gridApi) return;
    const uuid = uid();
    const rowSelected = gridApi.getSelectedRows();

    if (rowSelected.length == 0) {
      notification["warning"]({
        message: "サービスカードを選択してください。",
      });
    } else {
      mutateExport(
        {
          export_type: values?.export_type,
          service_card_ids: rowSelected.map((item) => ({ id: item.id })),
          uuid,
        },
        {
          onSuccess(res: any) {
            mutateFileExportStatus(
              { uuid },
              {
                onSuccess(data: any) {
                  if (data?.data?.status === "done") {
                    const link = document.createElement("a");
                    link.href = data?.data?.file_url;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    setStatusFileExport(true);
                  } else {
                    localStorage.setItem("output_export_uuid", uuid);
                  }
                },
              }
            );

            notification["warning"]({
              message: res?.data?.message,
            });
          },
          onError(error: any) {
            notification["error"]({
              message: { error }?.error?.response?.data?.error || "Failed",
            });
          },
        }
      );
    }
  };

  const onPrint = () => {
    const values = getValues();
    if (!values?.print_type || !gridApi) return;
    const rowSelected = gridApi.getSelectedRows();

    if (rowSelected.length == 0) {
      notification["warning"]({
        message: "サービスカードを選択してください。",
      });
    } else {
      mutatePrint(
        {
          print_type: values?.print_type,
          id: rowSelected?.[0]?.id,
          service_card_ids: rowSelected.map((item) => ({ id: item.id })),
        },
        {
          onSuccess(res: any) {
            window.open(res.data.pdf_url, "_blank");
          },
          onError(error: any) {
            notification["error"]({
              message: { error }?.error?.response?.data?.error || "Failed",
            });
          },
        }
      );
    }
  };

  useEffect(() => {
    if (!outputExportUuid || statusFileExport) return;
    const getFile = setInterval(() => {
      mutateFileExportStatus(
        { uuid: outputExportUuid },
        {
          onSuccess(data: any) {
            if (data?.data?.status === "done") {
              localStorage.removeItem("output_export_uuid");
              const link = document.createElement("a");
              link.href = data?.data?.file_url;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          },
        }
      );
    }, 10000);
    return () => {
      clearInterval(getFile);
    };
  }, [mutateFileExportStatus, outputExportUuid, statusFileExport]);

  return {
    ...props,
    control,
    filterParams,
    isPrintSubmitting,
    isExportSubmitting,
    gridApi,
    setGridApi,
    handleCheck,
    onExport,
    onPrint,
    setFilterParams,
    onSubmit,
    setValue,
    getValues,
    register,
    handleSubmit,
  };
};

export type Props = ReturnType<typeof useOutput>;

export default useOutput;
