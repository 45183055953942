import styled from "styled-components";

export const Styles = styled.div`
  margin-top: 118px;
  padding: 0 38px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .service-card {
    background: #2e8135;
    margin-bottom: 172px;
  }

  .other-service-cards {
    background-color: #154e5c;
    margin-bottom: 27px;
  }

  .schedule {
    background-color: #c8d344;
    margin-bottom: 118px;
  }

  .logout {
    background-color: #e83d3b;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  button {
    font-family: "Inter";
    font-weight: 400;
    font-size: 25px;
    color: #ffffff;
    border: 0;
    outline: none;
    max-width: 300px;
    width: 100%;
    height: 85px;
    line-height: 85px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 4px;
  }
`;
