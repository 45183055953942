import styled from "styled-components";
import Modal from "@mui/material/Modal";

export const Styles = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      color: var(--main-color);
      font-size: 28px;
      font-family: "Inter";
    }

    button {
      width: 120px;
      background-color: var(--main-color);
      color: #ffffff;
      padding: 6.5px 20px;
      border: none;
      border-radius: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      font-size: 16px;
      font-weight: 400;
      font-family: "Inter";
      margin-right: 14px;
    }

    .btn-back {
      background-color: #ffffff;
      border: 2px solid var(--main-color);
      color: var(--main-color);
    }
  }

  .title {
    margin-bottom: 30px;
    font-family: "Lato";
    font-weight: 400;
    font-size: 30px;
    color: var(--main-color);
  }
`;

export const SchedulesWrapper = styled.div``;

export const ModalWrapper = styled(Modal)`
  .MuiBox-root {
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  h3 {
    font-family: "Lato";
    font-weight: 400;
    font-size: 24px;
    color: #000000;
    text-align: center;
    margin-top: 20px;
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    margin: 3px auto 36px auto;
    width: 100%;

    .item {
      display: flex;
      align-items: flex-start;
      width: 100%;

      &.isBeforeDay {
        &:not(:last-child) {
          margin-bottom: 12px;
        }

        &:first-child {
          margin-top: 6px;
        }

        .time {
          margin-left: 0;
        }
      }

      .time {
        font-family: "Lato";
        font-weight: 400;
        font-size: 16px;
        color: #000000;
        margin-left: 10px;
        text-align: left;
        width: 30%;
      }

      .name {
        font-family: "Inter";
        font-weight: 400;
        font-size: 16px;
        color: #df691d;
        margin-left: 15px;
        width: 50%;
      }

      img {
        cursor: pointer;
        margin-right: 0;
        margin-left: auto;
      }
    }
  }

  .modal-footer {
    margin-bottom: 20px;

    .group-btn {
      display: flex;
      flex-direction: column;
      justify-content: end;
      align-items: center;
      gap: 12px;

      button {
        font-family: "Inter";
        font-weight: 400;
        font-size: 16px;
        border: none;
        min-width: 240px;
        padding: 9px 8px;
        border-radius: 4px;
        line-height: 1.75;
        letter-spacing: 0.02857em;
        text-transform: uppercase;
        color: #ffffff;

        &:nth-child(1) {
          background-color: var(--main-color);
        }

        &:nth-child(2) {
          background-color: #ff0000;
        }
      }
    }
  }
`;

export const StylesModal = {
  border: "none",
  outline: "none",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  backgroundColor: "#ffffff",
  pl: 2,
  pr: 2,
  borderRadius: 2,
  display: "flex",
  flexDirection: "column",
  // justifyContent: "space-between",
};
