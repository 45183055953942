import { FC } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { isEmpty } from "lodash";
import moment from "moment";
import { useQueryClient } from "@tanstack/react-query";

import { XIcon } from "@bms/assets";
import { ModalConfirm } from "@bms/components";
import CircularProgress from "@mui/material/CircularProgress";
import SchedulerBox from "./SchedulerBox";
import useCalendar, { Props } from "./hook";
import { ModalWrapper, SchedulesWrapper, Styles, StylesModal } from "./styled";
import { QUERY_NAME, ROUTES } from "@bms/constants";
import { ShiftSchedules } from "@bms/types";

const CalendarLayout: FC<Props> = ({
  openScheduleDialog,
  openRegularDialog,
  openDeleteDialog,
  currentDate,
  scheduleList,
  holidaysData,
  scheduleDetailData,
  isDetailLoading,
  isDetailSuccess,
  isSubmitting,
  currentDay,
  isSubmittingWorking,
  data,
  handleStatusShift,
  onDeleteDayWorkings,
  setShiftDetail,
  onDeleteWorkerShift,
  setCurrentDay,
  setCurrentDate,
  setOpenDeleteDialog,
  setOpenRegularDialog,
  setOpenScheduleDialog,
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return (
    <Styles>
      <div className="header">
        <h3>シフトカレンダー</h3>
        <button className="btn-back" onClick={() => navigate(-1)}>
          戻る
        </button>
      </div>

      <SchedulesWrapper className="container-schedules">
        <SchedulerBox
          specificHolidays={data?.meta?.specific_holidays}
          holidaysData={holidaysData}
          scheduleList={scheduleList}
          currentDate={currentDate}
          setCurrentDay={setCurrentDay}
          setCurrentDate={setCurrentDate}
          setOpenScheduleDialog={setOpenScheduleDialog}
          openScheduleFormDialog={openScheduleDialog}
        />
      </SchedulesWrapper>

      <ModalWrapper
        open={openScheduleDialog}
        onClose={() => setOpenScheduleDialog(false)}
      >
        <Box
          sx={{
            ...StylesModal,
            width: 380,
            overflowY: "auto",
            maxHeight: "90vh",
          }}
        >
          {isDetailLoading ? (
            <Box
              sx={{
                display: "flex",
                height: "200px",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress size={26} />
            </Box>
          ) : (
            <>
              {!isEmpty(scheduleDetailData) && (
                <>
                  <h3 className="title">
                    {moment(new Date(currentDay.startDate)).format("D")}
                  </h3>
                  <div className="modal-content">
                    {isDetailSuccess &&
                      scheduleDetailData?.leave_requests &&
                      (scheduleDetailData.leave_requests as ShiftSchedules[])
                        .filter((item) => item.worker && item.status == "on")
                        .map((item) => {
                          return (
                            <div
                              className={`item ${
                                !handleStatusShift() ? "isBeforeDay" : ""
                              }`}
                              key={item.worker?.id}
                            >
                              <div className="time">{`${
                                item?.start_working_hour || ""
                              }-${item?.end_working_hour || ""}`}</div>
                              <div
                                className="name"
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  navigate(`${ROUTES.SHIFT}/${item.shift.id}`, {
                                    state: {
                                      workingHourEffectAt:
                                        currentDay?.startDate,
                                    },
                                  });
                                  setCurrentDay(undefined);
                                  queryClient.invalidateQueries([
                                    QUERY_NAME.SHIFT_SCHEDULES,
                                  ]);
                                }}
                              >
                                {item.worker?.name || ""}
                              </div>
                              {handleStatusShift() && (
                                <img
                                  src={XIcon}
                                  alt=""
                                  onClick={() => {
                                    setOpenDeleteDialog(true);
                                    setShiftDetail({
                                      workerId: item.worker.id,
                                      leaveRequestId: item.id,
                                    });
                                  }}
                                />
                              )}
                            </div>
                          );
                        })}
                  </div>
                </>
              )}
              <div className="modal-footer">
                <div className="group-btn">
                  <button
                    onClick={() => {
                      setOpenScheduleDialog(false);
                      navigate(`${ROUTES.SHIFT}/${ROUTES.CREATE}`);
                    }}
                  >
                    シフトを作成
                  </button>
                  {handleStatusShift() && (
                    <button
                      type="submit"
                      onClick={() => setOpenRegularDialog(true)}
                    >
                      定休日にする
                    </button>
                  )}
                </div>
              </div>
            </>
          )}
        </Box>
      </ModalWrapper>

      <ModalConfirm
        isOpen={openRegularDialog}
        isSubmitting={isSubmittingWorking}
        content={"定休日にしますか？"}
        onOk={onDeleteDayWorkings}
        onClose={() => setOpenRegularDialog(false)}
        className="custom-title"
      />

      <ModalConfirm
        isOpen={openDeleteDialog}
        isSubmitting={isSubmitting}
        content={"一人を削除しますか？"}
        onOk={onDeleteWorkerShift}
        onClose={() => setOpenDeleteDialog(false)}
      />
    </Styles>
  );
};

const Calendar: FC = () => <CalendarLayout {...useCalendar()} />;

export default Calendar;
