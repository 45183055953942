import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QUERY_NAME, SERVICES, SORTING } from "@bms/constants";
import axios from "@bms/libs/axios";
import { Workers } from "@bms/types";

export const useFetchReceptions = (
  page: number,
  pageSize: number,
  filterParams?: any,
  sortParams?: any
) => {
  for (const obj in sortParams) {
    if (sortParams[obj] === SORTING.ASC) {
      delete sortParams[obj];
    }
  }

  return useQuery(
    [QUERY_NAME.RECEPTIONS, page, pageSize, filterParams, sortParams],
    () =>
      axios({
        method: "GET",
        url: SERVICES.RECEPTIONS,
        params: {
          page: page,
          per_page: pageSize,
          ...filterParams,
          ...sortParams,
        },
      }).then((res) => res.data || []),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
};

export const useFetchOneReception = (receptionId: string | undefined) => {
  return useQuery(
    [QUERY_NAME.RECEPTION_ID, receptionId],
    () =>
      axios({
        method: "GET",
        url: `${SERVICES.RECEPTIONS}/${receptionId}`,
      }).then((res) => res.data as Workers),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!receptionId,
    }
  );
};

export const useDeleteReception = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ payload }: any) =>
      axios({
        method: "DELETE",
        url: `${SERVICES.RECEPTIONS}/delete`,
        data: payload,
      }),
    {
      onSuccess() {
        queryClient.invalidateQueries([QUERY_NAME.RECEPTIONS]);
      },
    }
  );
};

export const useSubmitReception = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ payload, receptionId }: any) =>
      axios({
        method: receptionId ? "PUT" : "POST",
        url: receptionId
          ? `${SERVICES.RECEPTIONS}/${receptionId}`
          : SERVICES.RECEPTIONS,
        data: payload,
      }),
    {
      onSuccess() {
        queryClient.invalidateQueries([QUERY_NAME.RECEPTIONS]);
        queryClient.invalidateQueries([QUERY_NAME.RECEPTION_ID]);
      },
    }
  );
};
