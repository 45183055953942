import styled from "styled-components";

export const Styles = styled.div`
  .web-layout {
    .container {
      padding: 54px 0 0 230px;
      background: #fff;
      min-height: 100vh;

      .main {
        margin-top: 22px;
        padding-right: 32px;
      }
    }
  }

  .mobile-layout {
    padding-bottom: 80px;

    .header-control {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 40px;
      justify-content: center;
      position: relative;

      .btn-back {
        position: absolute;
        left: 0;
        top: 5px;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        background-color: #004bb1;
        max-width: 82px;
        height: 40px;
        line-height: 40px;
        width: 100%;
        border: 0;
        border-radius: 4px;
        margin-left: 16px;

        p {
          font-family: "Inter";
          font-weight: 400;
          font-size: 16px;
          color: #ffffff;
        }
      }
    }

    .container {
      display: flex;
      justify-content: flex-start;
      align-items: "center";
      flex-direction: column;
      text-align: center;
      width: 100%;
      min-height: 100vh;
    }

    .header-top {
      /* margin-top: 65px; */
      margin-top: 20px;
      margin-bottom: 40px;

      h3,
      h4 {
        font-family: "Lato";
        font-weight: 400;
        color: #174a84;
      }

      h3 {
        font-size: 30px;
      }
      h4 {
        font-size: 22px;
        margin-top: 8px;
        text-align: center;
        position: relative;
        z-index: 1;
      }
    }
  }
`;
