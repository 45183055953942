import styled from "styled-components";

export const Styles = styled.div`
  /* overflow: hidden; */
  /* overflow: scroll;
  max-width: 1200px;
  width: 100%; */

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 41px;

    h3 {
      color: var(--main-color);
      font-size: 28px;
      font-family: "Inter";
    }

    .group-btn {
      display: flex;
      gap: 20px;

      button {
        width: 120px;
        background-color: var(--main-color);
        color: #ffffff;
        padding: 6.5px 20px;
        border: none;
        border-radius: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        font-size: 16px;
        font-weight: 400;
        font-family: "Inter";
      }

      .btn-back {
        background-color: #ffffff;
        border: 2px solid var(--main-color);
        color: var(--main-color);
      }
    }
  }

  .taps-wrapper {
    overflow: scroll;
    max-width: 1200px;
    width: 100%;
  }

  label.Mui-focused,
  label.MuiInputLabel-shrink {
    top: 8px !important;
  }

  .MuiBox-root {
    /* width: fit-content; */
  }

  .MuiTabPanel-root {
    border: 1px solid #bcbcbc;
    border-top: none;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: 27px 17px;
    width: 1196.86px;
  }

  .tabs {
    border-bottom: 4px solid #a9cdfd;
  }

  .tab-label-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .MuiTabs-root {
    min-height: 35px;

    .MuiTabs-flexContainer {
      gap: 3px;
    }

    .MuiTabs-indicator {
      background-color: transparent;
    }

    .MuiButtonBase-root {
      min-width: 147px;
      min-height: 35px;
      padding: 0 16px;
      background-color: #174a84;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      color: #ffffff;
      font-family: "Inter";
      font-weight: 400;

      &.Mui-selected {
        background-color: #67a8ff;
      }
    }
  }
`;
