import styled from "styled-components";

export const Styles = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22.5px;

    h3 {
      color: var(--main-color);
      font-size: 28px;
      font-family: "Inter";
    }

    .group-btn {
      display: flex;
      align-items: center;
      margin-right: 5px;
      gap: 21px;

      button {
        width: 167px;
        background-color: var(--main-color);
        color: #ffffff;
        padding: 6.5px 23px;
        border: none;
        border-radius: 64px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        font-family: "Inter";

        &:nth-child(1) {
          background-color: #df1d60;
          width: 185px;
          display: block;
        }

        p {
          font-size: 16px;
          font-weight: 600;
          padding-left: 10px;
        }
      }
    }
  }

  .table-wrapper {
    .ag-root-wrapper {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    .ag-root {
      border-radius: 4px;
    }

    .tag {
      width: 66px;
      height: 31px;
      line-height: 20px;
      padding: 5px 0px;
      text-align: center;
      color: #ffffff;
      font-family: "Inter";
      font-size: 15px;

      &.bg-red {
        background-color: #ff0000;
      }

      &.bg-green {
        background-color: #4e7c3d;
      }

      &.bg-blue {
        background-color: #2d31aa;
      }
    }

    .footer {
      margin-top: 33px;
    }

    .ag-row {
      .cell-disabled {
        color: #c1c1c1;
      }
    }

    .ag-ltr {
      .ag-cell {
        &:last-child {
          display: flex;
        }
      }
    }

    .ag-cell {
      display: flex;
    }
  }
`;
