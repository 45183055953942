export const ERRORS_MESSAGE = {
  EMAIL_EXISTED: "このメールアドレスは既に登録されています。",
  REQUIRED: "必須項目です",
  PASSWORD_INVALID:
    "6文字以上、20文字以下、大文字、小文字、数字を少なくとも1文字ずつ含む必要です。",
  MATCH_NEW_PASSWORD: "パスワードは一致していません。",
  EMAIL_INVALID: "メールアドレスは正しい形式で入力してください。",
  MIN: "文字以上で入力してください。",
  MAX: "文字以内で入力してください。",
};
