import { FC } from "react";
import Box from "@mui/material/Box";
import { get } from "lodash";

import { Loading } from "@bms/components";
import { DAY_OF_WEEK_KIND, PERIOD_KIND } from "@bms/constants";
import { Holidays } from "@bms/types";
import useHolidaysView, { Props, ReceivedProps } from "./hook";
import { Styles } from "./styled";

const HolidaysViewLayout: FC<Props> = ({ isLoading, data, setIsEdit }) => {
  if (isLoading) {
    return <Loading />;
  }

  return (
    <Styles>
      <div className="header">
        <h3>定休日作成</h3>
        <div className="group-btn">
          <button onClick={() => setIsEdit(true)}>設定</button>
        </div>
      </div>

      <Box>
        <div className="title">
          <h3>曜日設定</h3>
          <h3>繰り返し設定</h3>
        </div>
        {((data?.holidays || []) as Holidays[]).map((item) => (
          <div className="field-wrapper" key={item.id}>
            <p className={`${item.day_of_week_kind ? "" : "placeholder"}`}>
              {get(DAY_OF_WEEK_KIND, item.day_of_week_kind, "選択")}
            </p>
            <div className="separator">曜日</div>
            <p className={`${item.period_kind ? "" : "placeholder"}`}>
              {get(PERIOD_KIND, item.period_kind, "選択")}
            </p>
          </div>
        ))}
      </Box>
    </Styles>
  );
};

const HolidaysView: FC<ReceivedProps> = (props) => (
  <HolidaysViewLayout {...useHolidaysView(props)} />
);

export default HolidaysView;
