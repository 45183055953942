import * as yup from "yup";

import { ERRORS_MESSAGE } from "@bms/constants";

export const validation = () => {
  return yup.object().shape({
    busines_trip_fee: yup
      .string()
      .nullable()
      .required(ERRORS_MESSAGE["REQUIRED"]),
    work_fee: yup.string().nullable().required(ERRORS_MESSAGE["REQUIRED"]),
    disposal_fee: yup.string().nullable().required(ERRORS_MESSAGE["REQUIRED"]),
  });
};
