import styled from "styled-components";

export const Styles = styled.div`
  .memo {
    &.main {
      border: 1px solid #bcbcbc;
      padding: 21px 11px 0;
      border-radius: 6px;
      margin-bottom: 24px;
      padding-right: 11px !important;
      height: 40vh;
      overflow: scroll;
      position: relative;
      z-index: 99;
    }

    p {
      font-family: "Inter";
      font-weight: 400;
      font-size: 16px;
      color: #000000;
    }

    .item {
      margin-bottom: 30px;

      &.last-child {
        margin-bottom: 20px;
      }
    }

    .owner {
      p {
        color: #ff0000;
      }
    }

    .message-photos-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      max-width: 600px;

      .message-photo {
        height: 312px;
        object-fit: contain;
      }
    }
  }

  .images-preview-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin-bottom: 20px;

    .images-preview-container {
      position: relative;

      .icon {
        right: -12px;
        top: -12px;
        z-index: 2;
        position: absolute;
        background-color: #e0e0e0;
        border-radius: 50%;
        cursor: pointer;
      }

      .photo-preview {
        position: relative;
        width: 100px;
        height: 100px;
        object-fit: cover;
        z-index: 1;
      }
    }
  }

  .MuiFormControl-root {
    width: 100%;
    margin-bottom: 20px;
    position: sticky;
    left: 0;
    right: 0;
    bottom: -2px;
    z-index: 100;
    background: #ffffff;

    .MuiInputBase-root {
      width: 100%;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-width: 0 !important;

    &:hover {
      border-color: #bcbcbc;
    }
  }

  button {
    width: 184px;
    background-color: #347bc3;
    color: #ffffff;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-family: "Inter";
    width: 184px;
    height: 46px;
    border-radius: 4px;

    p {
      font-family: "Inter";
      font-weight: 400;
      font-size: 11px;
      color: #ffffff;
    }
  }

  input {
    font-weight: 400;
    font-size: 15px;
    font-family: "Lato";
    padding: 18px 0;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
`;
