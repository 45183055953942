export const SERVICES = {
  BUILDINGS_IMPORT_CSV: "/buildings/import_csv",
  BUILDINGS: "/buildings",
  SHIFTS: "/shifts",
  HOLIDAYS: "/holidays",
  WORKERS: "/workers",
  SHIFTS_WORKERS: "/shifts/workers",
  WORKERS_SHIFTS: "/workers/shifts",
  RECEPTIONS: "/receptions",
  SUBCONTRACTORS: "/subcontractors",
  TAX_RATES: "/tax_rates",
  PERMISSIONS: "/permissions",
  PAYMENT_CATEGORIES: "/payment_categories",
  OUT_PUTS: "/out_puts",
  SHIFT_SCHEDULES: "/shifts/calendars",
  FILE_IMPORT: "/file_imports",
  SERVICE_CARDS: "/service_cards",
  STATUSES: "/statuses",
  ORDER_INFORMATIONS: "/order_informations",
  CONTRACTOR_INFORMATIONS: "/contractor_informations",
  REQUEST_CONTENTS: "/request_contents",
  WORK_REPORTS: "/work_reports",
  WORK_COSTS: "/work_costs",
  PAYMENTS: "/payments",
  MESSAGES: "/messages",
  MEMO: "/memos",
  RETURN_DATE_HISTORY: "/return_date_histories",
  AUTH: "/users/current_user",
  LOGIN: "/auth/login",
  LOGOUT: "/auth/logout",
  SCHEDULES: "schedules",
};

export const QUERY_NAME = {
  PERMISSIONS: "permissions",
  SHIFTS: "shifts",
  HOLIDAYS: "holidays",
  WORKERS: "workers",
  WORKER_ID: "worker_id",
  RECEPTIONS: "receptions",
  RECEPTION_ID: "reception_id",
  PAYMENT_CATEGORIES: "payment_categories",
  PAYMENT_CATEGORY_ID: "payment_category_id",
  SUBCONTRACTORS: "subcontractors",
  SUBCONTRACTOR_ID: "subcontractor_id",
  WORKER_SHIFT_ID: "worker_shift_id",
  SHIFT_SCHEDULES: "shift_schedules",
  WORKERS_SHIFTS: "workers_shifts",
  BUILDINGS: "buildings",
  BUILDING_ID: "building_id",
  TAX_RATE: "tax_rate",
  OUT_PUTS: "out_puts",
  OUT_PUT_ID: "output_id",
  SCHEDULES: "schedules",
  SERVICE_CARDS: "service_cards",
  SERVICE_CARDS_ID: "service_card_id",
  STATUSES: "statuses",
  ORDER_INFORMATIONS: "order_informations",
  CONTRACTOR_INFORMATIONS: "contractor_informations",
  WORK_REPORTS: "work_reports",
  REQUEST_CONTENTS: "request_contents",
  WORK_COSTS: "work_costs",
  PAYMENTS: "payments",
  MESSAGES: "messages",
};
