import styled from "styled-components";

export const Styles = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    h3 {
      color: var(--main-color);
      font-size: 28px;
      font-family: "Inter";
    }

    .group-btn {
      display: flex;
      gap: 20px;

      button {
        width: 120px;
        background-color: var(--main-color);
        color: #ffffff;
        padding: 6.5px 20px;
        border: none;
        border-radius: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        font-size: 16px;
        font-weight: 400;
        font-family: "Inter";
      }

      .btn-back {
        background-color: #ffffff;
        border: 2px solid var(--main-color);
        color: var(--main-color);
      }
    }
  }

  .title {
    font-weight: 400;
    font-size: 20px;
    color: var(--main-color);
    font-family: "Inter";
  }

  form {
    margin-top: 10px;
    border: 1px solid #bcbcbc;
    margin-left: 3px;
    border-radius: 6px;
    padding: 9px 19px 94px 21px;

    label {
      font-weight: 400;
      font-size: 15px;
      color: #adadad;
      top: -3px;
      font-family: "Lato";

      &.Mui-focused,
      &.MuiInputLabel-shrink {
        top: 0;
      }
    }

    .MuiFormLabel-root.Mui-focused {
      color: #adadad;
    }

    .MuiInputBase-root {
      &:hover:not(.Mui-disabled):before,
      &::after {
        border-bottom: 1px solid #bcbcbc;
      }

      &:before {
        border-bottom: 1px solid #bcbcbc;
      }
    }

    .MuiInputBase-input {
      &::after {
        border-bottom: 1px solid #bcbcbc;
      }
    }

    .css-1x51dt5-MuiInputBase-input-MuiInput-input {
      padding: 5px 0;
    }

    .field-wrapper {
      display: flex;
      flex-direction: row;
      gap: 20.5px;

      .input-field {
        .MuiFormControl-root {
          width: 100%;
        }

        &:nth-child(1) {
          width: 177.61px;
        }

        &:nth-child(2),
        &:nth-child(3) {
          width: 270.15px;
        }
      }

      input {
        font-weight: 400;
        font-size: 15px;
        font-family: "Lato";

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }

      &:nth-child(2) {
        margin-top: 16.8px;
        margin-bottom: 20.8px;

        .input-field {
          .MuiFormControl-root {
            width: 100%;
          }

          &:nth-child(2) {
            width: 559.54px;
          }

          &:nth-child(3) {
            width: 364px;
          }
        }
      }

      &:nth-child(3) {
        .input-field {
          width: 270.15px;

          .MuiFormControl-root {
            width: 100%;
          }
        }
      }
    }
  }
`;
