import styled from "styled-components";

export const Styles = styled.div`
  padding: 0 16px;

  .MuiInputBase-root {
    font-weight: 400;
    font-size: 25px;
    color: #030303;
    margin-top: 4px;
    font-family: "Inter";
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: #bcbcbc !important;
    border-width: 1px !important;

    &:hover {
      border-color: #bcbcbc;
    }
  }

  .service-card {
    border: 1px solid #1f3f5d;
    padding: 25px 0;
    border-radius: 4px;
    max-width: 352px;
    width: 100%;
    margin: 0 auto;

    &.hidden {
      padding-bottom: 0;

      .card-title {
        border-bottom: 0;
      }
    }

    .card-title {
      display: flex;
      justify-content: center;
      width: 100%;
      padding-bottom: 25px;
      border-bottom: 1px solid #1f3f5d;

      h3 {
        font-family: "Inter";
        font-weight: 400;
        font-size: 25px;
        color: #1f3f5d;
        width: 100%;
        margin-left: 28px;
      }

      img {
        margin-right: 28px;
        margin-left: auto;
        cursor: pointer;
      }
    }

    .box {
      border-radius: 4px;
      padding: 20px 12px 0;
      text-align: left;

      .edit-content-item {
        textarea {
          height: 217px !important;
          overflow: scroll !important;
        }
      }

      .multiline-content {
        white-space: break-spaces;
      }

      .edit {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 45px;
      }

      .btn {
        border: 0;
        display: flex;
        align-items: center;
        margin: 0 auto;
        max-width: 314px;
        width: 100%;
        height: 40px;
        border-radius: 64px;
        gap: 10px;
        justify-content: center;
        background-color: #004bb1;

        &.google-map {
          margin-bottom: 30px;
        }

        &.error {
          background-color: #fc0d1b;
        }

        p {
          font-family: "Lato";
          font-weight: 400;
          font-size: 18px;
          color: #ffffff;
        }
      }

      .item-box {
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;

        &.title {
          margin-bottom: 0;
        }

        &.address {
          margin-bottom: 16px;

          &:nth-child(3) {
            font-family: "Lato";
            font-weight: 500;
            font-size: 28px;
            color: #030303;
          }
        }

        &.notify {
          margin-top: 15px;
          margin-bottom: 48px;
          display: flex;
          flex-direction: column;
          gap: 17px;
          justify-content: flex-end;
          align-items: flex-start;

          img {
            width: 26px;
            height: 34px;
          }
        }

        span {
          font-family: "Inter";
          color: #7b7b7b;
          font-size: 15px;
          font-weight: 400;
        }

        p {
          font-weight: 400;
          font-size: 25px;
          color: #030303;
          margin-top: 4px;
          font-family: "Inter";
        }
      }
    }
  }

  .control {
    margin-top: 62px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;

    .btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 23px;
      gap: 8px;
      background-color: #2e8135;
      max-width: 343px;
      height: 85px;
      line-height: 85px;
      width: 100%;
      border: 0;
      border-radius: 4px;
      margin-bottom: 25px;

      &.done {
        background-color: #55606a;
      }

      p {
        font-family: "Inter";
        font-weight: 400;
        font-size: 25px;
        color: #ffffff;
      }

      &.btn-working {
        margin-top: 70px;

        p {
          width: 100%;
          margin-left: -35px;
        }
      }

      &.btn-report,
      &.btn-cost,
      &.btn-support,
      &.btn-memo {
        p {
          width: 100%;
          margin-left: -35px;
        }
      }
    }
  }
`;
