import Modal from "@mui/material/Modal";
import styled from "styled-components";

export const StylesModal = {
  border: "none",
  outline: "none",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  backgroundColor: "#ffffff",
  pl: 8,
  pr: 8,
  borderRadius: 2,
};

export const ModalWrapper = styled(Modal)`
  h3 {
    font-family: "Lato";
    font-weight: 400;
    font-size: 16px;
    color: #7b7b7b;
    text-align: center;
    padding-top: 52px;
    padding-bottom: 19px;
  }

  .ant-upload {
    width: 100%;
  }

  .modal-content {
    background-color: #eff7fd;
    border: 1.5px dashed #d3d3d3;

    &-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 539px;
      height: 237px;

      .icon {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 15px;
      }

      p {
        font-family: "Inter";
        font-weight: 400;
        font-size: 15px;
        color: #7b7b7b;
      }

      button {
        background: var(--main-color);
        color: #ffffff;
        width: 177px;
        height: 45px;
        border: 0;
        font-size: 15px;
        font-weight: 400;
        font-family: "Inter";
        border-radius: 2px;

        &.disabled {
          background-color: #c7c7c7;
        }
      }
    }
  }

  .modal-footer {
    button {
      width: 175px;
      height: 39px;
      border: 0;
      color: #ffffff;
      border-radius: 2px;
      font-weight: 400;
      font-size: 14px;
      font-family: "Inter";

      &.btn-close {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--sub-color);
        margin: 20px auto 0;
      }
    }
  }
`;
