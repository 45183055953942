import styled from "styled-components";

export const Styles = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22.5px;

    .title-btn-plus h3,
    .btn-plus {
      float: left;
    }

    .hide-display {
      display: none;
    }

    .title-btn-plus h3 {
      margin-right: 10px;
    }

    h3 {
      color: var(--main-color);
      font-size: 28px;
      font-family: "Inter";
    }

    button {
      min-width: 246px;
      background-color: var(--main-color);
      color: #ffffff;
      padding: 6.5px 20px;
      border: none;
      border-radius: 64px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      font-family: "Inter";

      p {
        font-size: 16px;
        font-weight: 600;
        padding-left: 20px;
      }

      > img {
        margin-left: 10px;
      }
    }
  }

  .btn-group {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;

    .MuiSvgIcon-root {
      height: 18px;
      width: 18px;
      border-radius: 4px;
      border: 1px solid #777777;
    }

    .Mui-checked {
      background-color: #000000;

      &:hover {
        background-color: #000000 !important;
      }
    }

    .MuiCheckbox-root {
      color: #ffffff;
      padding: 0px;
      width: 12px;
      height: 12px;
      border-radius: 4px;

      &:focus {
        outline: none;
      }

      &:hover {
        background-color: #ffffff;
      }
    }

    .btn-clear {
      border-radius: 64px;
      border: 0;
      background-color: var(--sub-color);
      width: 90px;
      margin: 7px 0 6px 10px;
      padding: 6px;
      color: #ffffff;
      text-align: center;
      cursor: pointer;
      margin-right: 50px;
      font-size: 16px;
      font-weight: 400;
    }
  }

  .table-wrapper {
    .ag-header-viewport {
      border-top: 1px solid #bcbcbc;
    }

    .ag-row-odd,
    .ag-row-even {
      .ag-cell-last-left-pinned {
        padding-left: 15px !important;
      }
    }

    .ag-root-wrapper {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
`;
