import styled from "styled-components";

export const SchedulerBoxWrapper = styled.div`
  width: 100%;
  margin-top: -13px;

  .MuiToolbar-root {
    padding: 0;

    &.Toolbar-toolbar {
      border-bottom: none;
      margin-bottom: 22px;
    }

    .header-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 27px;

      h2 {
        width: 100%;
        font-family: "Lato";
        font-weight: 500;
        font-size: 38px;
        color: #333333;
        text-align: center;
        /* margin-left: 4px; */
      }

      button {
        background-color: #ffffff;
        border: none;
        display: flex;
        align-items: center;
        font-family: "Lato";
        font-weight: 500;
        font-size: 22px;
        color: #333333;
        gap: 20px;

        p {
          margin-top: -6px;
        }

        &.btn-back {
          margin-left: 8px;
          margin-top: 14px;
        }

        &.btn-next {
          margin-right: 14px;
          margin-top: 14px;
        }
      }
    }
  }

  .MainLayout-container {
    margin-top: 10px;

    .Cell-cell,
    .MainLayout-background {
      border-right: none;
      border-bottom: none;

      &:hover,
      &:focus {
        background-color: #ffffff;
        outline: none;
        box-shadow: none;
      }
    }

    .MainLayout-header.MainLayout-stickyElement {
      .Cell-dayOfWeek {
        font-family: "Inter";
        font-weight: 400;
        font-size: 15px;
        color: #000000;
      }

      table {
        border-spacing: 0 !important;
      }

      .MuiTableCell-root {
        background: #ffffff;
        height: auto;

        &.saturday,
        &.sunday,
        &.holiday {
          background: #ffffff;

          div {
            background: #ffffff;
          }
        }

        div {
          background: #ffffff;
          padding: 0;
          height: auto;
        }
      }
    }

    .MainLayout-flexRow {
      table {
        border-collapse: separate;
        border-spacing: 12px;
      }

      .MuiTableCell-root {
        border-radius: 10px;
        background: #ebf2f7;
        height: 120px;

        &.saturday {
          background: #c4e6ff;
        }

        &.sunday {
          background: #ffdbe5;
        }

        &.holiday {
          background: #c9a5a5;
        }

        &.otherMonth {
          background-color: rgba(235, 242, 247, 0.52);
          .Cell-otherMonth {
            color: #d0dbe5;
          }
        }

        .Cell-today {
          background-color: transparent;
          padding-top: 0.5em;
        }

        div {
          margin-top: 0;
          font-family: "Lato";
          font-weight: 400;
          font-size: 15px;
          color: #000000;
        }
      }
    }
  }

  .Container-container {
    top: 12px !important;

    > div {
      margin-top: -20px;
    }

    .Appointment-appointment {
      background-color: transparent;
      font-family: "Lato";
      font-weight: 400;
      font-size: 10px;
      color: #000000;
      border: none;
      line-height: unset;
      letter-spacing: 0;

      &:hover {
        background-color: transparent;
      }

      .content {
        &-container {
          padding: 0 10px 0 17px;

          .summary {
            color: #838383;
          }
        }

        &-wrapper {
          display: flex;
          align-items: center;
          gap: 20px;

          p {
            width: 60px;
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &:last-child {
              color: #df691d;
            }
          }
        }
      }

      .HorizontalAppointment-content {
        padding: 0;
        padding-left: 18px;
        padding-right: 12px;
        margin-top: -3px;
      }

      .HorizontalAppointment-title {
        color: #000000;
        letter-spacing: 0;
      }
    }
  }
`;
