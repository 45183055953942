import { Dispatch, useEffect, SetStateAction, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { omit } from "lodash";

import { useFetchHolidays, useUpdateHolidays } from "@bms/hooks";
import { openNotification } from "@bms/components";

export type ReceivedProps = {
  setIsEdit: Dispatch<SetStateAction<boolean>>;
};

const useHolidaysControl = (props: ReceivedProps) => {
  const { data, isLoading, isSuccess } = useFetchHolidays();
  const { mutate, isLoading: isSubmitting } = useUpdateHolidays();
  const [initial, setInitial] = useState<boolean>(false);
  const [initFields, setInitFields] = useState<any>();

  const { register, reset, handleSubmit, control } = useForm({
    defaultValues: {
      holiday_attributes: [
        {
          day_of_week_kind: null,
          period_kind: null,
        },
        {
          day_of_week_kind: null,
          period_kind: null,
        },
        {
          day_of_week_kind: null,
          period_kind: null,
        },
        {
          day_of_week_kind: null,
          period_kind: null,
        },
      ],
    },
  });

  const { fields } = useFieldArray({
    control: control,
    name: "holiday_attributes",
  });

  const onSubmit = async (values: Record<string, any>) => {
    mutate(
      {
        payload: {
          holiday_attributes: values.holiday_attributes.map((item: any) =>
            omit(item, ["holiday_name"])
          ),
        },
      },
      {
        onSuccess() {
          openNotification();
          props.setIsEdit(false);
        },
      }
    );
  };

  useEffect(() => {
    if (isSuccess) {
      setInitial(true);
      reset({
        holiday_attributes: data.holidays,
      });
    }
  }, [data, isSuccess, reset]);

  useEffect(() => {
    if (initFields == undefined) {
      setInitFields(data.holidays);
    }
  }, [fields]);
  return {
    ...props,
    isSubmitting,
    fields,
    isLoading,
    initial,
    handleSubmit,
    register,
    onSubmit,
    reset,
    initFields,
    setInitFields,
  };
};

export type Props = ReturnType<typeof useHolidaysControl>;

export default useHolidaysControl;
