import { FC } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputLabel,
} from "@mui/material";
import { Controller } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";

import useOutput, { Props, ReceivedProps } from "./hook";
import { Styles } from "./styled";
import OutputSearch from "./OutputSearch";
import OutputList from "./OutputList";

const OutputLayout: FC<Props> = ({
  control,
  filterParams,
  isPrintSubmitting,
  isExportSubmitting,
  gridApi,
  setGridApi,
  handleCheck,
  onExport,
  onPrint,
  setFilterParams,
  onSubmit,
  setValue,
  getValues,
  register,
  handleSubmit,
}) => {
  return (
    <Styles>
      <div className="header">
        <h3>出力</h3>
        <h5>検索条件</h5>
      </div>

      <OutputSearch
        control={control}
        setValue={setValue}
        getValues={getValues}
        register={register}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
      />

      {/* <p className="no-data">検索条件を入力し検索をしてください。</p> */}
      <OutputList
        filterParams={filterParams}
        getValues={getValues}
        setFilterParams={setFilterParams}
        gridApi={gridApi}
        setGridApi={setGridApi}
      />

      <div className="footer">
        <div className="box">
          <InputLabel>印刷フォーマット</InputLabel>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              alignItems: "flex-end",
            }}
          >
            <Box className="form-search">
              <div className="form-search-container">
                <div className="input-field">
                  <Controller
                    name="print_type"
                    control={control}
                    render={(props: any) => (
                      <FormGroup className="checkbox-wrapper">
                        {[
                          { value: "invoice", label: "請求書" },
                          { value: "report", label: "報告書" },
                          { value: "estimate", label: "見積書" },
                        ].map((item, index) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={() =>
                                  props.field.onChange(
                                    handleCheck(item.value, "print_type")
                                  )
                                }
                                defaultChecked={getValues(
                                  "print_type"
                                )?.includes(item.value)}
                              />
                            }
                            key={index}
                            label={item.label}
                          />
                        ))}
                      </FormGroup>
                    )}
                  />
                </div>
              </div>
            </Box>
            <LoadingButton
              loadingPosition="start"
              loading={isPrintSubmitting}
              className="btn-print"
              variant="outlined"
              onClick={onPrint}
            >
              印刷
            </LoadingButton>
          </Box>
        </div>
        <div className="box">
          <InputLabel>出力フォーマット</InputLabel>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              alignItems: "flex-end",
            }}
          >
            <Box className="form-search">
              <div className="form-search-container">
                <div className="input-field">
                  <Controller
                    name="export_type"
                    control={control}
                    render={(props: any) => (
                      <FormGroup className="checkbox-wrapper">
                        {[
                          { value: "list_payment_months", label: "請求額一覧" },
                          {
                            value: "list_products",
                            label: "全項目一覧",
                          },
                        ].map((item, index) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={() =>
                                  props.field.onChange(
                                    handleCheck(item.value, "export_type")
                                  )
                                }
                                defaultChecked={getValues(
                                  "export_type"
                                )?.includes(item.value)}
                              />
                            }
                            key={index}
                            label={item.label}
                          />
                        ))}
                      </FormGroup>
                    )}
                  />
                </div>
              </div>
            </Box>
            <LoadingButton
              loadingPosition="start"
              loading={isExportSubmitting}
              variant="outlined"
              className="btn-export"
              onClick={onExport}
            >
              エクスポート
            </LoadingButton>
          </Box>
        </div>
      </div>
    </Styles>
  );
};

const Output: FC<ReceivedProps> = (props) => (
  <OutputLayout {...useOutput(props)} />
);

export default Output;
