import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { Note, Pen, Plus } from "@bms/assets";
import { ModalDelete, Table } from "@bms/components";
import { ROUTES } from "@bms/constants";
import usePaymentList, { Props, ReceivedProps } from "./hook";
import { Styles } from "./styled";

const BuildingListLayout: FC<Props> = ({
  rowData,
  pageSize,
  gridApi,
  gridRef,
  page,
  isOpenModalDelete,
  isSubmitting,
  columnDefs,
  total,
  isLoading,
  isFetching,
  onSortChanged,
  onFilterUpdate,
  onDeleteRecord,
  onOpenModalDelete,
  onCloseModalDelete,
  setPage,
  onGridReady,
  setPageSize,
}) => {
  const navigate = useNavigate();
  return (
    <Styles>
      <div className="header">
        <h3>支払区分一覧</h3>
        <button onClick={() => navigate(ROUTES.CREATE)}>
          <p>支払区分追加</p>
          <img src={Plus} alt="" />
        </button>
      </div>

      <Table
        total={total}
        page={page}
        pageSize={pageSize}
        gridRef={gridRef}
        rowData={rowData}
        onSortChanged={onSortChanged}
        onFilterUpdate={onFilterUpdate}
        columnDefs={[
          ...columnDefs,
          {
            maxWidth: 100,
            field: "動作",
            floatingFilter: false,
            cellRenderer: (e: any) => {
              return (
                <div className="group-action">
                  <img
                    src={Note}
                    onClick={() =>
                      navigate(`${e.data.id}`, {
                        state: {
                          view: true,
                        },
                      })
                    }
                  />
                  <img src={Pen} onClick={() => navigate(`${e.data.id}`)} />
                </div>
              );
            },
          },
        ]}
        loading={isLoading || isFetching}
        gridApi={gridApi}
        onOpenModalDelete={onOpenModalDelete}
        setPage={setPage}
        setPageSize={setPageSize}
        onGridReady={onGridReady}
      />

      <ModalDelete
        isOpen={isOpenModalDelete}
        isSubmitting={isSubmitting}
        onDeleteRecord={onDeleteRecord}
        onClose={onCloseModalDelete}
      />
    </Styles>
  );
};

export const BuildingList: FC<ReceivedProps> = (props) => (
  <BuildingListLayout {...usePaymentList(props)} />
);

export default BuildingList;
