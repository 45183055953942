import axios, { AxiosRequestConfig } from "axios";
import store from "@bms/app/store";
import { userLogout } from "@bms/features/auth/slice";
import { getAccessToken } from "@bms/utility";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 900000,
});

axios.defaults.timeout = 900000;

// Add a request interceptor
axiosInstance.interceptors.request.use(
  function (config: AxiosRequestConfig): AxiosRequestConfig {
    if (config.headers && getAccessToken()) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${getAccessToken()}`,
      };
    }
    return config;
  },

  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },

  function (error) {
    if (error.response.status === 401) {
      store.dispatch(userLogout());
      localStorage.clear();
      window.location.href === "/";
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
