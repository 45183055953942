import { FC } from "react";
import { InputLabel, TextField } from "@mui/material";
import { Upload } from "antd";
import { isEmpty, orderBy } from "lodash";
import LoadingButton from "@mui/lab/LoadingButton";

import { AddIcon, UploadCaptureIcon } from "@bms/assets";
import { Loading, ModalDelete } from "@bms/components";
import useWorkReport, { Props, ReceivedProps } from "./hook";
import { Styles } from "./styled";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const WorkReportLayout: FC<Props> = ({
  register,
  setIsView,
  handleSubmit,
  onSubmit,
  setValue,
  watch,
  setWorkReportId,
  reset,
  onOpenModalDeleteUpload,
  onCloseModalDeleteUpload,
  openModalDeleteImg,
  errors,
  isLoadingButton,
  isView,
  isLoading,
  isFetching,
  serviceCardData,
  uploadImage,
  deleteImage,
  loadingImage,
}) => {
  if (isLoading || isFetching || isLoadingButton) {
    return <Loading />;
  }

  const handleUrl = (value: File | any) => {
    if (value?.image_path) {
      return value.image_path;
    }

    if (value?.name) {
      return URL.createObjectURL(value);
    }

    return value;
  };

  return (
    <Styles>
      {isView ? (
        <div className="work-report-view">
          {!isEmpty(serviceCardData?.work_reports) ? (
            <div className="box">
              {orderBy(
                serviceCardData.work_reports,
                (obj) => obj.id,
                "asc"
              ).map((item) => {
                return (
                  <div
                    className="box-wrapper"
                    onClick={() => {
                      setIsView(false);
                      setWorkReportId(item.id);
                    }}
                    key={item.id}
                    style={{
                      background:
                        item?.after_photo?.image_path &&
                        item?.before_photo?.image_path
                          ? "#2e8135"
                          : "#55606A",
                    }}
                  >
                    <h3 className="title">{item.title}</h3>
                    <div className="image-wrapper">
                      <img
                        style={{ width: "100%" }}
                        src={item?.before_photo?.image_path}
                        alt=""
                      />
                      <img
                        style={{ width: "100%" }}
                        src={item?.after_photo?.image_path}
                        alt=""
                      />
                      <div style={{ width: 38 }}>
                        <ArrowForwardIosIcon style={{ color: "white" }} />
                      </div>
                    </div>
                    <p className="description">{item.comment}</p>
                  </div>
                );
              })}
            </div>
          ) : (
            <p className="no-content">作業報告はありません。</p>
          )}
        </div>
      ) : (
        <div className="work-report-control">
          <form>
            <div className="input-field">
              <InputLabel>作業タイトル</InputLabel>
              <TextField
                {...register("title")}
                name="title"
                placeholder="作業タイトルを入力"
              />
              {errors?.title && (
                <p className="error">{errors.title?.message}</p>
              )}
            </div>

            <div className="upload-wrapper">
              <div className="item">
                <InputLabel>作業前写真</InputLabel>
                <div>
                  <Upload
                    openFileDialogOnClick={!watch("before_photo")}
                    className={
                      handleUrl(watch("before_photo")) ? "before-photo" : ""
                    }
                    maxCount={1}
                    listType="picture"
                    showUploadList={false}
                    beforeUpload={(e) => {
                      uploadImage("before_photo", e);
                    }}
                  >
                    {loadingImage === "before_photo" ? (
                      <p>Uploading...</p>
                    ) : (
                      <img
                        onClick={() => {
                          watch("before_photo") &&
                            onOpenModalDeleteUpload("before_photo");
                        }}
                        src={
                          handleUrl(watch("before_photo")) || UploadCaptureIcon
                        }
                        alt=""
                        className={`${
                          handleUrl(watch("before_photo")) ? "photo" : ""
                        }`}
                      />
                    )}
                  </Upload>
                  {openModalDeleteImg === "before_photo" && (
                    <ModalDelete
                      isOpen={Boolean(openModalDeleteImg)}
                      isSubmitting={false}
                      onDeleteRecord={async () => {
                        setValue("before_photo", null);
                        setValue("before_photo_key", null);
                        deleteImage("before_photo", watch("before_photo_key"));
                        onCloseModalDeleteUpload();
                      }}
                      onClose={onCloseModalDeleteUpload}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="upload-wrapper">
              <div className="item">
                <InputLabel>作業後写真</InputLabel>
                <div>
                  <Upload
                    openFileDialogOnClick={!watch("after_photo")}
                    className={
                      handleUrl(watch("after_photo")) ? "after-photo" : ""
                    }
                    maxCount={1}
                    listType="picture"
                    showUploadList={false}
                    beforeUpload={(e) => {
                      uploadImage("after_photo", e);
                    }}
                  >
                    {loadingImage === "after_photo" ? (
                      <p>Uploading...</p>
                    ) : (
                      <img
                        onClick={() => {
                          watch("after_photo") &&
                            onOpenModalDeleteUpload("after_photo");
                        }}
                        src={
                          handleUrl(watch("after_photo")) || UploadCaptureIcon
                        }
                        alt=""
                        className={`${
                          handleUrl(watch("after_photo")) ? "photo" : ""
                        }`}
                      />
                    )}
                  </Upload>
                  {openModalDeleteImg === "after_photo" && (
                    <ModalDelete
                      isOpen={Boolean(openModalDeleteImg)}
                      isSubmitting={false}
                      onDeleteRecord={async () => {
                        setValue("after_photo", null);
                        setValue("after_photo_key", null);
                        deleteImage("after_photo", watch("after_photo_key"));
                        onCloseModalDeleteUpload();
                      }}
                      onClose={onCloseModalDeleteUpload}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="input-field">
              <InputLabel>作業コメント</InputLabel>
              <TextField
                multiline
                rows={6}
                maxRows={10}
                {...register("comment")}
                name="comment"
                placeholder="作業コメントを入力"
              />
              {errors?.comment && (
                <p className="error">{errors.comment?.message}</p>
              )}
            </div>

            <LoadingButton
              loadingPosition="start"
              loading={isLoadingButton}
              type="submit"
              variant="outlined"
              className="btn btn-keep"
              onClick={handleSubmit(onSubmit)}
            >
              <p>保存</p>
            </LoadingButton>
          </form>
        </div>
      )}

      {isView && (
        <button
          className="btn btn-add"
          onClick={() => {
            reset();
            setIsView(false);
          }}
        >
          <img src={AddIcon} />
          <p>追加</p>
        </button>
      )}
    </Styles>
  );
};

const WorkReport: FC<ReceivedProps> = (props) => (
  <WorkReportLayout {...useWorkReport(props)} />
);

export default WorkReport;
