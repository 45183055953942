import { FC } from "react";
import { TextField, CircularProgress, Box } from "@mui/material";
import { Upload } from "antd";

import { CaptureIcon, UploadCaptureIcon, XIcon } from "@bms/assets";
import useMemoNotice, { Props, ReceivedProps } from "./hook";
import { Styles } from "./styled";
import { isEmpty } from "lodash";

const MemoLayout: FC<Props> = ({
  isLoading,
  isFetching,
  isSuccess,
  messages,
  ref,
  user,
  isView,
  fields,
  append,
  remove,
  watch,
  setValue,
}) => {
  const handleUrl = (value: File | any) => {
    if (value?.name) {
      return URL.createObjectURL(value);
    }

    return null;
  };

  return (
    <Styles>
      <div className="memo main" id="memo">
        {(isLoading || isFetching) && isSuccess ? (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "40vh",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={26} />
          </Box>
        ) : (
          <>
            {messages.map((item, index) => (
              <div
                className={`item ${
                  index === messages.length - 1 ? "last-child" : ""
                } ${item?.sender?.role == "admin" ? "owner" : ""}`}
                key={item.id}
              >
                <p
                  style={{
                    whiteSpace: "pre-line",
                  }}
                >
                  {item.body}
                </p>
                <div className="message-photos-wrapper">
                  {!isEmpty(item?.images) && (
                    <>
                      {item?.images.map((image, i) => (
                        <img
                          key={i}
                          src={image.image_path || ""}
                          alt=""
                          className="message-photo"
                        />
                      ))}
                    </>
                  )}
                </div>
              </div>
            ))}
          </>
        )}

        {!isView && (
          <TextField
            multiline
            ref={ref}
            autoFocus={true}
            name="message"
            value={watch("message")}
            onChange={(e) => {
              setValue("message", e.target.value);
              setValue("is_new_message", !isEmpty(e.target.value));
            }}
          />
        )}
      </div>

      <div className="images-preview-wrapper">
        {fields.map((item: any, index) => (
          <div key={index} className="images-preview-container">
            <img
              src={handleUrl(item?.image) || UploadCaptureIcon}
              alt=""
              className={`${handleUrl(item?.image) ? "photo-preview" : ""}`}
            />
            <img
              src={XIcon}
              alt=""
              className="icon"
              onClick={() => remove(index)}
            />
          </div>
        ))}
      </div>

      {!isView && (
        <Upload
          multiple
          listType="picture"
          showUploadList={false}
          beforeUpload={(e) => {
            append({
              image: e,
            });
          }}
        >
          <button
            disabled={isView}
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <img src={CaptureIcon} />
            <p>写真</p>
          </button>
        </Upload>
      )}
    </Styles>
  );
};

const Memo: FC<ReceivedProps> = (props) => (
  <MemoLayout {...useMemoNotice(props)} />
);

export default Memo;
