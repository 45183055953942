import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormReset,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from "react-hook-form";
import { useEffect, Dispatch, SetStateAction, useMemo } from "react";
import { isEmpty } from "lodash";

import { SelectOptionsType } from "@bms/types";
import { DEPARTMENT_IN_CHANGE } from "@bms/constants";

export type ReceivedProps = {
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  reset: UseFormReset<FieldValues>;
  trigger: UseFormTrigger<FieldValues>;
  setRequestOrderDate: Dispatch<SetStateAction<string>>;
  setRequestStartDate: Dispatch<SetStateAction<string>>;
  setRequestEndDate: Dispatch<SetStateAction<string>>;
  clearErrors: UseFormClearErrors<FieldValues>;
  errors: FieldErrors<FieldValues>;
  control: Control<FieldValues>;
  isView: boolean;
  workerOptions: {
    value: string;
    label: string;
    department_in_charge: DEPARTMENT_IN_CHANGE;
  }[];
  subcontractorOptions: SelectOptionsType[];
  isWorkerLoading: boolean;
};

const useRequestContent = (props: ReceivedProps) => {
  const handleOptions = (departmentInCharge: DEPARTMENT_IN_CHANGE) => {
    return (
      props.workerOptions.filter(
        (item) => item.department_in_charge === departmentInCharge
      ) || null
    );
  };

  const diffTime = (startTime: any, endTime: any) => {
    if (startTime && endTime) {
      const diff = Math.abs(
        new Date(endTime).getTime() - new Date(startTime).getTime()
      );
      const diffAsDate = new Date(diff);

      return {
        minutes: parseInt(diffAsDate.getMinutes() as any),
        hours: parseInt(Math.floor(diff / 1000 / 60 / 60) as any),
      };
    }

    return {
      minutes: 0,
      hours: 0,
    };
  };

  const totalWorkingHours = useMemo(() => {
    if (!isEmpty(props.getValues("return_date_histories"))) {
      const getReturnDateHistories = props.getValues("return_date_histories");
      const getValues = getReturnDateHistories
        ?.filter(
          (item: any) => item?.actual_start_time && item?.actual_end_time
        )
        ?.map((item: any) => ({
          actual_start_time: item?.actual_start_time,
          actual_end_time: item?.actual_end_time,
        }));

      const minutes: any = Object.values(getValues)?.reduce(
        (curr: any, value: any) =>
          diffTime(curr?.actual_start_time, curr?.actual_end_time)?.minutes +
          diffTime(value?.actual_start_time, value?.actual_end_time)?.minutes,
        0
      );
      const hours: any = Object.values(getValues)?.reduce(
        (curr: any, value: any) =>
          diffTime(curr?.actual_start_time, curr?.actual_end_time)?.hours +
          diffTime(value?.actual_start_time, value?.actual_end_time)?.hours,
        0
      );

      const getActualTime: any = diffTime(
        props.getValues("actual_start_time"),
        props.getValues("actual_end_time")
      );

      return (
        `${Number(hours) + Number(getActualTime?.hours)}` +
        "時間" +
        `${Number(minutes) + Number(getActualTime?.minutes)}` +
        "分"
      );
    }

    return "";
  }, [props]);

  useEffect(() => {
    const querySelector = `.timepicker-start .ant-picker-input input`;
    const [dateInput] = document.querySelectorAll(querySelector);
    if (dateInput) {
      handleFormatValue(dateInput, true);
    }

    const queryEndSelector = `.timepicker-end .ant-picker-input input`;
    const [dateEndInput] = document.querySelectorAll(queryEndSelector);
    if (dateEndInput) {
      handleFormatValue(dateEndInput, false);
    }
  });

  const handleFormatValue = (dateInput: any, flagTime: any) => {
    dateInput.addEventListener("keyup", (e: any) => {
      if (/:/g.test(e.target.value)) return;

      let sanitizedValue = e.target.value.replace(/[^\d]/g, "");
      if (!/:/g.test(sanitizedValue) && sanitizedValue.length >= 4) {
        sanitizedValue = sanitizedValue.replace(/^(\d{2})(\d{2})?/, "$1:$2");
        if (flagTime) {
          props.setValue("start_working_hour", sanitizedValue);
          props.trigger("start_working_hour");
          props.setRequestStartDate(sanitizedValue);
        } else {
          props.setValue("end_working_hour", sanitizedValue);
          props.trigger("end_working_hour");
          props.setRequestEndDate(sanitizedValue);
        }
      }
    });
  };

  return {
    ...props,
    totalWorkingHours,
    diffTime,
    handleOptions,
  };
};

export type Props = ReturnType<typeof useRequestContent>;

export default useRequestContent;
