import { FC } from "react";
import Box from "@mui/material/Box";
import { Upload } from "antd";
import { RcFile } from "antd/es/upload";
import { FieldValues, UseFormSetValue } from "react-hook-form";

import { UploadIcon } from "@bms/assets";
import { ModalWrapper, StylesModal } from "./styled";

type BeforeUploadValueType = void | boolean | string | Blob | File;

type IProps = {
  isOpen: boolean;
  url?: File;
  name: string;
  setValue: UseFormSetValue<FieldValues>;
  beforeUpload: (
    file: RcFile,
    FileList: RcFile[]
  ) => BeforeUploadValueType | Promise<BeforeUploadValueType>;
  onClose: () => void;
};

const ModalUpload: FC<IProps> = ({ url, isOpen, onClose, beforeUpload }) => {
  return (
    <ModalWrapper open={isOpen} onClose={onClose}>
      <Box sx={{ ...StylesModal, width: 667, height: 460 }}>
        <h3>写真をアップロード</h3>

        <div className="modal-content">
          <Upload
            beforeUpload={beforeUpload}
            maxCount={1}
            listType="picture"
            showUploadList={false}
          >
            <div className="modal-content-wrapper">
              <div className="icon">
                <img src={UploadIcon} />
              </div>
              <p>
                {url?.name ? url?.name : "ファイルをここにドラッグ&ドロップ"}
              </p>
              <p
                style={{
                  marginTop: 10,
                  marginBottom: 30,
                }}
              >
                {!url?.name && "または"}
              </p>

              <button>ファイルを選択</button>
            </div>
          </Upload>
        </div>

        <div className="modal-footer">
          <button className="btn-close" onClick={onClose}>
            閉じる
          </button>
        </div>
      </Box>
    </ModalWrapper>
  );
};

export default ModalUpload;
