import { FC } from "react";
import { Select } from "antd";
import { Controller } from "react-hook-form";
import { FormControl } from "@mui/material";
import { DatePicker } from "antd";
import moment from "moment";

import { InvoiceStatus, PaymentStatus, WorkingStatus } from "@bms/types";
import useStatus, { Props, ReceivedProps } from "./hook";
import { Styles } from "./styled";
import dayjs from "dayjs";
import { formatDay } from "@bms/utility";

const { Option } = Select;

const StatusLayout: FC<Props> = ({
  control,
  isView,
  errors,
  watch,
  getValues,
  setValue,
}) => {
  return (
    <Styles className="status">
      <div className="field-wrapper">
        <div className="input-field">
          <FormControl variant="outlined">
            <label>作業</label>
            <Controller
              name="working_status"
              defaultValue={getValues("working_status")}
              control={control}
              render={({ field: { value } }) => (
                <Select
                  value={value}
                  disabled={isView}
                  popupClassName="select-custom service-card"
                  className="select-custom"
                  defaultValue={getValues("working_status")}
                  onChange={(value: WorkingStatus) => {
                    setValue("working_status", value);
                  }}
                >
                  {[
                    { value: "resolved", label: "対応済" },
                    { value: "not_compatible", label: "未対応" },
                  ].map((item) => (
                    <Option
                      label={item.label}
                      value={item.value}
                      key={item.value}
                    >
                      <div
                        className={`option-wrapper ${watch("working_status")}`}
                      >
                        <p>{item.label}</p>
                      </div>
                    </Option>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </div>

        <div className="input-field">
          <FormControl variant="outlined">
            <label>請求済</label>
            <Controller
              name="invoice_status"
              defaultValue={getValues("invoice_status")}
              control={control}
              render={({ field: { value } }) => (
                <Select
                  value={value}
                  disabled={isView}
                  popupClassName="select-custom service-card"
                  className="select-custom"
                  defaultValue={getValues("invoice_status")}
                  onChange={(value: InvoiceStatus) => {
                    setValue("invoice_status", value);
                    if (value === "billed") {
                      setValue("paid_at", moment());
                    } else {
                      setValue("paid_at", null);
                    }
                  }}
                >
                  {[
                    { value: "billed", label: "請求済" },
                    { value: "unclaimed", label: "未請求" },
                  ].map((item) => (
                    <Option
                      label={item.label}
                      value={item.value}
                      key={item.value}
                    >
                      <div
                        className={`option-wrapper ${watch("invoice_status")}`}
                      >
                        <p>{item.label}</p>
                      </div>
                    </Option>
                  ))}
                </Select>
              )}
            />
            {errors?.invoice_status && (
              <p
                style={{
                  transform: "translateX(-55px)",
                }}
                className="error"
              >
                {errors.invoice_status?.message}
              </p>
            )}
          </FormControl>
        </div>

        <div className="input-field date-picker-input">
          <FormControl variant="standard">
            <label>請求日時</label>
            <FormControl>
              <DatePicker
                className={`picker-working-hour 
                  ${
                    getValues("invoice_status") != "billed"
                      ? "hidden-date-picker"
                      : ""
                  }`}
                name="paid_at"
                showToday={false}
                showNow={false}
                defaultPickerValue={
                  getValues("invoice_status") == "billed"
                    ? dayjs(getValues("paid_at"))
                    : undefined
                }
                value={
                  getValues("invoice_status") == "billed"
                    ? dayjs(getValues("paid_at"))
                    : null
                }
                onChange={(date) => {
                  watch("paid_at");
                  setValue("paid_at", moment(dayjs(date).format("YYYY-MM-DD")));
                }}
                inputReadOnly={false}
                allowClear={false}
                placement="bottomLeft"
                format={`YYYY年MM月DD日 (${formatDay(getValues("paid_at"))})`}
              />
            </FormControl>
          </FormControl>
        </div>

        <div className="input-field">
          <FormControl variant="outlined">
            <label>入金確認</label>
            <Controller
              name="confirm_payment_status"
              control={control}
              defaultValue={getValues("confirm_payment_status")}
              render={({ field: { value } }) => (
                <Select
                  disabled={isView}
                  defaultValue={getValues("confirm_payment_status")}
                  popupClassName="select-custom service-card"
                  className="select-custom"
                  value={value}
                  onChange={(value: PaymentStatus) => {
                    setValue("confirm_payment_status", value);
                  }}
                >
                  {[
                    { value: "paid", label: "入金済" },
                    { value: "not_payment", label: "未入金" },
                  ].map((item) => (
                    <Option
                      label={item.label}
                      value={item.value}
                      key={item.value}
                    >
                      <div
                        className={`option-wrapper ${watch(
                          "confirm_payment_status"
                        )}`}
                      >
                        <p>{item.label}</p>
                      </div>
                    </Option>
                  ))}
                </Select>
              )}
            />
            {errors?.confirm_payment_status && (
              <p
                style={{
                  transform: "translateX(-70px)",
                }}
                className="error"
              >
                {errors.confirm_payment_status?.message}
              </p>
            )}
          </FormControl>
        </div>
      </div>
    </Styles>
  );
};

const Status: FC<ReceivedProps> = (props) => (
  <StatusLayout {...useStatus(props)} />
);

export default Status;
