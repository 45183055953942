import { FC } from "react";

import HolidaysControl from "./HolidaysControl";
import HolidaysView from "./HolidaysView";
import useHolidays, { Props } from "./hook";

const HolidaysLayout: FC<Props> = ({ isEdit, setIsEdit }) => {
  return (
    <>
      {isEdit ? (
        <HolidaysControl setIsEdit={setIsEdit} />
      ) : (
        <HolidaysView setIsEdit={setIsEdit} />
      )}
    </>
  );
};

const Holidays: FC = () => <HolidaysLayout {...useHolidays()} />;

export default Holidays;
