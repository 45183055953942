import { FC } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { TextField, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { Loading } from "@bms/components";
import useReceptionControl, { Props, ReceivedProps } from "./hook";
import { Styles } from "./styled";

const ReceptionControlLayout: FC<Props> = ({
  register,
  handleSubmit,
  onSubmit,
  isView,
  errors,
  isLoading,
  isSubmitting,
  id,
}) => {
  const navigate = useNavigate();

  if (id && isLoading) {
    return <Loading />;
  }

  return (
    <Styles>
      <div className="header">
        <h3>
          {!id ? "受付媒体登録" : isView ? "受付媒体詳細" : "受付媒体編集"}
        </h3>
        <div className="group-btn">
          <button className="btn-back" onClick={() => navigate(-1)}>
            戻る
          </button>
          {!isView && (
            <LoadingButton
              loadingPosition="start"
              loading={isSubmitting}
              type="submit"
              variant="outlined"
              onClick={handleSubmit(onSubmit)}
            >
              {!id ? "追加" : "保存"}
            </LoadingButton>
          )}
        </div>
      </div>

      <div className="title">
        <h4>受付媒体</h4>
      </div>

      <Box component="form">
        <div className="field-wrapper">
          <TextField
            {...register("id")}
            name="id"
            variant="standard"
            label="No."
            disabled
          />
          <div className="input-field">
            <TextField
              {...register("type_name")}
              name="type_name"
              variant="standard"
              label="種類"
              disabled={isView}
            />
            {errors.type_name && (
              <p className="error">{errors.type_name?.message}</p>
            )}
          </div>
        </div>
      </Box>
    </Styles>
  );
};

const ReceptionControl: FC<ReceivedProps> = (props) => (
  <ReceptionControlLayout {...useReceptionControl(props)} />
);

export default ReceptionControl;
