import styled from "styled-components";

export const Styles = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 38px;

    h3 {
      color: var(--main-color);
      font-size: 28px;
      font-family: "Inter";
    }

    button {
      width: 120px;
      background-color: var(--main-color);
      color: #ffffff;
      padding: 6.5px 20px;
      border: none;
      border-radius: 64px;
      gap: 10px;
      font-family: "Inter";
      font-size: 16px;
      font-weight: 600;
    }
  }

  .form {
    display: flex;
    flex-direction: column;

    label {
      font-family: "Inter";
      font-weight: 400;
      font-size: 15px;
      color: #b9b9b9;
      margin-bottom: 20px;
    }

    .field-wrapper {
      display: flex;
      align-items: flex-end;

      input {
        font-weight: 400;
        font-size: 25px;
        font-family: "Lato";

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }

      p {
        font-family: "Lato";
        font-weight: 400;
        font-size: 20px;
        color: #b9b9b9;
        margin-left: 16px;
        margin-bottom: 5px;
      }

      .MuiInputBase-root {
        &:hover:not(.Mui-disabled):before,
        &::after {
          border-bottom: 1px solid #bcbcbc;
        }

        &:before {
          border-bottom: 1px solid #bcbcbc;
        }
      }

      .MuiInputBase-input {
        &::after {
          border-bottom: 1px solid #bcbcbc;
        }
      }
    }
  }
`;
