import styled from "styled-components";

export const Styles = styled.div`
  padding: 0 12px;

  .other-service-card {
    .worker-name {
      text-align: center;
      font-family: "Lato";
      font-size: 25px;
      font-weight: 400;
      color: #174a84;
      margin-bottom: 16px;
    }

    &-wrapper {
      gap: 61px;
      display: flex;
      flex-direction: column;
    }
  }

  .box-wrapper {
    display: flex;
    flex-direction: column;
    gap: 61px;
    max-width: 352px;
    width: 100%;
    margin: 0 auto;
  }

  .box {
    background: #e2e2e2;
    border-radius: 4px;
    padding: 20px 18px;
    filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
    text-align: left;

    .disable-edit-content-item {
      fieldset {
        display: none;
      }

      .MuiInputBase-root {
        font-size: 28px !important;
        font-family: "Inter";
      }
    }

    .btn {
      display: flex;
      align-items: center;
      margin: 0 auto 30px;
      height: 40px;
      max-width: 314px;
      width: 100%;
      border-radius: 64px;
      gap: 10px;
      justify-content: center;
      background-color: #004bb1;
      border: 0;

      &.error {
        background-color: #fc0d1b;

        &.transparent {
          background-color: transparent;
          border: 1px solid #fc0d1b;

          p {
            color: #fc0d1b;
          }
        }
      }

      p {
        font-family: "Lato";
        font-weight: 400;
        font-size: 18px;
        color: #ffffff;
      }
    }

    .address {
      margin-bottom: 16px;

      &:nth-child(3) {
        font-family: "Lato";
        font-weight: 500;
        font-size: 25px;
        color: #030303;
      }
    }

    .notify {
      margin-top: 15px;
      margin-bottom: 48px;
      display: flex;
      flex-direction: column;
      gap: 17px;
      justify-content: flex-end;
      align-items: flex-start;

      img {
        width: 26px;
        height: 34px;
      }

      .no-notify {
        text-align: left;
        font-size: 17px;
        font-weight: 500;
        font-family: "Lato";
        letter-spacing: 0px;
        color: #bcbcbc;
        opacity: 1;
      }
    }

    .item-box {
      margin-bottom: 30px;

      &.schedule {
        .return-order-date {
          display: flex;
          align-items: center;
          gap: 4px;

          p {
            color: #ff0000;
          }
        }
      }

      span {
        font-family: "Inter";
        color: #7b7b7b;
        font-size: 15px;
        font-weight: 400;
      }

      p {
        font-weight: 400;
        font-size: 28px;
        color: #030303;
        margin-top: 4px;
        font-family: "Inter";
      }
    }
  }
`;
