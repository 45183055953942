import { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {
  Control,
  FieldValues,
  useFieldArray,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { orderBy } from "lodash";

import { userSelector } from "@bms/features/auth/selector";
import { useFetchMessages } from "@bms/hooks";
import { Messages, ServiceCard } from "@bms/types";

export type ReceivedProps = {
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  setMessages: Dispatch<SetStateAction<Messages[]>>;
  watch: UseFormWatch<FieldValues>;
  control: Control<FieldValues>;
  isView: boolean;
  messages: Messages[];
  serviceCardData: ServiceCard;
};

const useMemoNotice = (props: ReceivedProps) => {
  const user = useSelector(userSelector);
  const { isLoading, isFetching, isSuccess, data } = useFetchMessages(
    props?.serviceCardData?.memo?.id,
    props.watch("card_id")
  );
  const { fields, append, remove } = useFieldArray({
    control: props.control,
    name: "memo_images",
  });

  const ref: any = useRef();

  useEffect(() => {
    props?.setMessages(
      orderBy(
        data?.filter((item: Messages) => item.body || item.images),
        (obj) => obj.id,
        "asc"
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    const element: any = document.getElementById("memo");
    ref?.current?.focus();
    element.scroll({ top: element.scrollHeight, behavior: "smooth" });
  }, [props.messages]);

  return {
    ...props,
    isLoading,
    isFetching,
    ref,
    user,
    isSuccess,
    fields,
    append,
    remove,
  };
};

export type Props = ReturnType<typeof useMemoNotice>;

export default useMemoNotice;
