import { FC } from "react";
import { CircularProgress } from "@mui/material";

import { Logo1 } from "@bms/assets";
import { Styles } from "./styled";
import useLogin, { Props } from "./hook";
import { isEmpty } from "lodash";

const LoginLayout: FC<Props> = ({
  onSubmit,
  register,
  handleSubmit,
  contextHolder,
  errors,
  isSubmitting,
}) => {
  return (
    <>
      <Styles className="container flex-center">
        {contextHolder}
        <form action="" onSubmit={handleSubmit(onSubmit)}>
          <div className="logo">
            <img src={Logo1} alt="" />
          </div>
          <div className="input-field">
            <label htmlFor="email">id</label>
            <input
              {...register("email")}
              name="email"
              type="text"
              title="email"
              lang="ja"
            />
            {errors.email && <p className="error">{errors.email?.message}</p>}
          </div>
          <div className="input-field">
            <label htmlFor="password">password</label>
            <input
              {...register("password")}
              name="password"
              type="password"
              lang="ja"
              title="Please enter your password"
            />
            {errors.password && (
              <p className="error">{errors.password?.message}</p>
            )}
          </div>
          <button
            className={`btn-submit ${
              isSubmitting || !isEmpty(errors) ? "disabled" : ""
            }`}
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting && (
              <CircularProgress size={16} style={{ color: "#ffffff" }} />
            )}
            <p>ログイン</p>
          </button>
        </form>
      </Styles>
    </>
  );
};

const Login: FC = () => <LoginLayout {...useLogin()} />;

export default Login;
