import { MonthView } from "@devexpress/dx-react-scheduler-material-ui";
import classNames from "classnames";
import dayjs from "dayjs";
import moment from "moment";

export const handleDateOff = (data: any, date: any) => {
  const weekOfMonth = Math.ceil(new Date(date).getDate() / 7);
  const parsePeriod = () => {
    switch (weekOfMonth) {
      case 1:
        return "week_1st";
      case 2:
        return "week_2nd";
      case 3:
        return "week_3rd";
      case 4:
        return "week_4th";

      default:
        return null;
    }
  };

  const result = data?.holidays?.find((item: any) => {
    const dayOfWeek =
      item?.day_of_week_kind ===
      moment(new Date(date)).format("dddd").toLowerCase();
    const period = parsePeriod() === item?.period_kind;

    const week = dayOfWeek && period;
    const everyWeek = item?.period_kind === "every_week" && dayOfWeek;
    const twiceAWeek =
      item?.period_kind === "twice_a_week" &&
      [1, 3, 5].includes(weekOfMonth) &&
      dayOfWeek;

    if (everyWeek || week || twiceAWeek) {
      return true;
    }
    return false;
  });

  return Boolean(result);
};

export const handleDateOffSpecific = (data: any, date: any) => {
  const result = data?.find((item: any) =>
    dayjs(dayjs(item.day_off).format("YYYY/MM/DD")).isSame(
      dayjs(date).format("YYYY/MM/DD")
    )
  );
  return result;
};

export const MonthViewTimeTableCell = (
  props: MonthView.TimeTableCellProps,
  data: any,
  specificHolidays: any
) => {
  const { startDate, otherMonth } = props;
  const saturday = startDate.getDay() === 6;
  const sunday = startDate.getDay() === 0;
  const dateOff = handleDateOff(data, startDate);
  const specificDayOff = handleDateOffSpecific(specificHolidays, startDate);

  return (
    <MonthView.TimeTableCell
      {...props}
      className={classNames(
        saturday ? "saturday" : "",
        sunday ? "sunday" : "",
        otherMonth ? "otherMonth" : "",
        dateOff || specificDayOff ? "holiday" : ""
      )}
      formatDate={(nexDate) =>
        !otherMonth
          ? moment(nexDate).format("D")
          : moment(nexDate).format("M.D")
      }
    />
  );
};

export const MonthViewDayScaleCell = (
  props: MonthView.DayScaleCellProps,
  data: any[],
  specificHolidays: any
) => {
  const { startDate } = props;
  const saturday = startDate.getDay() === 6;
  const sunday = startDate.getDay() === 0;
  const dateOff = handleDateOff(data, startDate);
  const specificDayOff = handleDateOffSpecific(specificHolidays, startDate);

  return (
    <MonthView.DayScaleCell
      {...props}
      className={classNames(
        saturday ? "saturday" : "",
        sunday ? "sunday" : "",
        dateOff || specificDayOff ? "holiday" : ""
      )}
    />
  );
};
