import { FC } from "react";

type IProps = {
  stroke?: string;
};

const ArrowLeft: FC<IProps> = ({ stroke = "#BEBEBE" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7"
      height="10"
      fill="none"
      viewBox="0 0 7 10"
    >
      <path
        fill={stroke}
        d="M3.08 5.301L6.6 1.756 5.549.701l-4.571 4.6 4.57 4.6L6.6 8.846 3.08 5.301z"
      ></path>
    </svg>
  );
};

export default ArrowLeft;
