import { FC, memo } from "react";
import { NavLink } from "react-router-dom";

import { NavItems } from "./NavItems";
import { Logo2 } from "@bms/assets";
import { Styles } from "./styled";
import { useSelector } from "react-redux";
import { userSelector } from "@bms/features/auth/selector";
import { ADMIN_ID, PERMISSION_OPTIONS } from "@bms/constants";

const SideBar: FC = () => {
  const user = useSelector(userSelector);

  const isAdmin = user?.login_id === ADMIN_ID;

  const permissions = isAdmin
    ? PERMISSION_OPTIONS.map((item) => item.access_type)
    : user?.worker?.permissions?.map((item: any) => item.access_type);

  return (
    <Styles className="side-bar" style={{ zIndex: 100 }}>
      <div className="logo">
        <img src={Logo2} alt="" />
      </div>
      {permissions && (
        <ul>
          {NavItems.map((item, index) => (
            <>
              {permissions.includes(item.permission) && (
                <li key={index}>
                  <NavLink to={item.navigate} style={{ marginBottom: item.mb }}>
                    <div>{item.icon}</div>
                    {item.title}
                  </NavLink>
                </li>
              )}
            </>
          ))}
        </ul>
      )}
    </Styles>
  );
};

export default memo(SideBar);
