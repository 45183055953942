import { ColDef } from "ag-grid-community";

export const columns = () => {
  return [
    {
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      maxWidth: 40,
      floatingFilter: false,
    },
    {
      headerName: "No.",
      field: "index",
      maxWidth: 60,
      floatingFilter: false,
    },
    {
      headerName: "カテゴリ",
      field: "category",
      maxWidth: 265,
    },
    {
      headerName: "区分",
      field: "method",
    },
  ] as ColDef[];
};

export const SORT_PARAMS = {
  index: "sort_index",
  category: "sort_category",
  method: "sort_method",
};
