import { useEffect, useMemo, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { notification } from "antd";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { get, isEmpty, isNull } from "lodash";

import {
  useFetchOneServiceCard,
  useSubmitMemo,
  useSubmitRequestContent,
  useSubmitWorkCost,
  useSubmitWorkReport,
} from "@bms/hooks";
import { ServiceCard } from "@bms/types";
import { validation } from "./schema";

import dayjs from "dayjs";

export type ReceivedProps = Record<string, any>;

const useServiceCardTicketDetail = (props: ReceivedProps) => {
  const { id } = useParams();
  const { data, isSuccess, isLoading, isFetching } = useFetchOneServiceCard(id);
  const { mutate: requestContentAction } = useSubmitRequestContent();
  const { mutate: workReportAction } = useSubmitWorkReport();
  const { mutate: memoAction } = useSubmitMemo();
  const { mutate: workCostsAction } = useSubmitWorkCost();
  const inputRef: any = useRef();

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      content: "",
    },
    resolver: yupResolver(validation()),
  });

  const [isShowBox, setIsShowBox] = useState<boolean>(false);
  const [value, setValue] = useState("");
  const [isEditContent, setIsEditContent] = useState<boolean>(false);

  const onSubmit = async () => {
    const values = getValues();
    setIsEditContent(!isEditContent);
    setValue(data?.request_content?.content || "");
    if (isEditContent) {
      requestContentAction(
        {
          payload: {
            content: values?.content || "",
            id: data?.request_content?.id,
            service_card_id: data?.id,
            start_time: data?.request_content?.start_time,
            end_time: data?.request_content?.end_time,
            ...(data?.request_content?.id && {
              has_notification: true,
            }),
          },
          requestContentId: data?.request_content?.id,
        },
        {
          onSuccess() {
            setIsEditContent(false);
          },
          onError(error: any) {
            notification["error"]({
              message: { error }?.error?.response?.data?.error || "Failed",
            });
          },
        }
      );
    }
  };

  const workingHoursDone = useMemo(() => {
    const currentDay = dayjs().format("YYYY-MM-DD");

    const orderReturnDateLength =
      data?.request_content?.return_date_histories?.length;
    const getReturnOrderDate = get(
      data,
      `request_content.return_date_histories.[${orderReturnDateLength - 1}]`
    );
    const isConditionalReturnOrderDate = getReturnOrderDate?.return_order_date
      ? dayjs(
          dayjs(getReturnOrderDate?.return_order_date).format("YYYY-MM-DD")
        ).isSame(dayjs(currentDay))
      : null;

    if (isConditionalReturnOrderDate && isSuccess) {
      return (
        getReturnOrderDate?.actual_start_time &&
        getReturnOrderDate?.actual_end_time
      );
      //
    }

    return (
      isSuccess &&
      data?.request_content?.actual_start_time &&
      data?.request_content?.actual_end_time
    );
  }, [data, isSuccess]);

  const reportDone =
    isSuccess && !!data?.work_reports.find((item: any) => item?.before_photo);
  const workCostDone =
    isSuccess &&
    !isNull(data?.work_cost?.busines_trip_fee) &&
    !isNull(data?.work_cost?.work_fee) &&
    !isNull(data?.work_cost?.disposal_fee);

  const workStatusDone =
    isSuccess && data?.status?.working_status === "resolved";

  const handleAddress = (data: ServiceCard) => {
    return (
      [
        data?.order_information?.building?.building_prefecture_name,
        data?.order_information?.building?.building_municipality_name,
        data?.order_information?.building?.building_town_name,
        data?.order_information?.building?.building_district,
        data?.order_information?.building?.building_street_address,
      ]
        .filter((obj) => obj)
        .join("") ||
      data?.order_information?.building_data?.building_address
        ?.replaceAll(/,/g, "")
        ?.replaceAll(" ", "")
    );
  };

  const handleNotification = (key: string) => {
    const { request_content, work_reports, work_cost, id, memo } = data;

    if (key === "memo" && memo?.has_notification) {
      memoAction({
        payload: {
          has_notification: false,
          id: memo?.id,
          service_card_id: id,
        },
        memoId: memo?.id,
      });
    }

    if (key === "request-content" && request_content?.has_notification) {
      requestContentAction({
        payload: {
          has_notification: false,
          id: request_content?.id,
          // order_date: request_content?.request_order_date
          //   ? moment(request_content.request_order_date).format(
          //       DATE_TIME_FORMAT.PAYLOAD
          //     )
          //   : null,
          service_card_id: id,
        },
        requestContentId: request_content?.id,
      });
    }

    if (
      key === "work-report" &&
      work_reports?.find((item: any) => item?.has_notification)
    ) {
      const formData = new FormData();
      const payload = [
        {
          service_card_id: id,
          has_notification: false,
        },
      ];

      payload.forEach((item: any) => {
        Object.keys(item).forEach((key) => {
          formData.append(`work_report_attributes[]${key}`, item[key]);
        });
      });

      workReportAction({
        payload: formData,
        workReportId: !isEmpty(work_reports),
      });
    }

    if (key === "work-cost" && work_cost?.has_notification) {
      workCostsAction({
        payload: {
          has_notification: false,
          id: work_cost?.id,
          service_card_id: id,
        },
        workCostsId: work_cost?.id,
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      reset({
        content: data?.request_content?.content || "",
      });
    }
  }, [data?.request_content?.content, isSuccess, reset]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [isSuccess, isLoading, isFetching]);

  return {
    ...props,
    serviceCardData: data as ServiceCard,
    isLoading,
    isFetching,
    isShowBox,
    workingHoursDone,
    reportDone,
    workCostDone,
    workStatusDone,
    isEditContent,
    errors,
    handleNotification,
    handleAddress,
    onSubmit,
    register,
    handleSubmit,
    setIsShowBox,
    inputRef,
    value,
    setValue,
    isSuccess,
  };
};

export type Props = ReturnType<typeof useServiceCardTicketDetail>;

export default useServiceCardTicketDetail;
