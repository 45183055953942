import { FC, memo, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import { SignOut } from "@bms/assets";
import { userLogout } from "@bms/features/auth/slice";
import axios from "@bms/libs/axios";
import { ROUTES, SERVICES } from "@bms/constants";
import ModalConfirmLogout from "../Modal/ModalConfirmLogout";
import { Styles } from "./styled";

const Header: FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = async () => {
    try {
      setIsSubmitting(true);
      await axios({
        method: "delete",
        url: SERVICES.LOGOUT,
      });

      dispatch(userLogout());
      setIsOpen(false);
      navigate(ROUTES.SIGNIN);
      localStorage.clear();
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  return (
    <Styles className="flex-bw">
      <h3 className="time">{moment().format("YYYY.MM.DD")}</h3>
      <div className="header-wrapper">
        <h3>野村開発管理者</h3>
        <div className="btn-logout" onClick={() => setIsOpen(true)}>
          <img src={SignOut} />
        </div>
      </div>

      <ModalConfirmLogout
        isSubmitting={isSubmitting}
        isOpen={isOpen}
        onLogOut={onSubmit}
        onClose={() => setIsOpen(false)}
      />
    </Styles>
  );
};

export default memo(Header);
