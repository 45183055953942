import styled from "styled-components";

export const Styles = styled.div`
  &.my-schedule {
    padding: 0 20px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22.5px;

    .date {
      display: flex;
      align-items: center;
      margin-left: -200px;
      width: 100%;
      justify-content: center;
      gap: 60px;
    }

    img {
      margin-top: 3px;
      cursor: pointer;
    }

    h3 {
      color: var(--main-color);
      font-size: 28px;
      font-family: "Inter";
      width: 200px;
    }

    div {
      font-family: "Lato";
      font-weight: 500;
      font-size: 38px;
      color: #333333;
      text-align: center;
    }
  }

  .dx-scheduler-time-panel-cell {
    text-align: left;
  }

  .dx-scheduler-group-table {
    height: auto !important;
  }

  .dx-scheduler-work-space {
    border: 0;
    background-color: transparent;
    border: 1px solid #707070;
    border-radius: 20px;
    padding: 30px 30px 0;
  }

  .dx-toolbar-items-container {
    display: none;
  }

  .dx-scheduler-container {
    background-color: transparent;
  }

  .dx-scheduler-header {
    background-color: transparent;
    border: 0;
    display: none;
  }

  .dx-scheduler-time-panel-cell::after {
    content: none;
  }

  .dx-scheduler-time-panel-cell,
  .dx-scheduler-work-space:not(.dx-scheduler-work-space-all-day):not(.dx-scheduler-work-space-month):not(.dx-scheduler-timeline)
    .dx-scheduler-header-panel-empty-cell {
    border-bottom: 0;
  }

  .dx-scheduler-work-space:not(.dx-scheduler-work-space-all-day):not(.dx-scheduler-work-space-month):not(.dx-scheduler-timeline)
    .dx-scheduler-header-panel {
    border-bottom: 0;
  }

  .dx-scheduler-header-panel-empty-cell,
  .dx-scheduler-time-panel {
    border-right: 0;
  }

  .dx-scheduler-work-space-both-scrollbar .dx-scheduler-date-table {
    /* border-radius: 2px; */
  }

  .dx-scheduler-date-table-row {
    td {
      &:first-child {
        border-left: 1px solid #707070 !important;
      }
      &:last-child {
        border-right: 1px solid #707070 !important;
      }
    }
  }

  .dx-scheduler-work-space-grouped .dx-scheduler-group-row th {
    border-color: #ffffff;
  }

  .dx-scheduler-work-space.dx-scheduler-work-space-grouped
    .dx-scheduler-last-group-cell {
    border-color: #707070;
  }

  .dx-scheduler-all-day-table-cell,
  .dx-scheduler-date-table-cell {
    border-left: 0;
    border-bottom: 1.5px solid #c1c1c1 !important;
    border-top: 1.5px solid #c1c1c1 !important;
  }

  .dx-scheduler-group-header {
    background-color: #174a84;
    height: 47px;

    div {
      font-family: "Inter";
      font-weight: 400;
      font-size: 15px;
      color: #ffffff;
    }
  }

  .dx-scheduler-all-day-table-cell.dx-scheduler-focused-cell,
  .dx-scheduler-date-table-cell.dx-scheduler-focused-cell {
    box-shadow: none;
    background-color: transparent;
  }
  .dx-scheduler-all-day-table-cell.dx-state-hover,
  .dx-scheduler-date-table-cell.dx-state-hover {
    background-color: transparent;
  }

  .dx-item.dx-scheduler-appointment {
    border-radius: 10px;
    background-color: #ffffff;

    .dx-scheduler-appointment-content {
      padding: 0;
      height: inherit;
    }

    .appointment {
      padding: 4px 15%;
      border-radius: 8px;
      height: inherit;
    }
    .in_schedule {
      background-color: #df691d !important;
    }

    .finished {
      background-color: #55606a !important;
    }

    .in_progress {
      background-color: #df1d3b !important;
    }

    .dx-scheduler-appointment-content-date {
      font-family: "Inter";
      font-weight: 400;
      font-size: 12px;
      color: #ffffff;
      opacity: 1;
    }
  }

  .dx-scheduler-time-panel-cell.dx-scheduler-cell-sizes-vertical {
    font-family: "Lato";
    font-weight: 400;
    font-size: 14px;
    color: #6e6e6e;
  }

  .dx-scheduler-appointment {
    border-left: 0;
    margin-left: 6px;
    box-shadow: none;
  }

  .dx-scheduler-date-table-cell {
    /*  */
  }

  tbody {
    .dx-scheduler-date-table-row {
      &:nth-child(2n + 2) {
        td {
          border-top: 1.5px dashed #c1c1c1 !important;
        }
      }

      &:nth-child(2n - 1) {
        td {
          border-bottom: 1.5px dashed #c1c1c1 !important;
        }
      }
    }
  }
  .dx-scheduler-work-space-grouped .dx-scheduler-group-row th,
  .dx-scheduler-work-space.dx-scheduler-work-space-grouped
    .dx-scheduler-last-group-cell {
    width: 200px;
  }
  .multiline-content {
    white-space: break-spaces;
  }
`;
