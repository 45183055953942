import styled from "styled-components";

export const Styles = styled.div`
  padding: 0 16px;
  padding-bottom: 40px;

  .memo {
    &.main {
      border: 1px solid #bcbcbc;
      padding: 20px 10px 0;
      border-radius: 6px;
      margin-bottom: 24px;
      padding-right: 11px !important;
      height: 50vh;
      overflow: scroll;
      position: relative;
      z-index: 99;
    }

    p {
      font-family: "Inter";
      font-weight: 400;
      font-size: 20px;
      color: #000000;
      text-align: left;
    }

    .item {
      margin-bottom: 25px;
    }

    .owner {
      p {
        color: #ff0000;
      }
    }

    .message-photos-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      max-width: 600px;

      .message-photo {
        width: 311px;
        height: 188;
        object-fit: contain;
      }
    }
  }

  .images-preview-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin-bottom: 20px;

    .images-preview-container {
      position: relative;

      .icon {
        right: -12px;
        top: -12px;
        z-index: 2;
        position: absolute;
        background-color: #e0e0e0;
        border-radius: 50%;
        cursor: pointer;
      }

      .photo-preview {
        position: relative;
        width: 100px;
        height: 100px;
        object-fit: cover;
        z-index: 1;
      }
    }
  }

  .MuiFormControl-root {
    width: 100%;
    margin-bottom: 20px;
    position: sticky;
    left: 0;
    right: 0;
    bottom: -2px;
    z-index: 100;
    background: #ffffff;

    .MuiInputBase-root {
      width: 100%;
      padding-left: 0;
      font-family: "Inter";
      font-weight: 400;
      font-size: 20px;
      color: #000000;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-width: 0 !important;

    &:hover {
      border-color: #bcbcbc;
    }
  }

  .ant-upload.ant-upload-select {
    width: 100%;
  }

  .btn-add-img {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    background-color: #347bc3;
    max-width: 343px;
    height: 85px;
    line-height: 85px;
    width: 100%;
    border: 0;
    border-radius: 4px;

    p {
      font-family: "Inter";
      font-weight: 400;
      font-size: 25px;
      color: #ffffff;
    }

    img {
      width: 26px;
      height: 26px;
    }
  }

  .btn-keep {
    /* position: fixed;
    bottom: 0;
    left: 0;
    right: 0; */
    margin: 30px auto 0;
    background-color: #b23131;
    padding: 0 23px;
    gap: 8px;
    max-width: 343px;
    height: 85px;
    line-height: 85px;
    width: 100%;
    border: 0;
    border-radius: 4px;

    p {
      font-family: "Inter";
      font-weight: 400;
      font-size: 25px;
      color: #ffffff;
      text-align: center;
    }
  }

  input {
    font-family: "Inter";
    font-weight: 400;
    font-size: 20px;
    color: #000000;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
`;
