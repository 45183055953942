/* eslint-disable no-console */
import axios from "@bms/libs/axios";
import { ServiceCard } from "@bms/types";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useState } from "react";
import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormGetValues,
  UseFormRegister,
  UseFormReset,
  UseFormSetValue,
  UseFormWatch,
  useFieldArray,
} from "react-hook-form";

export type ReceivedProps = {
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  reset: UseFormReset<FieldValues>;
  errors: FieldErrors<FieldValues>;
  control: Control<FieldValues>;
  isView: boolean;
  serviceCardData: ServiceCard;
};

const useWorkReport = (props: ReceivedProps) => {
  const { fields } = useFieldArray({
    control: props.control,
    name: "work_report_attributes",
  });

  const [openModalUpload, setOpenModalUpload] = useState<string | null>(null);
  const [openModalDeleteImg, setOpenModalDeleteImg] = useState<string | null>(
    null
  );
  const [loadingImage, setLoadingImage] = useState<string | null>(null);

  const onOpenModalUpload = (name: string) => setOpenModalUpload(name);
  const onCloseModalUpload = () => setOpenModalUpload(null);
  const onOpenModalDeleteUpload = (name: string) => setOpenModalDeleteImg(name);
  const onCloseModalDeleteUpload = () => setOpenModalDeleteImg(null);

  const uploadImage = async (name: string, filePhoto: any) => {
    setLoadingImage(name);
    try {
      const formData = new FormData();
      formData.append("file_photo", filePhoto);

      const { data } = await axios({
        method: "POST",
        url: "/s3",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      props.setValue(name, data.url);
      props.setValue(`${name}_key`, data.key);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoadingImage(null);
    }
  };

  const deleteImage = useCallback(async (name: string, key: string) => {
    setLoadingImage(name);
    try {
      await axios({
        method: "DELETE",
        url: "/s3/delete",
        data: {
          key,
        },
      });
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoadingImage(null);
    }
  }, []);

  useEffect(() => {
    if (props.reset && isEmpty(props.getValues("work_report_attributes"))) {
      props.reset({
        ...props.getValues(),
        work_report_attributes: [
          {
            title: "",
            before_photo: null,
            after_photo: null,
            comment: "",
          },
        ],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    ...props,
    fields,
    openModalUpload,
    openModalDeleteImg,
    onOpenModalDeleteUpload,
    onCloseModalDeleteUpload,
    onOpenModalUpload,
    onCloseModalUpload,
    uploadImage,
    deleteImage,
    loadingImage,
  };
};

export type Props = ReturnType<typeof useWorkReport>;

export default useWorkReport;
