import { FC } from "react";
import { Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import "dayjs/locale/ja";
import {
  TextField,
  Box,
  Select as SelectField,
  MenuItem,
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import LoadingButton from "@mui/lab/LoadingButton";
import { Select, Tag } from "antd";
import { DateRangePicker } from "react-date-range";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import moment from "moment";
import { ja } from "date-fns/locale";

import { Loading } from "@bms/components";
import { DatePickerIcon, RemoveIcon } from "@bms/assets";
import {
  DATE_TIME_FORMAT,
  DEPARTMENT_IN_CHANGE,
  LOCALE_JP,
  ROLE,
} from "@bms/constants";
import useWorkerControl, { Props, ReceivedProps } from "./hook";
import { CheckBoxStyles, ModalWrapper, Styles, StylesModal } from "./styled";
import { formatDay } from "@bms/utility";

const { Option } = Select;

const WorkerControlLayout: FC<Props> = ({
  id,
  isSubmitting,
  permissionOptions,
  control,
  errors,
  isLoading,
  isView,
  isOpenScopeLeave,
  stateDateRange,
  initial,
  selected,
  contextHolder,
  isOpenDateSpecific,
  stateDateLongRange,
  setStateDateLongRange,
  setSelected,
  onOpenDateSpecific,
  onCloseDateSpecific,
  onCloseScopeLeave,
  onOpenScopeLeave,
  setStateDateRange,
  onSubmit,
  setValue,
  getValues,
  register,
  handleSubmit,
  trigger,
}) => {
  const navigate = useNavigate();

  if ((id && isLoading) || !initial) {
    return <Loading />;
  }

  return (
    <Styles>
      {contextHolder}
      <div className="header">
        <h3>
          作業員
          {!id ? "登録" : isView ? "詳細" : "編集"}
        </h3>
        <div className="group-btn">
          <button className="btn-back" onClick={() => navigate(-1)}>
            戻る
          </button>
          {!isView && (
            <LoadingButton
              loadingPosition="start"
              loading={isSubmitting}
              type="submit"
              variant="outlined"
              className="btn-submit"
              onClick={handleSubmit(onSubmit)}
            >
              {!id ? "追加" : "保存"}
            </LoadingButton>
          )}
        </div>
      </div>

      <div className="title">
        <h4>作業員情報</h4>
      </div>

      <Box component="form">
        <div className="field-wrapper">
          <div className="input-field">
            <TextField
              {...register("user_id")}
              variant="standard"
              label="ID"
              disabled={true}
            />
            {errors.id && <p className="error">{errors?.id?.message}</p>}
          </div>
          <div className="input-field">
            <TextField
              {...register("name")}
              name="name"
              variant="standard"
              label="氏名"
              disabled={isView}
            />
            {errors.name && <p className="error">{errors.name?.message}</p>}
          </div>
          <div className="input-field">
            <FormControl variant="outlined">
              <InputLabel>部署名</InputLabel>
              <SelectField
                {...register("department_in_charge")}
                name="department_in_charge"
                variant="standard"
                defaultValue={getValues("department_in_charge")}
                label="部署名"
                disabled={isView}
              >
                <MenuItem
                  value="third_houses"
                  style={{
                    display: "block",
                    textAlign: "center",
                    padding: "6px 16px",
                  }}
                >
                  {DEPARTMENT_IN_CHANGE["third_houses"]}
                </MenuItem>
                <MenuItem
                  value="oyakudachiya"
                  style={{
                    display: "block",
                    textAlign: "center",
                    padding: "6px 16px",
                  }}
                >
                  {DEPARTMENT_IN_CHANGE["oyakudachiya"]}
                </MenuItem>
                <MenuItem
                  disabled
                  value="consignment"
                  style={{
                    display: "block",
                    textAlign: "center",
                    padding: "6px 16px",
                  }}
                >
                  {DEPARTMENT_IN_CHANGE["consignment"]}
                </MenuItem>
              </SelectField>
            </FormControl>
          </div>
          <div className="input-field">
            <TextField
              {...register("phone_number")}
              name="phone_number"
              variant="standard"
              label="電話番号"
              onChange={(e) => {
                setValue("phone_number", e.target.value.replace(/\D+/g, ""));
                trigger("phone_number");
              }}
              disabled={isView}
            />
            {errors.phone_number && (
              <p className="error">{errors.phone_number?.message}</p>
            )}
          </div>
          <div className="input-field">
            <Controller
              name="retired_date"
              control={control}
              defaultValue={null}
              render={() => (
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={LOCALE_JP}
                >
                  <FormControl variant="outlined">
                    <InputLabel>退社日</InputLabel>
                    <MobileDatePicker
                      {...register("retired_date")}
                      onClose={() => setValue("retired_date", null)}
                      onAccept={(e) => setValue("retired_date", e)}
                      label="退社日"
                      disabled={isView}
                      value={getValues("retired_date")}
                      inputFormat={
                        DATE_TIME_FORMAT.DATE_JP +
                        `(${formatDay(getValues("retired_date"))})`
                      }
                      onChange={(e) => setValue("retired_date", e)}
                      components={{
                        ActionBar: ({ onCancel, onAccept }: any) => (
                          <div className="MuiDialogActions-root">
                            <button onClick={onCancel}>クリア</button>
                            <button onClick={onAccept}>OK</button>
                          </div>
                        ),
                      }}
                      renderInput={(params: any) => (
                        <OutlinedInput
                          {...params}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton edge="end">
                                <img src={DatePickerIcon} />
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      )}
                    />
                  </FormControl>
                </LocalizationProvider>
              )}
            />
          </div>
        </div>
        <div className="field-wrapper">
          <div className="input-field">
            <TextField
              {...register("login_pass")}
              name="login_pass"
              variant="standard"
              label="ログインパスワード"
              disabled={isView}
            />
            {errors.login_pass && (
              <p className="error">{errors.login_pass?.message}</p>
            )}
          </div>
          <div className="space"></div>
          <div
            className="input-field"
            {...(!isView && { onClick: onOpenScopeLeave })}
          >
            <div className="field">
              <div
                className={`start-date ${
                  getValues("start_of_holiday") ? "focus" : ""
                }`}
              >
                <p>休職範囲</p>
                <span>
                  {getValues("start_of_holiday") &&
                    moment(getValues("start_of_holiday")).format(
                      DATE_TIME_FORMAT.DATE_JP +
                        `(${formatDay(getValues("start_of_holiday"))})`
                    )}
                </span>
              </div>
              {getValues("end_of_holiday") && getValues("start_of_holiday") && (
                <span
                  style={{
                    paddingTop: 15,
                  }}
                >
                  ~
                </span>
              )}
              <div
                className={`end-date ${
                  getValues("start_of_holiday") ? "focus" : ""
                }`}
              >
                <p>範囲選択</p>
                <span>
                  {getValues("end_of_holiday") &&
                    moment(getValues("end_of_holiday")).format(
                      DATE_TIME_FORMAT.DATE_JP +
                        `(${formatDay(getValues("end_of_holiday"))})`
                    )}
                </span>
              </div>
            </div>
            <img src={DatePickerIcon} />
          </div>
        </div>

        <div className="field-wrapper">
          <div className="input-field">
            <label>権限</label>
            <Controller
              name="permission_ids"
              control={control}
              render={(...props) => (
                <Select
                  {...props}
                  disabled={isView}
                  style={{
                    minWidth: 555,
                  }}
                  mode="multiple"
                  value={selected}
                  defaultValue={getValues("permission_ids")}
                  onChange={(value: string[]) => {
                    if (value[value.length - 1] === "1") {
                      setSelected(
                        selected.length === permissionOptions.length
                          ? []
                          : permissionOptions.map((item) =>
                              item.value.toString()
                            )
                      );
                      return;
                    }
                    setSelected(value);
                  }}
                  tagRender={(props: CustomTagProps) => {
                    const { label, onClose } = props;
                    const onPreventMouseDown = (
                      event: React.MouseEvent<HTMLSpanElement>
                    ) => {
                      event.preventDefault();
                      event.stopPropagation();
                    };
                    return (
                      <Tag
                        className="tag-wrapper"
                        color={"#5076bf"}
                        {...(!isView && {
                          onMouseDown: onPreventMouseDown,
                          onClose: onClose,
                        })}
                      >
                        <img
                          {...(!isView && {
                            onMouseDown: onPreventMouseDown,
                            onClick: onClose,
                          })}
                          style={{
                            cursor: isView ? "not-allowed" : "pointer",
                          }}
                          src={RemoveIcon}
                        />
                        {label}
                      </Tag>
                    );
                  }}
                >
                  <Option label="すべて" value="1">
                    <div className="option-wrapper">
                      <CheckBoxStyles
                        name="checked"
                        checked={
                          (permissionOptions.length > 0 &&
                            selected.length === permissionOptions.length) ||
                          false
                        }
                      />
                      <p>すべて</p>
                    </div>
                  </Option>
                  {permissionOptions.map((item) => (
                    <Option
                      label={item.label}
                      value={item.value}
                      key={item.value}
                    >
                      <div className="option-wrapper">
                        <CheckBoxStyles
                          name="checked"
                          checked={selected.indexOf(item.value) > -1}
                        />
                        <p>{item.label}</p>
                      </div>
                    </Option>
                  ))}
                </Select>
              )}
            />
          </div>

          <div className="input-field">
            <FormControl variant="outlined">
              <InputLabel>役割</InputLabel>
              <SelectField
                {...register("role")}
                name="role"
                variant="standard"
                defaultValue={getValues("role")}
                label="役職"
                disabled={isView}
              >
                <MenuItem
                  style={{
                    display: "block",
                    textAlign: "center",
                    padding: "6px 16px",
                  }}
                  value="office_staff"
                >
                  {ROLE["office_staff"]}
                </MenuItem>
                <MenuItem
                  style={{
                    display: "block",
                    textAlign: "center",
                    padding: "6px 16px",
                  }}
                  value="worker"
                >
                  {ROLE["worker"]}
                </MenuItem>
                {/* <MenuItem value="entrustment">{ROLE["entrustment"]}</MenuItem> */}
              </SelectField>
            </FormControl>
            {errors.role && <p className="error">{errors.role?.message}</p>}
          </div>

          <div className="input-field"></div>

          <div
            className="input-field"
            {...(!isView && { onClick: onOpenDateSpecific })}
          >
            <div className="field">
              <div
                className={`start-date ${
                  getValues("start_of_long_holiday") ? "focus" : ""
                }`}
              >
                <p>長期休暇</p>
                <span>
                  {getValues("start_of_long_holiday") &&
                    moment(getValues("start_of_long_holiday")).format(
                      DATE_TIME_FORMAT.DATE_JP +
                        `(${formatDay(getValues("start_of_long_holiday"))})`
                    )}
                </span>
              </div>
              {getValues("end_of_long_holiday") &&
                getValues("start_of_long_holiday") && (
                  <span
                    style={{
                      paddingTop: 15,
                    }}
                  >
                    ~
                  </span>
                )}
              <div
                className={`end-date ${
                  getValues("start_of_long_holiday") ? "focus" : ""
                }`}
              >
                <p>範囲選択</p>
                <span>
                  {getValues("end_of_long_holiday") &&
                    moment(getValues("end_of_long_holiday")).format(
                      DATE_TIME_FORMAT.DATE_JP +
                        `(${formatDay(getValues("end_of_long_holiday"))})`
                    )}
                </span>
              </div>
            </div>
            <img src={DatePickerIcon} />
          </div>
        </div>

        <ModalWrapper open={isOpenScopeLeave} onClose={onCloseScopeLeave}>
          <Box sx={{ ...StylesModal, width: 690 }}>
            <h3 className="title">時間範囲を選択する</h3>

            <div className="modal-content">
              <DateRangePicker
                onChange={(ranges) => {
                  const { selection }: any = ranges;
                  if (!selection) return;
                  setStateDateRange([selection]);
                }}
                locale={ja}
                rangeColors={["#004bb1", "#004bb1"]}
                showDateDisplay={false}
                months={2}
                ranges={stateDateRange}
                monthDisplayFormat={"yyyy MMM"}
                direction="horizontal"
              />
            </div>

            <div className="modal-footer">
              <div className="group-btn">
                <button
                  className="btn-cancel"
                  onClick={() => {
                    onCloseScopeLeave();
                    setValue("start_of_holiday", null);
                    setValue("end_of_holiday", null);
                  }}
                >
                  クリア
                </button>
                <button
                  type="submit"
                  className="btn-submit"
                  onClick={() => {
                    setValue("start_of_holiday", stateDateRange[0].startDate);
                    setValue("end_of_holiday", stateDateRange[0].endDate);
                    onCloseScopeLeave();
                  }}
                >
                  OK
                </button>
              </div>
            </div>
          </Box>
        </ModalWrapper>

        <ModalWrapper open={isOpenDateSpecific} onClose={onCloseDateSpecific}>
          <Box sx={{ ...StylesModal, width: 690 }}>
            <h3 className="title">時間範囲を選択する</h3>

            <div className="modal-content">
              <DateRangePicker
                onChange={(ranges) => {
                  const { selection }: any = ranges;
                  if (!selection) return;
                  setStateDateLongRange([selection]);
                }}
                locale={ja}
                rangeColors={["#004bb1", "#004bb1"]}
                showDateDisplay={false}
                months={2}
                ranges={stateDateLongRange}
                monthDisplayFormat={"yyyy MMM"}
                direction="horizontal"
              />
            </div>

            <div className="modal-footer">
              <div className="group-btn">
                <button
                  className="btn-cancel"
                  onClick={() => {
                    onCloseDateSpecific();
                    setValue("start_of_long_holiday", null);
                    setValue("end_of_long_holiday", null);
                  }}
                >
                  クリア
                </button>
                <button
                  type="submit"
                  className="btn-submit"
                  onClick={() => {
                    setValue(
                      "start_of_long_holiday",
                      stateDateLongRange[0].startDate
                    );
                    setValue(
                      "end_of_long_holiday",
                      stateDateLongRange[0].endDate
                    );
                    onCloseDateSpecific();
                  }}
                >
                  OK
                </button>
              </div>
            </div>
          </Box>
        </ModalWrapper>
      </Box>
    </Styles>
  );
};

const WorkerControl: FC<ReceivedProps> = (props) => (
  <WorkerControlLayout {...useWorkerControl(props)} />
);

export default WorkerControl;
