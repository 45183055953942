import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import Modal from "@mui/material/Modal";

export const Styles = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 54px;

    h3 {
      color: var(--main-color);
      font-size: 28px;
      font-family: "Inter";
    }

    .group-btn {
      display: flex;
      gap: 20px;

      button {
        width: 120px;
        background-color: var(--main-color);
        color: #ffffff;
        padding: 6.5px 20px;
        border: none;
        border-radius: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        font-size: 16px;
        font-weight: 400;
        font-family: "Inter";
      }

      .btn-back {
        background-color: #ffffff;
        border: 2px solid var(--main-color);
        color: var(--main-color);
      }
    }
  }

  .title {
    font-weight: 400;
    font-size: 20px;
    color: var(--main-color);
    font-family: "Inter";
    margin-top: -7px;
  }

  form {
    margin-top: 10px;
    border: 1px solid #bcbcbc;
    margin-left: 3px;
    border-radius: 6px;
    padding: 9px 19px 97px 21px;

    label {
      font-weight: 400;
      font-size: 15px;
      font-family: "Lato";
      color: #adadad;

      &.Mui-focused,
      &.MuiInputLabel-shrink {
        top: 0;
      }
    }

    .MuiFormLabel-root.Mui-focused {
      color: #adadad;
    }

    .MuiInputBase-root {
      &:hover:not(.Mui-disabled):before,
      &::after {
        border-bottom: 1px solid #bcbcbc;
      }

      &:before {
        border-bottom: 1px solid #bcbcbc;
      }
    }

    .MuiInputBase-input {
      padding: 7px 0 5px;
      &::after {
        border-bottom: 1px solid #bcbcbc;
      }
    }

    .field-wrapper {
      display: flex;
      flex-direction: row;
      gap: 19px;

      .MuiFormControl-root {
        .MuiSelect-select {
          padding: 6px 0 5px;
          font-weight: 400;
          font-family: "Lato";
        }
      }

      &:nth-child(1) {
        width: 100%;

        .input-field {
          width: 100%;

          .MuiFormControl-root {
            width: 100%;
          }

          &:nth-child(1) {
            min-width: 140px;
            @media screen and (max-width: 1420px) {
              min-width: unset;
              max-width: 140px;
            }
          }

          &:nth-child(2) {
            min-width: 271px;
            @media screen and (max-width: 1420px) {
              min-width: unset;
              max-width: 271px;
            }
          }

          &:nth-child(3) {
            min-width: 124px;
            @media screen and (max-width: 1420px) {
              min-width: unset;
              max-width: 124px;
            }

            label {
              top: 3px;
              left: -13px;
            }

            label.Mui-focused,
            label.MuiInputLabel-shrink {
              top: 8px !important;
            }

            .MuiSelect-select {
              background-color: transparent;
            }
          }

          &:nth-child(4) {
            min-width: 233px;
            @media screen and (max-width: 1420px) {
              min-width: unset;
              max-width: 233px;
            }
          }

          &:nth-child(5) {
            min-width: 263px;
            @media screen and (max-width: 1420px) {
              min-width: unset;
              max-width: 263px;
            }

            .MuiFormControl-root {
              .MuiOutlinedInput-notchedOutline {
                border: 0;
                border-bottom: 1px solid #bcbcbc;
                border-radius: 0;
              }

              .MuiOutlinedInput-input {
                padding: 22px 0 6px 0;
              }
            }

            .MuiInputBase-root {
              padding-right: 0;
            }

            label {
              top: 4px;
              left: -13px;
            }

            button {
              padding: 0px;
              margin-top: 15px;
              margin-right: 0px;
            }
          }
        }
      }

      &:nth-child(2) {
        margin-top: 15px;
        justify-content: space-between;
        align-items: flex-end;

        .MuiFormControl-root {
          &:nth-child(1) {
            width: 174.15px;

            label {
              top: 0px;
            }

            .MuiSelect-select {
              background-color: transparent;
              padding: 6px 0 5px;
            }
          }
        }

        .input-field {
          border-bottom: 1px solid #bcbcbc;
          display: flex;
          /* align-items: center; */
          align-items: flex-end;
          justify-content: space-between;
          min-width: 263.15px;

          &:nth-child(1) {
            border-bottom: none;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
          }

          .focus {
            p {
              transform: translate(-7px, -8px) scale(0.75);
              margin-bottom: 0;
              transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
                transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
                max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
            }

            span {
              margin-bottom: 5px;
            }
          }

          span {
            /* margin-left: 10px; */
            /* margin-right: 10px; */
            font-family: "Lato";
            font-weight: 400;
            font-size: 15px;
            color: #000000;
          }

          img {
            cursor: pointer;
            margin-left: 10px;
            margin-bottom: 10px;
          }

          p {
            margin-bottom: 7px;
            font-family: "Lato";
            font-weight: 400;
            font-size: 15px;
            color: #adadad;
          }

          .start-date,
          .end-date {
            display: flex;
            flex-direction: column;
            align-items: start;
          }

          .field {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            gap: 10px;
          }
        }
      }

      &:nth-child(3) {
        margin-top: 35px;
        position: relative;
        align-items: flex-end;
        justify-content: space-between;

        .input-field {
          &:nth-child(1) {
            .MuiCheckbox-root {
              padding: 0;
              margin-right: 14px;

              &.Mui-checked {
                color: #ffffff;
                position: relative;

                > svg {
                  background-color: red;
                }
              }
            }

            .MuiFormControl-root {
              min-width: 260px;

              .MuiIconButton-root {
                padding: 0;
              }
            }
          }

          label {
            font-family: "Inter";
            font-weight: 400;
            font-size: 15px;
            color: #adadad;
            position: absolute;
            top: 23px;
            left: 0;
            z-index: 1;
          }

          .ant-select-selection-overflow {
            gap: 7px;
            padding-left: 40px;
            padding-right: 20px;
          }

          .tag-wrapper {
            display: flex;
            width: 100%;
            align-items: center;
            height: 28px;
            line-height: 28px;
            border-radius: 24px;
            padding-inline: 14px !important;

            .option-wrapper {
              .MuiButtonBase-root {
                display: none;
              }

              p {
                font-family: "Inter";
                font-weight: 400;
                font-size: 15px;
                color: #ffffff;
                margin-left: 7px;
              }
            }
          }

          &:nth-child(2) {
            width: 100%;
            max-width: 307px;
            @media screen and (max-width: 1420px) {
              min-width: unset;
              max-width: 124px;
            }

            .MuiFormControl-root {
              width: 100%;
            }

            label {
              top: 3px;
              left: -13px;
            }

            label.Mui-focused,
            label.MuiInputLabel-shrink {
              top: 8px !important;
            }

            .MuiSelect-select {
              background-color: transparent;
            }
          }

          &:nth-child(3) {
            min-width: 100px;
            @media screen and (max-width: 1420px) {
              min-width: unset;
              max-width: 271px;
            }
          }

          &:nth-child(4) {
            border-bottom: 1px solid #bcbcbc;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            min-width: 263.15px;
            /* margin-right: 0; */
            /* margin-left: auto; */

            &:nth-child(1) {
              border-bottom: none;
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              flex-direction: column;
            }

            .focus {
              p {
                transform: translate(-7px, -8px) scale(0.75);
                margin-bottom: 0;
                transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
                  transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
                  max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
              }

              span {
                margin-bottom: 5px;
              }
            }

            span {
              /* margin-left: 10px; */
              /* margin-right: 10px; */
              font-family: "Lato";
              font-weight: 400;
              font-size: 15px;
              color: #000000;
            }

            img {
              cursor: pointer;
              margin-left: 10px;
              margin-bottom: 10px;
            }

            p {
              margin-bottom: 7px;
              font-family: "Lato";
              font-weight: 400;
              font-size: 15px;
              color: #adadad;
            }

            .start-date,
            .end-date {
              display: flex;
              flex-direction: column;
              align-items: start;
            }

            .field {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              gap: 10px;
            }
          }
        }
      }

      input {
        font-weight: 400;
        font-size: 15px;
        font-family: "Lato";

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }
  }
`;

export const CheckBoxStyles = withStyles(() => ({
  root: {
    "&:hover": {
      backgroundColor: "#ffffff",
    },

    "& .MuiSvgIcon-root": {
      fill: "#ffffff",
      width: 15,
      height: 15,
      fontSize: 15,

      "&:hover": {
        backgroundColor: "#ffffff",
      },
    },

    "&$checked": {
      "& .MuiIconButton-label": {
        position: "relative",
        zIndex: 0,
        border: "1px solid #7e7e7e",
        borderRadius: 3,
      },

      "& .MuiIconButton-label:after": {
        content: '""',
        top: 2,
        left: 3,
        width: 9,
        height: 11,
        position: "absolute",
        backgroundColor: "#000000",
        zIndex: -1,
        borderColor: "transparent",
      },
    },

    "&:not($checked) .MuiIconButton-label": {
      position: "relative",
      zIndex: 0,
      border: "1px solid #7e7e7e",
      borderRadius: 3,
    },

    "&:not($checked) .MuiIconButton-label:after": {
      content: '""',
      top: 2,
      left: 3,
      width: 9,
      height: 11,
      position: "absolute",
      backgroundColor: "#ffffff",
      zIndex: -1,
      borderColor: "transparent",
    },
  },
  checked: {},
}))(Checkbox);

export const ModalWrapper = styled(Modal)`
  h3 {
    font-family: "Inter";
    font-weight: 400;
    font-size: 20px;
    color: #174a84;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .modal-footer {
    padding-bottom: 15px;

    .group-btn {
      display: flex;
      justify-content: end;
      align-items: center;
      gap: 12px;

      button {
        font-family: "Inter";
        font-weight: 400;
        border: none;
        min-width: 64px;
        padding: 6px 8px;
        border-radius: 4px;
        line-height: 1.75;
        letter-spacing: 0.02857em;
        text-transform: uppercase;
        color: #ffffff;
        font-size: 12px;

        &.btn-submit {
          background-color: #004bb1;
        }

        &.btn-cancel {
          background-color: var(--sub-color);
        }
      }
    }
  }
`;

export const StylesModal = {
  border: "none",
  outline: "none",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  backgroundColor: "#ffffff",
  pl: 2,
  pr: 2,
  borderRadius: 2,
};
