import styled from "styled-components";

export const Styles = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  left: 197px;
  padding: 15px 31px 9px 36px;
  background: #ffffff;
  border-bottom: 0.1rem solid #c9c9c9;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    font-size: 18px;
    color: var(--main-color);
    /* margin-top: -10px; */
    margin-right: 29px;
    font-family: "Lato";
    margin-top: -1px;
  }

  .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    h3 {
      font-family: "Inter";
    }
  }

  .btn-logout {
    display: flex;
    justify-content: center;
    align-items: center;

    > img {
      cursor: pointer;
    }
  }
`;
