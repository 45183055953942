import { useLocation } from "react-router-dom";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { isEmpty } from "lodash";
import {
  GridSizeChangedEvent,
  GridApi,
  DragStoppedEvent,
} from "ag-grid-community";
import { ColDef } from "ag-grid-community";

import { ColumnProps } from "@bms/types";
import { AgGridReactProps } from "ag-grid-react";

export interface ReceivedProps extends Partial<AgGridReactProps> {
  rowData: Record<string, any>[];
  pageSize: number;
  gridRef: any;
  columnDefs: ColumnProps[];
  gridApi: GridApi | undefined;
  loading: boolean;
  enableCellTextSelection?: boolean;
  height?: any;
  total: number;
  tableHeader?: ReactNode;
  page: number;
  //TODO
  onFilterUpdate?: any;
  onSortChanged?: any;
  rowSelectionType?: "multiple" | "single";
  setPage: Dispatch<SetStateAction<number>>;
  setPageSize: Dispatch<SetStateAction<number>>;
  onGridReady: (params: GridSizeChangedEvent) => void;
  onDragStopped?: (params: DragStoppedEvent) => void;
  onOpenModalDelete?: () => void;
  disableDeleteButton?: boolean;
  setValueSelected?: any;
  rowHeightChange?: any;
  floatFilterHeightChange?: any;
  headerHeightChange?: any;
}

export const ROW_HEIGHT = 52;
export const FLOAT_FILTER_HEIGHT = 44;
export const HEADER_HEIGHT = 54;

const useTable = (props: ReceivedProps) => {
  const location = useLocation();
  const [isShowDelete, setIsShowDelete] = useState<boolean>(false);

  const containerStyle = useMemo(
    () => ({
      width: "100%",
      height: !props.height ? "605px" : props.height,
      maxHeight: "calc(100vh - 276px)",
    }),
    [props.height]
  );
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      resizable: false,
      floatingFilter: true,
      filter: true,
      sortable: true,
    };
  }, []);

  const calculatePagesCount = () =>
    props.total < props.pageSize ? 1 : Math.ceil(props.total / props.pageSize);

  const isFistPage = props.page === 1;
  const isLastPage = props.page === calculatePagesCount();

  const onVirtualColumnsChanged = () => {
    const element = document.querySelectorAll(".ag-text-field-input");

    element.forEach((item: any) => {
      item.placeholder = "検索";
    });
  };

  const onListenRowSelected = () => {
    if (!props.gridApi) return;
    const rowSelected = props.gridApi.getSelectedRows();
    if (props.setValueSelected) props?.setValueSelected(rowSelected);
    setIsShowDelete(!isEmpty(rowSelected));
  };

  useEffect(() => {
    props?.gridApi?.sizeColumnsToFit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.screen.width]);

  useEffect(() => {
    if (props.disableDeleteButton) {
      setIsShowDelete(false);
    }

    props?.gridApi?.sizeColumnsToFit();

    if (props.gridApi) {
      if (props.loading) {
        props.gridApi.showLoadingOverlay();
      } else {
        if (!props.rowData?.length) {
          props.gridApi.showNoRowsOverlay();
        } else {
          props.gridApi.hideOverlay();
        }
      }
    }
  }, [props.gridApi, props.loading, props.rowData?.length, isShowDelete]);

  useEffect(() => {
    const element = document.querySelectorAll(".ag-text-field-input");

    element.forEach((item: any) => {
      item.placeholder = "検索";
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.rowData, props.loading, location, props.gridApi]);

  useEffect(() => {
    if (!props.gridApi) return;
    const rowSelected = props.gridApi.getSelectedNodes();
    setIsShowDelete(!isEmpty(rowSelected));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.rowData]);

  return {
    ...props,
    containerStyle,
    gridStyle,
    defaultColDef,
    isFistPage,
    isLastPage,
    isShowDelete,
    onListenRowSelected,
    calculatePagesCount,
    // isFullWidthRow,
    onVirtualColumnsChanged,
  };
};

export type Props = ReturnType<typeof useTable>;

export default useTable;
