import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QUERY_NAME, SERVICES, SORTING } from "@bms/constants";
import axios from "@bms/libs/axios";
import { Workers } from "@bms/types";
import { notification } from "antd";

export const useFetchSubcontractors = (
  page: number,
  pageSize: number,
  filterParams?: any,
  sortParams?: any
) => {
  for (const obj in sortParams) {
    if (sortParams[obj] === SORTING.ASC) {
      delete sortParams[obj];
    }
  }

  return useQuery(
    [QUERY_NAME.SUBCONTRACTORS, page, pageSize, filterParams, sortParams],
    () =>
      axios({
        method: "GET",
        url: SERVICES.SUBCONTRACTORS,
        params: {
          page: page,
          per_page: pageSize,
          ...filterParams,
          ...sortParams,
        },
      }).then((res) => res.data || []),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
};

export const useFetchOneSubcontractor = (
  subcontractorId: string | undefined
) => {
  return useQuery(
    [QUERY_NAME.SUBCONTRACTOR_ID, subcontractorId],
    () =>
      axios({
        method: "GET",
        url: `${SERVICES.SUBCONTRACTORS}/${subcontractorId}`,
      }).then((res) => res.data as Workers),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!subcontractorId,
    }
  );
};

export const useDeleteSubcontractor = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ payload }: any) =>
      axios({
        method: "DELETE",
        url: `${SERVICES.SUBCONTRACTORS}/delete`,
        data: payload,
      }),
    {
      onSuccess() {
        queryClient.invalidateQueries([QUERY_NAME.SUBCONTRACTORS]);
      },
    }
  );
};

export const useSubmitSubcontractor = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ payload, subcontractorId }: any) =>
      axios({
        method: subcontractorId ? "PUT" : "POST",
        url: subcontractorId
          ? `${SERVICES.SUBCONTRACTORS}/${subcontractorId}`
          : SERVICES.SUBCONTRACTORS,
        data: payload,
      }),
    {
      onSuccess() {
        queryClient.invalidateQueries([QUERY_NAME.SUBCONTRACTORS]);
        queryClient.invalidateQueries([QUERY_NAME.SUBCONTRACTOR_ID]);
      },
      onError(error: any) {
        notification["error"]({
          message: { error }?.error?.response?.data?.error || "Failed",
        });
      },
    }
  );
};
