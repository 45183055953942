import styled from "styled-components";

export const Styles = styled.div`
  margin: 40px auto 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  position: relative;

  .btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 23px;
    gap: 8px;
    max-width: 343px;
    height: 85px;
    line-height: 85px;
    width: 100%;
    border-radius: 4px;
    margin-bottom: 25px;
    background-color: #3396a2;
    border: 2px solid #3396a2;
    position: relative;

    &.end {
      background-color: transparent;

      p {
        color: #3396a2;
      }
    }

    &.transparent {
      background-color: #ffffff;

      p {
        color: #3396a2;
        font-family: "Lato";
        font-weight: 400;
        font-size: 43px;
      }
    }

    &-end {
      background-color: #ff0000;
      border: 2px solid #ff0000;

      &.transparent {
        p {
          color: #ff0000;
        }
      }
    }

    p {
      width: 100%;
      font-family: "Lato";
      font-weight: 400;
      font-size: 18px;
      color: #ffffff;
      text-align: center;
    }

    button {
      width: 40px;
      height: 40px;
    }
  }

  h3 {
    margin-top: 50px;
    font-family: "Inter";
    font-weight: 400;
    font-size: 22px;
    color: #1f3f5d;
    margin-bottom: 8px;
  }

  .box {
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    width: 100%;
    align-items: center;

    .btn-date {
      background-color: #2e8135;
      border: 2px solid #2e8135;

      p {
        font-family: "Lato";
        font-weight: 400;
        font-size: 36px;
        color: #ffffff;
      }
    }
  }

  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
  }

  .picker-working-hour {
    border: none;
    .ant-picker-suffix,
    .ant-picker-clear {
      display: none;
    }
    .ant-picker-input {
      input {
        color: #3396a2;
        font-family: "Lato";
        font-weight: 400;
        font-size: 28px;
      }
    }
  }

  .time-date-picker {
    background-color: #df691d;
    font-weight: 400;
    font-family: "Lato";
    box-shadow: none;
    border: none;
    color: #fff;
    border-radius: 4px;
  }
`;
