import Modal from "@mui/material/Modal";
import styled from "styled-components";

export const StylesModal = {
  border: "none",
  outline: "none",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  backgroundColor: "#ffffff",
  pl: 4,
  pr: 4,
  borderRadius: 2,
};

export const ModalWrapper = styled(Modal)`
  h3 {
    font-family: "Lato";
    font-weight: 400;
    font-size: 16px;
    color: #7b7b7b;
    text-align: center;
    padding-top: 35px;
  }

  .modal-footer {
    .group-btn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 12px;
      margin-top: 19px;

      button {
        width: 100%;
        height: 48px;
        border: 0;
        color: #ffffff;
        border-radius: 2px;
        font-weight: 400;
        font-family: "Inter";

        &.btn-submit {
          background-color: #004bb1;
        }

        &.btn-cancel {
          background-color: var(--sub-color);
        }
      }
    }
  }
`;
