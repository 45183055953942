import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QUERY_NAME, SERVICES } from "@bms/constants";
import axios from "@bms/libs/axios";

export const useFetchTaxRate = () => {
  return useQuery(
    [QUERY_NAME.TAX_RATE],
    () =>
      axios({
        method: "GET",
        url: SERVICES.TAX_RATES,
      }).then((res) => res.data || []),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
};

export const useUpdateTaxRate = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ payload, id }: any) =>
      axios({
        method: "PUT",
        url: `${SERVICES.TAX_RATES}/${id}`,
        data: payload,
      }),
    {
      onSuccess() {
        queryClient.invalidateQueries([QUERY_NAME.TAX_RATE]);
      },
    }
  );
};
