import { makeStyles } from "@material-ui/core";
import styled from "styled-components";

export const Styles = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 51px;

    h3 {
      color: var(--main-color);
      font-size: 28px;
      font-family: "Inter";
    }

    .group-btn {
      display: flex;
      gap: 25px;
      padding-top: 7px;

      button {
        width: 120px;
        background-color: var(--main-color);
        color: #ffffff;
        height: 37px;
        border: none;
        border-radius: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        font-size: 16px;
        font-weight: 600;
        font-family: "Inter";
      }

      .btn-back {
        background-color: #ffffff;
        border: 2px solid var(--main-color);
        color: var(--main-color);
      }
    }
  }

  .title {
    display: flex;
    align-items: center;
    gap: 152px;
    margin-bottom: 19px;
    margin-left: 7px;

    h3 {
      font-family: "Inter";
      font-weight: 400;
      font-size: 20px;
      color: var(--main-color);
    }
  }

  .clearable {
    cursor: pointer;
  }

  .field-wrapper {
    display: flex;
    align-items: center;
    margin-left: 7px;
    margin-bottom: 19px;
    gap: 17px;

    .separator {
      font-family: "Inter";
      font-weight: 400;
      font-size: 20px;
      color: var(--main-color);
      margin-right: 28px;
      padding-top: 20px;
    }

    .MuiInputBase-root {
      border: 1px solid #cdcdcd;
      border-radius: 6px;
      width: 130px;

      .placeholder {
        font-family: "Inter";
        font-weight: 400;
        font-size: 16px;
        color: #adadad;
      }

      fieldset {
        border: none;
      }

      .MuiSelect-select {
        padding: 0 14px;
        height: 50px;
        line-height: 52px;
        font-weight: 400;
        font-size: 16px;
        font-family: "Inter";
      }
    }
  }
`;

export const useStyles = makeStyles(() => ({
  select: {
    fontSize: "20px",
  },
  paper: {
    marginTop: 2,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    "& li": {
      minHeight: "39px",
      fontFamily: "Inter",
      fontWeight: 400,
      // fontSize: "20px !important",
      color: "#8b8b8b",
      borderBottom: "1px solid #bcbcbc",
      paddingBottom: 8,
    },
  },
}));
