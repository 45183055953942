import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QUERY_NAME, SERVICES, SORTING } from "@bms/constants";
import axios from "@bms/libs/axios";
import { Workers } from "@bms/types";

export const useFetchPaymentCategories = (
  page: number,
  pageSize: number,
  filterParams?: any,
  sortParams?: any
) => {
  for (const obj in sortParams) {
    if (sortParams[obj] === SORTING.ASC) {
      delete sortParams[obj];
    }
  }

  return useQuery(
    [QUERY_NAME.PAYMENT_CATEGORIES, page, pageSize, filterParams, sortParams],
    () =>
      axios({
        method: "GET",
        url: SERVICES.PAYMENT_CATEGORIES,
        params: {
          page: page,
          per_page: pageSize,
          ...filterParams,
          ...sortParams,
        },
      }).then((res) => res.data || []),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
};

export const useFetchOnePaymentCategory = (
  paymentCategoryId: string | undefined
) => {
  return useQuery(
    [QUERY_NAME.PAYMENT_CATEGORY_ID, paymentCategoryId],
    () =>
      axios({
        method: "GET",
        url: `${SERVICES.PAYMENT_CATEGORIES}/${paymentCategoryId}`,
      }).then((res) => res.data as Workers),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!paymentCategoryId,
    }
  );
};

export const useDeletePaymentCategory = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ payload }: any) =>
      axios({
        method: "DELETE",
        url: `${SERVICES.PAYMENT_CATEGORIES}/delete`,
        data: payload,
      }),
    {
      onSuccess() {
        queryClient.invalidateQueries([QUERY_NAME.PAYMENT_CATEGORIES]);
      },
    }
  );
};

export const useSubmitPaymentCategory = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ payload, paymentCategoryId }: any) =>
      axios({
        method: paymentCategoryId ? "PUT" : "POST",
        url: paymentCategoryId
          ? `${SERVICES.PAYMENT_CATEGORIES}/${paymentCategoryId}`
          : SERVICES.PAYMENT_CATEGORIES,
        data: payload,
      }),
    {
      onSuccess() {
        queryClient.invalidateQueries([QUERY_NAME.PAYMENT_CATEGORIES]);
        queryClient.invalidateQueries([QUERY_NAME.PAYMENT_CATEGORY_ID]);
      },
    }
  );
};
