import styled from "styled-components";

export const Styles = styled.div`
  &.request-content {
    .title {
      margin-top: 44px;
      margin-bottom: 7px;
      font-family: "Inter";
      font-weight: 400;
      font-size: 14px;
      color: #000000;
    }

    .clearable {
      font-size: 16px;
      margin-right: 3px;
      cursor: pointer;
    }

    label {
      font-weight: 400;
      font-size: 15px;
      font-family: "Lato";
      color: #adadad;

      &.Mui-focused,
      &.MuiInputLabel-shrink {
        top: 0;
      }
    }

    .MuiFormLabel-root.Mui-focused {
      color: #adadad;
    }

    .MuiInputBase-root {
      &:hover:not(.Mui-disabled):before,
      &::after {
        border-bottom: 1px solid #bcbcbc;
      }

      &:before {
        border-bottom: 1px solid #bcbcbc;
      }
    }

    .MuiInputBase-input {
      &::after {
        border-bottom: 1px solid #bcbcbc;
      }
    }

    .MuiSelect-select {
      background-color: transparent;
    }

    .field-wrapper {
      display: flex;
      flex-direction: row;
      gap: 19px;

      .MuiFormControl-root {
        width: 100%;
        .MuiSelect-select {
          padding: 6px 0 5px;
          font-weight: 400;
          font-family: "Lato";
        }
      }

      input {
        font-weight: 400;
        font-size: 15px;
        font-family: "Lato";

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }

    .main {
      border-radius: 6px;
      border: 1px solid #bcbcbc;

      &:first-child {
        border: none;
        padding: 0;

        .field-wrapper {
          /* padding-left: 13px; */
          gap: 65px;
        }

        .input-field {
          width: 218.31px;

          &:nth-child(1) {
            .MuiFormControl-root {
              margin-top: 12px;
            }
          }

          &:nth-child(2) {
            width: 238.31px;
            background-color: #f2f2f2;
            padding: 10px 20px 20px;
            display: flex;
            align-items: center;

            span {
              padding-top: 13px;
              transform: translateX(-8px);
              color: #7c7c7c;
              font-size: 22px;
              cursor: pointer;
            }
          }

          .MuiFormControl-root {
            .MuiOutlinedInput-notchedOutline {
              border: 0;
              border-bottom: 1px solid #bcbcbc;
              border-radius: 0;
            }

            .MuiOutlinedInput-input {
              padding: 22px 0 6px 0;
            }
          }

          .MuiInputBase-root {
            padding-right: 0;
          }

          label {
            top: 4px;
            left: -13px;
          }

          button {
            padding: 0px;
            margin-top: 15px;
            margin-right: 0px;
          }
        }
      }

      &:nth-child(3) {
        padding: 20px 19px 20px 19px;

        .field-wrapper {
          gap: 30px;
        }

        .input-field {
          width: 259.01px;
          display: flex;
          align-items: center;
          border-bottom: 1px solid #bcbcbc;

          .ant-picker-disabled {
            cursor: default;
            input {
              cursor: default;
              color: #000000 !important;
            }
          }

          .ant-picker:hover,
          .ant-picker-focused {
            border-color: #cdcdcd;
            box-shadow: none;
          }

          .ant-picker {
            border: 0;

            .ant-picker-input {
            }
          }
        }
      }

      &:nth-child(5) {
        padding: 10px 20px 30px 20px;

        label {
          position: absolute;
          top: 4px;
          left: -13px;
          z-index: 1;

          &.Mui-focused,
          &.MuiInputLabel-shrink {
            top: 0;
          }
        }

        .field-wrapper {
          flex-direction: row;
          gap: 60px;

          &:not(:last-child) {
            margin-bottom: 10px;
          }

          .index {
            margin-top: 20px;
            font-family: "Lato";
            font-weight: 400;
            font-size: 12px;
            color: #adadad;
            border: 1px solid #adadad;
            padding: 2px 6px;
            border-radius: 50%;
          }

          .item-wrapper {
            width: 100%;
          }

          &:last-child {
            margin-top: 110px;
            gap: 20px;
          }
        }

        .input-field {
          width: 238.95px;
        }
      }

      &:nth-child(6),
      &:nth-child(7) {
        padding: 10px 0 10px 0;
        border: none;

        .title {
          margin-top: 0;
        }

        span {
          color: #777777;
        }

        .input-field {
          width: 100%;

          .MuiOutlinedInput-notchedOutline {
            border-color: #bcbcbc;
            border-width: 1px;

            &:hover {
              border-color: #bcbcbc;
            }
          }

          .MuiFormControl-root {
            width: 100%;
          }
        }
      }

      &:nth-child(8) {
        border: none;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .return-order-date {
          flex-wrap: wrap;
          display: flex;
          align-items: flex-start;
          gap: 65px;
        }

        .input-field {
          width: 218.3px;
        }
      }
    }
  }
`;
