import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import store from "./store";
import "./App.css";
import AppRoutes from "../routes";
import { ConfigProvider } from "antd";
import ja_JP from "antd/es/locale/ja_JP";
const queryClient = new QueryClient();

function App() {
  return (
    <ReduxProvider store={store}>
      <ConfigProvider locale={ja_JP}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <AppRoutes />
        </QueryClientProvider>
      </ConfigProvider>
    </ReduxProvider>
  );
}

export default App;
