import { ICellRendererParams } from "ag-grid-community";

import { Subcontractors } from "@bms/types";
import { formatTel } from "@bms/utility";

export const columns = () => {
  return [
    {
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      maxWidth: 40,
      floatingFilter: false,
    },
    {
      headerName: "No.",
      field: "id",
      maxWidth: 60,
      floatingFilter: false,
    },
    {
      headerName: "取引業者名",
      field: "trader_name",
      maxWidth: 300,
    },
    {
      headerName: "電話番号",
      field: "phone_number",
      maxWidth: 300,
      cellRenderer: (e: ICellRendererParams<Subcontractors>) => (
        <>{formatTel(e.value)}</>
      ),
    },
    {
      headerName: "FAX",
      field: "fax",
      cellRenderer: (e: ICellRendererParams<Subcontractors>) => (
        <>{formatTel(e.value)}</>
      ),
    },
  ];
};

export const SORT_PARAMS = {
  id: "sort_index",
  type_name: "sort_trader_name",
  phone_number: "sort_phone_number",
  fax: "sort_fax",
};
