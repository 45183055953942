import { FC } from "react";
import Pagination from "@mui/material/Pagination";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";

import { Trash, ArrowLeft, ArrowRight, ArrowDown } from "@bms/assets";
import { Styles, TableWrapper, SelectWrapper } from "./styled";
import useTable, {
  FLOAT_FILTER_HEIGHT,
  HEADER_HEIGHT,
  Props,
  ReceivedProps,
  ROW_HEIGHT,
} from "./hook";

const TableLayout: FC<Props> = ({
  gridRef,
  rowData,
  columnDefs,
  loading,
  pageSize,
  containerStyle,
  gridStyle,
  defaultColDef,
  isFistPage,
  isLastPage,
  page,
  tableHeader,
  total,
  isShowDelete,
  rowSelectionType = "multiple",
  setPage,
  onOpenModalDelete,
  calculatePagesCount,
  setPageSize,
  // isFullWidthRow,
  onVirtualColumnsChanged,
  onGridReady,
  onListenRowSelected,
  onFilterUpdate,
  onSortChanged,
  onDragStopped,
  enableCellTextSelection,
  rowHeightChange,
  floatFilterHeightChange,
  headerHeightChange,
  ...props
}) => {
  return (
    <Styles>
      <div className="table-inner">
        {tableHeader}

        <div style={containerStyle}>
          <div style={gridStyle} className="ag-theme-alpine">
            <TableWrapper
              localeText={{
                noRowsToShow: "データがありません。",
              }}
              headerHeight={headerHeightChange || HEADER_HEIGHT}
              floatingFiltersHeight={
                floatFilterHeightChange || FLOAT_FILTER_HEIGHT
              }
              rowHeight={rowHeightChange || ROW_HEIGHT}
              ref={gridRef}
              rowData={rowData}
              rowSelection={rowSelectionType}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              getRowNodeId={(e: any) => e.id}
              onSortChanged={onSortChanged}
              onFilterChanged={onFilterUpdate}
              onGridReady={onGridReady}
              onDragStopped={onDragStopped}
              onSelectionChanged={onListenRowSelected}
              onVirtualColumnsChanged={onVirtualColumnsChanged}
              enableCellTextSelection={enableCellTextSelection}
              loadingOverlayComponent={() => (
                <Box
                  className="spin"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress
                    size={26}
                    style={{
                      color: "#174a84",
                    }}
                  />
                </Box>
              )}
              {...props}
            />
          </div>
        </div>
      </div>

      <div className="footer">
        <div className="btn-delete">
          {isShowDelete && <img src={Trash} onClick={onOpenModalDelete} />}
        </div>
        <div className="pagination">
          <div className="select">
            <div>表示: </div>
            <SelectWrapper
              disabled={loading}
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
                setPage(1);
              }}
              IconComponent={() => (
                <ArrowDown stroke={loading ? "#777777" : "#000000"} />
              )}
            >
              {[10, 50, 100, 150].map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </SelectWrapper>
          </div>

          {!!calculatePagesCount() && total > pageSize && (
            <div className="btn-paging">
              <button
                onClick={() => {
                  if (page === 1) return;
                  setPage(page - 1);
                }}
                className="btn-prev"
              >
                <ArrowLeft stroke={!isFistPage ? "#7D8185" : "#BEBEBE"} />
              </button>
              <div className="group-paging">
                <Pagination
                  className="paging"
                  page={page}
                  count={calculatePagesCount()}
                  disabled={loading}
                  onChange={(e, page) => {
                    e.preventDefault();
                    setPage(page);
                  }}
                />
              </div>
              <button
                onClick={() => {
                  if (page === calculatePagesCount()) return;
                  setPage(page + 1);
                }}
                className="btn-next"
              >
                <ArrowRight stroke={!isLastPage ? "#7D8185" : "#BEBEBE"} />
              </button>
            </div>
          )}
        </div>
      </div>
    </Styles>
  );
};

const Table: FC<ReceivedProps> = (props) => (
  <TableLayout {...useTable(props)} />
);

export default Table;
