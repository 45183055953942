import { FC } from "react";
import { Controller } from "react-hook-form";
import {
  Checkbox,
  FormControlLabel,
  InputLabel,
  TextField,
  FormControl,
  Box,
  FormGroup,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

import { DatePickerIcon2 } from "@bms/assets";
import { DATE_TIME_FORMAT, LOCALE_JP } from "@bms/constants";
import useOutputSearch, { Props, ReceivedProps } from "./hook";

const OutputSearchLayout: FC<Props> = ({
  control,
  handleCheck,
  onSubmit,
  handleSubmit,
  getValues,
  setValue,
  register,
}) => {
  return (
    <Box
      style={{
        display: "flex",
        alignItems: "flex-end",
        gap: "20px",
      }}
    >
      <Box className="form-search">
        <div className="form-search-container">
          <div className="input-field">
            <InputLabel>範囲指定</InputLabel>
            <div className="date-range-picker">
              <Controller
                name="start_date"
                control={control}
                defaultValue={null}
                render={() => (
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={LOCALE_JP}
                  >
                    <FormControl variant="outlined">
                      <MobileDatePicker
                        {...register("start_date")}
                        onClose={() => setValue("start_date", null)}
                        onAccept={(e) => setValue("start_date", e)}
                        value={getValues("start_date")}
                        inputFormat={DATE_TIME_FORMAT.DATE_JP}
                        onChange={(e) => setValue("start_date", e)}
                        components={{
                          ActionBar: ({ onCancel, onAccept }: any) => (
                            <div className="MuiDialogActions-root">
                              <button onClick={onCancel}>クリア</button>
                              <button onClick={onAccept}>OK</button>
                            </div>
                          ),
                        }}
                        renderInput={(params: any) => (
                          <OutlinedInput
                            placeholder="範囲を指定する"
                            {...params}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton edge="end">
                                  <img src={DatePickerIcon2} />
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        )}
                      />
                    </FormControl>
                  </LocalizationProvider>
                )}
              />

              <span>~</span>

              <Controller
                name="end_date"
                control={control}
                defaultValue={null}
                render={() => (
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={LOCALE_JP}
                  >
                    <FormControl variant="outlined">
                      <MobileDatePicker
                        {...register("end_date")}
                        onClose={() => setValue("end_date", null)}
                        onAccept={(e) => setValue("end_date", e)}
                        value={getValues("end_date")}
                        inputFormat={DATE_TIME_FORMAT.DATE_JP}
                        onChange={(e) => setValue("end_date", e)}
                        components={{
                          ActionBar: ({ onCancel, onAccept }: any) => (
                            <div className="MuiDialogActions-root">
                              <button onClick={onCancel}>クリア</button>
                              <button onClick={onAccept}>OK</button>
                            </div>
                          ),
                        }}
                        renderInput={(params: any) => (
                          <OutlinedInput
                            placeholder="範囲を指定する"
                            {...params}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton edge="end">
                                  <img src={DatePickerIcon2} />
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        )}
                      />
                    </FormControl>
                  </LocalizationProvider>
                )}
              />
            </div>
          </div>

          <div className="input-field">
            <InputLabel>検索キーワード</InputLabel>
            <TextField {...register("keyword")} name="keyword" />
          </div>

          <div className="input-field">
            <InputLabel>ステータス</InputLabel>
            <Controller
              name="status"
              control={control}
              defaultValue={["請求済のみ"]}
              render={(props: any) => (
                <FormGroup className="checkbox-wrapper">
                  {["請求済のみ", "請求金額記載のみ", "すべて"].map(
                    (item, index) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={() =>
                              props.field.onChange(handleCheck(item, "status"))
                            }
                            // defaultChecked={getValues("status")?.includes(item)}
                            defaultChecked={item === "請求済のみ"}
                          />
                        }
                        key={index}
                        label={item}
                      />
                    )
                  )}
                </FormGroup>
              )}
            />
          </div>

          <div className="input-field">
            <InputLabel>請求先</InputLabel>
            <Controller
              name="address_payment_type"
              control={control}
              defaultValue={["all"]}
              render={(props: any) => (
                <FormGroup className="checkbox-wrapper">
                  {[
                    { value: "all", label: "すべて" },
                    { value: "contractor", label: "契約者" },
                    { value: "tenant", label: "入居者" },
                    { value: "owner", label: "オーナー" },
                    { value: "general", label: "一般" },
                    { value: "nomura_development", label: "野村開発(営業3家)" },
                  ].map((item, index) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={() =>
                            props.field.onChange(
                              handleCheck(item.value, "address_payment_type")
                            )
                          }
                          defaultChecked={item.value === "all"}
                        />
                      }
                      key={index}
                      label={item.label}
                    />
                  ))}
                </FormGroup>
              )}
            />
          </div>
        </div>
      </Box>
      <button
        type="submit"
        className="btn-search"
        onClick={handleSubmit(onSubmit)}
      >
        検索
      </button>
    </Box>
  );
};

const OutputSearch: FC<ReceivedProps> = (props) => (
  <OutputSearchLayout {...useOutputSearch(props)} />
);

export default OutputSearch;
