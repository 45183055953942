import styled from "styled-components";

export const Styles = styled.div`
  &.status {
    border: 1px solid #bcbcbc;
    padding: 10px 19px 7px 19px;
    border-radius: 6px;

    .ant-select {
      border-bottom: 0;
    }
  }

  label {
    font-family: "Inter";
    font-weight: 400;
    font-size: 15px;
    color: #adadad;
    position: absolute;
    top: 4px;
    z-index: 1;

    &.Mui-focused,
    &.MuiInputLabel-shrink {
      top: 0;
    }
  }

  .option-wrapper {
    p {
      background-color: #174a84;
      height: 26px;
      line-height: 26px;
      width: 59px;
      color: #ffffff;
      text-align: center;
      border-radius: 2px;
      margin-top: 2px;
      font-family: "Inter";
      font-weight: 400;
    }

    &.not_compatible,
    &.unclaimed,
    &.not_payment {
      p {
        background-color: #ff0000;
      }
    }
  }

  .ant-select-selector {
    padding-left: 0 !important;
  }

  .field-wrapper {
    display: flex;
    flex-direction: row;
    /* gap: 19px; */
    position: relative;

    .ant-select-arrow {
      display: none;
    }

    .input-field {
      width: 140px;

      &:nth-child(1) {
        margin-left: 50px;

        label {
          left: -40px;
        }
      }

      &:nth-child(2) {
        margin-left: 60px;

        label {
          left: -55px;
        }
      }

      &:nth-child(3) {
        width: 250px;

        label {
          left: 21px;
        }

        .MuiInputBase-root:before,
        .MuiInputBase-root:after {
          border: none;
        }

        input {
          padding-left: 95px;
          font-weight: 400;
          font-size: 15px;
          font-family: "Lato";

          &:focus {
            outline: none;
            box-shadow: none;
          }
        }
      }

      &:nth-child(4) {
        margin-left: 120px;

        label {
          left: -70px;
        }
      }
    }
  }
  .date-picker-input {
    .MuiFormControl-root {
      width: 280px;
    }
    .picker-working-hour {
      border: none;
    }
    .hidden-date-picker .ant-picker-input {
      display: none;
    }
  }
`;
