import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { Note, Pen } from "@bms/assets";
import { Table } from "@bms/components";
import useOutputList, { Props, ReceivedProps } from "./hook";
import { useQueryClient } from "@tanstack/react-query";
import { QUERY_NAME, ROUTES } from "@bms/constants";

const OutputListLayout: FC<Props> = ({
  rowData,
  pageSize,
  gridApi,
  gridRef,
  page,
  columnDefs,
  total,
  isLoading,
  isFetching,
  onSortChanged,
  onFilterUpdate,
  setPage,
  onGridReady,
  setPageSize,
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  if (rowData && rowData.length > 0) {
    columnDefs = [
      ...columnDefs,
      {
        minWidth: 100,
        field: "操作",
        floatingFilter: false,
        cellRenderer: (e: any) => {
          return (
            <div className="group-action">
              <img
                src={Note}
                onClick={() =>
                  navigate(`${ROUTES.SERVICE_CARD}/${e.data.id}`, {
                    state: {
                      view: true,
                    },
                  })
                }
              />
              <img
                src={Pen}
                onClick={() => navigate(`${ROUTES.SERVICE_CARD}/${e.data.id}`)}
              />
            </div>
          );
        },
      },
    ];
  }

  return (
    <>
      <div className="table-header">
        <h3>検索結果</h3>
      </div>

      <Table
        rowSelectionType="multiple"
        total={total}
        page={page}
        pageSize={pageSize}
        gridRef={gridRef}
        rowData={rowData}
        onSortChanged={onSortChanged}
        onFilterUpdate={onFilterUpdate}
        columnDefs={columnDefs}
        disableDeleteButton={true}
        loading={isLoading || isFetching}
        gridApi={gridApi}
        setPage={setPage}
        setPageSize={setPageSize}
        onGridReady={onGridReady}
        onRowDoubleClicked={(e) => {
          queryClient.invalidateQueries([e.data.id]);
          queryClient.invalidateQueries([QUERY_NAME.OUT_PUTS]);
          navigate(ROUTES.SERVICE_CARD + "/" + e.data.id);
        }}
      />
    </>
  );
};

const OutputList: FC<ReceivedProps> = (props) => (
  <OutputListLayout {...useOutputList(props)} />
);

export default OutputList;
