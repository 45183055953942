import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useFetchTaxRate, useUpdateTaxRate } from "@bms/hooks";
import { validation } from "./schema";
import { openNotification } from "@bms/components";

export type ReceivedProps = Record<string, any>;

const useTaxRate = (props: ReceivedProps) => {
  const { data, isSuccess, isLoading } = useFetchTaxRate();
  const { mutate, isLoading: isSubmitting } = useUpdateTaxRate();

  const {
    register,
    reset,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validation()),
  });

  const onSubmit = async (values: Record<string, any>) => {
    mutate(
      {
        payload: values,
        id: getValues("id"),
      },
      {
        onSuccess() {
          openNotification();
        },
      }
    );
  };

  useEffect(() => {
    if (isSuccess) {
      reset(data[0]);
    }
  }, [data, isSuccess, reset]);

  return {
    ...props,
    isLoading,
    isSubmitting,
    errors,
    setValue,
    onSubmit,
    register,
    handleSubmit,
  };
};

export type Props = ReturnType<typeof useTaxRate>;

export default useTaxRate;
