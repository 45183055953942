import moment from "moment";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QUERY_NAME, SERVICES, SORTING } from "@bms/constants";
import axios from "@bms/libs/axios";

export const useFetchWorkersShifts = (
  page: number,
  pageSize: number,
  filterParams: Record<string, any>,
  sortParams: Record<string, any>
) => {
  for (const obj in sortParams) {
    if (sortParams[obj] === SORTING.ASC) {
      delete sortParams[obj];
    }
  }

  return useQuery(
    [QUERY_NAME.WORKERS_SHIFTS, page, pageSize, filterParams, sortParams],
    () =>
      axios({
        method: "GET",
        url: SERVICES.WORKERS_SHIFTS,
        params: {
          page: page,
          per_page: pageSize,
          ...filterParams,
          ...sortParams,
        },
      }).then((res) => res.data || []),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
};

export const useFetchOneWorkerShift = (workerShiftId: string | undefined) => {
  return useQuery(
    [QUERY_NAME.WORKER_SHIFT_ID, workerShiftId],
    () =>
      axios({
        method: "GET",
        url: `${SERVICES.SHIFTS}/${workerShiftId}`,
      }).then((res) => res.data),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!workerShiftId,
    }
  );
};

export const useFetchShiftSchedules = (currentDate: Date) => {
  const firstDay: any = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const lastDay: any = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );

  return useQuery(
    [QUERY_NAME.SHIFT_SCHEDULES, currentDate],
    () =>
      axios({
        method: "GET",
        url: SERVICES.SHIFT_SCHEDULES,
        params: {
          start_date: moment(firstDay).format("DD/MM/YYYY"),
          end_date: moment(lastDay).format("DD/MM/YYYY"),
        },
      }).then((res) => res.data),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!currentDate,
    }
  );
};

export const useFetchOneShiftSchedule = (
  startDate: string | undefined,
  endDate: string | undefined
) => {
  return useQuery(
    [QUERY_NAME.SHIFT_SCHEDULES, startDate, endDate],
    () =>
      axios({
        method: "GET",
        url: `${SERVICES.SHIFT_SCHEDULES}`,
        params: {
          start_date: startDate,
          end_date: endDate,
        },
      }).then((res) => res.data),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!startDate && !!endDate,
    }
  );
};

export const useDeleteShiftWorker = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ payload }: any) => {
      return axios({
        method: "DELETE",
        url: `${SERVICES.SHIFTS}/delete`,
        data: {
          shift_attributes: payload,
        },
      });
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([QUERY_NAME.WORKERS_SHIFTS]);
      },
    }
  );
};

export const useDeleteShift = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      // selectedDate,
      workerId,
      leaveRequestId,
    }: any) => {
      return axios({
        method: "DELETE",
        url: `workers/${workerId}/leave_requests/${leaveRequestId}`,
        // params: {
        //   selected_date: selectedDate,
        // },
      });
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([QUERY_NAME.SHIFT_SCHEDULES]);
      },
    }
  );
};

export const useSubmitShift = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ payload, shiftId }: any) =>
      axios({
        method: shiftId ? "PUT" : "POST",
        url: shiftId ? `${SERVICES.SHIFTS}/${shiftId}` : SERVICES.SHIFTS,
        data: payload,
      }),
    {
      onSuccess() {
        queryClient.invalidateQueries([QUERY_NAME.WORKERS_SHIFTS]);
        queryClient.invalidateQueries([QUERY_NAME.WORKER_SHIFT_ID]);
      },
    }
  );
};

export const useUpdateDayWorking = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ worker_ids, date_on }: any) =>
      axios({
        method: "DELETE",
        url: `workers/delete_working_days`,
        params: {
          worker_ids,
          date_on,
        },
      }),
    {
      onSuccess() {
        queryClient.invalidateQueries([QUERY_NAME.SHIFT_SCHEDULES]);
      },
    }
  );
};
