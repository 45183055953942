import { FC, memo, useEffect } from "react";
import {
  Outlet,
  RouterProvider,
  createBrowserRouter,
  useLocation,
  useRoutes,
} from "react-router-dom";
import { useSelector } from "react-redux";

import {
  ADMIN_ID,
  PERMISSION_OPTIONS,
  PERMISSION,
  ROUTES,
} from "@bms/constants";
import {
  Buildings,
  Receptions,
  Payments,
  SubContractors,
  Workers,
  ReceptionControl,
  PaymentControl,
  SubContractorControl,
  WorkerControl,
  Holidays,
  Shifts,
  ShiftControl,
  Calendar,
  TaxRate,
  ServiceCardsList,
  ServiceCardControl,
  Schedule,
  DashBoard,
  ServiceCardTicket,
  ServiceCardTicketDetail,
  WorkReport,
  WorkingHours,
  WorkCost,
  Memo,
  RequestContent,
  Output,
} from "@bms/pages";
import { Login } from "@bms/pages";
import { CommonLayout, Loading } from "@bms/components";
import {
  loggedInUserSelector,
  userSelector,
} from "@bms/features/auth/selector";
import { useReAuthentication, useWindowSize } from "@bms/hooks";
import ProtectedRoute from "./ProtectRoute";
import Redirect from "./Redirect";
import { get, isEmpty } from "lodash";

const NotFoundPage: FC = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <p>There is nothing here !</p>
    </div>
  );
};

const AppRoutes: FC = () => {
  const currentUser = useSelector(loggedInUserSelector);
  const user = useSelector(userSelector);

  const isAdmin = user?.login_id === ADMIN_ID;
  const permissions = isAdmin
    ? PERMISSION_OPTIONS.map((item) => item.access_type).filter(
        (obj) => obj !== "access_all"
      )
    : user?.worker?.permissions
        ?.map((item: any) => item.access_type)
        ?.filter((obj: any) => obj !== "access_all");

  // const { pathname } = useLocation();
  const { loading } = useReAuthentication();
  const isMobile = useWindowSize();

  // useEffect(() => {
  //   window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  // }, [pathname]);

  const handleActiveRouter = (router: string) => {
    return permissions?.includes(router);
  };

  const routerAdmin = createBrowserRouter([
    {
      path: "*",
      element: <NotFoundPage />,
    },
    {
      ...(isMobile && {
        path: ROUTES.HOME,
        element: <Redirect to={ROUTES.DASHBOARD} />,
      }),
    },
    {
      ...(!isEmpty(permissions) && {
        path: ROUTES.HOME,
        element: <Redirect to={get(ROUTES, permissions[0].toUpperCase())} />,
      }),
    },
    // {
    //   path: ROUTES.HOME,
    //   element: <Redirect to={isMobile ? ROUTES.DASHBOARD : ROUTES.HOME} />,
    // },
    {
      path: ROUTES.HOME,
      element: (
        <ProtectedRoute condition={currentUser} redirectTo={ROUTES.SIGNIN}>
          <CommonLayout />
        </ProtectedRoute>
      ),
      //TODO:
      children: [
        {
          path: ROUTES.DASHBOARD,
          element: (
            <ProtectedRoute condition={isMobile} redirectTo={ROUTES.HOME}>
              <DashBoard />
            </ProtectedRoute>
          ),
        },
        {
          path: ROUTES.MY_SERVICE_CARD_TICKET,
          children: [
            {
              path: "",
              element: (
                <ProtectedRoute condition={isMobile} redirectTo={ROUTES.HOME}>
                  <ServiceCardTicket />
                </ProtectedRoute>
              ),
            },
            {
              path: ROUTES.ID,
              element: (
                <ProtectedRoute condition={isMobile} redirectTo={ROUTES.HOME}>
                  <ServiceCardTicketDetail />
                </ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: ROUTES.MY_SCHEDULE,
          children: [
            {
              path: ROUTES.ID,
              element: (
                <ProtectedRoute condition={isMobile} redirectTo={ROUTES.HOME}>
                  <Schedule />
                </ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: ROUTES.OTHER_SERVICE_CARD_TICKET,
          children: [
            {
              path: "",
              element: (
                <ProtectedRoute condition={isMobile} redirectTo={ROUTES.HOME}>
                  <ServiceCardTicket />
                </ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: ROUTES.WORKER_REPORT,
          children: [
            {
              path: "",
              children: [
                {
                  path: ROUTES.ID,
                  element: (
                    <ProtectedRoute
                      condition={isMobile}
                      redirectTo={ROUTES.HOME}
                    >
                      <WorkReport />
                    </ProtectedRoute>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: ROUTES.WORKING_HOURS,
          children: [
            {
              path: ROUTES.ID,
              element: (
                <ProtectedRoute condition={isMobile} redirectTo={ROUTES.HOME}>
                  <WorkingHours />
                </ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: ROUTES.WORK_COST,
          children: [
            {
              path: ROUTES.ID,
              element: (
                <ProtectedRoute condition={isMobile} redirectTo={ROUTES.HOME}>
                  <WorkCost />
                </ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: ROUTES.MEMO,
          children: [
            {
              path: ROUTES.ID,
              element: (
                <ProtectedRoute condition={isMobile} redirectTo={ROUTES.HOME}>
                  <Memo />
                </ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: ROUTES.REQUEST_CONTENT,
          children: [
            {
              path: ROUTES.ID,
              element: (
                <ProtectedRoute condition={isMobile} redirectTo={ROUTES.HOME}>
                  <RequestContent />
                </ProtectedRoute>
              ),
            },
          ],
        },

        //
        {
          path: ROUTES.SERVICE_CARD,
          children: [
            {
              path: "",
              element: (
                <ProtectedRoute
                  condition={
                    !isMobile && handleActiveRouter(PERMISSION.SERVICE_CARD)
                  }
                  redirectTo={ROUTES.HOME}
                >
                  <ServiceCardsList />
                </ProtectedRoute>
              ),
            },
            {
              path: `${ROUTES.CREATE}/${ROUTES.ID}`,
              element: (
                <ProtectedRoute
                  condition={
                    !isMobile && handleActiveRouter(PERMISSION.SERVICE_CARD)
                  }
                  redirectTo={ROUTES.HOME}
                >
                  <ServiceCardControl />
                </ProtectedRoute>
              ),
            },
            {
              path: ROUTES.ID,
              element: (
                <ProtectedRoute
                  condition={
                    !isMobile && handleActiveRouter(PERMISSION.SERVICE_CARD)
                  }
                  redirectTo={ROUTES.HOME}
                >
                  <ServiceCardControl />
                </ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: ROUTES.CLEANING,
          element: <>定期●掃</>,
        },
        {
          path: ROUTES.SCHEDULE,
          element: (
            <ProtectedRoute
              condition={!isMobile && handleActiveRouter(PERMISSION.SCHEDULE)}
              redirectTo={ROUTES.HOME}
            >
              <Schedule />
            </ProtectedRoute>
          ),
        },
        {
          path: ROUTES.SHIFT,
          children: [
            {
              path: "",
              element: (
                <ProtectedRoute
                  condition={!isMobile && handleActiveRouter(PERMISSION.SHIFT)}
                  redirectTo={ROUTES.HOME}
                >
                  <Shifts />
                </ProtectedRoute>
              ),
            },
            {
              path: ROUTES.ID,
              element: (
                <ProtectedRoute
                  condition={!isMobile && handleActiveRouter(PERMISSION.SHIFT)}
                  redirectTo={ROUTES.HOME}
                >
                  <ShiftControl />
                </ProtectedRoute>
              ),
            },
            {
              path: ROUTES.CREATE,
              element: (
                <ProtectedRoute
                  condition={!isMobile && handleActiveRouter(PERMISSION.SHIFT)}
                  redirectTo={ROUTES.HOME}
                >
                  <ShiftControl />
                </ProtectedRoute>
              ),
            },
            {
              path: ROUTES.CALENDAR,
              element: (
                <ProtectedRoute
                  condition={!isMobile && handleActiveRouter(PERMISSION.SHIFT)}
                  redirectTo={ROUTES.HOME}
                >
                  <Calendar />
                </ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: ROUTES.HOLIDAY,
          element: (
            <ProtectedRoute
              condition={!isMobile && handleActiveRouter(PERMISSION.HOLIDAY)}
              redirectTo={ROUTES.HOME}
            >
              <Holidays />
            </ProtectedRoute>
          ),
        },
        {
          path: ROUTES.OPERATOR,
          children: [
            {
              path: "",
              element: (
                <ProtectedRoute
                  condition={
                    !isMobile && handleActiveRouter(PERMISSION.OPERATOR)
                  }
                  redirectTo={ROUTES.HOME}
                >
                  <Workers />
                </ProtectedRoute>
              ),
            },
            {
              path: ROUTES.ID,
              element: (
                <ProtectedRoute
                  condition={
                    !isMobile && handleActiveRouter(PERMISSION.OPERATOR)
                  }
                  redirectTo={ROUTES.HOME}
                >
                  <WorkerControl />
                </ProtectedRoute>
              ),
            },
            {
              path: ROUTES.CREATE,
              element: (
                <ProtectedRoute
                  condition={
                    !isMobile && handleActiveRouter(PERMISSION.OPERATOR)
                  }
                  redirectTo={ROUTES.HOME}
                >
                  <WorkerControl />
                </ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: ROUTES.PROPERTY,
          element: (
            <ProtectedRoute
              condition={!isMobile && handleActiveRouter(PERMISSION.PROPERTY)}
              redirectTo={ROUTES.HOME}
            >
              <Buildings />
            </ProtectedRoute>
          ),
        },
        // {
        //   path: ROUTES.MERCHANDISE,
        //   element: (
        //     <ProtectedRoute
        //       condition={
        //         !isMobile && handleActiveRouter(PERMISSION.MERCHANDISE)
        //       }
        //       redirectTo={ROUTES.HOME}
        //     >
        //       商品
        //     </ProtectedRoute>
        //   ),
        // },
        {
          path: ROUTES.RECEPTION,
          children: [
            {
              path: "",
              element: (
                <ProtectedRoute
                  condition={
                    !isMobile && handleActiveRouter(PERMISSION.RECEPTION)
                  }
                  redirectTo={ROUTES.HOME}
                >
                  <Receptions />
                </ProtectedRoute>
              ),
            },
            {
              path: ROUTES.ID,
              element: (
                <ProtectedRoute
                  condition={
                    !isMobile && handleActiveRouter(PERMISSION.RECEPTION)
                  }
                  redirectTo={ROUTES.HOME}
                >
                  <ReceptionControl />
                </ProtectedRoute>
              ),
            },
            {
              path: ROUTES.CREATE,
              element: (
                <ProtectedRoute
                  condition={
                    !isMobile && handleActiveRouter(PERMISSION.RECEPTION)
                  }
                  redirectTo={ROUTES.HOME}
                >
                  <ReceptionControl />
                </ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: ROUTES.PAYMENT,
          children: [
            {
              path: "",
              element: (
                <ProtectedRoute
                  condition={
                    !isMobile && handleActiveRouter(PERMISSION.PAYMENT)
                  }
                  redirectTo={ROUTES.HOME}
                >
                  <Payments />
                </ProtectedRoute>
              ),
            },
            {
              path: ROUTES.ID,
              element: (
                <ProtectedRoute
                  condition={
                    !isMobile && handleActiveRouter(PERMISSION.PAYMENT)
                  }
                  redirectTo={ROUTES.HOME}
                >
                  <PaymentControl />
                </ProtectedRoute>
              ),
            },
            {
              path: ROUTES.CREATE,
              element: (
                <ProtectedRoute
                  condition={
                    !isMobile && handleActiveRouter(PERMISSION.PAYMENT)
                  }
                  redirectTo={ROUTES.HOME}
                >
                  <PaymentControl />
                </ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: ROUTES.SUBCONTRACTOR,
          children: [
            {
              path: "",
              element: (
                <ProtectedRoute
                  condition={
                    !isMobile && handleActiveRouter(PERMISSION.SUBCONTRACTOR)
                  }
                  redirectTo={ROUTES.HOME}
                >
                  <SubContractors />
                </ProtectedRoute>
              ),
            },
            {
              path: ROUTES.ID,
              element: (
                <ProtectedRoute
                  condition={
                    !isMobile && handleActiveRouter(PERMISSION.SUBCONTRACTOR)
                  }
                  redirectTo={ROUTES.HOME}
                >
                  <SubContractorControl />
                </ProtectedRoute>
              ),
            },
            {
              path: ROUTES.CREATE,
              element: (
                <ProtectedRoute
                  condition={
                    !isMobile && handleActiveRouter(PERMISSION.SUBCONTRACTOR)
                  }
                  redirectTo={ROUTES.HOME}
                >
                  <SubContractorControl />
                </ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: ROUTES.TAX_RATE,
          element: (
            <ProtectedRoute
              condition={!isMobile && handleActiveRouter(PERMISSION.TAX_RATE)}
              redirectTo={ROUTES.HOME}
            >
              <TaxRate />
            </ProtectedRoute>
          ),
        },
        {
          path: ROUTES.OUTPUT,
          element: (
            <ProtectedRoute
              condition={!isMobile && handleActiveRouter(PERMISSION.OUTPUT)}
              redirectTo={ROUTES.HOME}
            >
              <Output />
            </ProtectedRoute>
          ),
        },
        // {
        //   path: ROUTES.INVOICE,
        //   element: (
        //     <ProtectedRoute
        //       condition={!isMobile && handleActiveRouter(PERMISSON.INVOICE)}
        //       redirectTo={ROUTES.HOME}
        //     >
        //       請求書
        //     </ProtectedRoute>
        //   ),
        // },
        // {
        //   path: ROUTES.REPORT,
        //   element: (
        //     <ProtectedRoute
        //       condition={!isMobile && handleActiveRouter(PERMISSON.REPORT)}
        //       redirectTo={ROUTES.HOME}
        //     >
        //       報告書
        //     </ProtectedRoute>
        //   ),
        // },
      ],
    },
    {
      path: ROUTES.HOME,
      element: (
        <ProtectedRoute condition={!currentUser} redirectTo={ROUTES.HOME}>
          <Outlet />
        </ProtectedRoute>
      ),
      children: [{ path: ROUTES.SIGNIN, element: <Login /> }],
    },
  ]);

  if (loading) return <Loading />;

  return <RouterProvider router={routerAdmin} />;
};

export default memo(AppRoutes);
