import { useState } from "react";

const useHolidays = () => {
  const [isEdit, setIsEdit] = useState<boolean>(false);

  return {
    isEdit,
    setIsEdit,
  };
};

export type Props = ReturnType<typeof useHolidays>;

export default useHolidays;
