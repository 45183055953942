import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { isEmpty, orderBy, pick } from "lodash";
import { useFieldArray, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";

import { userSelector } from "@bms/features/auth/selector";
import { Messages } from "@bms/types";
import {
  useFetchMessages,
  useFetchOneServiceCard,
  useSendMessage,
} from "@bms/hooks";
import { openNotification } from "@bms/components";
import { QUERY_NAME } from "@bms/constants";
import { useQueryClient } from "@tanstack/react-query";

export type ReceivedProps = Record<string, any>;

const useMemoNotice = (props: ReceivedProps) => {
  const ref: any = useRef();
  const { search } = useLocation();
  const user = useSelector(userSelector);
  const serviceCardId = search.split("=")?.[1];
  const queryClient = useQueryClient();

  const [messages, setMessages] = useState<Messages[]>([]);

  const { data: serviceCardData } = useFetchOneServiceCard(serviceCardId);
  const { isLoading, isFetching, isSuccess, data } = useFetchMessages(
    serviceCardData?.memo?.id,
    serviceCardId
  );
  const { mutate: sendMessage, isLoading: isLoadingButton } = useSendMessage();
  const { control, watch, setValue, getValues } = useForm({});
  const { fields, append, remove } = useFieldArray({
    control: control,
    name: "memo_images",
  });

  const onSubmit = async () => {
    const payload = pick(getValues(), ["message", "memo_images"]);
    const formData = new FormData();
    const parsePayload: any = {
      ...(serviceCardData?.memo?.id && {
        memo_id: serviceCardData?.memo?.id,
      }),
      has_notification: true,
      service_card_id: serviceCardId,
      worker_id: user?.worker?.id,
      body: payload?.message || "",
    };

    Object.keys(parsePayload).forEach((item: any) => {
      formData.append(`${item}`, parsePayload?.[item]);
    });

    if (!isEmpty(payload?.memo_images)) {
      (payload?.memo_images as any).forEach((item: any) => {
        formData.append(`images[]`, item?.image);
      });
    }

    sendMessage(
      {
        payload: formData,
      },
      {
        onSuccess: () => {
          openNotification();
          // setMessages(orderBy([...messages, res.data], (obj) => obj.id, "asc"));
          setValue("message", "");
          setValue("memo_images", []);
          queryClient.invalidateQueries([QUERY_NAME.MESSAGES]);
        },
      }
    );
  };

  useEffect(() => {
    setMessages(orderBy(data, (obj) => obj.id, "asc"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    const element: any = document.getElementById("memo");
    ref?.current?.focus();
    element.scroll({ top: element.scrollHeight, behavior: "smooth" });
  }, [messages]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [isSuccess, isLoading, isFetching]);

  return {
    ...props,
    user,
    isLoading,
    isFetching,
    ref,
    messages,
    isLoadingButton,
    isSuccess,
    fields,
    append,
    remove,
    setValue,
    watch,
    onSubmit,
  };
};

export type Props = ReturnType<typeof useMemoNotice>;

export default useMemoNotice;
