import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Note, Pen, CopyIcon, Plus } from "@bms/assets";

import { ModalConfirm, ModalDelete, Table } from "@bms/components";
import useServiceCardsList, { Props, ReceivedProps } from "./hook";
import { Styles } from "./styled";
import { QUERY_NAME, ROUTES } from "@bms/constants";
import { useQueryClient } from "@tanstack/react-query";

const ServiceCardsListLayout: FC<Props> = ({
  isLoading,
  isFetching,
  rowData,
  pageSize,
  gridRef,
  gridApi,
  page,
  isOpenModalDelete,
  isSubmitting,
  total,
  columnDefs,
  isOpenCopyDialog,
  btnActive,
  setBtnActive,
  setServiceCardId,
  setIsOpenCopyDialog,
  onCopy,
  getDataToday,
  onCreateServiceCard,
  clearFilter,
  onSortChanged,
  onFilterUpdate,
  onDeleteRecord,
  onOpenModalDelete,
  onCloseModalDelete,
  setPage,
  onGridReady,
  setPageSize,
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  if (rowData && rowData.length > 0) {
    columnDefs = [
      ...columnDefs,
      {
        minWidth: 120,
        field: "動作",
        floatingFilter: false,
        cellRenderer: (e: any) => {
          return (
            <div className="group-action">
              <img
                src={Note}
                onClick={() => {
                  queryClient.invalidateQueries([e.data.id]);
                  queryClient.invalidateQueries([QUERY_NAME.SERVICE_CARDS_ID]);
                  navigate(`${e.data.id}`, {
                    state: {
                      view: true,
                    },
                  });
                }}
              />
              <img
                src={CopyIcon}
                onClick={(event) => {
                  event.preventDefault();
                  setServiceCardId(e.data.id);
                  setIsOpenCopyDialog(true);
                }}
              />
              <img
                src={Pen}
                onClick={() => {
                  queryClient.invalidateQueries([e.data.id]);
                  queryClient.invalidateQueries([QUERY_NAME.SERVICE_CARDS_ID]);
                  navigate(`${e.data.id}`);
                }}
              />
            </div>
          );
        },
      },
    ];
  }

  return (
    <Styles>
      <div className="header">
        <h3>サービスカード一覧</h3>
        <div className="group-btn">
          <button
            className="btn-refresh"
            onClick={() => window.location.reload()}
          >
            <p>更新</p>
          </button>
          <button onClick={onCreateServiceCard}>
            <p>サービスカード追加</p>
            <img src={Plus} />
          </button>
        </div>
      </div>

      <div className="table-wrapper">
        <Table
          total={total}
          page={page}
          pageSize={pageSize}
          gridRef={gridRef}
          rowData={rowData}
          onSortChanged={onSortChanged}
          onFilterUpdate={onFilterUpdate}
          columnDefs={columnDefs}
          height={"100vh"}
          // height={660}
          rowHeightChange={52}
          floatFilterHeightChange={44}
          headerHeightChange={54}
          loading={isLoading || isFetching}
          gridApi={gridApi}
          setPage={setPage}
          setPageSize={setPageSize}
          onOpenModalDelete={onOpenModalDelete}
          tableHeader={
            <div className="btn-group">
              <button
                className={`btn-clear ${btnActive === 1 ? "btn-active" : ""}`}
                onClick={() => {
                  clearFilter();
                  setBtnActive(1);
                }}
              >
                すべて
              </button>
              <button
                className={`btn-outline ${btnActive === 2 ? "btn-active" : ""}`}
                onClick={() => {
                  getDataToday();
                  setBtnActive(2);
                }}
              >
                本日訪問日
              </button>
            </div>
          }
          onGridReady={onGridReady}
          onRowDoubleClicked={(e) => {
            queryClient.invalidateQueries([e.data.id]);
            queryClient.invalidateQueries([QUERY_NAME.SERVICE_CARDS_ID]);
            navigate(ROUTES.SERVICE_CARD + "/" + e.data.id);
          }}
        />
      </div>

      <ModalConfirm
        isOpen={isOpenCopyDialog}
        isSubmitting={false}
        content="コピーしますか？"
        onOk={onCopy}
        onClose={() => {
          setIsOpenCopyDialog(false);
          setServiceCardId(null);
        }}
        className="custom-title"
      />

      <ModalDelete
        isOpen={isOpenModalDelete}
        isSubmitting={isSubmitting}
        onDeleteRecord={onDeleteRecord}
        onClose={onCloseModalDelete}
      />
    </Styles>
  );
};

const ServiceCardsList: FC<ReceivedProps> = (props) => (
  <ServiceCardsListLayout {...useServiceCardsList(props)} />
);

export default ServiceCardsList;
