export const columns = () => {
  return [
    {
      field: "index",
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      maxWidth: 40,
      floatingFilter: false,
      sort_index: 1,
    },
    {
      headerName: "建物ID",
      field: "building_id",
      minWidth: 100,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 2,
    },
    {
      headerName: "物件管理番号",
      field: "management_number",
      minWidth: 200,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 3,
    },
    {
      headerName: "建物名",
      field: "building_name",
      minWidth: 350,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 4,
    },
    {
      headerName: "建物名カナ",
      field: "building_name_kana",
      minWidth: 350,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 5,
    },
    {
      headerName: "建物_所在地_郵便番号_上3桁",
      field: "building_zip_code_firt_3_digits",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 6,
    },
    {
      headerName: "建物_所在地_郵便番号_下4桁",
      field: "building_zip_code_last_4_digits",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 7,
    },
    {
      headerName: "建物_所在地_都道府県名",
      field: "building_prefecture_name",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 8,
    },
    {
      headerName: "建物_所在地_市区町村名",
      field: "building_municipality_name",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 9,
    },
    {
      headerName: "建物_所在地_町域名",
      field: "building_town_name",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 10,
    },
    {
      headerName: "建物_所在地_丁目",
      field: "building_district",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 11,
    },
    {
      headerName: "建物_所在地_番地",
      field: "building_street_address",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 12,
    },
    {
      headerName: "建物仮称",
      field: "building_tentative_name",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 13,
    },
    {
      headerName: "家主ID",
      field: "owner_id",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 14,
    },
    {
      headerName: "家主名",
      field: "owner_name",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 15,
    },
    {
      headerName: "家主名_フリガナ",
      field: "owner_name_furigana",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 16,
    },
    {
      headerName: "家主管理番号",
      field: "owner_management_number",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 17,
    },
    {
      headerName: "家主_連絡先1_宛名",
      field: "owner_address",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 18,
    },
    {
      headerName: "家主_連絡先1_連絡先住所_郵便番号_上3桁",
      field: "owner_zip_code_firt_3_digits",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 19,
    },
    {
      headerName: "家主_連絡先1_連絡先住所_郵便番号_下4桁",
      field: "owner_zip_code_last_4_digits",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 20,
    },
    {
      headerName: "家主_連絡先1_連絡先住所_都道府県名",
      field: "owner_prefecture_name",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 21,
    },
    {
      headerName: "家主_連絡先1_連絡先住所_市区町村名",
      field: "owner_municipality_name",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 22,
    },
    {
      headerName: "家主_連絡先1_連絡先住所_町域名",
      field: "owner_town_name",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 23,
    },
    {
      headerName: "家主_連絡先1_連絡先住所_番地",
      field: "owner_district_name",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 24,
    },
    {
      headerName: "家主_連絡先1_連絡先住所_マンション名",
      field: "owner_apartment_name",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 25,
    },
    {
      headerName: "家主_連絡先1_TEL",
      field: "owner_tel",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 26,
    },
    {
      headerName: "家主_連絡先1_携帯TEL",
      field: "owner_mobile_phone",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 27,
    },
    {
      headerName: "管理パターン",
      field: "management_pattern",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 28,
    },
    {
      headerName: "システム区分",
      field: "system_division",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 29,
    },
    {
      headerName: "部屋番号",
      field: "resident_room_number",
      minWidth: 100,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 30,
    },
    {
      headerName: "契約者_名称",
      field: "contractor_name",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 31,
    },
    {
      headerName: "契約者ID",
      field: "contractor_id",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 32,
    },
    {
      headerName: "入居者_名称",
      field: "resident_name",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 33,
    },
    {
      headerName: "入居者ID",
      field: "resident_id",
      minWidth: 100,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 34,
    },
    {
      headerName: "入居日",
      field: "contractor_move_in_date",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 35,
    },
    {
      headerName: "契約者_連絡先1_宛名",
      field: "contractor_address",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 36,
    },
    {
      headerName: "契約者_連絡先1_連絡先住所_郵便番号_上3桁",
      field: "contractor_zip_code_firt_3_digits",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 37,
    },
    {
      headerName: "契約者_連絡先1_連絡先住所_郵便番号_下4桁",
      field: "contractor_zip_code_last_4_digits",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 38,
    },
    {
      headerName: "契約者_連絡先1_連絡先住所_都道府県名",
      field: "contractor_prefecture_name",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 39,
    },
    {
      headerName: "契約者_連絡先1_連絡先住所_市区町村名",
      field: "contractor_municipality_name",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 40,
    },
    {
      headerName: "契約者_連絡先1_連絡先住所_町域名",
      field: "contractor_town_name",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 41,
    },
    {
      headerName: "契約者_連絡先1_連絡先住所_番地",
      field: "contractor_district_name",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 42,
    },
    {
      headerName: "契約者_連絡先1_連絡先住所_マンション名",
      field: "contractor_apartment_name",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 43,
    },
    {
      headerName: "契約者_連絡先1_TEL",
      field: "contractor_tel",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 44,
    },
    {
      headerName: "契約者_連絡先1_携帯TEL",
      field: "contractor_mobile_phone",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 45,
    },
    {
      headerName: "入居者_連絡先1_宛名",
      field: "resident_address",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 46,
    },
    {
      headerName: "入居者_連絡先1_連絡先住所_郵便番号_上3桁",
      field: "resident_zip_code_firt_3_digits",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 47,
    },
    {
      headerName: "入居者_連絡先1_連絡先住所_郵便番号_下4桁",
      field: "resident_zip_code_last_4_digits",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 48,
    },
    {
      headerName: "入居者_連絡先1_連絡先住所_都道府県名",
      field: "resident_prefecture_name",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 49,
    },
    {
      headerName: "入居者_連絡先1_連絡先住所_市区町村名",
      field: "resident_municipality_name",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 50,
    },
    {
      headerName: "入居者_連絡先1_連絡先住所_町域名",
      field: "resident_town_name",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 51,
    },
    {
      headerName: "入居者_連絡先1_連絡先住所_番地",
      field: "resident_district_name",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 52,
    },
    {
      headerName: "入居者_連絡先1_連絡先住所_マンション名",
      field: "resident_apartment_name",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 53,
    },
    {
      headerName: "入居者_連絡先1_TEL",
      field: "resident_tel",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 54,
    },
    {
      headerName: "入居者_連絡先1_携帯TEL",
      field: "resident_mobile_phone",
      minWidth: 250,
      unSortIcon: true,
      filter: "agTextColumnFilter",
      sort_index: 55,
    },
  ];
};
