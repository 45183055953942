import styled from "styled-components";

export const Styles = styled.div`
  &.order-information-wrapper {
    .title {
      margin-top: 27px;
      margin-bottom: 7px;
      font-family: "Noto Sans JP", sans-serif;
      font-weight: 400;
      font-size: 14px;
      color: #000000;
    }

    .main {
      border: 1px solid #bcbcbc;
      padding: 20px 20px 10px 20px;
      border-radius: 6px;

      &:nth-child(3),
      &:nth-child(5) {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 25px;
      }
    }

    .input-field {
      width: 100%;

      .title {
        margin-top: 40px;
      }

      label {
        color: #adadad;
        font-weight: 400;
        font-size: 15px;
        font-family: "Noto Sans JP", sans-serif;
      }

      .MuiFormControl-root {
        width: 100%;
      }

      .MuiOutlinedInput-notchedOutline {
        border-color: #bcbcbc;
        border-width: 1px;

        &:hover {
          border-color: #bcbcbc;
        }
      }
    }

    textarea {
      font-weight: 400;
      font-size: 15px;
      font-family: "Noto Sans JP", sans-serif;
    }

    input {
      font-weight: 400;
      font-size: 15px;
      font-family: "Noto Sans JP", sans-serif;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    label {
      font-weight: 400;
      font-size: 15px;
      font-family: "Noto Sans JP", sans-serif;
      color: #adadad;

      &.Mui-focused,
      &.MuiInputLabel-shrink {
        top: 0 !important;
      }
    }

    .MuiFormLabel-root.Mui-focused {
      color: #adadad;
    }

    .MuiInputBase-root {
      &:hover:not(.Mui-disabled):before,
      &::after {
        border-bottom: 1px solid #bcbcbc;
      }

      &:before {
        border-bottom: 1px solid #bcbcbc;
      }
    }

    .MuiInputBase-input {
      &::after {
        border-bottom: 1px solid #bcbcbc;
      }
    }

    .field-wrapper {
      display: flex;
      flex-direction: row;
      gap: 10px;
      margin-bottom: 17px;
      width: 100%;

      .MuiFormControl-root {
        width: 100%;

        &.MuiInput-root.MuiInput-underline {
          margin-top: 13.5px;
        }

        .MuiSelect-select {
          padding: 7px 15px 7px 0;
          font-weight: 400;
          font-family: "Noto Sans JP", sans-serif;
        }
      }

      &:nth-child(1) {
        align-items: flex-start;

        .input-field {
          width: 180px;

          &:nth-child(1) {
            .MuiFormControl-root {
              .MuiOutlinedInput-notchedOutline {
                border: 0;
                border-bottom: 1px solid #bcbcbc;
                border-radius: 0;
              }

              .MuiOutlinedInput-input {
                padding: 22px 0 6px 0;
              }
            }

            .MuiInputBase-root {
              padding-right: 0;
            }

            label {
              top: 8px;
              left: -13px;
            }

            button {
              padding: 0px;
              margin-top: 15px;
              margin-right: 0px;
            }
          }

          &:nth-child(2) {
            width: 200px;
            transform: translateY(4px);

            label {
              top: 0px;
            }
          }
        }
      }

      &:nth-child(2) {
        .input-field {
          &:nth-child(1) {
            width: 180px;
          }

          &:nth-child(2) {
            width: 270.15px;
          }

          &:nth-child(3) {
            width: 123.32px;

            &.building-select {
              label {
                top: 3px;
                left: -13px;
              }
            }
          }

          &:nth-child(4) {
            width: 234.13px;
          }

          &:nth-child(5) {
            width: 261.29px;
          }
        }
      }

      &:nth-child(3) {
        .input-field {
          &:nth-child(1) {
            width: 180px;

            &.building-select {
              label {
                top: 3px;
                left: -13px;
              }
            }
          }

          &:nth-child(2) {
            width: 402.06px;
          }

          &:nth-child(3) {
            width: 234.13px;
          }

          &:nth-child(4) {
            width: 262.13px;
          }
        }
      }

      &:nth-child(4) {
        .input-field {
          &:nth-child(1) {
            width: 590.16px;
          }

          &:nth-child(2) {
            width: 234.13px;
          }

          &:nth-child(3) {
            width: 262.13px;
          }
        }
      }

      &:nth-child(5) {
        .input-field {
          &:nth-child(1) {
            width: 180px;
          }

          &:nth-child(2) {
            width: 270.15px;
          }

          &:nth-child(3) {
            width: 123.32px;
          }

          &:nth-child(4) {
            width: 234.13px;
          }

          &:nth-child(5) {
            width: 262.13px;

            label {
              top: 3px;
              left: -13px;
            }

            label.Mui-focused,
            label.MuiInputLabel-shrink {
              top: 8px !important;
            }

            .MuiSelect-select {
              background-color: transparent;
            }
          }
        }
      }

      &:nth-child(6) {
        .input-field {
          width: 180px;
        }
      }
    }

    .checkbox-wrapper {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 75px;
      width: 100%;

      label {
        min-width: 100px;

        span {
          margin-left: 6px;
        }
      }

      .MuiCheckbox-root {
        &:hover {
          background-color: transparent;
        }
      }
    }
  }
`;
