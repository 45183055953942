import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { Note, Pen, Plus } from "@bms/assets";
import { ModalDelete, Table } from "@bms/components";
import { ROUTES } from "@bms/constants";
import useSubContractorList, { Props, ReceivedProps } from "./hook";
import { Styles } from "./styled";

const SubContractorListLayout: FC<Props> = ({
  rowData,
  pageSize,
  gridApi,
  gridRef,
  page,
  isOpenModalDelete,
  isSubmitting,
  columnDefs,
  total,
  isLoading,
  isFetching,
  onSortChanged,
  onFilterUpdate,
  onDeleteRecord,
  onOpenModalDelete,
  onCloseModalDelete,
  setPage,
  onGridReady,
  setPageSize,
}) => {
  const navigate = useNavigate();

  if (rowData && rowData.length > 0) {
    columnDefs = [
      ...columnDefs,
      {
        maxWidth: 100,
        field: "動作",
        floatingFilter: false,
        cellRenderer: (e: any) => {
          return (
            <div className="group-action">
              <img
                src={Note}
                onClick={() =>
                  navigate(`${e.data.id}`, {
                    state: {
                      view: true,
                    },
                  })
                }
              />
              <img src={Pen} onClick={() => navigate(`${e.data.id}`)} />
            </div>
          );
        },
      },
    ];
  }

  return (
    <Styles>
      <div className="header">
        <h3>外注業者一覧</h3>
        <button onClick={() => navigate(ROUTES.CREATE)}>
          <p>外注業者追加</p>
          <img src={Plus} alt="" />
        </button>
      </div>

      <Table
        total={total}
        page={page}
        pageSize={pageSize}
        gridRef={gridRef}
        rowData={rowData}
        onSortChanged={onSortChanged}
        onFilterUpdate={onFilterUpdate}
        columnDefs={columnDefs}
        loading={isLoading || isFetching}
        gridApi={gridApi}
        setPage={setPage}
        onOpenModalDelete={onOpenModalDelete}
        setPageSize={setPageSize}
        onGridReady={onGridReady}
      />

      <ModalDelete
        isOpen={isOpenModalDelete}
        isSubmitting={isSubmitting}
        onDeleteRecord={onDeleteRecord}
        onClose={onCloseModalDelete}
      />
    </Styles>
  );
};

export const SubContractorList: FC<ReceivedProps> = (props) => (
  <SubContractorListLayout {...useSubContractorList(props)} />
);

export default SubContractorList;
