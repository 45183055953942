import { FC } from "react";

type IProps = {
  stroke?: string;
};

const ArrowDown: FC<IProps> = ({ stroke = "#000" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="9"
      fill="none"
      viewBox="0 0 10 9"
    >
      <path fill={stroke} d="M5 9L0 0h10L5 9z"></path>
    </svg>
  );
};

export default ArrowDown;
