import styled from "styled-components";

export const Styles = styled.div`
  label {
    font-family: "Inter";
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    margin-bottom: 9px;

    span {
      color: #777777;
    }

    &.Mui-focused,
    &.MuiInputLabel-shrink {
      top: 0;
    }
  }

  .before-photo,
  .after-photo {
    &.ant-upload-wrapper,
    .ant-upload.ant-upload-select {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  .upload-wrapper {
    display: flex;
    gap: 14px;
    width: 100%;
    margin-bottom: 38px;

    .item {
      width: 100%;
      > div {
        border: 1px dashed #707070;
        width: 480px;
        height: 360px;
        display: flex;
        justify-content: center;
        align-items: center;

        .photo {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .field-wrapper {
    display: flex;
    flex-direction: column;
    gap: 29px;
    position: relative;

    .MuiOutlinedInput-notchedOutline {
      border-color: #bcbcbc;
      border-width: 1px;

      &:hover {
        border-color: #bcbcbc;
      }
    }

    .MuiFormControl-root {
      width: 100%;

      .MuiInputBase-input {
        padding: 19.3px 14px;
        font-family: "Inter";
        font-weight: 400;
        font-size: 16px;
      }
    }

    .input-field {
      width: 100%;
      margin-bottom: 38px;

      &:nth-child(3),
      &:nth-child(6) {
        .MuiInputBase-input {
          padding: 0;
        }
      }
    }
  }

  input {
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
`;
