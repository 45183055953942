import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QUERY_NAME, SERVICES } from "@bms/constants";
import axios from "@bms/libs/axios";

export const useFetchHolidays = () => {
  return useQuery(
    [QUERY_NAME.HOLIDAYS],
    () =>
      axios({
        method: "GET",
        url: `${SERVICES.HOLIDAYS}`,
      }).then((res) => res.data || []),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      retry: 1,
    }
  );
};

export const useUpdateHolidays = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ payload }: any) =>
      axios({
        method: "PUT",
        url: `${SERVICES.HOLIDAYS}/update`,
        data: payload,
      }),
    {
      onSuccess() {
        queryClient.invalidateQueries([QUERY_NAME.HOLIDAYS]);
      },
    }
  );
};
