import { FC } from "react";
import { Controller } from "react-hook-form";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import {
  FormControl,
  InputLabel,
  TextField,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Select as SelectField,
  MenuItem,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { TimePicker } from "antd";
import dayjs from "dayjs";

import {
  DATE_TIME_FORMAT,
  DEPARTMENT_IN_CHANGE,
  LOCALE_JP,
} from "@bms/constants";
import { DatePickerIcon } from "@bms/assets";
import useRequestContent, { Props, ReceivedProps } from "./hook";
import { Styles } from "./styled";
import { isEmpty } from "lodash";
import { formatDay } from "@bms/utility";

const RequestContentLayout: FC<Props> = ({
  register,
  setValue,
  getValues,
  handleOptions,
  watch,
  trigger,
  diffTime,
  setRequestOrderDate,
  setRequestStartDate,
  setRequestEndDate,
  clearErrors,
  totalWorkingHours,
  errors,
  control,
  isView,
  subcontractorOptions,
  workerOptions,
  isWorkerLoading,
}) => {
  const clearWorker = () => {
    for (let i = 0; i < 8; i++) {
      setValue(`worker_id_${i}`, "");
    }
  };
  return (
    <Styles className="request-content">
      <div className="main">
        <div className="field-wrapper">
          <div className="input-field">
            <Controller
              name="request_order_date"
              control={control}
              defaultValue={null}
              render={() => (
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={LOCALE_JP}
                >
                  <FormControl variant="outlined">
                    <InputLabel>訪問日</InputLabel>
                    <MobileDatePicker
                      {...register("request_order_date")}
                      onClose={() => {
                        setValue("request_order_date", null);
                        clearWorker();
                      }}
                      onAccept={(e) => {
                        setValue("request_order_date", e);
                        clearWorker();
                        if (!e) return;
                        setRequestOrderDate(dayjs(e).format("YYYY-MM-DD"));
                        clearErrors("request_order_date");
                      }}
                      label="訪問日"
                      disabled={isView}
                      value={getValues("request_order_date")}
                      inputFormat={
                        DATE_TIME_FORMAT.DATE_JP +
                        `(${formatDay(getValues("request_order_date"))})`
                      }
                      onChange={(e) => setValue("request_order_date", e)}
                      components={{
                        ActionBar: ({ onCancel, onAccept }: any) => (
                          <div className="MuiDialogActions-root">
                            <button onClick={onCancel}>クリア</button>
                            <button onClick={onAccept}>OK</button>
                          </div>
                        ),
                      }}
                      renderInput={(params: any) => (
                        <OutlinedInput
                          {...params}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton edge="end">
                                <img src={DatePickerIcon} />
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      )}
                    />
                  </FormControl>
                </LocalizationProvider>
              )}
            />
            {errors?.request_order_date && (
              <p className="error">{errors.request_order_date?.message}</p>
            )}
          </div>

          <div
            className="input-field"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <span>+</span>
              <Controller
                name="return_order_date"
                control={control}
                defaultValue={null}
                render={() => (
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={LOCALE_JP}
                  >
                    <FormControl variant="outlined">
                      <InputLabel>再訪問日</InputLabel>
                      <MobileDatePicker
                        {...register("return_order_date")}
                        onClose={() => setValue("return_order_date", null)}
                        onAccept={(e) => setValue("return_order_date", e)}
                        label="再訪問日"
                        disabled={!watch("request_content_id") || isView}
                        value={getValues("return_order_date")}
                        inputFormat={
                          DATE_TIME_FORMAT.DATE_JP +
                          `(${formatDay(getValues("return_order_date"))})`
                        }
                        onChange={(e) => setValue("return_order_date", e)}
                        components={{
                          ActionBar: ({ onCancel, onAccept }: any) => (
                            <div className="MuiDialogActions-root">
                              <button onClick={onCancel}>クリア</button>
                              <button onClick={onAccept}>OK</button>
                            </div>
                          ),
                        }}
                        renderInput={(params: any) => (
                          <OutlinedInput
                            {...params}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton edge="end">
                                  <img src={DatePickerIcon} />
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        )}
                      />
                    </FormControl>
                  </LocalizationProvider>
                )}
              />
            </div>
            {errors?.return_order_date && (
              <p
                className="error"
                style={{
                  paddingLeft: "12px",
                }}
              >
                {errors.return_order_date?.message}
              </p>
            )}
          </div>
        </div>
      </div>

      <h4 className="title">作業予定時間</h4>
      <div className="main">
        <div className="field-wrapper">
          <div>
            <div className="input-field">
              <InputLabel>開始</InputLabel>
              <Controller
                name="start_working_hour"
                control={control}
                defaultValue={null}
                render={(props) => (
                  <TimePicker
                    {...props}
                    disabled={isView}
                    clearIcon={<></>}
                    name="start_working_hour"
                    format={DATE_TIME_FORMAT.TIME}
                    className={"timepicker-start"}
                    value={
                      getValues("start_working_hour")
                        ? dayjs(
                            getValues("start_working_hour"),
                            DATE_TIME_FORMAT.TIME
                          )
                        : null
                    }
                    placeholder=""
                    suffixIcon={<></>}
                    showNow={false}
                    onChange={(_, timeString: any) => {
                      setValue("start_working_hour", timeString);
                      trigger("start_working_hour");
                      setRequestStartDate(timeString);
                    }}
                  />
                )}
              />
            </div>
            {errors?.start_working_hour && (
              <p className="error">{errors.start_working_hour?.message}</p>
            )}
          </div>
          <div>
            <div className="input-field">
              <InputLabel>終了</InputLabel>
              <Controller
                name="end_working_hour"
                control={control}
                defaultValue={null}
                render={(props) => (
                  <TimePicker
                    {...props}
                    disabled={isView}
                    clearIcon={<></>}
                    name="end_working_hour"
                    format={DATE_TIME_FORMAT.TIME}
                    className={"timepicker-end"}
                    value={
                      getValues("end_working_hour")
                        ? dayjs(
                            getValues("end_working_hour"),
                            DATE_TIME_FORMAT.TIME
                          )
                        : null
                    }
                    placeholder=""
                    suffixIcon={<></>}
                    showNow={false}
                    onChange={(_, timeString: any) => {
                      setValue("end_working_hour", timeString);
                      trigger("end_working_hour");
                      setRequestEndDate(timeString);
                    }}
                  />
                )}
              />
            </div>
            {errors?.end_working_hour && (
              <p className="error">{errors.end_working_hour?.message}</p>
            )}
          </div>
        </div>
        {Array.from(Array(8).keys()).map((item, index) => {
          const department_in_charge =
            watch(`department_in_charge_${item}`) ||
            getValues(`department_in_charge_${item}`);
          const work_id =
            watch(`worker_id_${item}`) || getValues(`worker_id_${item}`);
          if (
            department_in_charge &&
            work_id &&
            getValues("end_working_hour") &&
            getValues("start_working_hour")
          ) {
            const existOption = workerOptions.filter((idx) => {
              if (
                idx.department_in_charge == department_in_charge &&
                parseInt(idx.value) == parseInt(work_id)
              ) {
                return idx;
              }
            });

            if (
              !isWorkerLoading &&
              existOption != undefined &&
              existOption.length == 0
            ) {
              return (
                <p className="error" key={index}>
                  サービス追加できません。作業予定時間か作業員を変更してください。
                </p>
              );
            }
          }
        })}
      </div>

      <h4 className="title">担当作業員</h4>
      <div className="main">
        <div
          className="field-wrapper"
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: "6px 0",
          }}
        >
          {Array.from(Array(8).keys()).map((item) => (
            <div
              key={item}
              style={{
                width: "47%",
                display: "flex",
                alignItems: "center",
                gap: "6px",
              }}
            >
              <span className="index">{item + 1}</span>
              <div
                className="item-wrapper"
                style={{
                  marginRight: "6px",
                }}
              >
                <FormControl variant="outlined">
                  <InputLabel>部署名</InputLabel>
                  <SelectField
                    sx={{
                      "& .MuiSelect-icon": {
                        display: watch(`department_in_charge_${item}`)
                          ? "none"
                          : "",
                      },
                    }}
                    {...(!isView && {
                      endAdornment: (
                        <ClearIcon
                          sx={{
                            visibility: watch(`department_in_charge_${item}`)
                              ? "visible"
                              : "hidden",
                          }}
                          className="clearable"
                          onClick={() => {
                            setValue(`department_in_charge_${item}`, "");
                            setValue(`worker_id_${item}`, "");
                          }}
                        />
                      ),
                    })}
                    onChange={(e) => {
                      setValue(`department_in_charge_${item}`, e.target.value);
                    }}
                    value={watch(`department_in_charge_${item}`) || ""}
                    // {...register(`department_in_charge_${item}`)}
                    name={`department_in_charge_${item}`}
                    variant="standard"
                    defaultValue={getValues(`department_in_charge_${item}`)}
                    label="部署名"
                    disabled={isView}
                  >
                    <MenuItem value="third_houses">
                      {DEPARTMENT_IN_CHANGE["third_houses"]}
                    </MenuItem>
                    <MenuItem value="oyakudachiya">
                      {DEPARTMENT_IN_CHANGE["oyakudachiya"]}
                    </MenuItem>
                    <MenuItem value="consignment" disabled>
                      {DEPARTMENT_IN_CHANGE["consignment"]}
                    </MenuItem>
                  </SelectField>
                </FormControl>
              </div>
              <div className="item-wrapper">
                <FormControl variant="outlined">
                  <InputLabel>作業員名</InputLabel>
                  <SelectField
                    sx={{
                      "& .MuiSelect-icon": {
                        display: watch(`worker_id_${item}`) ? "none" : "",
                      },
                    }}
                    {...(!isView && {
                      endAdornment: (
                        <ClearIcon
                          sx={{
                            visibility: watch(`worker_id_${item}`)
                              ? "visible"
                              : "hidden",
                          }}
                          className="clearable"
                          onClick={() => setValue(`worker_id_${item}`, "")}
                        />
                      ),
                    })}
                    onChange={(e) => {
                      setValue(`worker_id_${item}`, e.target.value);
                    }}
                    // {...register(`worker_id_${item}`)}
                    name={`worker_id_${item}`}
                    value={watch(`worker_id_${item}`) || ""}
                    variant="standard"
                    defaultValue={getValues(`worker_id_${item}`)}
                    label="作業員名"
                    disabled={
                      isView ||
                      // !watch("request_order_date") ||
                      !watch(`department_in_charge_${item}`)
                    }
                  >
                    {handleOptions(
                      watch(`department_in_charge_${item}`) ||
                        getValues(`department_in_charge_${item}`)
                    ).map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </SelectField>
                </FormControl>
              </div>
            </div>
          ))}
        </div>

        <div className="field-wrapper">
          {Array.from(Array(4).keys()).map((item) => (
            <div key={item} className="item-wrapper">
              <FormControl variant="outlined">
                <InputLabel>外注</InputLabel>
                <SelectField
                  sx={{
                    "& .MuiSelect-icon": {
                      display: watch(`subcontractor_id_${item}`) ? "none" : "",
                    },
                  }}
                  {...(!isView && {
                    endAdornment: (
                      <ClearIcon
                        sx={{
                          visibility: watch(`subcontractor_id_${item}`)
                            ? "visible"
                            : "hidden",
                        }}
                        className="clearable"
                        onClick={() => {
                          setValue(`subcontractor_id_${item}`, "");
                        }}
                      />
                    ),
                  })}
                  displayEmpty
                  onChange={(e) => {
                    setValue(`subcontractor_id_${item}`, e.target.value);
                  }}
                  // {...register(`subcontractor_id_${item}`)}
                  value={watch(`subcontractor_id_${item}`) || ""}
                  name={`subcontractor_id_${item}`}
                  variant="standard"
                  defaultValue={getValues(`subcontractor_id_${item}`)}
                  label="外注"
                  disabled={isView}
                >
                  {subcontractorOptions.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </SelectField>
              </FormControl>
            </div>
          ))}
        </div>
      </div>

      <div className="main">
        <div className="input-field">
          <InputLabel className="title">
            相談依頼内容 <span>(報告書に記載)</span>
          </InputLabel>
          <TextField
            className="edit-content-item"
            // inputRef={(input) => input && input.focus()}
            onFocus={(e) => {
              const textarea = e.currentTarget;
              e.currentTarget.setSelectionRange(
                e.currentTarget.value.length,
                e.currentTarget.value.length
              );
              if (textarea.selectionStart == textarea.selectionEnd) {
                textarea.scrollTop = textarea.scrollHeight + 10;
              }
            }}
            multiline
            rows={4}
            {...register("content")}
            name="content"
            // autoFocus
            disabled={isView}
            placeholder=""
          />
          {errors?.content && (
            <p className="error">{errors.content?.message}</p>
          )}
        </div>
      </div>

      <div className="main">
        <div className="input-field">
          <InputLabel className="title">
            工事件名 <span>(報告書／見積書／請求書に記載)</span>
          </InputLabel>
          <TextField
            {...register("contruction_subject")}
            name="contruction_subject"
            placeholder=""
            disabled={isView}
          />
          {errors?.contruction_subject && (
            <p className="error">{errors.contruction_subject?.message}</p>
          )}
        </div>
      </div>

      <div className="main">
        {!isEmpty(getValues("return_date_histories")) ? (
          <>
            <div className="return-order-date">
              <div className="field-wrapper">
                <div className="input-field">
                  <TextField
                    {...(getValues("actual_start_time") &&
                      getValues("actual_end_time") && {
                        value: `${
                          diffTime(
                            getValues("actual_start_time"),
                            getValues("actual_end_time")
                          ).hours
                        }時間${
                          diffTime(
                            getValues("actual_start_time"),
                            getValues("actual_end_time")
                          ).minutes
                        }分`,
                      })}
                    placeholder="作業時間"
                    disabled
                  />
                </div>
              </div>
              {!isEmpty(getValues("return_date_histories")) ? (
                getValues("return_date_histories")
                  ?.filter(
                    (item: any) =>
                      item?.actual_start_time && item?.actual_end_time
                  )
                  ?.map((item: any, index: number) => (
                    <div className="field-wrapper" key={index}>
                      <div className="input-field">
                        <TextField
                          value={`${
                            diffTime(
                              item?.actual_start_time,
                              item?.actual_end_time
                            ).hours
                          }時間${
                            diffTime(
                              item?.actual_start_time,
                              item?.actual_end_time
                            ).minutes
                          }分`}
                          placeholder="作業時間"
                          disabled
                        />
                      </div>
                    </div>
                  ))
              ) : (
                <div className="field-wrapper">
                  <div className="input-field">
                    <TextField placeholder="作業時間" disabled />
                  </div>
                </div>
              )}
            </div>
            <div className="field-wrapper">
              <div className="input-field">
                <TextField
                  value={totalWorkingHours}
                  placeholder="合計時間"
                  disabled
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="return-order-date">
              <div className="field-wrapper">
                <div className="input-field">
                  <TextField
                    {...(getValues("actual_start_time") &&
                      getValues("actual_end_time") && {
                        value: `${
                          diffTime(
                            getValues("actual_start_time"),
                            getValues("actual_end_time")
                          ).hours
                        }時間${
                          diffTime(
                            getValues("actual_start_time"),
                            getValues("actual_end_time")
                          ).minutes
                        }分`,
                      })}
                    placeholder="作業時間"
                    disabled
                  />
                </div>
              </div>
              <div className="field-wrapper">
                <div className="input-field">
                  <TextField placeholder="作業時間" disabled />
                </div>
              </div>
            </div>
            <div className="field-wrapper">
              <div className="input-field">
                <TextField
                  {...(getValues("actual_start_time") &&
                    getValues("actual_end_time") && {
                      value: `${
                        diffTime(
                          getValues("actual_start_time"),
                          getValues("actual_end_time")
                        ).hours
                      }時間${
                        diffTime(
                          getValues("actual_start_time"),
                          getValues("actual_end_time")
                        ).minutes
                      }分`,
                    })}
                  placeholder="合計時間"
                  disabled
                />
              </div>
            </div>
          </>
        )}
      </div>
    </Styles>
  );
};

const RequestContent: FC<ReceivedProps> = (props) => (
  <RequestContentLayout {...useRequestContent(props)} />
);

export default RequestContent;
