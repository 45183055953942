import { FC } from "react";

type IProps = {
  stroke?: string;
};

const ArrowRight: FC<IProps> = ({ stroke = "#7D8185" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="10"
      fill="none"
      viewBox="0 0 6 10"
    >
      <path
        fill={stroke}
        d="M3.898 5.3L.378 8.845 1.428 9.9 6 5.3 1.429.7.378 1.755 3.898 5.3z"
      ></path>
    </svg>
  );
};

export default ArrowRight;
