import { FC, memo } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Header, SideBar } from "@bms/components";
import moment from "moment";
import { Styles } from "./styled";
import { BackIcon } from "@bms/assets";
import { QUERY_NAME, ROUTES } from "@bms/constants";
import { useScreenWidth } from "@bms/hooks";
import { useSelector } from "react-redux";
import { userSelector } from "@bms/features/auth/selector";
import { useQueryClient } from "@tanstack/react-query";

const CommonLayout: FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const user = useSelector(userSelector);
  const isMobile = useScreenWidth();
  const queryClient = useQueryClient();

  return (
    <Styles>
      {!isMobile ? (
        <div className="web-layout">
          <Header />
          <SideBar />
          <div className="container">
            <div className="main">
              <Outlet />
            </div>
          </div>
        </div>
      ) : (
        <div className="mobile-layout">
          <div className="container">
            <div className="header-top">
              <h3>{moment().format("YYYY.MM.DD")}</h3>
              <div className="header-control">
                {pathname !== ROUTES.DASHBOARD && (
                  <button
                    className="btn btn-back"
                    onClick={() => {
                      queryClient.invalidateQueries([QUERY_NAME.SERVICE_CARDS]);
                      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                      navigate(-1);
                    }}
                  >
                    <img src={BackIcon} alt="" />
                    <p>戻る</p>
                  </button>
                )}
                <h4>{user?.worker?.name || ""}</h4>
              </div>
            </div>
            <div className="main">
              <Outlet />
            </div>
          </div>
        </div>
      )}
    </Styles>
  );
};

export default memo(CommonLayout);
