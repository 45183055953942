import styled from "styled-components";

export const Styles = styled.div`
  padding-bottom: 40px;
  .flex {
    display: flex;
  }
  .btn-search {
    width: 120px;
    background-color: var(--main-color);
    color: #ffffff;
    padding: 6.5px 20px;
    border: none;
    border-radius: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 16px;
    font-weight: 600;
    font-family: "Inter";
  }

  .btn-print {
    width: 120px;
    background-color: #df691d;
    color: #ffffff;
    padding: 6.5px 20px;
    border: none;
    border-radius: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 16px;
    font-weight: 600;
    font-family: "Inter";
  }

  .btn-export {
    width: 160px;
    background-color: #df691d;
    color: #ffffff;
    padding: 6.5px 20px;
    border: none;
    border-radius: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 16px;
    font-weight: 600;
    font-family: "Inter";
  }

  .header {
    display: flex;
    flex-direction: column;
    margin-bottom: 14px;

    h3 {
      color: var(--main-color);
      font-size: 28px;
      font-family: "Inter";
      margin-bottom: 43px;
    }

    h5 {
      font-family: "Inter";
      font-weight: 600;
      font-size: 20px;
      color: var(--main-color);
    }
  }

  .footer {
    display: flex;
    align-items: center;
    gap: 70px;
    margin-top: 30px;

    .box {
      gap: 20px;
      display: flex;
      align-items: center;

      &:last-child {
        .MuiFormLabel-root.MuiInputLabel-root {
          width: 170px;
        }
      }

      .MuiFormLabel-root.MuiInputLabel-root {
        width: 170px;
        font-family: "Inter";
        font-weight: 600;
        font-size: 20px;
        color: #174a84;
        margin-top: -60px;
      }

      .form-search {
        max-width: fit-content;
      }

      .form-search-container {
        padding: 17px 32px;

        .input-field .MuiFormGroup-root {
          gap: 0;

          label {
            &:not(:last-child) {
              margin-right: 35px;
            }
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  .order-information-date {
    .order-date {
      transform: translateY(-8px);
    }
  }

  .return-order-date {
    display: flex;
    /* transform: translate(-18px, -15px); */
    transform: translateY(-41px);

    span {
      color: #ff0000;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      border: 1px solid #ff0000;
      margin-right: 3px;
      line-height: 17px;
      height: 17px;
      width: 15px;
      transform: translateY(18px);
    }
  }

  .table-header {
    font-family: "Inter";
    font-weight: 400;
    font-size: 28px;
    color: #174a84;
    margin-top: 70px;
    margin-bottom: 26px;
  }

  .no-data {
    margin-top: 33px;
    font-family: "Inter";
    font-weight: 400;
    font-size: 20px;
    color: #000000;
  }

  .form-search {
    background: #efefef;
    border-radius: 10px;
    max-width: 1050px;
    width: 100%;

    &-container {
      padding: 32px;
      display: flex;
      flex-direction: column;
      gap: 40px;

      .input-field {
        display: flex;
        align-items: center;
        width: 100%;

        .checkbox-wrapper {
          display: flex;
          align-items: center;
          flex-direction: row;
          gap: 75px;
          width: 100%;

          label {
            min-width: 100px;

            span {
              margin-left: 6px;
            }
          }

          .MuiCheckbox-root {
            &:hover {
              background-color: transparent;
            }
          }
        }

        .date-range-picker {
          display: flex;
          align-items: center;
          gap: 33px;

          span {
            font-family: "Inter";
            font-weight: 400;
            font-size: 16px;
            color: #adadad;
          }
        }

        .MuiFormLabel-root {
          font-family: "Inter";
          font-weight: 600;
          font-size: 20px;
          color: var(--main-color);
          min-width: 145px;
          margin-right: 60px;
        }

        .MuiFormControl-root {
          max-width: 550px;
          width: 100%;
        }

        .MuiInputBase-root {
          background-color: #ffffff;
          /* border: 1px solid #cdcdcd; */
          border-radius: 8px;
          height: 52px;
          width: 100%;
        }

        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
          border-color: #cdcdcd;
          border-width: 1px;
        }

        .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
          border-color: #cdcdcd;
        }

        .MuiFormGroup-root {
          display: flex;
          flex-direction: row;
          gap: 35px;
        }
      }
    }

    input {
      font-weight: 400;
      font-size: 15px;
      font-family: "Lato";

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }

  .ant-picker-input {
    input {
      font-size: 15px;
      font-family: "Lato";
      &::placeholder {
        font-family: "Noto Sans JP", sans-serif;
        color: rgb(185, 185, 185);
      }
    }
  }

  .ag-header-viewport {
    border-top: 1px solid #bcbcbc;
  }

  .ag-root-wrapper {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .ag-cell {
    // display: flex;
  }

  .return-order-date {
    display: flex;
    /* transform: translate(-18px, -15px); */
    transform: translateY(-15px);

    span {
      color: #ff0000;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      border: 1px solid #ff0000;
      margin-right: 3px;
      line-height: 17px;
      height: 17px;
      width: 15px;
      transform: translateY(18px);
    }
  }

  .order-information-date {
    .order-date {
      transform: translateY(16px);
    }
  }

  .tag {
    width: 66px;
    height: 31px;
    line-height: 20px;
    padding: 5px 0px;
    text-align: center;
    color: #ffffff;
    font-size: 15px;
    font-family: "Inter";

    &.unclaimed,
    &.not_compatible,
    &.not_payment {
      background-color: #ff0000;
    }

    &.billed,
    &.resolved,
    &.paid {
      background-color: #174a84;
    }
  }

  .ant-picker-active-bar {
    display: none;
  }
  .ant-picker-input {
    input {
      font-weight: 400;
      color: black;
    }
  }
  .ant-picker-borderless:after {
    content: "";
    background: #b9b9b9;
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: 6px;
  }
`;
