import { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { get, isEmpty, pick } from "lodash";

import { useFetchScheduleServiceCard } from "@bms/hooks";
import { useQueryClient } from "@tanstack/react-query";
import { QUERY_NAME } from "@bms/constants";

export type ReceivedProps = Record<string, any>;

const useSchedule = (props: ReceivedProps) => {
  const [currentDate, setCurrentDate] = useState<Dayjs>(dayjs());
  const [workerColumn, setWorkerColumn] = useState<any[]>([]);
  const [schedule, setScheduleData] = useState<any[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState<any>();

  const queryClient = useQueryClient();

  const { data, isSuccess, isLoading, isFetching } =
    useFetchScheduleServiceCard(
      dayjs(currentDate).format("YYYY/MM/DD"),
      undefined
    );

  const getDate = (type: string) => {
    if (type === "previous") {
      setCurrentDate(dayjs(currentDate).add(-1, "day"));
    }

    if (type === "next") {
      setCurrentDate(dayjs(currentDate).add(1, "day"));
    }

    setWorkerColumn([]);
    setScheduleData([]);
    queryClient.invalidateQueries([QUERY_NAME.SCHEDULES]);
  };

  const isReturnOrderDate = (data: any) => {
    const orderReturnDateLength = data?.return_date_histories?.length;
    const currentDay = dayjs(currentDate).format("YYYY-MM-DD");
    const getReturnOrderDate = get(
      data,
      `return_date_histories.[${orderReturnDateLength - 1}]`
    );
    const isConditionalReturnOrderDate = getReturnOrderDate?.return_order_date
      ? dayjs(
          dayjs(getReturnOrderDate?.return_order_date).format("YYYY-MM-DD")
        ).isSame(dayjs(currentDay))
      : null;

    return (
      orderReturnDateLength > 0 &&
      isConditionalReturnOrderDate &&
      !getReturnOrderDate?.actual_start_time
    );
  };

  const handleStartTime = (data: any) => {
    const orderReturnDateLength = data?.return_date_histories?.filter(
      (item: any) => item?.actual_start_time || item?.start_time
    )?.length;
    const currentDay = dayjs(currentDate).format("YYYY-MM-DD");
    const getReturnOrderDate = get(
      data,
      `return_date_histories.[${orderReturnDateLength - 1}]`
    );
    const isConditionalReturnOrderDate = getReturnOrderDate?.return_order_date
      ? dayjs(
          dayjs(getReturnOrderDate?.return_order_date).format("YYYY-MM-DD")
        ).isSame(dayjs(currentDay))
      : null;

    if (orderReturnDateLength > 0 && isConditionalReturnOrderDate) {
      if (!getReturnOrderDate?.actual_start_time) {
        return new Date(getReturnOrderDate.start_time);
      } else {
        return new Date(getReturnOrderDate.actual_start_time);
      }
    }

    const result =
      data.actual_start_time &&
      ["finished", "in_progress"].includes(data?.status);
    return result
      ? new Date(data.actual_start_time)
      : data?.start_time
      ? new Date(dayjs(data?.start_time).toISOString())
      : null;
  };

  const handleEndTime = (data: any) => {
    const orderReturnDateLength = data?.return_date_histories?.filter(
      (item: any) => item?.actual_end_time || item?.end_time
    )?.length;
    const currentDay = dayjs(currentDate).format("YYYY-MM-DD");
    const getReturnOrderDate = get(
      data,
      `return_date_histories.[${orderReturnDateLength - 1}]`
    );
    const isConditionalReturnOrderDate = getReturnOrderDate?.return_order_date
      ? dayjs(
          dayjs(getReturnOrderDate?.return_order_date).format("YYYY-MM-DD")
        ).isSame(dayjs(currentDay))
      : null;

    if (orderReturnDateLength > 0 && isConditionalReturnOrderDate) {
      if (
        !getReturnOrderDate?.actual_start_time ||
        (getReturnOrderDate?.actual_start_time &&
          !getReturnOrderDate?.actual_end_time)
      ) {
        return new Date(dayjs(getReturnOrderDate?.end_time).toISOString());
      } else {
        return new Date(
          dayjs(getReturnOrderDate?.actual_end_time).toISOString()
        );
      }
    }

    const result =
      data.actual_end_time &&
      ["finished", "in_progress"].includes(data?.status);
    return result
      ? new Date(data.actual_end_time)
      : data?.end_time
      ? new Date(dayjs(data?.end_time).toISOString())
      : null;
  };

  useEffect(() => {
    if (isSuccess) {
      const getData = data.data;

      const parseRequestContentData = getData.map((item: any) => ({
        ...pick(item, ["worker_id", "request_contents"]),
        worker_name: item.name,
      }));

      if (isEmpty(parseRequestContentData)) return;

      const fn = parseRequestContentData.flatMap(
        ({ request_contents, ...rest }: any) =>
          request_contents.map((o: any) => ({
            ...rest,
            ...o,
          }))
      );

      setWorkerColumn(
        getData?.map((item: any) => ({
          id: item?.worker_id,
          text: item?.name,
        }))
      );

      setScheduleData(
        fn
          ?.filter((item: any) => {
            return item;
            // const currentDay = dayjs().format("YYYY-MM-DD");

            // if (item?.order_date && isEmpty(item?.return_date_histories)) {
            //   return dayjs(dayjs(item?.order_date).format("YYYY-MM-DD")).isSame(
            //     dayjs(currentDay)
            //   );
            // } else if (
            //   item?.order_date &&
            //   !isEmpty(item?.return_date_histories)
            // ) {
            //   const orderReturnDateLength = item?.return_date_histories?.length;
            //   const getReturnOrderDate = get(
            //     item,
            //     `return_date_histories.[${orderReturnDateLength - 1}]`
            //   );
            //   return dayjs(
            //     dayjs(getReturnOrderDate?.return_order_dates).format(
            //       "YYYY-MM-DD"
            //     )
            //   ).isSame(dayjs(currentDay));
            // } else {
            //   return false;
            // }
          })
          ?.map((item: any) => {
            return {
              ...pick(item, [
                "actual_start_time",
                "actual_end_time",
                "service_card_id",
                "id",
              ]),
              status: isReturnOrderDate(item)
                ? "in_schedule"
                : item.status || "in_progress",
              text:
                item?.content +
                `\n${item?.order_information?.building_name}\n${item?.order_information?.room_number}\n${item?.order_information?.resident_name}`,
              workerId: item?.worker_id,
              startDate: handleStartTime(item),
              endDate: handleEndTime(item),
              content: item?.content,
              building_name: item?.order_information?.building_name,
              resident_room_number: item?.order_information?.room_number,
              resident_name: item?.order_information?.resident_name,
              color:
                item?.status === "finished"
                  ? "#55606A"
                  : item?.status === "in_progress"
                  ? "#DF1D3B"
                  : "#DF691D",
            };
          })
      );
    }
  }, [data, isSuccess, currentDate]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [isSuccess, isLoading, isFetching]);

  return {
    ...props,
    isLoading,
    isFetching,
    currentDate,
    workerColumn,
    schedule,
    open,
    value,
    queryClient,
    setWorkerColumn,
    setScheduleData,
    setValue,
    setCurrentDate,
    setOpen,
    getDate,
  };
};

export type Props = ReturnType<typeof useSchedule>;

export default useSchedule;
