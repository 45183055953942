import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useFieldArray, useForm } from "react-hook-form";
import moment from "moment";
import { get, omit } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import { notification } from "antd";
import { useQueryClient } from "@tanstack/react-query";

import { SelectOptionsType, Workers } from "@bms/types";
import { DATE_TIME_FORMAT, QUERY_NAME } from "@bms/constants";
import {
  useFetchOneWorkerShift,
  useFetchWorkers,
  useSubmitShift,
} from "@bms/hooks";
import { validation } from "./schema";
import { openNotification } from "@bms/components";

export type ReceivedProps = Record<string, any>;

const useShiftControl = (props: ReceivedProps) => {
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const isView = state?.view || false;
  const [api, contextHolder] = notification.useNotification();
  const queryClient = useQueryClient();

  const {
    control,
    formState: { errors },
    setValue,
    reset,
    getValues,
    register,
    handleSubmit,
    trigger,
  } = useForm({
    resolver: yupResolver(validation()),
    defaultValues: {
      worker_id: state?.worker?.value || null,
      start_working_hour: null,
      end_working_hour: null,
      holiday_attributes: Array.from(Array(4).keys()).map(() => ({
        day_of_week_kind: null,
        period_kind: null,
      })),
    },
  });

  const { fields } = useFieldArray({
    control: control,
    name: "holiday_attributes",
  });

  const [initial, setInitial] = useState<boolean>(false);
  const [workerId, setWorkerId] = useState<string | null>(null);
  const [isOpenModalDelete, setIsOpenModalDelete] = useState<boolean>(false);
  const [workerOptions, setWorkerOptions] = useState<SelectOptionsType[]>([]);
  const [workersSelect, setWorkersSelect] = useState<SelectOptionsType[]>([]);
  const [pagination, setPagination] = useState<{
    page: number;
    total: number;
    filterName: string | null;
  }>({
    page: 1,
    total: 0,
    filterName: null,
  });

  const {
    data: workerShiftData,
    isSuccess,
    isLoading,
  } = useFetchOneWorkerShift(id);
  const {
    data: workerData,
    isLoading: isLoadingWorker,
    isSuccess: isWorkerSuccess,
    refetch: refetchWorker,
  } = useFetchWorkers(pagination.page, 100000, { filter_by_shifts: true });
  const { mutate, isLoading: isSubmitting } = useSubmitShift();

  const onOpenModalDelete = (id: string) => {
    setWorkerId(id);
    setIsOpenModalDelete(true);
  };
  const onCloseModalDelete = () => setIsOpenModalDelete(false);

  const onDeleteRecord = async () => {
    const result = workersSelect.filter((item) => item.value !== workerId);
    setWorkersSelect(result);
    setIsOpenModalDelete(false);
  };

  const onSubmit = (values: Record<string, any>) => {
    const payload = {
      ...values,
      worker_ids: workersSelect.map((item) => +item.value),
      start_of_holiday: values.start_of_holiday
        ? moment(new Date(values.start_of_holiday)).format(
            DATE_TIME_FORMAT.PAYLOAD
          )
        : null,
      end_of_holiday: values.end_of_holiday
        ? moment(new Date(values.end_of_holiday)).format(
            DATE_TIME_FORMAT.PAYLOAD
          )
        : null,
      holiday_attributes: values.holiday_attributes.filter(
        (item: any) => item.day_of_week_kind && item.period_kind
      ),
    };

    mutate(
      {
        payload: id ? omit(payload, ["holidays", "workers"]) : payload,
        shiftId: id,
      },
      {
        onSuccess() {
          if (!id) {
            reset();
            setWorkersSelect([]);
          }
          openNotification();
          navigate(-1);
          queryClient.invalidateQueries([QUERY_NAME.SHIFT_SCHEDULES]);
        },
        onError(error: any) {
          api["error"]({
            message: { error }?.error?.response?.data?.error || "Failed",
          });
        },
      }
    );
  };

  useEffect(() => {
    if (state?.worker?.value) {
      setWorkersSelect([state?.worker]);
    }
  }, [state?.worker]);

  useEffect(() => {
    if (!id) {
      setInitial(true);
    }
    if (isSuccess) {
      setInitial(true);
      reset({
        ...workerShiftData,
        worker_id: workerShiftData.worker?.id?.toString() || null,
        holiday_attributes: Array.from(Array(4).keys()).map((item) => {
          const field = get(workerShiftData.holidays, `[${item}]`);
          return {
            ...(field?.id && { id: field.id }),
            ...(field?.apply_type && { apply_type: field.apply_type }),
            day_of_week_kind: field?.day_of_week_kind || null,
            period_kind: field?.period_kind || null,
          };
        }),
      });
      setWorkersSelect([
        {
          value: workerShiftData.worker?.id?.toString(),
          label: workerShiftData.worker?.name,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, reset, workerShiftData]);

  useEffect(() => {
    if (isWorkerSuccess) {
      setPagination({
        ...pagination,
        total: workerData.meta.total_records,
      });
      setWorkerOptions([
        ...(workerData.workers as Workers[])
          .filter((i: any) => !i.shift)
          .map((item) => ({
            value: item.id.toString(),
            label: item.name,
          })),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.page, isWorkerSuccess, workerData]);

  useEffect(() => {
    refetchWorker();
  }, []);

  return {
    ...props,
    id,
    isOpenModalDelete,
    workerOptions,
    workersSelect,
    pagination,
    control,
    fields,
    errors,
    isSubmitting,
    isView,
    contextHolder,
    isLoading,
    isLoadingWorker,
    initial,
    api,
    workerShiftData,
    setValue,
    getValues,
    register,
    handleSubmit,
    setPagination,
    setWorkersSelect,
    onDeleteRecord,
    onOpenModalDelete,
    onCloseModalDelete,
    onSubmit,
    trigger,
  };
};

export type Props = ReturnType<typeof useShiftControl>;

export default useShiftControl;
