import styled from "styled-components";

export const Styles = styled.div`
  padding: 0 16px;
  padding-bottom: 40px;

  .work-cost {
    max-width: 352px;
    margin: 0 auto;
  }

  .btn-delete {
    width: 100%;
    margin-right: 0;
    margin-left: auto;
    justify-content: flex-end;
  }

  label {
    text-align: left;
    font-family: "Inter";
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    margin-bottom: 10px;
  }

  .MuiFormControl-root {
    width: 100%;

    .MuiInputBase-root {
      width: 100%;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: #bcbcbc !important;
    border-width: 1px !important;

    &:hover {
      border-color: #bcbcbc;
    }
  }

  .input-field {
    margin-bottom: 23px;
  }

  .btn-add {
    margin: 23px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    background-color: #347bc3;
    max-width: 343px;
    height: 85px;
    line-height: 85px;
    width: 100%;
    border: 0;
    border-radius: 4px;

    p {
      font-family: "Inter";
      font-weight: 400;
      font-size: 25px;
      color: #ffffff;
    }

    img {
      width: 26px;
      height: 26px;
    }
  }

  .btn-keep {
    /* position: fixed; */
    /* bottom: 0; */
    /* left: 0; */
    /* right: 0; */
    margin: 30px auto 0;
    background-color: #b23131;
    padding: 0 23px;
    gap: 8px;
    max-width: 343px;
    height: 85px;
    line-height: 85px;
    width: 100%;
    border: 0;
    border-radius: 4px;

    p {
      font-family: "Inter";
      font-weight: 400;
      font-size: 25px;
      color: #ffffff;
      text-align: center;
    }
  }

  .error {
    text-align: left;
    margin-top: 0;
  }
`;
